<script setup lang="ts">
import { ref } from 'vue'

import { formatTimePart } from '@collector/shared-utils'
import {
  SoccerEventDetailId,
  SoccerEventStatusId,
} from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { useEvent } from '@desktop/views/Relation/composables'
import { getClockTime } from '@desktop/views/Relation/Sports/components/Soccer/configuration'
import { useEventClock } from '@desktop/views/Relation/Sports/composables'

import Button from './Button.vue'
import EditForm from './EditForm.vue'

// composables
const {
  eventTimeFormatted,
  eventTime,
  startClock,
  stopClock,
  resetClockTime,
  clockRunning,
} = useEventClock()

const { event, eventDetailsMap } = useEvent()

// state
const shouldEdit = ref(false)
const newMinute = ref('')
const newSecond = ref('')

// methods
function editTime(): void {
  shouldEdit.value = true

  newMinute.value = formatTimePart(eventTime.value.minute)
  newSecond.value = formatTimePart(eventTime.value.second)
}

function updateEventClock(minute: number, second: number): number | null {
  const periodLength = Number(
    eventDetailsMap.value[SoccerEventDetailId.PeriodLength].value,
  )
  const extraPeriodLength = Number(
    eventDetailsMap.value[SoccerEventDetailId.ExtraPeriodLength].value,
  )

  const clockTime = getClockTime(
    event.value.status_id as SoccerEventStatusId,
    minute,
    second,
    periodLength,
    extraPeriodLength,
  )

  if (clockTime !== null) {
    sportsApiClient.Events.patch(event.value.id, {
      event: {
        clock_time: clockTime,
        clock_status: clockRunning.value,
      },
    })
  }

  return clockTime
}

function updateEditedTime(): void {
  const clockTime = updateEventClock(
    Number(newMinute.value),
    Number(newSecond.value),
  )

  if (clockTime !== null) {
    resetClockTime(clockTime)
  }

  shouldEdit.value = false
}

function toggleClock(): void {
  if (clockRunning.value) stopClock()
  else startClock()

  updateEventClock(eventTime.value.minute, eventTime.value.second)
}
</script>

<template>
  <div class="flex max-w-36 justify-center gap-x-2">
    <Button
      v-if="shouldEdit"
      icon="x"
      @click="shouldEdit = false"
    />
    <Button
      v-else
      icon="pencil"
      iconSize="lg"
      @click="editTime"
    />

    <div class="h-8 w-full">
      <EditForm
        v-if="shouldEdit"
        v-model:minute="newMinute"
        v-model:second="newSecond"
        @update="updateEditedTime"
      />
      <div
        v-else
        class="bg-neutral-light-6 flex h-full items-center justify-center rounded px-1.5 text-lg"
      >
        <div class="min-w-8 text-right">
          {{ eventTimeFormatted.minute }}
        </div>
        <div class="mx-0.5">:</div>
        <div class="w-6">
          {{ eventTimeFormatted.second }}
        </div>
      </div>
    </div>

    <Button
      v-if="shouldEdit"
      icon="save"
      iconSize="lg"
      @click="updateEditedTime"
    />
    <Button
      v-else
      :icon="clockRunning ? 'pause' : 'play'"
      iconSize="lg"
      @click="toggleClock"
    />
  </div>
</template>
