import * as R from 'ramda'

import {
  hasIncidentMasterAttribute,
  IsIncidentImportant,
  type HasIncidentMasterAttribute,
} from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import { IncidentId } from '@collector/sportsapi-client-legacy'

import { injectBetIncidents } from '../provide-inject'

type UseSportIncidentConfig = {
  isIncidentEmergencyOrBet: IsIncidentImportant
  hasIncidentMasterAttribute: HasIncidentMasterAttribute
}

function isIncidentEmergencyOrBet(
  betIncidents: Set<IncidentId>,
  incident: Pick<Incident, 'incident_id'> & Partial<Pick<Incident, 'category'>>,
): boolean {
  return (
    betIncidents.has(incident.incident_id) || incident.category === 'emergency'
  )
}

export function useBetIncidents(): UseSportIncidentConfig {
  const betIncidents = injectBetIncidents()

  return {
    isIncidentEmergencyOrBet: R.partial(isIncidentEmergencyOrBet, [
      betIncidents,
    ]),
    hasIncidentMasterAttribute,
  }
}
