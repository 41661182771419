<script setup lang="ts">
import { MultiSelect } from '@collector/shared-ui'
import { EventLatency } from '@collector/sportsapi-client-legacy'
import { useEvent } from '@desktop/views/Relation/composables'

const { event } = useEvent()
</script>

<template>
  <MultiSelect
    v-model="event.latency"
    :options="[EventLatency.ThreeSix, EventLatency.SevenFifteen]"
    disabled
  >
    Latency
  </MultiSelect>
</template>
