import * as R from 'ramda'

import { Incident } from '@collector/sportsapi-client'
import {
  IncidentsGroup,
  isIncidentsGroup,
} from '@desktop/views/Relation/Sports/configuration/incidentsGroup'

import {
  FilterFunction,
  IncidentFilterSelect,
  IncidentFilterToggle,
} from './types'

export function filterIncidents(
  incidents: (Incident | IncidentsGroup)[],
  filterFunctions: FilterFunction[],
): (Incident | IncidentsGroup)[] {
  const matchesFilterFunctions = R.allPass(filterFunctions)

  return incidents.filter((value) => {
    if (isIncidentsGroup(value)) {
      return value.incidents.some((incident) =>
        matchesFilterFunctions(incident),
      )
    }

    return matchesFilterFunctions(value)
  })
}

export function getFilterFunctionsToggle(
  filters: IncidentFilterToggle[],
): FilterFunction[] {
  const filterFunctions: FilterFunction[] = []

  for (const filter of filters) {
    if (filter.value) {
      filterFunctions.push(filter.filterFunction)
    }
  }

  return filterFunctions
}

export function getFilterFunctionsSelect(
  filters: IncidentFilterSelect[],
): FilterFunction[] {
  const filterFunctions: FilterFunction[] = []

  for (const filter of filters) {
    const hasValue = Array.isArray(filter.value)
      ? filter.value.length > 0
      : filter.value !== undefined

    if (hasValue) {
      filterFunctions.push(
        R.partialRight(filter.filterFunction, [
          // prefill last argument of filter function (currying) with current value from multiselect
          Array.isArray(filter.value)
            ? new Set(R.flatten(filter.value))
            : filter.value,
        ]),
      )
    }
  }

  return filterFunctions
}
