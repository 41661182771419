<script setup lang="ts">
import { computed, ComputedRef, ref, watch } from 'vue'

import { MultiSelect } from '@collector/shared-ui'
import { Incident } from '@collector/sportsapi-client'
import { useSubParticipants } from '@desktop/views/Relation/composables'
import { useEventIncidentsQueue } from '@desktop/views/Relation/Sports/composables'
import { SubParticipantType } from '@desktop/views/Relation/Sports/configuration'
import {
  SubParticipantOption,
  SubParticipantOptionValue,
} from '@desktop/views/Relation/utils'

type SubParticipantValue = Omit<SubParticipantOptionValue, 'teamId'> | undefined

// props
interface Props {
  disabled: boolean
  incident: Incident
  label: string
  options: SubParticipantOption[]
  required: boolean
  type: SubParticipantType
  keepSelectedOption?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  keepSelectedOption: false,
})

// composables
const eventIncidentsQueue = useEventIncidentsQueue()
const { homePlayersOptions, awayPlayersOptions } = useSubParticipants()

// data
const propertyId =
  props.type === 'participant' ? 'participant_id' : 'assistant_id'

const propertyName =
  props.type === 'participant' ? 'participant_name' : 'assistant_name'

// state
const subParticipantValue = ref(
  getSubparticipantValue(
    props.incident[propertyName],
    props.incident[propertyId],
  ),
)

// computed
type OptionWithoutTeamId = Omit<SubParticipantOption, 'value'> & {
  value: Omit<SubParticipantOptionValue, 'teamId'>
}

type OptionsWithoutTeamId = OptionWithoutTeamId[]

const optionsWithoutTeamId: ComputedRef<OptionsWithoutTeamId> = computed(() => {
  const options = props.options.map((option) => {
    const { teamId, ...rest } = option.value
    return { ...option, value: rest }
  })

  if (props.keepSelectedOption) {
    appendCurrentSelectedOption(options)
  }

  return options
})

// methods
function appendCurrentSelectedOption(options: OptionsWithoutTeamId): void {
  const isSubParticipantNotSelected =
    subParticipantValue.value &&
    !options.some((option) => option.value.id === subParticipantValue.value?.id)

  if (isSubParticipantNotSelected) {
    options.unshift(prepareCurrentSelectedOption())
  }
}

function prepareCurrentSelectedOption(): OptionWithoutTeamId {
  const subParticipantOption = [
    ...homePlayersOptions.value,
    ...awayPlayersOptions.value,
  ].find((player) => player.value.id === subParticipantValue.value?.id)!

  const metadata = subParticipantOption.metadata

  return {
    name: subParticipantValue.value?.shortName ?? '',
    value: {
      shortName: subParticipantValue.value?.shortName ?? '',
      id: subParticipantValue.value?.id ?? null,
    },
    metadata,
  }
}

function getSubparticipantValue(
  shortName: string | null,
  id: number | null,
): SubParticipantValue {
  if (shortName === null && id === null) return undefined

  return {
    shortName,
    id,
  }
}

function updatePlayer(value: SubParticipantValue): void {
  const payload = {
    [propertyId]: value?.id ?? null,
    [propertyName]: value?.shortName ?? null,
    participant_team_id: props.incident.participant_team_id,
  }

  eventIncidentsQueue.updateIncident(payload, props.incident)

  subParticipantValue.value = value
}

// watchers
watch(
  [() => props.incident[propertyName], () => props.incident[propertyId]],
  ([newName, newId]) => {
    subParticipantValue.value = getSubparticipantValue(newName, newId)
  },
)
</script>

<template>
  <MultiSelect
    :options="optionsWithoutTeamId"
    size="xs"
    :disabled
    :required
    withFilter
    withOptionsFullWidth
    :modelValue="subParticipantValue"
    @update:modelValue="(value) => updatePlayer(value as SubParticipantValue)"
  >
    <template #option="{ option }">
      <div class="whitespace-nowrap">
        {{ option.value.shortName }}
      </div>
      <div class="text-2xs">
        {{ option.metadata.position }}
        <span
          v-if="option.metadata.shirtNr"
          class="font-bold"
        >
          {{ option.metadata.shirtNr }}
        </span>
      </div>
    </template>
    {{ label }}
  </MultiSelect>
</template>
