export enum SoccerIncidentId {
  InPossession = 400,
  Attack = 401,
  DangerousAttack = 402,
  FreeKick = 403,
  DangerousFreeKick = 404,
  ShotOnTarget = 405,
  ShotOffTarget = 406,
  GoalKick = 407,
  Corner = 408,
  ThrowIn = 409,
  Foul = 410,
  ShotBlocked = 411,
  GoalkeeperSaved = 412,
  Goal = 413,
  Injury = 415,
  Offside = 416,
  SecondYellowCard = 417,
  RedCard = 418,
  YellowCard = 419,
  Penalty = 420,
  PenaltyGoal = 421,
  MissedPenalty = 422,
  OwnGoal = 423,
  GoalCancelled = 424,
  ShotWoodwork = 425,
  AerialDuelWon = 426,
  AddedTime = 449,
  SubstitutionOut = 450,
  SubstitutionIn = 452,
  WaterBreak = 1822,
  RefereesInjury = 1823,
  BreakDuringTheGame = 1824,
  PossibleCorner = 1825,
  CornerCancelled = 1826,
  PossibleGoal = 1827,
  PossibleCard = 1829,
  PossibleCardCancelled = 1830,
  PenaltyCancelled = 1831,
  PossiblePenalty = 1832,
  KickOff = 1833,
  PossibleVAR = 1834,
  NotStarted = 427,
  Interrupted = 441,
  Cancelled = 439,
  Postponed = 440,
  StartDelayed = 438,
  Abandoned = 442,
  Halftime = 445,
  FinishedRegularTime = 437,
  FinishedAwardedWin = 436,
  FinishedAfterPenalties = 435,
  FinishedAfterExtratime = 434,
  FirstHalf = 429,
  SecondHalf = 430,
  ExtratimeFirstHalf = 431,
  ExtratimeSecondHalf = 432,
  ExtratimeHalftime = 447,
  WaitingForExtratime = 446,
  PenaltyShootoutStarted = 433,
  PenaltyShootout = 433,
  WaitingForPenalty = 448,
  ToFinish = 451,
  VideoAssistantReferee = 1834,
  VarEnded = 2753,
  PenaltySavedByGoalkeeper = 2532,
  MatchAboutToStart = 2699,
  BookingMarketsDisabled = 2688,
  BookingMarketsEnabled = 2689,
  CornerMarketsEnabled = 2691,
  CornerMarketsDisabled = 2690,
  Tackle = 2810,
  Interception = 2811,
  BallSkills = 2919,
  LooseBall = 2920,
}

export const possessionIncidents = Object.seal([
  SoccerIncidentId.InPossession,
  SoccerIncidentId.Attack,
  SoccerIncidentId.DangerousAttack,
] as SoccerIncidentId[])
