<script setup lang="ts">
import { ref } from 'vue'

import { formatDateInTimezone } from '@collector/shared-utils'

import FormControl from './FormControl.vue'

// props
interface Props {
  mode?: 'time' | 'datetime' | 'date'
  min?: string
  max?: string
  size?: 'sm' /** 32px */
}

withDefaults(defineProps<Props>(), {
  mode: 'date',
  size: 'sm',
})

// emits
type Emits = {
  blur: [{ event: Event; formattedValue: string }]
}

const emit = defineEmits<Emits>()

// state
const selectedDate = defineModel<string>()
const localValue = ref(selectedDate.value)

// methods
function onBlur(event: Event): void {
  const target = event.target as HTMLInputElement

  if (!target.value) {
    selectedDate.value = localValue.value
    return
  }

  const formattedValue = formatDateInTimezone(
    target.value,
    'yyyy-MM-dd HH:mm:ss',
  )

  emit('blur', {
    event,
    formattedValue,
  })

  localValue.value = target.value
}
</script>

<template>
  <FormControl>
    <template #label>
      <slot />
    </template>

    <input
      v-model="selectedDate"
      class="border-neutral-dark-3/15 bg-neutral-light-10 h-8 rounded border px-2 text-xs"
      :type="mode === 'datetime' ? 'datetime-local' : mode"
      :min
      :max
      @blur="onBlur"
    />
  </FormControl>
</template>
