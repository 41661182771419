<script setup lang="ts">
import { computed } from 'vue'

import { Button, type ButtonSize } from '@collector/shared-ui'
import { Participant } from '@collector/sportsapi-client-legacy'

// props
interface Props {
  participant: Participant
  size?: ButtonSize
}

const props = defineProps<Props>()

// data
const hoverVariantClass = 'hover:border-transparent'
const fontClass = 'font-bold'
const textClass = 'text-neutral-light-10'

const homeButtonColorClasses = [
  'bg-team-home-5',
  'border-team-home-5',
  'hover:bg-team-home-5/80',
]
const awayButtonColorClasses = [
  'bg-team-away-5',
  'border-team-away-5',
  'hover:bg-team-away-5/80',
]

// computed
const teamButtonColorClasses = computed(() => {
  const teamSide = props.participant.counter === 1 ? 'home' : 'away'

  return teamSide === 'home' ? homeButtonColorClasses : awayButtonColorClasses
})
const classes = computed(() => [
  ...teamButtonColorClasses.value,
  textClass,
  fontClass,
  hoverVariantClass,
])
</script>

<template>
  <Button
    :class="[...classes, $attrs.class]"
    :size
  >
    {{ props.participant.short_name }}
  </Button>
</template>
