import { Ref } from 'vue'

import { Participant } from '@collector/sportsapi-client-legacy'

import { fetchParticipants } from '../../dependencies'
import {
  injectParticipants,
  provideParticipants,
} from '../../provide-inject/participants'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useParticipantsQuery(
  eventId: number,
  initRef?: Ref<Participant[]>,
) {
  const data = initRef ?? injectParticipants()
  if (initRef) provideParticipants(initRef)

  let fetchPromise: Promise<void> | null = null
  async function fetch(): Promise<void> {
    data.value = await fetchParticipants(eventId)
    fetchPromise = null
  }

  if (!data.value && !fetchPromise) fetchPromise = fetch()

  return {
    data,
    fetch,
    suspense: () => fetchPromise,
  }
}
