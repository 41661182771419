import { useConfirmModal } from '@collector/shared-ui'
import { SubParticipantPosition } from '@collector/sportsapi-client-legacy'

import CreateNewPlayerWithoutShirtNrConfirmModal from './CreateNewPlayerWithoutShirtNrConfirmModal.vue'

function shouldShowConfirmModal(
  shirtNr: string,
  subParticipantPosition: SubParticipantPosition,
): boolean {
  return (
    shirtNr === '' && subParticipantPosition !== SubParticipantPosition.Coach
  )
}

type UseNewPlayerConfirmModal = {
  canCreateNewPlayer(
    shirtNr: string,
    subParticipantPosition: SubParticipantPosition,
  ): Promise<boolean>
}

export function useNewPlayerConfirmModal(): UseNewPlayerConfirmModal {
  // composables
  const { openModalCancelConfirm } = useConfirmModal()

  async function canCreateNewPlayer(
    shirtNr: string,
    subParticipantPosition: SubParticipantPosition,
  ): Promise<boolean> {
    return (
      !shouldShowConfirmModal(shirtNr, subParticipantPosition) ||
      (await openModalCancelConfirm(CreateNewPlayerWithoutShirtNrConfirmModal))
    )
  }

  return {
    canCreateNewPlayer,
  }
}
