import { RouteRecordRaw } from 'vue-router'

import _404 from '@desktop/views/404.vue'
import Home from '@desktop/views/Home.vue'
import Relation from '@desktop/views/Relation/Relation.vue'

import { Routes } from './consts'

const routes: RouteRecordRaw[] = [
  {
    path: Routes.Home,
    component: Home,
  },
  {
    path: Routes.Relation,
    component: Relation,
  },
  {
    // no route match - fallback
    path: '/:pathMatch(.*)*',
    component: _404,
  },
]

export { routes }
