<script setup lang="ts">
import { EventScoutRank } from '@collector/sportsapi-client-legacy'
import { useEventScouts } from '@desktop/views/Relation/composables'

interface Props {
  rank: EventScoutRank
}

defineProps<Props>()

// composables
const { eventScouts } = useEventScouts()

// methods
function getScoutName(rank: EventScoutRank): string {
  return (
    eventScouts.value.find((eventScout) => eventScout.rank === rank)
      ?.full_name || ''
  )
}

function getScoutDisplayName(rank: EventScoutRank): string {
  return getScoutName(rank) || 'Not assigned'
}
</script>

<template>
  <span
    class="ml-1 font-bold"
    :class="{
      'text-error-5': !getScoutName(rank),
    }"
  >
    {{ getScoutDisplayName(rank) }}
  </span>
</template>
