<script lang="ts" setup>
import { computed, ref } from 'vue'

import { getElementPercentagePosition } from '@collector/shared-ui'

import BallPositionIndicator from '../../Field/FieldIndicators/BallPositionIndicator/BallPositionIndicator.vue'

// emits
const emit = defineEmits<{
  click: [position: { x: number; y: number }]
}>()

// state
const mousePosition = ref<{ x: number; y: number }>()
const backgroundElement = ref<HTMLDivElement | null>(null)
const ballPositionIndicatorVisible = ref(false)

// computed
const percentageBallPosition = computed(() => {
  if (!backgroundElement.value || !mousePosition.value) {
    return { x: 0, y: 0 }
  }

  return getElementPercentagePosition(
    backgroundElement.value,
    mousePosition.value,
  )
})

// methods
function emitPercentagePosition(event: MouseEvent): void {
  const position = getElementPercentagePosition(backgroundElement.value!, {
    x: event.clientX,
    y: event.clientY,
  })

  emit('click', position)
}

function setMousePosition(event: MouseEvent): void {
  mousePosition.value = { x: event.clientX, y: event.clientY }
}

function clearMousePosition(): void {
  mousePosition.value = undefined
}

function onMouseEnter(event: MouseEvent): void {
  setMousePosition(event)
  ballPositionIndicatorVisible.value = true
}

function onMouseLeave(): void {
  clearMousePosition()
  ballPositionIndicatorVisible.value = false
}
</script>

<template>
  <div
    ref="backgroundElement"
    class="text-neutral-light-10 relative h-full w-full"
    @mousemove="setMousePosition"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click="emitPercentagePosition"
  >
    <slot />

    <BallPositionIndicator
      v-if="ballPositionIndicatorVisible"
      :position="percentageBallPosition"
    />
  </div>
</template>
