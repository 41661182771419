import {
  ApiResponse,
  EventUpdateResponse,
  Participant,
  UpdateEventParticipantsObject,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type ParticipantUpdateStatsResponse = ApiResponse<{
  participants: Participant[]
}>

// TODO: remove `subparticipants` field from `UpdateEventParticipantsObject` (it needs to be tested on mobile app)
export async function patch(
  httpClient: HttpClient,
  eventId: number,
  payload: Omit<UpdateEventParticipantsObject, 'subparticipants'>[],
): Promise<EventUpdateResponse> {
  const data = payload.map((item) => ({ ...item, subparticipants: [] }))

  return httpClient
    .url(`/events/${eventId}/participants`)
    .patch({ data })
    .json()
}

export async function updateStats(
  httpClient: HttpClient,
  eventId: number,
  payload: UpdateEventParticipantsObject[],
): Promise<ParticipantUpdateStatsResponse> {
  return httpClient
    .url(`/events/${eventId}/participants`)
    .patch({ data: payload })
    .json()
}
