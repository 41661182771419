/**
 * Transforms formation string into array of arrays with positions (including goalkeeper).
 *
 * @example getFormationSectionsPlayerPositions('4-4-2') // [['1'], ['2','3','4','5'], ['6','7','8','9'], ['10', '11']]
 */
export function getFormationSectionsPlayersPosition(
  formation: string,
): string[][] {
  const result: string[][] = []

  if (!formation) {
    return result
  }

  const sections = [1, ...formation.split('-').map((section) => +section)]

  let currentPosition = 0

  for (const section of sections) {
    const positions = []

    for (let i = 0; i < section; i++) {
      positions.push(String(++currentPosition))
    }

    result.push(positions)
  }

  return result
}
