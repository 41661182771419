import {
  EventIncidentsQueue,
  parseRawQueueIncidents,
} from '@collector/desktop-feature-incidents-queue-core'
import { Event } from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'

import { captureQueueIncidentErrors } from './sentry'
import {
  getEventIncidentsQueueRecord,
  persistOnChangeEventIncidentsQueue,
} from './storage'

function createEventIncidentQueue(eventId: Event['id']): EventIncidentsQueue {
  const eventIncidentsQueue = new EventIncidentsQueue(eventId, sportsApiClient)

  return eventIncidentsQueue
}

export async function initEventIncidentsQueue(
  eventId: Event['id'],
): Promise<EventIncidentsQueue> {
  const eventIncidentsQueue = createEventIncidentQueue(eventId)
  const eventIncidentsQueueRecord = await getEventIncidentsQueueRecord(eventId)

  if (eventIncidentsQueueRecord) {
    eventIncidentsQueue.queue = parseRawQueueIncidents(
      eventIncidentsQueueRecord.rawQueueIncidents,
    )

    if (eventIncidentsQueueRecord.queueStatus === 'processing') {
      eventIncidentsQueue.startProcessing(false)
    }
  }

  persistOnChangeEventIncidentsQueue(eventIncidentsQueue)
  captureQueueIncidentErrors(eventIncidentsQueue)

  return eventIncidentsQueue
}
