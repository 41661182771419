import {
  GetIncident,
  isIncidentDeleted,
  TeamSide,
} from '@collector/shared-ui-domain'
import { Incident, Participant } from '@collector/sportsapi-client'
import {
  EventStatusId,
  IncidentId,
  SportIncident,
} from '@collector/sportsapi-client-legacy'

export function getMissingIncident(
  incidentIds: IncidentId[],
  incidentIdToIdsMap: Record<string, Set<Incident['id']>>,
  incidentIdSportIncidentMap: Record<IncidentId, SportIncident>,
): SportIncident | null {
  for (const incidentId of incidentIds) {
    if (
      !incidentIdToIdsMap[incidentId] ||
      incidentIdToIdsMap[incidentId].size === 0
    ) {
      return incidentIdSportIncidentMap[incidentId]
    }
  }

  return null
}

export function getIncidentIdToIdsMap(
  incidents: Incident[],
  supportedIncidentIds: Set<Incident['id']>,
): Record<string, Set<number>> {
  return incidents.reduce(
    (acc: Record<string, Set<Incident['id']>>, incident) => {
      const incidentId = incident.incident_id

      if (
        supportedIncidentIds.has(incidentId) &&
        !isIncidentDeleted(incident)
      ) {
        acc[incidentId] = acc[incidentId] || new Set()
        acc[incidentId].add(incident.id)
      }

      return acc
    },
    {},
  )
}

export function hasDeletedIncidentId(
  incidentIdToIncidentsMap: Record<string, Set<Incident['id']>>,
  incident: Incident,
): boolean {
  return (
    incident.action === 'delete' &&
    !!incidentIdToIncidentsMap[incident.incident_id]?.has(incident.id)
  )
}

export function getIncidentToSend(
  eventStatusId: EventStatusId,
  eventStatusToIncidentIdMap: Map<EventStatusId, IncidentId[]>,
  incidentIdToIdsMap: Record<string, Set<Incident['id']>>,
  incidentIdToSportIncidentMap: Record<IncidentId, SportIncident>,
): SportIncident | null {
  const incidentIds = eventStatusToIncidentIdMap.get(eventStatusId)

  if (incidentIds) {
    return getMissingIncident(
      incidentIds,
      incidentIdToIdsMap,
      incidentIdToSportIncidentMap,
    )
  }

  return null
}

export function getTeamSidePossesion(
  homeParticipant: Participant,
  incidentIdToIdsMap: Record<string, Set<Incident['id']>>,
  getIncident: GetIncident,
  incidentId: Incident['incident_id'],
  participantTeamId: Incident['participant_team_id'] | undefined,
  kickOffIncidentId: IncidentId,
  secondHalfIncidentId: IncidentId,
): TeamSide | null {
  if (incidentId === kickOffIncidentId) {
    return participantTeamId === homeParticipant.id ? 'home' : 'away'
  }

  if (
    incidentId === secondHalfIncidentId &&
    incidentIdToIdsMap[kickOffIncidentId]
  ) {
    const lastKickOffIncidentId = [
      ...incidentIdToIdsMap[kickOffIncidentId],
    ].pop()

    /** get latest non-delete kick-off incident sent to API */
    if (lastKickOffIncidentId) {
      const incident = getIncident(lastKickOffIncidentId)

      return incident.participant_team_id === homeParticipant.id
        ? 'away'
        : 'home'
    }
  }

  return null
}
