<script setup lang="ts">
import { computed } from 'vue'

import { Button } from '@collector/shared-ui'
import { IncidentId } from '@collector/sportsapi-client-legacy'
import {
  useBlockades,
  useEventIncidentsQueue,
} from '@desktop/views/Relation/Sports/composables'
import { injectPocketIncidentsConfig } from '@desktop/views/Relation/Sports/provide-inject'

interface Props {
  incidentId?: IncidentId
}

const props = defineProps<Props>()

type Emits = { incidentSent: [] }
const emit = defineEmits<Emits>()

// composables
const eventIncidentsQueue = useEventIncidentsQueue()

// inject
const { blockedPocketIncidentsMap } = injectPocketIncidentsConfig()

// composables
const { isIncidentBlocked } = useBlockades()

// computed
const disabled = computed(
  () =>
    props.incidentId &&
    blockedPocketIncidentsMap &&
    isIncidentBlocked(props.incidentId, blockedPocketIncidentsMap),
)

// methods
function sendIncident(): void {
  if (props.incidentId) {
    eventIncidentsQueue.addIncident({ incident_id: props.incidentId })

    // for more pocket incidents in modal
    emit('incidentSent')
  }
}
</script>

<template>
  <Button
    class="bg-neutral-light-7 border-neutral-light-0 hover:enabled:bg-neutral-3 hover:enabled:border-neutral-3 font-bold"
    fontSize="text-2xs"
    size="lg"
    width="full"
    :disabled
    @click="incidentId ? sendIncident() : undefined"
  >
    <slot />
  </Button>
</template>
