<script lang="ts" setup>
import { useModalStack } from '../../composables'

const modalStack = useModalStack()
</script>

<template>
  <div id="modal_container">
    <component
      :is="modalComponent"
      v-for="(modalComponent, index) in modalStack"
      :key="`opened-modal-${index}`"
    />
  </div>
</template>
