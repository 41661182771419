import { Extras } from '@sentry/types'

const key = 'issueTitle'

export function createExtraIssueTitle(value: string): Extras {
  return { extra: { [key]: value } }
}

export function getExtraIssueTitle(extra: Extras | undefined): string {
  return (extra?.[key] as string) ?? ''
}

export function deleteExtraIssueTitle(extra: Extras | undefined): void {
  delete extra?.[key]
}

export function hasExtraIssueTitle(extra: Extras | undefined): boolean {
  return !!extra?.[key]
}
