import {
  AddIncident,
  DeleteIncident,
  QueueIncident,
  UpdateIncident,
} from '@collector/desktop-feature-incidents-queue-core'
import { GetIncident } from '@collector/shared-ui-domain'

import { DisplayedIncidentWithIds } from './types'

export function getDisplayedIncidentWithIds(
  getIncident: GetIncident,
  queueIncident: QueueIncident,
): DisplayedIncidentWithIds {
  if (queueIncident instanceof AddIncident) {
    return {
      ...queueIncident.payload,
      category: queueIncident.payload.category ?? 'standard',
      uuid: queueIncident.uuid,
    }
  }

  if (queueIncident instanceof UpdateIncident) {
    const incident = getIncident(queueIncident.id)

    return {
      incident_id: incident.incident_id,
      participant_team_id: incident.participant_team_id,
      minute: queueIncident.payload.minute ?? incident.minute,
      second: queueIncident.payload.second ?? incident.second,
      category: incident.category,
      id: queueIncident.id,
    }
  }

  if (queueIncident instanceof DeleteIncident) {
    const incident = getIncident(queueIncident.id)

    return {
      incident_id: incident.incident_id,
      participant_team_id: incident.participant_team_id,
      minute: incident.minute,
      second: incident.second,
      category: incident.category,
      id: queueIncident.id,
    }
  }

  throw Error('Unknown queue incident type')
}
