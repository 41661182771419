export const relationStatuses = [
  {
    name: 'Not started',
    value: 'not_started',
  },
  {
    name: '30min left',
    value: '30_min_left',
  },
  {
    name: '5min left',
    value: '5_min_left',
  },
  {
    name: 'In progress',
    value: 'in_progress',
  },
  {
    name: 'Finished',
    value: 'finished',
  },
]
