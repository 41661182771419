import { ApiResponse, Skin } from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../httpClient'

export type SkinsGetForParticipantResponse = ApiResponse<{
  skins: Skin[]
}>

export async function getForParticipant(
  httpClient: HttpClient,
  participantId: number,
): Promise<SkinsGetForParticipantResponse> {
  return httpClient.url(`/skins?participants_ids=${participantId}`).get().json()
}
