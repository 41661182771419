import memoizee from 'memoizee'

import {
  useEventClock as _useEventClock,
  UseEventClock,
} from '@collector/shared-ui-domain'
import { useEvent } from '@desktop/views/Relation/composables'

import { injectEventPeriodTime } from '../provide-inject'

const memoizedUseEventClock = memoizee(_useEventClock)

export function useEventClock(): UseEventClock {
  const { event, eventTimestamp } = useEvent()
  const periodTime = injectEventPeriodTime()

  /**
   * Memoized function returns same instance of clock for each event
   */
  return memoizedUseEventClock(event, eventTimestamp, periodTime)
}
