<script setup lang="ts">
import { computed, ref } from 'vue'

import { Button, Modal, runLoader, Separator } from '@collector/shared-ui'
import { SkinPattern } from '@collector/shared-ui-domain'
import { CommonStatId, Participant } from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { useEvent, useParticipants } from '@desktop/views/Relation/composables'

interface Props {
  participant: Participant
}
const props = defineProps<Props>()

type Emits = { close: [] }
const emit = defineEmits<Emits>()

// composables
const { getParticipantStatsMap } = useParticipants()
const { event } = useEvent()

const skins =
  (
    await runLoader(
      sportsApiClient.Skins.getForParticipant(props.participant.id),
    )
  ).api.data.skins || []

// state
const participantStatsMap = getParticipantStatsMap(props.participant)
const participantSkinId = Number(
  participantStatsMap.value[CommonStatId.SkinPattern]?.value || undefined,
)

const participantSkin = skins.find((skin) => skin.id === participantSkinId)

const participantSkinIndex = participantSkin
  ? skins.indexOf(participantSkin)
  : undefined

// computed
const selectedSkinIndex = ref<number | undefined>(participantSkinIndex)

const hasChanges = computed(() => {
  return selectedSkinIndex.value !== participantSkinIndex
})

// methods
async function updateParticipantSkin(): Promise<void> {
  await runLoader(
    sportsApiClient.Events.Participants.patch(event.value.id, [
      {
        id: props.participant.id,
        stats: [
          {
            id: CommonStatId.SkinPattern,
            value:
              selectedSkinIndex.value === undefined
                ? ''
                : skins[selectedSkinIndex.value].id,
          },
        ],
      },
    ]),
  )
}

function selectSkin(index: number): void {
  if (selectedSkinIndex.value === index) {
    selectedSkinIndex.value = undefined
  } else {
    selectedSkinIndex.value = index
  }
}
</script>

<template>
  <Modal
    :title="`Select skin for ${participant.short_name}`"
    :confirmButton="{
      text: 'Save',
      confirm: updateParticipantSkin,
      disabled: !hasChanges,
    }"
    @close="emit('close')"
  >
    <Separator class="mb-4" />

    <!-- w-[77.5vw] - smooth width shrinking on 'sm' breakpoint -->
    <div class="@sm:w-[31rem] flex w-[77.5vw] gap-x-4">
      <template
        v-for="i in [0, 1, 2]"
        :key="i"
      >
        <Button
          class="bg-neutral-light-10 relative w-full rounded-lg border-2 p-4"
          :class="{
            'border-neutral-3': selectedSkinIndex === i,
            'border-transparent': selectedSkinIndex !== i,
          }"
          :disabled="!skins[i]"
          :title="skins[i] ? '' : 'Shirt not available'"
          @click="selectSkin(i)"
        >
          <Button
            v-if="skins[i] && participantSkinId === skins[i].id"
            class="bg-neutral-dark-10 text-neutral-light-10 absolute -left-3 -top-3 h-8 w-8 rounded-full"
            icon="check"
            iconSize="lg"
            square
          />
          <SkinPattern
            v-bind="
              skins[i]
                ? {
                    patternId: skins[i].pattern_id,
                    firstColor: `#${skins[i].first_color}`,
                    secondColor: `#${skins[i].second_color}`,
                    thirdColor: `#${skins[i].third_color}`,
                  }
                : {
                    opacity: 0.1,
                  }
            "
          />
        </Button>
      </template>
    </div>
  </Modal>
</template>
