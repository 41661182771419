<script setup lang="ts">
import { computed } from 'vue'

import { TextInput } from '@collector/shared-ui'

interface Props {
  withValidationStyle?: boolean
}

const { withValidationStyle = false } = defineProps<Props>()

// model
const model = defineModel<string | number | null>()

// methods
function getBorderClass(): string {
  if (!withValidationStyle) {
    return ''
  }

  const borderColor = model.value ? 'border-success-5' : 'border-error-5'

  return `${borderColor} border-2`
}

// computed
const inputClass = computed(() => {
  return `w-10 text-center !text-sm ${getBorderClass()}`
})
</script>

<template>
  <TextInput
    v-model="model"
    :inputClass
    :maxlength="2"
    numeric
    variant="light"
    placeholder="Nr"
  />
</template>
