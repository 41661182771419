import { SportsApiClient } from '@collector/sportsapi-client'

import { QueueIncidentType } from './types'

export type QueueIncidentStatus = 'Pending' | 'In Progress' | 'Error'

export type QueueIncidentError = unknown | null

export abstract class BaseIncident {
  readonly eventId: number

  public status: QueueIncidentStatus

  readonly time: number

  abstract type: QueueIncidentType

  error: QueueIncidentError

  constructor(
    eventId: number,
    status: QueueIncidentStatus = 'Pending',
    time = new Date().getTime(),
    error: QueueIncidentError = null,
  ) {
    this.eventId = eventId
    this.status = status
    this.time = time
    this.error = error
  }

  public getEventId(): number {
    return this.eventId
  }

  public getStatus(): QueueIncidentStatus {
    return this.status
  }

  public getTime(): number {
    return this.time
  }

  public setStatus(status: Exclude<QueueIncidentStatus, 'pending'>): void {
    this.status = status
  }

  public abstract process(sportsApiClient: SportsApiClient): Promise<string>
}
