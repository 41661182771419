<script setup lang="ts">
import { Button } from '@collector/shared-ui'
import {
  SubParticipant,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'

interface Props {
  subParticipants: SubParticipant[]
}

defineProps<Props>()

// model
const selectedPlayerPosition = defineModel<string | undefined>(
  'selectedPlayerPosition',
  {
    required: true,
  },
)

const playerPositionToSubParticipantMap = defineModel<
  Record<string, SubParticipant>
>('playerPositionToSubParticipantMap', { default: {}, required: true })

// methods
function getCurrentPlayerPosition(
  subParticipant: SubParticipant,
): string | null {
  const entries: [string, SubParticipant][] = Object.entries(
    playerPositionToSubParticipantMap.value,
  )

  for (const [key, value] of entries) {
    if (value.lineup_id === subParticipant.lineup_id) {
      return key
    }
  }

  return null
}

function canSetPlayerPosition(subParticipant: SubParticipant): boolean {
  return (
    !!selectedPlayerPosition.value && !getCurrentPlayerPosition(subParticipant)
  )
}

function setPlayerPostion(
  playerPosition: string,
  subParticipant: SubParticipant,
): void {
  const currentPlayerPosition = getCurrentPlayerPosition(subParticipant)

  if (currentPlayerPosition) {
    // unset current player position to prevent 2 player positions
    delete playerPositionToSubParticipantMap.value[currentPlayerPosition]
  }

  playerPositionToSubParticipantMap.value[playerPosition] = subParticipant
}

function canDeletePlayerPosition(subParticipant: SubParticipant): boolean {
  return (
    getCurrentPlayerPosition(subParticipant) !== null &&
    subParticipant.position !== SubParticipantPosition.Goalkeeper
  )
}

function deletePlayerPosition(subParticipant: SubParticipant): void {
  const playerPosition = getCurrentPlayerPosition(subParticipant)

  if (playerPosition) {
    delete playerPositionToSubParticipantMap.value[playerPosition]
  }
}
</script>

<template>
  <div class="ml-2 w-56 space-y-1">
    <div
      v-for="subParticipant in subParticipants"
      :key="subParticipant.short_name"
      class="bg-neutral-light-10 shadow-simple-shadow flex h-10 select-none items-center rounded"
    >
      <div
        class="flex h-full w-full items-center text-sm"
        :class="{
          'text-neutral-dark-3/30': getCurrentPlayerPosition(subParticipant),
          'cursor-pointer': canSetPlayerPosition(subParticipant),
        }"
        @click="
          () => {
            if (canSetPlayerPosition(subParticipant)) {
              setPlayerPostion(selectedPlayerPosition as string, subParticipant)
              selectedPlayerPosition = undefined
            }
          }
        "
      >
        <div class="ml-2 mr-4 w-4 text-right">
          {{ subParticipant.shirt_nr || '-' }}
        </div>
        <div>
          {{ subParticipant.short_name }}
        </div>
      </div>
      <Button
        v-if="canDeletePlayerPosition(subParticipant)"
        class="text-error-6 mr-2 border-none"
        icon="x"
        size="xs"
        square
        @click="deletePlayerPosition(subParticipant)"
      />
      <div
        v-else
        class="mr-2 h-6 w-6"
      />
    </div>
  </div>
</template>
