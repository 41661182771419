<script setup lang="ts">
import { ref } from 'vue'

import { Expand, SuccessBadge } from '@collector/shared-ui'

import EventDetails from './EventDetails.vue'
import EventStatus from './EventStatus.vue'
import Latency from './Latency.vue'
import OfficialWebsiteLink from './OfficialWebsiteLink.vue'
import { provideSuccessBadge } from './provide-inject/successBadge'
import Referee from './Referee.vue'
import RelationStatus from './RelationStatus.vue'
import StartDate from './StartDate.vue'

// state
const successBadge = ref<InstanceType<typeof SuccessBadge> | null>(null)

// methods
function showSuccessBadge(): void {
  successBadge.value?.show()
}

// provide
provideSuccessBadge({ showSuccessBadge })
</script>

<template>
  <Expand>
    <template #title>
      <div class="flex w-full items-center">
        Event info
        <OfficialWebsiteLink />
        <SuccessBadge
          ref="successBadge"
          class="ml-auto pr-4"
          title="Event Info successfully updated"
        />
      </div>
    </template>

    <div class="select-none space-y-3 pr-4">
      <div
        class="flex flex-wrap gap-x-4 gap-y-2"
        data-test-id="EventInfoSelects"
      >
        <RelationStatus />
        <StartDate />
        <EventStatus />
        <Latency />
        <Referee />
        <EventDetails />
      </div>
    </div>
  </Expand>
</template>
