<script setup lang="ts">
import * as R from 'ramda'

import { getRandomHtmlId } from '@collector/shared-utils'

import { vAutofocus } from '../../directives'
import {
  isPressedArrow,
  isPressedBackspace,
  isPressedDelete,
  isPressedEnter,
  isPressedNumber,
  isPressedSelectAll,
  isPressedTab,
} from '../../utils'
import FormControl from '../FormControl.vue'

interface Props {
  type?: 'text' | 'password'
  size?: 'xs' /** 24px */ | 'sm' /** 32px */ | 'md' /** 40px */
  autofocus?: boolean
  width?: 'full'
  inputClass?: string
  maxlength?: number
  min?: number
  max?: number
  numeric?: boolean
  disabled?: boolean
  placeholder?: string
  variant?: 'default' | 'light'
  withError?: boolean
  withOutlineFocus?: 'default' | 'light'
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  variant: 'default',
  size: 'sm',
})

// emits
type Emits = {
  blur: [{ event: Event; value: string }]
  focus: [event: FocusEvent]
}

defineEmits<Emits>()

// data
const id = getRandomHtmlId()

// model
const inputValue = defineModel<string | null | number>({ default: '' })

// methods
function onKeydown(event: KeyboardEvent): void {
  if (
    props.numeric &&
    !R.anyPass([
      isPressedNumber,
      isPressedBackspace,
      isPressedTab,
      isPressedSelectAll,
      isPressedEnter,
      isPressedDelete,
      isPressedArrow,
    ])(event)
  ) {
    event.preventDefault()
  }
}
</script>

<template>
  <FormControl
    :id
    :size
    :disabled
  >
    <template #label>
      <slot />
    </template>
    <div class="flex w-full">
      <input
        :id
        v-model="inputValue"
        v-autofocus="autofocus"
        class="text-neutral-dark-3 bg-neutral-light-10 placeholder-neutral-dark-3/40 rounded border outline-none"
        :class="{
          'focus:outline-primary-4 focus:-outline-offset-1':
            withOutlineFocus === 'default' && !withError,
          'w-full': width === 'full',
          'border-error-5': withError,
          'h-10 text-sm': size === 'md',
          'px-3': size === 'md' && !width,
          'h-8 text-xs': size === 'sm',
          'px-2': size === 'sm' && !width,
          'h-6 text-xs': size === 'xs',
          'h-6 px-1': size === 'xs',
          'border-neutral-dark-3/15': variant === 'default',
          [`${inputClass}`]: inputClass,
        }"
        :type
        :maxlength
        :placeholder
        :disabled
        @blur="
          $emit('blur', {
            event: $event,
            value: ($event.target as HTMLInputElement).value,
          })
        "
        @keydown="onKeydown"
      />
      <slot name="content" />
    </div>
  </FormControl>
</template>
