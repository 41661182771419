<script setup lang="ts">
import { computed } from 'vue'

import { getParticipantTeamSide } from '@collector/shared-ui-domain'
import { Participant, Stat } from '@collector/sportsapi-client-legacy'

import SportStatIcon from '../../components/SportStatIcon.vue'
import { useParticipants } from '../../composables'
import { formatStatValue } from '../../utils'
import { injectImportantStatsId } from '../provide-inject'

interface Props {
  participant: Participant
}

const props = defineProps<Props>()

// composables
const { getParticipantStatsMap } = useParticipants()

// data
const teamSide = getParticipantTeamSide(props.participant)
const participantStatsMap = getParticipantStatsMap(props.participant)
const direction = teamSide === 'home' ? 'left' : 'right'

// inject
const importantStatsId = injectImportantStatsId()

// computed
const importantStats = computed(() => {
  const stats: Stat[] = []

  for (const statId of importantStatsId) {
    if (participantStatsMap.value[statId]) {
      stats.push(participantStatsMap.value[statId]!)
    }
  }

  return stats
})
</script>

<template>
  <div
    class="flex justify-end gap-3"
    :class="
      direction === 'left' ? 'flex-row' : 'flex-row-reverse space-x-reverse'
    "
  >
    <div
      v-for="stat in importantStats"
      :key="stat.id"
      class="flex items-center space-x-0.5"
      :class="{
        'flex-row pl-2': direction === 'left',
        'flex-row-reverse space-x-reverse pr-2': direction === 'right',
      }"
      :data-test-id="'Stat-' + stat.id"
    >
      <div class="min-w-3 text-lg">
        {{ formatStatValue(stat.value) }}
      </div>
      <SportStatIcon
        :statId="stat.id"
        size="2xs"
      />
    </div>
  </div>
</template>
