<script setup lang="ts">
import { Dropdown } from 'floating-vue'
import { ref } from 'vue'

import { Icon } from '@collector/shared-ui'

interface Props {
  variant?: 'wide'
}

defineProps<Props>()

// state
const popperOpened = ref(false)
</script>

<template>
  <Dropdown
    :popperClass="`shared-ui-dropdown ${variant === 'wide' ? 'shared-ui-dropdow-wide' : ''}`"
    @applyHide="popperOpened = false"
    @applyShow="popperOpened = true"
  >
    <div
      v-if="popperOpened"
      class="fixed left-0 top-0 z-10 h-screen w-screen"
    ></div>
    <slot
      name="trigger"
      @click="popperOpened = true"
    />
    <template #popper="{ hide }">
      <div
        v-if="variant === 'wide'"
        class="shadow-simple-shadow bg-neutral-light-8 min-h-36 min-w-[26rem] rounded-2xl px-6"
      >
        <div class="flex justify-end py-0.5">
          <button
            class="text-neutral-dark-3/60 hover:text-neutral-2"
            @click="hide"
          >
            <Icon icon="close" />
          </button>
        </div>
        <slot name="content" />
      </div>
      <div
        v-else
        class="bg-neutral-light-8 shadow-simple-shadow rounded px-3 py-2"
      >
        <slot
          v-bind="{ hide }"
          name="content"
        />
      </div>
    </template>
  </Dropdown>
</template>

<style>
.shared-ui-dropdow-wide .v-popper__inner {
  @apply rounded-[1.125rem];
}

.shared-ui-dropdown .v-popper__arrow-inner {
  @apply border-neutral-light-8;
}
</style>
