import { SoccerEventStatusId } from '@collector/sportsapi-client-legacy'

// statuses for which clock time starts from 0
const firstHalfStatuses: SoccerEventStatusId[] = [
  SoccerEventStatusId.NotStarted,
  SoccerEventStatusId.StartDelayed,
  SoccerEventStatusId.Postponed,
  SoccerEventStatusId.Interrupted,
  SoccerEventStatusId.Abandoned,
  SoccerEventStatusId.FirstHalf,
  SoccerEventStatusId.FinishedAfterPenalties,
  SoccerEventStatusId.FinishedAwardedWin,
  SoccerEventStatusId.Cancelled,
  SoccerEventStatusId.ToFinish,
]

const secondHalfStatuses: SoccerEventStatusId[] = [
  SoccerEventStatusId.Halftime,
  SoccerEventStatusId.SecondHalf,
  SoccerEventStatusId.WaitingForPenalty,
  SoccerEventStatusId.PenaltyShootout,
  SoccerEventStatusId.FinishedRegularTime,
]

const firstHalfExtratimeStatuses: SoccerEventStatusId[] = [
  SoccerEventStatusId.WaitingForExtratime,
  SoccerEventStatusId.ExtratimeFirstHalf,
]

const secondHalfExtratimeStatuses: SoccerEventStatusId[] = [
  SoccerEventStatusId.ExtratimeHalftime,
  SoccerEventStatusId.ExtratimeSecondHalf,
  SoccerEventStatusId.FinishedAfterExtratime,
]

export function getPeriodTime(
  statusId: SoccerEventStatusId,
  periodLength: number,
  extraPeriodLength: number,
): number {
  if (firstHalfStatuses.includes(statusId)) {
    return 0
  }

  if (secondHalfStatuses.includes(statusId)) {
    return periodLength * 60
  }

  if (firstHalfExtratimeStatuses.includes(statusId)) {
    return periodLength * 2 * 60
  }

  if (secondHalfExtratimeStatuses.includes(statusId)) {
    return periodLength * 2 * 60 + extraPeriodLength * 60
  }

  return 0
}

export function getClockTime(
  statusId: SoccerEventStatusId,
  minute: number,
  second: number,
  periodLength: number,
  extraPeriodLength: number,
): number | null {
  // null - invalid value
  if (minute > 999 || second > 59) {
    return null
  }

  if (secondHalfStatuses.includes(statusId)) {
    if (minute < periodLength && second <= 59) {
      return null
    }
  }

  if (firstHalfExtratimeStatuses.includes(statusId)) {
    if (minute < periodLength * 2 && second <= 59) {
      return null
    }
  }

  if (secondHalfExtratimeStatuses.includes(statusId)) {
    if (minute < periodLength * 2 + extraPeriodLength && second <= 59) {
      return null
    }
  }

  return (
    minute * 60 +
    second -
    getPeriodTime(statusId, periodLength, extraPeriodLength)
  )
}
