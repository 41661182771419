<script setup lang="ts">
import { ref } from 'vue'

import { Badge, Button, ButtonGroup, Expand } from '@collector/shared-ui'
import { ParticipantName } from '@collector/shared-ui-domain'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import Status from '@desktop/views/Relation/components/RelationView/Main/Summary/Status.vue'
import {
  useEvent,
  useParticipants,
  useSport,
  useSubParticipants,
} from '@desktop/views/Relation/composables'
import Summary from '@desktop/views/Relation/Sports/components/Summary.vue'

import { injectStatisticsConfig } from '../../provide-inject'
import BackToRelation from './BackToRelation.vue'
import PlayerStats from './PlayerStats.vue'
import { Data } from './Table/types'
import TeamStat from './TeamStat.vue'
import {
  buildExistingStats,
  buildParticipantStats,
  buildPlayerStatsPayload,
} from './utils'

// emits
defineEmits(['close'])

// composables
const { sport } = useSport()
const { event } = useEvent()
const {
  awayParticipantStatsMap,
  homeParticipantStatsMap,
  homeParticipant,
  awayParticipant,
} = useParticipants()
const { awaySubParticipants, homeSubParticipants } = useSubParticipants()

const statisticsConfig = injectStatisticsConfig()

// state
const statsOptions = ['Team Stats', 'Player Stats']
const selectedOptionIndex = ref(0)

const topGroup = buildExistingStats(
  sport,
  statisticsConfig.team.slice(0, 10),
  homeParticipantStatsMap.value,
  awayParticipantStatsMap.value,
)

const bottomGroup = buildExistingStats(
  sport,
  statisticsConfig.team.slice(10),
  homeParticipantStatsMap.value,
  awayParticipantStatsMap.value,
)

const homeParticipantStats = buildParticipantStats(
  [...topGroup, ...bottomGroup],
  homeParticipantStatsMap,
)

const awayParticipantStats = buildParticipantStats(
  [...topGroup, ...bottomGroup],
  awayParticipantStatsMap,
)

// functions
function isEventResultVerified(): boolean {
  return event.value.verified_result === 'yes'
}

async function savePlayerStats(data: Data[][]): Promise<void> {
  const payloadWithStats = buildPlayerStatsPayload(
    homeSubParticipants.value,
    awaySubParticipants.value,
    data,
  )

  await sportsApiClient.Events.SubParticipants.patch(
    event.value.id,
    payloadWithStats,
    false,
  )
}

async function saveTeamStats(): Promise<void> {
  const homeStats = Object.values(homeParticipantStatsMap.value).map((stat) => {
    if (homeParticipantStats.value[stat.id as number]) {
      return {
        ...stat,
        value: homeParticipantStats.value[stat.id as number],
      }
    }

    return {
      ...stat,
    }
  })

  const awayStats = Object.values(awayParticipantStatsMap.value).map((stat) => {
    if (awayParticipantStats.value[stat.id as number]) {
      return {
        ...stat,
        value: awayParticipantStats.value[stat.id as number],
      }
    }

    return {
      ...stat,
    }
  })

  sportsApiClient.Events.Participants.patch(event.value.id, [
    {
      id: homeParticipant.value.id,
      results: [],
      stats: homeStats,
    },
    {
      id: awayParticipant.value.id,
      results: [],
      stats: awayStats,
    },
  ])
}
</script>

<template>
  <div class="max-w-screen flex w-full flex-col">
    <BackToRelation />
    <Summary class="my-6">
      <template #header>
        <div class="flex w-full">
          <ParticipantName
            class="flex w-full justify-end pt-1 text-3xl font-bold"
            :participant="homeParticipant"
          />
          <Status />
          <ParticipantName
            class="flex w-full justify-start pt-1 text-3xl font-bold"
            :participant="awayParticipant"
          />
        </div>
      </template>
      <template #middle>
        <Badge
          v-if="isEventResultVerified()"
          variant="success"
        >
          The result has been verified
        </Badge>
      </template>
    </Summary>
    <div class="flex h-full flex-grow flex-col">
      <ButtonGroup
        v-model="selectedOptionIndex"
        withBorder
        :options="statsOptions"
        width="full"
      />
      <div
        v-show="selectedOptionIndex === 0"
        class="bg-background shadow-simple-shadow mt-2 flex min-h-[calc(100vh-20.65rem)] w-full flex-col justify-between rounded-lg px-4 pt-4"
      >
        <div class="mx-auto flex w-full max-w-[33rem] flex-col items-center">
          <TeamStat
            v-for="stat in topGroup"
            :key="stat.id"
            v-model:home="homeParticipantStats[stat.id]"
            v-model:away="awayParticipantStats[stat.id]"
            :baseValues="{
              home: homeParticipantStatsMap[stat.id].value,
              away: awayParticipantStatsMap[stat.id].value,
            }"
            :stat
          />

          <div class="mt-4 w-full">
            <Expand variant="header">
              <template #title="{ expanded }">
                {{ expanded ? 'Show less' : 'Show more' }}
              </template>
              <div class="bg-neutral-light-10">
                <TeamStat
                  v-for="stat in bottomGroup"
                  :key="stat.id"
                  v-model:home="homeParticipantStats[stat.id]"
                  v-model:away="awayParticipantStats[stat.id]"
                  :baseValues="{
                    home: homeParticipantStatsMap[stat.id].value,
                    away: awayParticipantStatsMap[stat.id].value,
                  }"
                  :stat
                />
              </div>
            </Expand>
          </div>
        </div>
        <div class="mx-auto mb-7 mt-4 grid w-full grid-cols-3 items-center">
          <BackToRelation />
          <Button
            class="bg-success-2 border-success-2 hover:border-success-4 hover:bg-success-4 text-neutral-10 mx-auto max-w-24 font-bold"
            size="md"
            color="blue-light"
            @click="saveTeamStats"
          >
            Save
          </Button>
        </div>
      </div>
      <PlayerStats
        v-show="selectedOptionIndex === 1"
        class="bg-background shadow-simple-shadow mt-2 flex min-h-[calc(100vh-20.65rem)] w-full flex-col items-center rounded-lg pt-4"
      >
        <template #default="{ data }">
          <div class="mx-auto mb-7 mt-4 grid w-full grid-cols-3 items-center">
            <BackToRelation />
            <Button
              class="bg-success-2 border-success-2 hover:border-success-4 hover:bg-success-4 text-neutral-10 mx-auto max-w-24 font-bold"
              size="md"
              color="blue-light"
              @click="savePlayerStats(data)"
            >
              Save
            </Button>
          </div>
        </template>
      </PlayerStats>
    </div>
  </div>
</template>
