import { createNullableProvideInject } from '@collector/shared-ui'

import { IncidentsQueuePayloadConfig } from '../configuration'

export const [
  provideIncidentsQueuePayloadConfig,
  injectIncidentsQueuePayloadConfig,
] = createNullableProvideInject<IncidentsQueuePayloadConfig>(
  'incidentsQueuePayloadConfig',
)
