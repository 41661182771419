<script setup lang="ts">
import * as R from 'ramda'
import { computed } from 'vue'

import { useEvent } from '../../../../../composables'
import { RelatedEventStatuses } from '../../../../../Sports/configuration'
import { injectRelatedEventStatusConfig } from '../../../../../Sports/provide-inject'
import StatusesDropdownIncidents from './StatusesDropdownIncidents.vue'

// emits
defineEmits(['close'])

// inject
const relatedEventStatusConfig = injectRelatedEventStatusConfig()

// composables
const { event } = useEvent()

// computed
const relatedEventStatuses = computed(
  () =>
    R.prop(
      event.value.status_id,
      relatedEventStatusConfig,
    ) as RelatedEventStatuses,
)
</script>

<template>
  <div
    v-if="relatedEventStatuses"
    class="header-dropdown-scrollable-statuses"
  >
    <StatusesDropdownIncidents
      title="Possible next statuses"
      :relatedEventStatusValues="relatedEventStatuses.possibleNextStatuses"
      @click="$emit('close')"
    />
    <StatusesDropdownIncidents
      title="Other"
      :relatedEventStatusValues="relatedEventStatuses.otherStatuses"
      @click="$emit('close')"
    />
  </div>
  <div v-else>No possible next statuses</div>
</template>
