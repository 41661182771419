<script setup lang="ts">
import { Box } from '@collector/shared-ui'
import { useParticipants } from '@desktop/views/Relation/composables'

import ParticipantImportantStats from './ParticipantImportantStats.vue'

interface Props {
  reversed?: boolean
}

defineProps<Props>()

const { homeParticipant, awayParticipant } = useParticipants()
</script>

<template>
  <Box
    class="flex flex-col"
    data-testid="summary-container"
  >
    <slot name="header" />
    <div class="m-auto flex">
      <ParticipantImportantStats
        class="my-0.5"
        :class="{
          'mr-8': !reversed,
          'ml-8': reversed,
        }"
        :participant="homeParticipant"
      />
      <slot name="middle" />
      <ParticipantImportantStats
        class="my-0.5"
        :class="{
          'ml-8': !reversed,
          'mr-8': reversed,
        }"
        :participant="awayParticipant"
      />
    </div>
  </Box>
</template>
