<script setup lang="ts">
import { Icon } from '@collector/shared-ui'
import {
  CommonEventStatusId,
  CommonIncidentId,
} from '@collector/sportsapi-client-legacy'

import BetMarketButton from './BetMarketButton.vue'

const disabledStatuses = new Set([
  CommonEventStatusId.Abandoned,
  CommonEventStatusId.Cancelled,
])
</script>

<template>
  <BetMarketButton
    betMarketEventKey="bet_status"
    betStatusActiveText="Close markets"
    betStatusInactiveText="Open markets"
    :openMarketsIncidentId="CommonIncidentId.Betstart"
    :closeMarketsIncidentId="CommonIncidentId.Betstop"
    :disabledStatuses
  >
    <Icon
      class="text-neutral-light-10"
      icon="unlock"
    />
  </BetMarketButton>
</template>
