import { Sport } from '@collector/sportsapi-client-legacy'

import { injectSport } from '../provide-inject'

type UseSport = {
  sport: Sport
  sportResourceName: string
}

export function useSport(): UseSport {
  const sport = injectSport()

  /**
   * TODO: it's probably temporary due to the fact that LMP resource name is different than sport name in some way
   * for example for Ice Hockey it's `hockey` instead of `ice_hockey`.
   * It will be injected in some way or fetched from API.
   */
  const sportResourceName = sport.name.toLowerCase()

  return { sport, sportResourceName }
}
