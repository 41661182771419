import { SoccerEventStatusId } from '@collector/sportsapi-client-legacy'

export const eventStatuses: SoccerEventStatusId[] = [
  SoccerEventStatusId.NotStarted,
  SoccerEventStatusId.StartDelayed,
  SoccerEventStatusId.FirstHalf,
  SoccerEventStatusId.Halftime,
  SoccerEventStatusId.SecondHalf,
  SoccerEventStatusId.WaitingForExtratime,
  SoccerEventStatusId.ExtratimeFirstHalf,
  SoccerEventStatusId.ExtratimeHalftime,
  SoccerEventStatusId.ExtratimeSecondHalf,
  SoccerEventStatusId.WaitingForPenalty,
  SoccerEventStatusId.PenaltyShootout,
  SoccerEventStatusId.FinishedRegularTime,
  SoccerEventStatusId.FinishedAfterExtratime,
  SoccerEventStatusId.FinishedAfterPenalties,
  SoccerEventStatusId.FinishedAwardedWin,
  SoccerEventStatusId.Interrupted,
  SoccerEventStatusId.ToFinish,
  SoccerEventStatusId.Postponed,
  SoccerEventStatusId.Abandoned,
  SoccerEventStatusId.Cancelled,
]
