<script setup lang="ts">
import { IncidentsQueueButton } from '@collector/desktop-feature-incidents-queue-ui'
import { IsIncidentImportant } from '@collector/shared-ui-domain'
import {
  useIncidents,
  useParticipants,
  useSportMaps,
} from '@desktop/views/Relation/composables'
import { injectEventIncidentsQueue } from '@desktop/views/Relation/provide-inject'
import { useBetIncidents } from '@desktop/views/Relation/Sports/composables'

import { buttonClass } from './consts'

// inject
const eventIncidentsQueue = injectEventIncidentsQueue()

// composables
const { isIncidentEmergencyOrBet } = useBetIncidents()
const { getIncident } = useIncidents()
const { homeParticipant, awayParticipant } = useParticipants()
const { incidentIdToSportIncidentMap } = useSportMaps()
</script>

<template>
  <IncidentsQueueButton
    :class="buttonClass"
    :eventIncidentsQueue
    :isIncidentImportant="isIncidentEmergencyOrBet as IsIncidentImportant"
    :getIncident
    :homeParticipant
    :awayParticipant
    :incidentIdToSportIncidentMap
    icon="wifi"
    size="sm"
    iconSize="sm"
  >
    Queue
  </IncidentsQueueButton>
</template>
