export type MaybeGetter<TVal, TArg = void> =
  | (TArg extends undefined ? () => TVal : (arg: TArg) => TVal)
  | TVal

export type ValueType<TMaybeGetter> = TMaybeGetter extends (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arg?: any,
) => infer R
  ? R
  : TMaybeGetter

export function toValue<TVal>(valueOrGetter: MaybeGetter<TVal>): TVal
export function toValue<TVal, TArg>(
  valueOrGetter: MaybeGetter<TVal, TArg>,
  arg: TArg,
): TVal
export function toValue<TVal, TArg>(
  valueOrGetter: MaybeGetter<TVal, TArg>,
  arg?: NoInfer<TArg> | undefined,
): TVal {
  if (valueOrGetter instanceof Function)
    return (valueOrGetter as (arg?: TArg) => TVal)(arg)
  else return valueOrGetter
}
