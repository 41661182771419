<script setup lang="ts">
import { computed } from 'vue'

import { Logo } from '@collector/shared-ui-domain'
import { useEvent } from '@desktop/views/Relation/composables'

import EventScouts from './EventScouts/EventScouts.vue'
import LivematchProDemoButton from './LivematchProDemoButton.vue'
import NotificationsButton from './NotificationsButton.vue'
import Participants from './Participants.vue'
import RelationButtons from './RelationButtons/RelationButtons.vue'
import ResetEventButton from './ResetEventButton.vue'
import StatsLevel from './StatsLevel.vue'

// composables
const { event } = useEvent()

// computed
const shouldDisplayResetEventButton = computed(() =>
  event.value.start_date.includes('1900'),
)
</script>

<template>
  <div class="flex items-center">
    <Logo class="h-6" />
    <Participants />
    <div class="z-10 flex flex-1 items-center justify-end gap-2">
      <StatsLevel />
      <ResetEventButton v-if="shouldDisplayResetEventButton" />
      <NotificationsButton />
      <LivematchProDemoButton />
    </div>
  </div>

  <div class="mt-2 flex">
    <EventScouts />
    <div class="relative z-10 flex flex-1 justify-end gap-2">
      <RelationButtons />
    </div>
  </div>
</template>
