import type { RefreshTokenResponse } from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type AuthRefreshPostPayload = {
  access_token: string
}

export type AuthRefreshPostResponse = RefreshTokenResponse

export async function post(
  httpClient: HttpClient,
  payload: AuthRefreshPostPayload,
): Promise<AuthRefreshPostResponse> {
  return httpClient.url('/auth/refresh').post(payload).json()
}
