import { SoccerEventStatusId } from '@collector/sportsapi-client-legacy'

import { PitchInteractionDisabledStatuses } from '../../../configuration'

export const pitchInteractionDisabledStatuses: PitchInteractionDisabledStatuses =
  new Set([
    SoccerEventStatusId.NotStarted,
    SoccerEventStatusId.StartDelayed,
    SoccerEventStatusId.Postponed,
    SoccerEventStatusId.Halftime,
    SoccerEventStatusId.WaitingForExtratime,
    SoccerEventStatusId.ExtratimeHalftime,
    SoccerEventStatusId.WaitingForPenalty,
    SoccerEventStatusId.PenaltyShootout,
    SoccerEventStatusId.FinishedRegularTime,
    SoccerEventStatusId.FinishedAfterExtratime,
    SoccerEventStatusId.FinishedAfterPenalties,
    SoccerEventStatusId.FinishedAwardedWin,
    SoccerEventStatusId.Interrupted,
    SoccerEventStatusId.Abandoned,
    SoccerEventStatusId.Cancelled,
    SoccerEventStatusId.ToFinish,
  ])
