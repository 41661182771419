import { ref, Ref } from 'vue'

import { SubParticipantsPatchPayload } from '@collector/sportsapi-client'
import {
  Sport,
  SportStats,
  Stat,
  StatId,
  SubParticipant,
} from '@collector/sportsapi-client-legacy'

import { Data } from './Table/types'

export function buildParticipantStats(
  stats: SportStats[],
  participanStatsMap: Ref<Record<StatId, Stat>>,
): Ref<Record<number, number | string | null>> {
  return ref(
    stats.reduce(
      (acc, stat) => {
        acc[stat.id] = participanStatsMap.value[stat.id].value

        return acc
      },
      {} as Record<StatId, number | string | null>,
    ),
  )
}

export function buildExistingStats(
  sport: Sport,
  stats: StatId[],
  homeParticipantStatsMap: Record<StatId, Stat>,
  awayParticipantStatsMap: Record<StatId, Stat>,
): SportStats[] {
  return stats
    .map((id) => sport.stats.team.find((stat) => stat.id === id))
    .filter((x) => x !== undefined)
    .filter(
      (x) => homeParticipantStatsMap[x.id] && awayParticipantStatsMap[x.id],
    )
}

export function buildPlayerStatsPayload(
  homeSubParticipants: SubParticipant[],
  awaySubParticipants: SubParticipant[],
  data: Data[][],
): SubParticipantsPatchPayload[] {
  return [...homeSubParticipants, ...awaySubParticipants].map((participant) => {
    return {
      id: participant.id,
      lineup_id: participant.lineup_id,
      short_name: participant.short_name,
      team_id: Number(participant.team_id),
      stats:
        data
          .find((row) => row[1].value === participant.short_name)
          ?.slice(2)
          .map((row) => {
            return {
              id: row.id as StatId,
              short_name: row.name,
              name: row.name,
              value: row.value,
              data_type: row.dataType,
            }
          }) || [],
    }
  })
}
