import { Incident, Participant } from '@collector/sportsapi-client'
import {
  BetStartAttribute,
  BetStopAttribute,
  BookingMarketsDisabledAttribute,
  BookingMarketsEnabledAttribute,
  Confirmation,
  CornerMarketsDisabledAttribute,
  CornerMarketsEnabledAttribute,
  IncidentAttributeId,
} from '@collector/sportsapi-client-legacy'

import { getParticipantTeamSide } from './participant'

export function hasEqualIncidentParticipantId(
  incident: Incident,
  participant: Participant,
): boolean {
  return incident.participant_team_id === participant.id
}

export function hasIncidentPosition(incident: Incident): boolean {
  return incident.x_pos !== null && incident.y_pos !== null
}

export function isIncidentDeleted(incident: Incident): boolean {
  return incident.action === 'delete'
}

export function isIncidentCancelled(incident: Incident): boolean {
  return incident.confirmation === Confirmation.Cancelled
}

export function isIncidentEditable(incident: Incident): boolean {
  return !isIncidentDeleted(incident) && !isIncidentCancelled(incident)
}

export function isIncidentAddedByUser(
  incident: Incident,
  userId: number,
): boolean {
  return !!incident.source_id && incident.source_id === userId
}

/**
 * Normalize percentage position 'x' of incident for participant, as if participant was playing on home side
 */
export function getPercentagePositionXWithParticipant(
  percentagePositionX: NonNullable<Incident['x_pos']>,
  participant: Participant,
): NonNullable<Incident['x_pos']> {
  return getParticipantTeamSide(participant) === 'home'
    ? percentagePositionX
    : 100 - percentagePositionX
}

export function isDangerousAttackForPercentagePositionX(
  percentagePositionX: NonNullable<Incident['x_pos']>,
): boolean {
  return percentagePositionX >= 75 && percentagePositionX <= 100
}

export type HasIncidentMasterAttribute = typeof hasIncidentMasterAttribute

export function hasIncidentMasterAttribute(incident: Incident): boolean {
  const masterAttributes: IncidentAttributeId[] = [
    BetStartAttribute.Master,
    BetStopAttribute.Master,
    BookingMarketsEnabledAttribute.Master,
    BookingMarketsDisabledAttribute.Master,
    CornerMarketsEnabledAttribute.Master,
    CornerMarketsDisabledAttribute.Master,
  ]

  return (
    incident.attribute_ids?.some((attributeId) =>
      masterAttributes.includes(attributeId),
    ) ?? false
  )
}
