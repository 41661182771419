import { BaseParticipant } from './BaseParticipant'

export enum SubParticipantPosition {
  Goalkeeper = 'goalkeeper',
  FirstLineup = '1st lineup',
  Bench = 'bench',
  Coach = 'coach',
  WillNotPlay = 'will not play',
}

export type SubParticipant = Omit<BaseParticipant, 'stats' | 'id'> & {
  id: BaseParticipant['id'] | null
  position: SubParticipantPosition
  participant_id: number
  lineup_id: number
  captain: boolean
  team_id?: string
  stats?: BaseParticipant['stats']
}
