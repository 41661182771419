<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import { setContextPageTitle } from '@collector/shared-plugin-sentry-vue'
import { SportId } from '@collector/sportsapi-client-legacy'

import { fetchRelationDependencies } from './dependencies'
import { initQueries, provideAndInitRelation } from './provide-inject'
import SoccerRelation from './Sports/components/Soccer/Soccer.vue'
import { initEventIncidentsQueue, validateRelationAccess } from './utils'

// state
const initialized = ref(false)

// composables
const route = useRoute()

// data
const eventId = Number(route.params.eventId)

const {
  competition,
  competitionTimestamp,
  eventSettings,
  eventScouts,
  incidents,
  sport,
  subParticipants,
  swappableIncidents,
} = await fetchRelationDependencies(eventId)

const queryRefs = await initQueries(eventId)

const { event } = competition.season.stage.group

// methods
function isSport(sportId: SportId): boolean {
  return sport.id === sportId
}

// init
validateRelationAccess(sport, eventScouts)

const eventIncidentsQueue = await initEventIncidentsQueue(eventId)

provideAndInitRelation(
  {
    competition,
    event,
    eventTimestamp: competitionTimestamp,
    eventScouts,
    eventSettings,
    incidents,
    season: competition.season,
    subParticipants,
    sport,
    swappableIncidents,
    eventIncidentsQueue,
  },
  queryRefs,
)

/* Sentry grouped issue title */
const [homeParticipant, awayParticipant] = queryRefs.participants.value
setContextPageTitle(
  `${homeParticipant.short_name} - ${awayParticipant.short_name} (${event.start_date})`,
)

initialized.value = true
</script>

<template>
  <div>
    <!--  wrapping in 1 root element is required to prevent live-reload errors when using <Suspense> -->
    <SoccerRelation v-if="initialized && isSport(SportId.Soccer)" />
    <div v-else>Error during loading Relation</div>
  </div>
</template>
