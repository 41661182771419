import { createNullableProvideInject } from '@collector/shared-ui'

import { HandyBarInfoAlwaysVisibleIncidents } from '../configuration'

export const [
  provideHandyBarInfoAlwaysVisibleIncidents,
  injectHandyBarInfoAlwaysVisibleIncidents,
] = createNullableProvideInject<HandyBarInfoAlwaysVisibleIncidents>(
  'handyBarInfoAlwaysVisibleIncidents',
)
