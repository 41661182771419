import {
  QueueStatus,
  RawQueueIncident,
} from '@collector/desktop-feature-incidents-queue-core'

import { Database } from '..'

export type EventIncidentsQueueRecord = {
  eventId: number
  rawQueueIncidents: RawQueueIncident[]
  queueStatus: QueueStatus
}

export async function put(
  eventId: number,
  eventIncidentsQueue: EventIncidentsQueueRecord,
): Promise<void> {
  const database = Database.get()
  const table = database.table(Database.Tables.EVENT_INCIDENTS_QUEUE)

  await table.put(eventIncidentsQueue, eventId)
}

export function get(
  eventId: number,
): Promise<EventIncidentsQueueRecord | undefined> {
  const database = Database.get()
  const table = database.table(Database.Tables.EVENT_INCIDENTS_QUEUE)

  return table.get(eventId)
}
