import Sentry from '@collector/shared-plugin-sentry-vue'
import { AuthInfo } from '@collector/sportsapi-client'

let authInfo: null | AuthInfo.AuthInfoType = null

export function get(): AuthInfo.AuthInfoType {
  if (authInfo === null) throw Error('authInfo is not initialized')

  return authInfo
}

export function init(value: AuthInfo.AuthInfoType): void {
  authInfo = value
  Sentry.setUser({ id: authInfo.id })
}

export function clear(): void {
  authInfo = null
}
