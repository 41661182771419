import { IncidentAttributeId } from '@collector/sportsapi-client-legacy'
import { ShouldLoadOppositeSubParticipants } from '@desktop/views/Relation/Sports/configuration'

export function getLoadOppositeParticipants(
  attributeIds: IncidentAttributeId[] | null,
  shouldLoadOppositeSubParticipants:
    | ShouldLoadOppositeSubParticipants
    | undefined,
): boolean {
  if (!attributeIds) return false
  if (!shouldLoadOppositeSubParticipants) return false

  return typeof shouldLoadOppositeSubParticipants === 'boolean'
    ? shouldLoadOppositeSubParticipants
    : shouldLoadOppositeSubParticipants(attributeIds)
}
