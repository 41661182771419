import { Ref } from 'vue'

import {
  EventSettingsRecord,
  EventSettingsTable,
} from '@desktop/utils/database'

import { injectEvent, injectEventSettings } from '../provide-inject'

type UseEventSettings = {
  eventSettings: Ref<EventSettingsRecord>
  updateEventSettings(value: EventSettingsRecord): Promise<unknown>
}

export function useEventSettings(): UseEventSettings {
  const eventSettings = injectEventSettings()
  const event = injectEvent()

  function updateEventSettings(value: EventSettingsRecord): Promise<void> {
    eventSettings.value = Object.assign(eventSettings.value, value)

    return EventSettingsTable.upsert(event.value.id, value)
  }

  return {
    eventSettings,
    updateEventSettings,
  }
}
