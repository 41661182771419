<script lang="ts" setup>
import { ref } from 'vue'

import { Button, useConfirmModal } from '@collector/shared-ui'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { useEvent } from '@desktop/views/Relation/composables'

import ResetEventConfirmModal from './ResetEventConfirmModal.vue'

// composables
const { event } = useEvent()
const { openModalCancelConfirm } = useConfirmModal()

// state
const processing = ref(false)

// methods
async function promptForReset(): Promise<void> {
  const result = await openModalCancelConfirm(ResetEventConfirmModal)

  if (result) {
    resetEvent()
  }
}

async function resetEvent(): Promise<void> {
  try {
    processing.value = true

    await sportsApiClient.Events.reset(event.value.id)

    window.location.reload()
  } finally {
    processing.value = false
  }
}
</script>

<template>
  <Button
    class="border-neutral-light-2 bg-neutral-light-2"
    icon="repeat"
    size="xs"
    square
    :disabled="processing"
    @click="promptForReset()"
  />
</template>
