import * as R from 'ramda'

import { Events } from '@collector/sportsapi-client'
import {
  Event,
  Participant,
  SoccerPersonStatId,
  Sport,
  SubParticipant,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { sortFormations } from '@desktop/views/Relation/utils/sportsapi/sport'

const positionStatId = SoccerPersonStatId.PPosition as number

function getPlayerPosition(subParticipant: SubParticipant): string | null {
  return subParticipant.stats?.find((stat) => stat.id === positionStatId)
    ?.value as string
}

export function getPlayerPositionToSubParticipantMap(
  subParticipants: SubParticipant[],
): Record<string, SubParticipant> {
  return subParticipants.reduce(
    (acc, subParticipant) => {
      const playerPosition = getPlayerPosition(subParticipant)

      if (playerPosition) {
        acc[playerPosition] = subParticipant
      }

      return acc
    },
    {} as Record<string, SubParticipant>,
  )
}

export function sortSportFormations(formations: Sport['formations']): string[] {
  return sortFormations(R.uniq(formations))
}

export function getGoalkeeperPositionToSubParticipantMap(
  subParticipants: SubParticipant[],
): Record<string, SubParticipant> {
  const result = {} as Record<string, SubParticipant>

  const goalkeeper = subParticipants.find(
    (subParticipant) =>
      subParticipant.position === SubParticipantPosition.Goalkeeper,
  )

  if (goalkeeper) {
    const playerPosition = getPlayerPosition(goalkeeper)

    if (playerPosition) {
      result[playerPosition] = goalkeeper
    }
  }

  return result
}

export async function patchParticipantFormation(
  eventId: Event['id'],
  participantId: Participant['id'],
  formationStatId: number,
  formation: string,
): Promise<void> {
  await sportsApiClient.Events.Participants.patch(eventId, [
    {
      id: participantId,
      stats: [
        {
          id: formationStatId,
          value: formation,
        },
      ],
    },
  ])
}

function getSubParticipantsPatchPayload(
  subParticipant: SubParticipant,
  playerPosition: Events.SubParticipants.SubParticipantsPatchPayload['player_position'],
): Events.SubParticipants.SubParticipantsPatchPayload {
  return {
    ...R.pick(
      ['id', 'position', 'short_name', 'lineup_id', 'team_id'],
      subParticipant,
    ),
    player_position: playerPosition,
  }
}

export function getSquadSubParticipantsPatchPayload(
  playerPositionToSubParticipantMap: Record<string, SubParticipant>,
): Events.SubParticipants.SubParticipantsPatchPayload[] {
  return Object.entries(playerPositionToSubParticipantMap).reduce(
    (acc, [playerPosition, subParticipant]) => {
      acc.push(getSubParticipantsPatchPayload(subParticipant, playerPosition))

      return acc
    },
    [] as Events.SubParticipants.SubParticipantsPatchPayload[],
  )
}

export function getDeletedSubParticipantsPatchPayload(
  playerPositionToSubParticipantMap: Record<string, SubParticipant>,
  initialPlayerPositionToSubParticipantMap: Record<string, SubParticipant>,
): Events.SubParticipants.SubParticipantsPatchPayload[] {
  const deletedSubParticipants = R.difference(
    Object.values(initialPlayerPositionToSubParticipantMap),
    Object.values(playerPositionToSubParticipantMap),
  )

  return deletedSubParticipants.map((subParticipant) =>
    getSubParticipantsPatchPayload(subParticipant, null),
  )
}
