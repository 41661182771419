<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { TextInput } from '@collector/shared-ui'
import { getEventTime, isIncidentDeleted } from '@collector/shared-ui-domain'
import { formatTimePart } from '@collector/shared-utils'
import { Incident } from '@collector/sportsapi-client'
import {
  useBetIncidents,
  useEventIncidentsQueue,
} from '@desktop/views/Relation/Sports/composables'
import { vOnClickOutside } from '@vueuse/components'

interface Props {
  incident: Incident
  groupedIncident?: Incident
  isDeleted: boolean
}

const props = defineProps<Props>()

// composables
const eventIncidentsQueue = useEventIncidentsQueue()
const { hasIncidentMasterAttribute } = useBetIncidents()

// state
const label = ref()
const editing = ref(false)
const newMinute = ref<number | null>(null)
const newSecond = ref<number | null>(null)

// methods
function onClick(): void {
  if (isEditable.value) {
    newMinute.value = props.incident.minute
    newSecond.value = props.incident.second

    editing.value = true
  }
}

function onClickOutside(e: Event): void {
  if (e.target !== label.value) {
    updateEventTime()
  }
}

function formatTime(value: number | null): string | null {
  return value === null ? null : formatTimePart(value)
}

function isValidNewMinute(): boolean {
  // API allows maximum 255 minutes
  return (
    newMinute.value !== null && newMinute.value >= 0 && newMinute.value <= 255
  )
}

function isValidNewSecond(): boolean {
  return (
    newSecond.value !== null && newSecond.value >= 0 && newSecond.value <= 59
  )
}

function updateEventTime(): void {
  if (
    isValidNewMinute() &&
    isValidNewSecond() &&
    (props.incident.minute !== newMinute.value ||
      props.incident.second !== newSecond.value)
  ) {
    const payload = {
      minute: newMinute.value,
      second: newSecond.value,
    }

    if (!isIncidentDeleted(props.incident)) {
      eventIncidentsQueue.updateIncident(payload, props.incident)
    }

    if (props.groupedIncident && !isIncidentDeleted(props.groupedIncident)) {
      eventIncidentsQueue.updateIncident(payload, props.groupedIncident)
    }
  }

  editing.value = false
}

// computed
const isEditable = computed(
  () =>
    !props.isDeleted &&
    props.incident.category !== 'emergency' &&
    !hasIncidentMasterAttribute(props.incident),
)

const eventTime = computed(() => getEventTime(props.incident))

// watch
watch(
  () => props.isDeleted,
  (newVal) => {
    if (newVal) {
      editing.value = false
    }
  },
)
</script>

<template>
  <div>
    <div
      v-if="editing && !isDeleted"
      v-on-click-outside="onClickOutside"
      class="flex justify-end"
    >
      <TextInput
        autofocus
        :modelValue="formatTime(incident.minute)"
        size="xs"
        numeric
        :maxlength="3"
        inputClass="text-right w-7 pr-0.25 px-1"
        @update:modelValue="newMinute = parseInt($event as string)"
        @keyup.enter="updateEventTime"
      />
      <div class="relative -top-[0.06rem] w-1 text-center">:</div>
      <TextInput
        :modelValue="formatTime(incident.second)"
        size="xs"
        numeric
        :maxlength="2"
        inputClass="w-6 text-right pr-0.75 h-6 px-1"
        @update:modelValue="newSecond = parseInt($event as string)"
        @keyup.enter="updateEventTime"
      />
    </div>
    <div
      v-else
      class="flex justify-end"
    >
      <div
        ref="label"
        class="flex h-6 w-[3.5rem] items-center justify-end border border-transparent pr-0.5 text-sm font-bold"
        :class="{
          'hover:border-neutral-dark-3/10 hover:bg-neutral-light-10 cursor-pointer hover:rounded hover:border':
            !editing && isEditable,
          'text-neutral-dark-3/40': isDeleted,
        }"
        @click="onClick"
      >
        {{ eventTime }}
      </div>
    </div>
  </div>
</template>
