import { SoccerEventStatusId } from '@collector/sportsapi-client-legacy'

import { PitchDisabledStatuses } from '../../../configuration'

export const pitchDisabledStatuses: PitchDisabledStatuses = new Set([
  SoccerEventStatusId.FinishedRegularTime,
  SoccerEventStatusId.FinishedAfterExtratime,
  SoccerEventStatusId.FinishedAfterPenalties,
  SoccerEventStatusId.FinishedAwardedWin,
  SoccerEventStatusId.Interrupted,
  SoccerEventStatusId.Abandoned,
  SoccerEventStatusId.Postponed,
  SoccerEventStatusId.Cancelled,
  SoccerEventStatusId.ToFinish,
])
