<script setup lang="ts">
import { Button } from '@collector/shared-ui'
import { getEventTime, TeamSide } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import { Confirmation } from '@collector/sportsapi-client-legacy'
import SportIncidentIcon from '@desktop/views/Relation/components/SportIncidentIcon.vue'
import { useEventIncidentsQueue } from '@desktop/views/Relation/Sports/composables'

interface Props {
  incident: Incident
  teamSide: TeamSide
}

const props = defineProps<Props>()

// composables
const eventIncidentsQueue = useEventIncidentsQueue()

// methods
function confirmIncident(confirmation: Confirmation): void {
  eventIncidentsQueue.updateIncident({ confirmation }, props.incident)
}
</script>

<template>
  <div
    class="py-0.75 px-0.75 flex justify-between rounded-lg border"
    :class="{
      'border-team-home-5 bg-team-home-5/15': teamSide === 'home',
      'border-team-away-5 bg-team-away-5/15': teamSide === 'away',
    }"
  >
    <Button
      class="bg-error-5 border-error-5 hover:bg-error-7 hover:border-error-7 text-neutral-10"
      icon="xmark"
      size="xs"
      square
      aria-label="cancel"
      @click="confirmIncident(Confirmation.Cancelled)"
    />
    <span
      class="flex items-center px-1"
      data-test-id="ConfirmIncidentsElement"
    >
      <SportIncidentIcon
        :incidentId="incident.incident_id"
        size="3xs"
      />
      <span class="@lg:ml-2 ml-[0.75vw] w-11 text-xs font-bold">
        {{ getEventTime(incident) }}
      </span>
    </span>
    <Button
      class="bg-success-2 border-success-2 hover:border-success-4 hover:bg-success-4 text-neutral-10"
      size="xs"
      icon="check"
      square
      aria-label="confirm"
      @click="confirmIncident(Confirmation.Confirmed)"
    />
  </div>
</template>
