<script setup lang="ts">
import { computed } from 'vue'

import {
  getParticipantTeamSide,
  hasEqualIncidentParticipantId,
} from '@collector/shared-ui-domain'
import { useLastIncident } from '@desktop/views/Relation/composables'
import {
  useBlockades,
  useParticipantInPossesion,
} from '@desktop/views/Relation/Sports/composables'
import { injectPitchInteractionDisabledStatuses } from '@desktop/views/Relation/Sports/provide-inject'

import BallPositionIndicator from './BallPositionIndicator/BallPositionIndicator.vue'
import PossesionIndicator from './PossesionIndicator/PossesionIndicator.vue'

// composables
const { lastIncidentWithPosition } = useLastIncident()
const { participantInPossession } = useParticipantInPossesion()
const { hasCurrentEventStatus } = useBlockades()

// inject
const pitchInteractionDisabledStatuses =
  injectPitchInteractionDisabledStatuses()

// computed
const participantInPossessionTeamSide = computed(() =>
  participantInPossession.value
    ? getParticipantTeamSide(participantInPossession.value)
    : null,
)
const isBallVisible = computed(
  () =>
    lastIncidentWithPosition.value &&
    participantInPossession.value &&
    hasEqualIncidentParticipantId(
      lastIncidentWithPosition.value,
      participantInPossession.value,
    ),
)
const isPossesionIndicatorVisible = computed(
  () => !hasCurrentEventStatus(pitchInteractionDisabledStatuses),
)
</script>

<template>
  <div
    v-if="isPossesionIndicatorVisible"
    class="relative h-full"
  >
    <PossesionIndicator
      v-if="participantInPossessionTeamSide"
      :teamSideInPossession="participantInPossessionTeamSide"
      :incident="lastIncidentWithPosition || null"
    />
    <BallPositionIndicator
      v-if="isBallVisible"
      :position="{
        x: lastIncidentWithPosition!.x_pos!,
        y: lastIncidentWithPosition!.y_pos!,
      }"
    />
  </div>
</template>
