<script setup lang="ts">
import {
  Table,
  TBody,
  TBodyColumn,
  THead,
  THeadColumn,
  TRow,
} from '@collector/shared-ui'
import { CommonResultId } from '@collector/sportsapi-client-legacy'
import {
  useEvent,
  useParticipants,
  useSport,
  useSportMaps,
} from '@desktop/views/Relation/composables'

import Result from './Result.vue'

// composables
const { sport } = useSport()
const { event } = useEvent()
const { eventStatusToSportStatusMap } = useSportMaps()
const { homeParticipantResultsMap, awayParticipantResultsMap } =
  useParticipants()

// data
const sportResults = sport.results
  .filter(
    (result) =>
      ![CommonResultId.Winner, CommonResultId.Progress].includes(
        result.id as CommonResultId,
      ),
  )
  .map((result) => ({
    id: result.id,
    name: result.name,
  }))
</script>

<template>
  <Table>
    <THead>
      <TRow>
        <THeadColumn>Result and status</THeadColumn>
        <THeadColumn>
          <span class="font-bold">STATSCORE</span>
        </THeadColumn>
        <THeadColumn>Source #1</THeadColumn>
        <THeadColumn>Source #2</THeadColumn>
      </TRow>
    </THead>
    <TBody>
      <TRow>
        <TBodyColumn>Status</TBodyColumn>
        <TBodyColumn class="font-bold">
          {{ eventStatusToSportStatusMap[event.status_id].name }}
        </TBodyColumn>
        <TBodyColumn>
          <div />
        </TBodyColumn>
        <TBodyColumn>
          <div />
        </TBodyColumn>
      </TRow>
      <TRow
        v-for="result of sportResults"
        :key="`verify-result-${result.id}`"
      >
        <TBodyColumn>
          {{ result.name }}
        </TBodyColumn>
        <TBodyColumn>
          <Result
            :homeResult="homeParticipantResultsMap[result.id].value"
            :awayResult="awayParticipantResultsMap[result.id].value"
          />
        </TBodyColumn>
        <TBodyColumn class="text-neutral-dark-9/60">
          <Result />
        </TBodyColumn>
        <TBodyColumn class="text-neutral-dark-9/60">
          <Result />
        </TBodyColumn>
      </TRow>
    </TBody>
  </Table>
</template>

<style scoped>
:deep(tr > *:first-child) {
  @apply text-left;
}

:deep(tr > *:not(:first-child)) {
  @apply text-center font-bold;
}
</style>
