<script setup lang="ts">
import { TextInput } from '@collector/shared-ui'

// emits
defineEmits(['update'])

// model
const minute = defineModel<string>('minute', { default: '' })
const second = defineModel<string>('second', { default: '' })
</script>

<template>
  <div class="flex">
    <TextInput
      v-model="minute"
      numeric
      inputClass="text-right !text-sm !px-1 w-9"
      :maxlength="3"
      @keyup.enter="$emit('update')"
    />

    <span class="p-0.5">:</span>

    <TextInput
      v-model="second"
      numeric
      :maxlength="2"
      inputClass="!text-sm !px-1 w-8"
      @keyup.enter="$emit('update')"
    />
  </div>
</template>
