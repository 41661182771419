function setScaledRootFontSize(height?: number): void {
  const baseFontSize = 16
  const scaledFontSize = (getScaledRemFactor(height) * baseFontSize).toFixed(4)

  document.documentElement.style.fontSize = `${scaledFontSize}px`
}

/**
 * Calculate root element font-size factor for scaling the layout based on the screen size
 */
export function getScaledRemFactor(height?: number): number {
  // 1440px is the base width of the Figma design
  // 790px is the base height of the Figma design, which acknowledges the unavailable height (browser bar, system bar, etc.)
  const baseHeight = 790

  return (height || window.innerHeight) / baseHeight
}

/**
 * Scale the base layout:
 * - when changing resolution
 * - when changing window height, to adjust the layout to the available height
 */
export function scaleLayout(): void {
  const scaleHeightBreakpointPercentage = 0.8

  let previousHeight = window.innerHeight

  window.addEventListener('resize', () => {
    const currentHeight = window.innerHeight

    if (currentHeight !== previousHeight) {
      previousHeight = currentHeight

      if (currentHeight / screen.height > scaleHeightBreakpointPercentage) {
        setScaledRootFontSize()
      }
    }
  })

  // init
  if (window.innerHeight / screen.height > scaleHeightBreakpointPercentage) {
    setScaledRootFontSize()
  } else {
    setScaledRootFontSize(
      screen.height - window.outerHeight + window.innerHeight,
    )
  }
}
