<script setup lang="ts">
import { IncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@desktop/views/Relation/components/IncidentButton.vue'
import { useEventIncidentsQueue } from '@desktop/views/Relation/Sports/composables'
import { RelatedEventStatusValue } from '@desktop/views/Relation/Sports/configuration'

interface Props {
  title: string
  relatedEventStatusValues: RelatedEventStatusValue[]
}

defineProps<Props>()

// emits
const emit = defineEmits(['click'])

// composables
const eventIncidentsQueue = useEventIncidentsQueue()

// methods
function addIncidentToQueue(incidentId: IncidentId): void {
  eventIncidentsQueue.addIncident({ incident_id: incidentId })
  emit('click')
}

function getIncidentId(value: RelatedEventStatusValue): IncidentId {
  return typeof value === 'object' ? value.incidentId : value
}

function getDescription(value: RelatedEventStatusValue): string | null {
  return typeof value === 'object' ? value.description : null
}
</script>

<template>
  <div class="mt-4 flex flex-col">
    <div class="mb-1 font-bold">
      {{ title }}
    </div>
    <div class="flex flex-col gap-2">
      <IncidentButton
        v-for="relatedEventStatusValue in relatedEventStatusValues"
        :key="getIncidentId(relatedEventStatusValue)"
        class="bg-neutral-light-10 border-neutral-light-5 h-12 !justify-start px-3"
        :incidentId="getIncidentId(relatedEventStatusValue)"
        size="lg"
        @click="addIncidentToQueue"
      >
        <template #default="{ incidentName }">
          <span class="inline-flex flex-col pl-2 text-xs">
            <span class="text-left font-bold">
              {{ incidentName }}
            </span>
            <span class="font-normal">
              {{ getDescription(relatedEventStatusValue) }}
            </span>
          </span>
        </template>
      </IncidentButton>
    </div>
  </div>
</template>
