export function formatTimePart(num: number): string {
  return num.toString().padStart(2, '0')
}

export function getFormattedTime(minute: number, second: number): string {
  return formatTimePart(minute) + ':' + formatTimePart(second)
}

export function getMinuteWithSecond(seconds: number): {
  minute: number
  second: number
} {
  const minute = Math.floor(seconds / 60)
  const second = seconds % 60

  return {
    minute,
    second,
  }
}

export function getSecondsDiffFromNow(unixTime: number): number {
  const nowSeconds = Math.floor(new Date().getTime() / 1000)

  return nowSeconds - unixTime
}
