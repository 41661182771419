<script setup lang="ts">
import { computed } from 'vue'

import { Datepicker } from '@collector/shared-ui'
import { formatDateInTimezone } from '@collector/shared-utils'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { useEvent } from '@desktop/views/Relation/composables'

import { injectSuccessBadge } from './provide-inject/successBadge'

// inject
const { showSuccessBadge } = injectSuccessBadge()

// composables
const { event } = useEvent()

// methods
async function updateValue(value: string): Promise<void> {
  await sportsApiClient.Events.patch(event.value.id, {
    event: { start_date: value },
  })

  showSuccessBadge()
}

function formatStartDate(hours: number, minutes: number): string {
  const date = new Date(event.value.start_date)

  date.setHours(hours, minutes)

  return formatDateInTimezone(date, 'yyyy-MM-dd HH:mm').replace(' ', 'T')
}

// computed
const min = computed(() => formatStartDate(0, 0))
const max = computed(() => formatStartDate(23, 59))
</script>

<template>
  <Datepicker
    :modelValue="event.start_date"
    mode="datetime"
    :min
    :max
    @blur="({ formattedValue }) => updateValue(formattedValue)"
  >
    Start date
  </Datepicker>
</template>
