import * as R from 'ramda'

import {
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'

import {
  BlockedPitchIncidentsMap,
  PitchIncidentsConfig,
} from '../../../configuration'

const pitchIncidents: Set<SoccerIncidentId> = new Set([
  SoccerIncidentId.Goal,
  SoccerIncidentId.Penalty,
  SoccerIncidentId.Corner,
  SoccerIncidentId.YellowCard,
  SoccerIncidentId.RedCard,
  SoccerIncidentId.ShotOnTarget,
  SoccerIncidentId.ShotOffTarget,
  SoccerIncidentId.ShotBlocked,
  SoccerIncidentId.ThrowIn,
  SoccerIncidentId.Offside,
  SoccerIncidentId.Foul,
  SoccerIncidentId.PenaltySavedByGoalkeeper,
  SoccerIncidentId.MissedPenalty,
  SoccerIncidentId.Injury,
  SoccerIncidentId.SubstitutionIn,
  SoccerIncidentId.GoalKick,
])

const pitchIncidentsWithoutSubstitutionIn: Set<SoccerIncidentId> = new Set(
  R.without([SoccerIncidentId.SubstitutionIn], [...pitchIncidents]),
)

const blockedPitchIncidentsMap: BlockedPitchIncidentsMap = new Map([
  [SoccerEventStatusId.Abandoned, pitchIncidents],
  [SoccerEventStatusId.Cancelled, pitchIncidents],
  [SoccerEventStatusId.NotStarted, pitchIncidents],
  [SoccerEventStatusId.StartDelayed, pitchIncidents],
  [SoccerEventStatusId.Postponed, pitchIncidents],
  [SoccerEventStatusId.Halftime, pitchIncidentsWithoutSubstitutionIn],
  [
    SoccerEventStatusId.WaitingForExtratime,
    pitchIncidentsWithoutSubstitutionIn,
  ],
  [SoccerEventStatusId.ExtratimeHalftime, pitchIncidentsWithoutSubstitutionIn],
  [SoccerEventStatusId.WaitingForPenalty, pitchIncidents],
  [
    SoccerEventStatusId.PenaltyShootout,
    new Set(
      R.without(
        [
          SoccerIncidentId.Goal,
          SoccerIncidentId.YellowCard,
          SoccerIncidentId.RedCard,
          SoccerIncidentId.MissedPenalty,
        ],
        [...pitchIncidents],
      ),
    ),
  ],
  [SoccerEventStatusId.FinishedAfterExtratime, pitchIncidents],
  [SoccerEventStatusId.FinishedAfterPenalties, pitchIncidents],
  [SoccerEventStatusId.FinishedAwardedWin, pitchIncidents],
  [SoccerEventStatusId.FinishedRegularTime, pitchIncidents],
  [SoccerEventStatusId.Interrupted, pitchIncidents],
  [SoccerEventStatusId.ToFinish, pitchIncidents],
])

export const pitchIncidentsConfig: PitchIncidentsConfig = {
  pitchIncidents,
  blockedPitchIncidentsMap,
}
