<script setup lang="ts">
import { computed } from 'vue'

import { flagChipCva, FlagChipProps } from '.'
import { FontAwesomeIconProps } from '../../Icon/fontAwesome'
import Icon from '../../Icon/Icon.vue'

interface Props {
  size?: FlagChipProps['size']
  variant?: FlagChipProps['variant']
  rounded?: FlagChipProps['rounded']
  indeterminate?: boolean
  // eslint-disable-next-line vue/prop-name-casing, @typescript-eslint/no-unsafe-function-type
  'onUpdate:modelValue'?: Function
}
const props = defineProps<Props>()

// model
const checked = defineModel<boolean>({ default: true })

// computed
const checkVariant = computed(() => {
  const result = checked.value ? 'checked' : 'unchecked'
  return props.indeterminate ? 'indeterminate' : result
})

const iconSize = computed(() => props.size as FontAwesomeIconProps['size'])
const interactive = computed(() => !!props['onUpdate:modelValue'])
</script>

<template>
  <div
    :class="
      flagChipCva({
        size,
        variant,
        rounded,
        color: checkVariant,
        interactive: interactive ? 'interactive' : undefined,
      })
    "
    :aria-label="`flag chip ${checkVariant}`"
    @click="interactive && (checked = !checked)"
  >
    <slot>
      <Icon
        :icon="indeterminate ? 'minus' : checked ? 'check' : 'xmark'"
        :size="iconSize"
      />
    </slot>
  </div>
</template>
-
