import { Ref } from 'vue'

import { QueueRabbitClient } from '@collector/queue-gateway-shared-client'
import { RabbitEventsScoutsMessage } from '@collector/queue-gateway-shared-types'
import { EventScout, ShortEventInfo } from '@collector/sportsapi-client-legacy'

import { fetchEventScouts } from '../../dependencies'
import { validateAssignedScouts } from '../validation'

async function updateEventScouts(
  eventScouts: Ref<EventScout[]>,
  eventScoutsUpdate: ShortEventInfo,
): Promise<void> {
  const scouts = await fetchEventScouts(eventScoutsUpdate.id)

  eventScouts.value = scouts
  validateAssignedScouts(scouts)
}

export function updateEventScoutsOnConnect(
  queueRabbitClient: QueueRabbitClient,
  eventScouts: Ref<EventScout[]>,
): void {
  queueRabbitClient.subScouts<RabbitEventsScoutsMessage>({
    onMessage: (message) => {
      updateEventScouts(eventScouts, message.data)
    },
  })
}
