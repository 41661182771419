export function nullCoalesce<A, B>(a: A, b: B): B | NonNullable<A> {
  return a ?? b
}

export function opChain<TValue, TReturn>(
  value: TValue | undefined | null,
  cb: (value: NoInfer<TValue>) => TReturn,
): TReturn | undefined {
  if (value !== undefined && value !== null && cb) return cb(value)
  else return undefined
}
