<script setup lang="ts">
import { computed } from 'vue'

import { Tooltip } from '@collector/shared-ui'
import { formatDatetime, formatTime } from '@collector/shared-utils'
import { Incident } from '@collector/sportsapi-client'

interface Props {
  incident: Incident
  isDeleted?: boolean
}

const props = defineProps<Props>()

// data
const createdTime = formatTime(props.incident.ct)
const createdDatetime = formatDatetime(props.incident.ct)

// computed
const confirmationDatetime = computed(() =>
  props.incident.confirmation_time
    ? formatDatetime(props.incident.confirmation_time)
    : '',
)
const updatedDatetime = computed(() => formatDatetime(props.incident.ut))
</script>

<template>
  <Tooltip>
    <div
      class="text-xs"
      :class="{
        'text-neutral-dark-2': !isDeleted,
        'text-neutral-dark-2/40': isDeleted,
      }"
    >
      {{ createdTime }}
    </div>
    <template #tooltip>
      <div class="font-bold">
        <div>{{ createdDatetime }}</div>
        <div v-if="confirmationDatetime">
          {{ confirmationDatetime }}
        </div>
        <div
          v-if="updatedDatetime !== createdDatetime"
          :class="{
            underline: incident.action === 'update',
            'line-through': incident.action === 'delete',
          }"
        >
          {{ updatedDatetime }}
        </div>
      </div>
    </template>
  </Tooltip>
</template>
