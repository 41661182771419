<script setup lang="ts">
import { ref } from 'vue'

import { showQueueView } from '@collector/desktop-feature-incidents-queue-ui'
import { Button, useBreakpoints } from '@collector/shared-ui'
import { injectEventIncidentsQueue } from '@desktop/views/Relation/provide-inject'

// inject
const eventIncidentsQueue = injectEventIncidentsQueue()
const { breakpoints } = useBreakpoints()

// state
const errorQueueIncidentsCount = ref(0)

eventIncidentsQueue.subscribeOnChange(({ errorQueueIncidents }) => {
  errorQueueIncidentsCount.value = errorQueueIncidents.length
})
</script>

<template>
  <Button
    class="bg-error-10 border-error-10 text-error-6 hover:bg-error-9 hover:border-error-9 font-bold"
    :class="{
      invisible: !errorQueueIncidentsCount,
      hidden:
        breakpoints.smallerOrEqual('lg').value && !errorQueueIncidentsCount,
    }"
    size="xs"
    width="slim"
    @click="showQueueView"
  >
    <span class="w-4 text-xs">{{ errorQueueIncidentsCount }}</span>
    <span class="px-0.25 text-base">!</span>
  </Button>
</template>
