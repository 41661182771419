import * as R from 'ramda'

import {
  CommonIncidentId,
  EventStatusId,
  IncidentId,
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'

import { PocketIncidents, PocketIncidentsConfig } from '../../../configuration'

const pocketIncidents: PocketIncidents = [
  [SoccerIncidentId.PossibleVAR, 'Possible VAR', 'var'],
  [SoccerIncidentId.VarEnded, 'VAR Ended', 'var-ended'],
  [SoccerIncidentId.AddedTime, 'Added time', 'added-time'],
  [CommonIncidentId.TechnicalProblems, 'Tech problem', 'bolt-lightning'],
]

const morePocketIncidents: PocketIncidents = [
  [CommonIncidentId.MatchResumed, 'Match resumed'],
  [SoccerIncidentId.WaterBreak, 'Water break'],
  [SoccerIncidentId.RefereesInjury, "Referee's injury"],
  [SoccerIncidentId.BreakDuringTheGame, 'Break during the game'],
]

const commonBlockedIncidents: Set<IncidentId> = new Set(
  R.without(
    [CommonIncidentId.TechnicalProblems],
    [...pocketIncidents, ...morePocketIncidents].map((a) => a[0]),
  ),
)

const blockedPocketIncidentsMap = new Map<EventStatusId, Set<IncidentId>>([
  [SoccerEventStatusId.Abandoned, commonBlockedIncidents],
  [SoccerEventStatusId.Cancelled, commonBlockedIncidents],
  [SoccerEventStatusId.NotStarted, commonBlockedIncidents],
  [SoccerEventStatusId.StartDelayed, commonBlockedIncidents],
  [SoccerEventStatusId.Postponed, commonBlockedIncidents],
  [SoccerEventStatusId.Halftime, commonBlockedIncidents],
  [SoccerEventStatusId.WaitingForExtratime, commonBlockedIncidents],
  [SoccerEventStatusId.ExtratimeHalftime, commonBlockedIncidents],
  [SoccerEventStatusId.WaitingForPenalty, commonBlockedIncidents],
  [SoccerEventStatusId.FinishedAfterExtratime, commonBlockedIncidents],
  [SoccerEventStatusId.FinishedAfterPenalties, commonBlockedIncidents],
  [SoccerEventStatusId.FinishedAwardedWin, commonBlockedIncidents],
  [SoccerEventStatusId.FinishedRegularTime, commonBlockedIncidents],
  [SoccerEventStatusId.Interrupted, commonBlockedIncidents],
  [SoccerEventStatusId.ToFinish, commonBlockedIncidents],
  [
    SoccerEventStatusId.PenaltyShootout,
    new Set(
      R.without(
        [SoccerIncidentId.PossibleVAR, SoccerIncidentId.VarEnded],
        [...commonBlockedIncidents],
      ),
    ),
  ],
])

export const pocketIncidentsConfig: PocketIncidentsConfig = {
  pocketIncidents,
  morePocketIncidents,
  blockedPocketIncidentsMap,
}
