import { LineupsShowResponse } from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'
import { SeasonSquadParticipant } from '../../participants/squad'

export async function post(
  httpClient: HttpClient,
  eventId: number,
  seasonSquadParticipant: SeasonSquadParticipant,
): Promise<LineupsShowResponse> {
  return httpClient
    .url(`/events/${eventId}/sub-participants`)
    .post(seasonSquadParticipant)
    .json()
}
