/**
 * based on below npm package, which is not maintained anymore and doesn't work with vite
 *
 * https://www.npmjs.com/package/self-adjusting-interval
 */

export type Callback = (ticks: number) => void

export type StopInterval = () => void

export function setSelfAdjustingInterval(
  callback: Callback,
  milliseconds: number,
): StopInterval {
  let expectedMilliseconds = Date.now() + milliseconds
  let shouldStop = false

  function doStep(): void {
    if (shouldStop) {
      return
    }

    const delta = Date.now() - expectedMilliseconds
    const ticks = Math.max(1, 1 + Math.round(delta / milliseconds))
    callback(ticks)

    const elapsed = milliseconds * ticks
    expectedMilliseconds += elapsed

    setTimeout(doStep, elapsed - delta)
  }

  setTimeout(doStep, milliseconds)

  function stopInterval(): void {
    shouldStop = true
  }

  return stopInterval
}
