import { computed, Ref, ref, watch } from 'vue'

import {
  formatTimePart,
  getMinuteWithSecond,
  getSecondsDiffFromNow,
} from '@collector/shared-utils'
import { ClockStatus, Event } from '@collector/sportsapi-client-legacy'

import { createTimer } from './timer'

export type Time = { minute: number; second: number }

export type UseEventClock = {
  eventTime: Ref<Time>
  eventTimeFormatted: Ref<{ minute: string; second: string }>
  startClock: () => void
  stopClock: () => void
  resetClockTime(value?: number): void
  clockRunning: Ref<boolean>
}

export function useEventClock(
  event: Ref<Event>,
  eventTimestamp: Ref<number>,
  periodTime: Ref<number>,
): UseEventClock {
  const { startTimer, stopTimer } = createTimer()

  const clockRunning = ref<boolean>(
    event.value.clock_status === ClockStatus.Running,
  )

  const elapsedSeconds = ref(0)
  const localClockTime = ref(0)

  const eventTimeSeconds = computed(
    () => periodTime.value + localClockTime.value + elapsedSeconds.value,
  )
  const eventTime = computed(() => getMinuteWithSecond(eventTimeSeconds.value))
  const eventTimeFormatted = computed(() => ({
    minute: formatTimePart(eventTime.value.minute),
    second: formatTimePart(eventTime.value.second),
  }))

  /**
   * when this composable is executed, eventTimestamp (provided from API) may be late few seconds comparing to now
   *
   * without calculating difference, started clock wouldn't be accurate
   */
  function getEventTimestampDiff(): number {
    return event.value.clock_status === ClockStatus.Running
      ? getSecondsDiffFromNow(eventTimestamp.value)
      : 0
  }

  function setClockTime(value?: number): void {
    localClockTime.value =
      value === undefined ? event.value.clock_time || 0 : value
  }

  function resetClockTime(value?: number): void {
    setClockTime(value)
    elapsedSeconds.value = 0

    if (clockRunning.value) {
      stopClock()
      startClock()
    }
  }

  function initClock(): void {
    setClockTime()
    elapsedSeconds.value = getEventTimestampDiff()
  }

  function startClock(): void {
    clockRunning.value = true
    startTimer((ticks) => {
      elapsedSeconds.value += ticks
    })
  }

  function stopClock(): void {
    clockRunning.value = false
    stopTimer()
  }

  // remote start / stop clock
  watch(
    () => event.value.clock_status,
    (newVal) => {
      if ((newVal === ClockStatus.Running) !== clockRunning.value) {
        initClock()

        if (clockRunning.value) stopClock()
        else startClock()
      }
    },
  )

  // remote change time
  watch(
    () => event.value.clock_time,
    (newVal) => {
      if (clockRunning.value) {
        const remoteClockTime =
          Number(newVal) - elapsedSeconds.value + getEventTimestampDiff()

        // 0-1 second diff is ignored, to avoid unnecessary clock reset and "jumping time" from UX perspective
        // (event.clock_time updates frequently when sending incidents to API)
        if (Math.abs(remoteClockTime - localClockTime.value) > 1) {
          initClock()
          stopClock()
          startClock()
        }
      } else {
        setClockTime()
        elapsedSeconds.value = 0
      }
    },
  )

  initClock()

  if (clockRunning.value) {
    startClock()
  }

  return {
    eventTime,
    eventTimeFormatted,
    startClock,
    stopClock,
    resetClockTime,
    clockRunning,
  }
}
