<script setup lang="ts">
import { computed, ref } from 'vue'

import { Button, PagedList } from '@collector/shared-ui'
import { useSportMaps } from '@desktop/views/Relation/composables'
import { isIncidentsGroup } from '@desktop/views/Relation/Sports/configuration/incidentsGroup'

import HistoryFilters from './HistoryFilters/HistoryFilters.vue'
import HistoryIncident from './HistoryIncident/HistoryIncident.vue'
import HistoryNoFilteredIncidentsInfo from './HistoryInfo/HistoryNoFilteredIncidentsInfo.vue'
import HistoryNoIncidentsInfo from './HistoryInfo/HistoryNoIncidentsInfo.vue'
import ManualAddIncidentModal from './ManualAddIncidentModal/ManualAddIncidentModal.vue'

// composables
const { getIncidentName } = useSportMaps()

// state
const filters = ref<InstanceType<typeof HistoryFilters> | null>(null)
const isModalVisible = ref(false)

// computed
const incidents = computed(() => filters.value?.filteredIncidents || [])
const isAnyFilterActive = computed(() => !!filters.value?.isAnyFilterActive)
</script>

<template>
  <div
    class="flex flex-col"
    data-test-id="HistoryList"
  >
    <HistoryFilters ref="filters">
      <Button
        class="bg-success-2 border-success-2 hover:border-success-4 hover:bg-success-4 text-neutral-10 font-bold"
        icon="plus"
        size="sm"
        @click="isModalVisible = true"
      >
        Manual add incident
      </Button>
      <ManualAddIncidentModal
        v-if="isModalVisible"
        @close="isModalVisible = false"
      />
    </HistoryFilters>

    <PagedList
      class="bg-neutral-light-10 flex-1 space-y-2 overflow-y-auto py-2"
      :items="incidents"
      reversed
    >
      <template #item="{ item }">
        <HistoryIncident
          :key="isIncidentsGroup(item) ? item.incidents[0].id : item.id"
          :incident="isIncidentsGroup(item) ? item.incidents[0] : item"
          :groupedIncident="
            isIncidentsGroup(item) ? item.incidents[1] : undefined
          "
          :incidentName="
            isIncidentsGroup(item)
              ? item.name
              : getIncidentName(item.incident_id)
          "
        />
      </template>
      <template #empty>
        <div class="py-8">
          <HistoryNoFilteredIncidentsInfo v-if="isAnyFilterActive" />
          <HistoryNoIncidentsInfo v-else />
        </div>
      </template>
    </PagedList>
  </div>
</template>
