type Callback = (token: string) => void

let onNewAccessTokenCallback: Callback

export function setOnNewAccessToken(callback: Callback): void {
  onNewAccessTokenCallback = callback
}

export function emitOnNewAccessToken(accessToken: string): void {
  if (onNewAccessTokenCallback) {
    onNewAccessTokenCallback(accessToken)
  }
}
