import type { ApiResponse, Event } from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'
import { Incident } from '../incidents/get'

/**
 * Delete existing incident
 *
 * `/v2/events/{eventId}/incidents-queue/{incidentId}`
 */
export async function _delete(
  httpClient: HttpClient,
  eventId: Event['id'],
  incidentId: Incident['id'],
): Promise<ApiResponse<{ uuid: string }>> {
  return httpClient
    .url(`/events/${eventId}/incidents-queue/${incidentId}`)
    .delete()
    .json()
}
