import {
  ApiResponse,
  type IncidentId,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type SwappableIncidentsConfiguration = {
  sameTeam: IncidentId[]
  rivalTeam: IncidentId[]
}

export type SwappableIncidentsConfigurationMap = Partial<
  Record<number, SwappableIncidentsConfiguration>
>

export type SwappableIncidentsGetResponse =
  ApiResponse<SwappableIncidentsConfigurationMap>

export async function get(
  httpClient: HttpClient,
  eventId: number,
): Promise<SwappableIncidentsGetResponse> {
  return httpClient.url(`/events/${eventId}/swappable-incidents`).get().json()
}
