import { jwtDecode } from 'jwt-decode'

import { SportsApiAccessToken } from '@desktop/utils/localStorage'

let timoutId: ReturnType<typeof setTimeout> | null = null

export function reauthenticateBeforeTokenExpiration(
  callback: () => void,
): void {
  const accessToken = SportsApiAccessToken.get()
  const reauthenticateBeforeExpirationMinutes = 1

  if (!accessToken) {
    return
  }

  const { exp } = jwtDecode(accessToken)

  if (!exp) {
    return
  }

  if (timoutId) {
    clearTimeout(timoutId)
  }

  timoutId = setTimeout(
    callback,
    exp * 1000 - Date.now() - reauthenticateBeforeExpirationMinutes * 60 * 1000,
  )
}
