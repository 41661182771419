export enum SoccerGoalAttribute {
  OwnGoal = 397,
  PenaltyGoal = 398,
  LeftFoot = 400,
  RightFoot = 401,
  FreeKick = 402,
  InsidePenaltyArea = 415,
  OutsidePenaltyArea = 416,
  Head = 417,
  AfterCorner = 418,
  AfterFastAttack = 419,
  AfterPositionalAttack = 420,
  UpperLeft = 421,
  UpperRight = 422,
  UpperMiddle = 423,
  BottomMiddle = 424,
  BottomLeft = 425,
  BottomRight = 426,
  ShotWoodwork = 439,
  Crossbar = 739,
  Post = 740,
}
