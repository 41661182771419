<script setup lang="ts">
import { ref } from 'vue'

import { SuccessBadge, TextInput } from '@collector/shared-ui'
import { Incident } from '@collector/sportsapi-client'
import { useEventIncidentsQueue } from '@desktop/views/Relation/Sports/composables'

interface Props {
  incident: Incident
  displayInfo: string
  disabled: boolean
}

const props = defineProps<Props>()

// composables
const eventIncidentsQueue = useEventIncidentsQueue()

// state
const value = ref(props.incident.display_info)
const successBadge = ref<InstanceType<typeof SuccessBadge> | null>(null)

// methods
function showSuccessBadge(): void {
  successBadge.value?.show()
}

function updateValue(newValue: string): void {
  if (newValue !== value.value) {
    eventIncidentsQueue.updateIncident(
      { display_info: newValue },
      props.incident,
    )

    value.value = newValue
    showSuccessBadge()
  }
}
</script>

<template>
  <TextInput
    class="relative"
    :modelValue="value"
    :maxlength="2"
    width="full"
    size="xs"
    :disabled
    numeric
    @blur="({ value }) => updateValue(value)"
  >
    {{ displayInfo }}

    <template #content>
      <SuccessBadge
        ref="successBadge"
        class="absolute right-0"
      />
    </template>
  </TextInput>
</template>
