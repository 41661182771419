<script setup lang="ts">
import { Icon, Separator } from '@collector/shared-ui'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { useCompetition } from '@desktop/views/Relation/composables/useCompetition'

// composables
const { competition } = useCompetition()

const links = await sportsApiClient.Links.get({ object_id: competition.id })

const officialSiteLink = links.api.data.links[0]
</script>

<template>
  <template v-if="officialSiteLink">
    <Separator
      class="h-5"
      vertical
      variant="dark"
    />
    <a
      class="hover:text-neutral-3 hover:bg-neutral-light-10 hover:border-neutral-dark-3/15 border-neutral-light-0 bg-neutral-light-7 text-neutral-dark-0 inline-flex h-5 items-center gap-x-1.5 rounded border pl-2.5 pr-2 text-xs transition"
      :href="officialSiteLink.url"
      target="_blank"
      @click.stop
    >
      <Icon
        icon="globe"
        size="xs"
      />

      Official website
    </a>
  </template>
</template>
