import { toValue } from '@collector/shared-utils'
import { Incident } from '@collector/sportsapi-client'
import { IncidentAttributeId } from '@collector/sportsapi-client-legacy'
import {
  FilterSubParticipantsOptions,
  isSubParticipantSetting,
  ShouldLoadOppositeSubParticipants,
  SubParticipantSettings,
  SubParticipantType,
} from '@desktop/views/Relation/Sports/configuration'
import { injectHistoryIncidentsConfig } from '@desktop/views/Relation/Sports/provide-inject'

type Settings =
  | {
      label: string
      required: boolean
      type: SubParticipantType
      shouldLoadOppositeSubParticipants: ShouldLoadOppositeSubParticipants
      keepSelectedOption: boolean
      filterSubParticipantsOptions: FilterSubParticipantsOptions
    }
  | undefined

type UseIncidentSettings = {
  attributeIds: IncidentAttributeId[] | undefined
  singleAttributes: boolean
  displayInfo?: string
  swappable: boolean

  getSubParticipantSettings(subParticipantType: SubParticipantType): Settings
}

export function useIncidentSettings(incident: Incident): UseIncidentSettings {
  // inject
  const historyIncidentsConfig = injectHistoryIncidentsConfig()

  // data
  function getSetting(
    subParticipantType: SubParticipantType,
  ): string | SubParticipantSettings | undefined {
    return toValue(
      historyIncidentsConfig[incident.incident_id]?.[subParticipantType],
      incident,
    )
  }

  // methods
  function getSettingRequired(subParticipantType: SubParticipantType): boolean {
    const setting = getSetting(subParticipantType)

    if (isSubParticipantSetting(setting)) {
      return !!setting.required
    }

    return false
  }

  function getSettingName(
    subParticipantType: SubParticipantType,
  ): string | undefined {
    const setting = getSetting(subParticipantType)

    if (isSubParticipantSetting(setting)) {
      return setting.name
    }

    return setting
  }

  function getSettingShouldLoadOppositeParticipant(
    subParticipantType: SubParticipantType,
  ): ShouldLoadOppositeSubParticipants {
    const setting = getSetting(subParticipantType)

    if (isSubParticipantSetting(setting)) {
      return setting.shouldLoadOppositeSubParticipants ?? false
    }

    return false
  }

  function getSettingFilterSubParticipantsOptionsHandler(
    subParticipantType: SubParticipantType,
  ): FilterSubParticipantsOptions {
    const setting = getSetting(subParticipantType)

    if (isSubParticipantSetting(setting)) {
      return setting.filterSubParticipantsOptions ?? ((options) => options)
    }

    return (options) => options
  }

  function getSettingKeepSelectedOption(
    subParticipantType: SubParticipantType,
  ): boolean {
    const setting = getSetting(subParticipantType)

    if (isSubParticipantSetting(setting)) {
      return setting.keepSelectedOption ?? false
    }

    return false
  }

  function getSubParticipantSettings(
    subParticipantType: SubParticipantType,
  ): Settings {
    const required = getSettingRequired(subParticipantType)
    const name = getSettingName(subParticipantType)
    const shouldLoadOppositeSubParticipants =
      getSettingShouldLoadOppositeParticipant(subParticipantType)
    const filterSubParticipantsOptions =
      getSettingFilterSubParticipantsOptionsHandler(subParticipantType)
    const keepSelectedOption = getSettingKeepSelectedOption(subParticipantType)

    if (name) {
      return {
        label: name,
        required,
        type: subParticipantType,
        keepSelectedOption,
        shouldLoadOppositeSubParticipants,
        filterSubParticipantsOptions,
      }
    }

    return undefined
  }

  const attributeIds = toValue(
    historyIncidentsConfig[incident.incident_id]?.attributeIds,
    incident,
  )

  const singleAttributes =
    !!historyIncidentsConfig[incident.incident_id]?.singleAttributes

  const swappable =
    toValue(
      historyIncidentsConfig[incident.incident_id]?.swappable,
      incident,
    ) ?? true

  const displayInfo = historyIncidentsConfig[incident.incident_id]?.displayInfo

  return {
    getSubParticipantSettings,
    attributeIds,
    singleAttributes,
    swappable,
    displayInfo,
  }
}
