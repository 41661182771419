<script setup lang="ts">
import { Button, Tooltip } from '@collector/shared-ui'
import { SubParticipantPosition } from '@collector/sportsapi-client-legacy'

interface Props {
  subParticipantPosition: SubParticipantPosition
  disabled?: boolean
  tooltip?: string
}

defineProps<Props>()

// data
const subParticipantPositionAcronyms: Partial<
  Record<SubParticipantPosition, string>
> = {
  [SubParticipantPosition.Goalkeeper]: 'GK',
  [SubParticipantPosition.FirstLineup]: '1ST',
  [SubParticipantPosition.Bench]: 'BEN',
  [SubParticipantPosition.Coach]: 'COA',
}
</script>

<template>
  <Tooltip>
    <Button
      class="font-bold hover:enabled:border-transparent"
      :class="{
        'cursor-pointer': !disabled,
        'bg-team-home-4 border-team-home-4 text-neutral-light-10 hover:bg-team-home-4/60':
          subParticipantPosition === SubParticipantPosition.Goalkeeper &&
          !disabled,
        'bg-success-2 border-success-2 text-neutral-light-10 hover:bg-success-2/60':
          subParticipantPosition === SubParticipantPosition.FirstLineup &&
          !disabled,
        'bg-neutral-light-7 border-neutral-light-0 text-neutral-dark-2 hover:bg-neutral-light-0':
          subParticipantPosition === SubParticipantPosition.Bench && !disabled,
        'bg-neutral-light-10 border-neutral-dark-3/15 text-neutral-dark-2 hover:bg-neutral-dark-3/15':
          subParticipantPosition === SubParticipantPosition.Coach && !disabled,
      }"
      :disabled
      width="slim"
      size="sm"
    >
      {{ subParticipantPositionAcronyms[subParticipantPosition] }}
    </Button>
    <template
      v-if="tooltip"
      #tooltip
    >
      {{ tooltip }}
    </template>
  </Tooltip>
</template>
