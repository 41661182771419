import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'

import { IncidentsChangeTeamPossessionConfig } from '../../../configuration'

export const incidentsChangeTeamPossessionConfig: IncidentsChangeTeamPossessionConfig =
  {
    [SoccerIncidentId.Goal]: { toTeamPossession: 'opposite' },
    [SoccerIncidentId.Penalty]: { toTeamPossession: 'current' },
    [SoccerIncidentId.Corner]: { toTeamPossession: 'current' },
    [SoccerIncidentId.ShotOnTarget]: { toTeamPossession: 'current' },
    [SoccerIncidentId.ShotOffTarget]: { toTeamPossession: 'current' },
    [SoccerIncidentId.ShotBlocked]: { toTeamPossession: 'current' },
    [SoccerIncidentId.ThrowIn]: { toTeamPossession: 'current' },
    [SoccerIncidentId.Offside]: { toTeamPossession: 'opposite' },
    [SoccerIncidentId.Foul]: { toTeamPossession: 'opposite' },
    [SoccerIncidentId.PenaltySavedByGoalkeeper]: {
      toTeamPossession: 'current',
    },
    [SoccerIncidentId.MissedPenalty]: { toTeamPossession: 'current' },
    [SoccerIncidentId.GoalKick]: { toTeamPossession: 'current' },
    [SoccerIncidentId.Attack]: { toTeamPossession: 'current' },
    [SoccerIncidentId.DangerousAttack]: { toTeamPossession: 'current' },
    [SoccerIncidentId.InPossession]: { toTeamPossession: 'current' },
  }
