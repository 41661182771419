<script lang="ts" setup>
import { ref } from 'vue'

import { Button, ConfirmModal } from '@collector/shared-ui'
import { useStatistics } from '@desktop/views/Relation/composables'

const { statsVisible } = useStatistics()
const isModalVisible = ref(false)
</script>

<template>
  <div
    class="flex w-fit cursor-pointer gap-2"
    @click="isModalVisible = true"
  >
    <Button
      class="bg-neutral-light-7 cursor-pointer"
      size="xs"
      icon="chevron-left"
    />
    Back to Relation view
  </div>
  <ConfirmModal
    v-if="isModalVisible"
    @confirm="statsVisible = false"
    @close="isModalVisible = false"
  >
    <template #title>Are you sure you want to leave Statistics?</template>
    <div>Not saved data will be lost.</div>
  </ConfirmModal>
</template>
