import memoizee from 'memoizee'

import {
  useBreakpoints as _useBreakpoints,
  breakpointsTailwind,
} from '@vueuse/core'

import { getScaledRemFactor } from '../utils'

const memoizedUseBreakpoints = memoizee(() => {
  const scaledRemFactor = getScaledRemFactor()

  const breakpointsTailwindScaled = Object.keys(breakpointsTailwind).reduce(
    (acc, _key) => {
      const key = _key as keyof typeof breakpointsTailwind
      acc[key] = breakpointsTailwind[key] * scaledRemFactor

      return acc
    },
    {} as Record<string, number>,
  )

  return _useBreakpoints(breakpointsTailwindScaled)
})

/**
 * tailwind breakpoints as reactive composable, useful when css breakpoint classes are not enough
 */
// eslint-disable-next-line
export function useBreakpoints() {
  const breakpoints = memoizedUseBreakpoints()

  return { breakpoints }
}
