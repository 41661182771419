import { BaseTransportOptions, Client, ClientOptions } from '@sentry/types'
import * as Sentry from '@sentry/vue'

import { captureFeedbackWithAttachments, getBeforeBreadcrumb } from './utils'
import type { SentryInitOptions } from './utils/types'

export * from './utils'

export function init(
  options: SentryInitOptions,
): Client<ClientOptions<BaseTransportOptions>> | undefined {
  if (options.dsn === '') {
    // eslint-disable-next-line no-console
    console.warn(
      '(@collector/shared-plugin-sentry-vue) dsn is empty string, Sentry is not initialized',
    )
    return
  }

  // https://docs.sentry.io/platforms/javascript/configuration/transports/#offline-caching
  const offlineTransport = Sentry.makeBrowserOfflineTransport(
    Sentry.makeFetchTransport,
  )

  const beforeBreadcrumb = options.urlsWithTrackedPayload
    ? getBeforeBreadcrumb(options.urlsWithTrackedPayload)
    : undefined

  return Sentry.init({
    transport: offlineTransport,
    replaysOnErrorSampleRate: 1,
    beforeBreadcrumb,
    normalizeDepth: 10,
    ...options,
  })
}

export default {
  ...Sentry,
  init,
  captureFeedbackWithAttachments,
}
