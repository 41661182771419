export const enum SoccerPersonStatId {
  ShotsOnTarget = 20,
  ShotsOffTarget = 21,
  Corners = 13,
  YellowCards = 8,
  RedCards = 9,
  TotalShots = 19,
  Fouls = 22,
  Offsides = 24,
  PenaltiesScored = 14,
  PenaltiesMissed = 15,
  PenaltiesGiven = 16,
  FreeKickGoals = 18,
  ShotsWoodwork = 27,
  ShotsBlocked = 28,
  GoalkeeperSaves = 29,
  Appearance = 35,
  FirstLineups = 36,
  Goals = 40,
  Assists = 41,
  MinutesPlayed = 34,
  OnBench = 37,
  Captain = 701,
  OwnGoals = 17,
  AerialDuelsWon = 33,
  PPosition = 717,
  Ps = 639,
  Pu = 697,
  Goals_1_15 = 772,
  Goals_16_30 = 773,
  Goals_31_45 = 774,
  Goals_46_60 = 775,
  Goals_61_75 = 776,
  Goals_76_90 = 777,
  MinutesPerGoal = 778,
  MinutesPerShot = 779,
  AverageMinutesPlayed = 780,
}
