import { ApiResponse, Participant } from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type ParticipantsGetResponse = ApiResponse<{
  participants: Participant[]
}>

export async function get(
  httpClient: HttpClient,
  eventId: number,
): Promise<ParticipantsGetResponse> {
  return httpClient.url(`/events/${eventId}/participants`).get().json()
}
