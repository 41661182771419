export enum SoccerEventStatusId {
  NotStarted = 1,
  Interrupted = 2,
  Cancelled = 3,
  Postponed = 5,
  StartDelayed = 6,
  Abandoned = 7,
  Halftime = 9,
  FinishedRegularTime = 11,
  FinishedAwardedWin = 12,
  FinishedAfterPenalties = 13,
  FinishedAfterExtratime = 14,
  Extratime = 15,
  FirstHalf = 33,
  SecondHalf = 34,
  ExtratimeFirstHalf = 35,
  ExtratimeSecondHalf = 36,
  ExtratimeHalftime = 37,
  WaitingForExtratime = 48,
  PenaltyShootout = 141,
  WaitingForPenalty = 142,
  ToFinish = 152,
}

export const soccerBreakEventStatuses = Object.seal([
  SoccerEventStatusId.Halftime,
  SoccerEventStatusId.WaitingForExtratime,
  SoccerEventStatusId.ExtratimeHalftime,
  SoccerEventStatusId.WaitingForPenalty,
] as SoccerEventStatusId[])
