import { SoccerStatId } from '@collector/sportsapi-client-legacy'

import { StatisticsConfig } from '../../../configuration'

export const statisticsConfig: StatisticsConfig = {
  team: [
    SoccerStatId.TotalShots,
    SoccerStatId.ShotsOnTarget,
    SoccerStatId.ShotsOffTarget,
    SoccerStatId.GoalkeeperSaves,
    SoccerStatId.Fouls,
    SoccerStatId.Offsides,
    SoccerStatId.FreeKicks,
    SoccerStatId.ThrowIns,
    SoccerStatId.Assists,
    SoccerStatId.BallPossessionPercentage,
    SoccerStatId.Goals,
    SoccerStatId.OwnGoals,
    SoccerStatId.PenaltiesGiven,
    SoccerStatId.PenaltiesScored,
    SoccerStatId.PenaltiesSaved,
    SoccerStatId.PenaltiesMissed,
    SoccerStatId.Attacks,
    SoccerStatId.DangerousAttacks,
    SoccerStatId.DangerousFreeKicks,
    SoccerStatId.GoalKicks,
    SoccerStatId.ShotWoodwork,
    SoccerStatId.GoalLineClearences,
    SoccerStatId.Tackles,
    SoccerStatId.Interceptions,
  ],
  player: [
    SoccerStatId.Goals,
    SoccerStatId.Assists,
    SoccerStatId.OwnGoals,
    SoccerStatId.YellowCards,
    SoccerStatId.RedCards,
    SoccerStatId.PenaltiesGiven,
    SoccerStatId.PenaltiesScored,
    SoccerStatId.PenaltiesSaved,
    SoccerStatId.PenaltiesMissed,
    SoccerStatId.FirstLineups,
    SoccerStatId.Bench,
    SoccerStatId.Appearance,
    SoccerStatId.CurrentGoalkeeper,
    SoccerStatId.TotalShots,
    SoccerStatId.ShotsOnTarget,
    SoccerStatId.ShotsOffTarget,
    SoccerStatId.ShotsBlocked,
    SoccerStatId.GoalkeeperSaves,
    SoccerStatId.Fouls,
    SoccerStatId.Offsides,
    SoccerStatId.Blocks,
    SoccerStatId.GoalLineClearences,
    SoccerStatId.ShotWoodwork,
    SoccerStatId.Tackles,
    SoccerStatId.Interceptions,
  ],
}
