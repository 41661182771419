import { Event } from '@collector/sportsapi-client-legacy'

const betMarketMasterBaseName = 'bet_master_status'

export type BetMarketType = 'main' | 'corners' | 'cards'

export type BetMarketEventKey = keyof Pick<
  Event,
  'bet_status' | 'bet_cards' | 'bet_corners'
>

export function translateBetMarketKeyToBetMarketType(
  key: BetMarketEventKey,
): BetMarketType {
  switch (key) {
    case 'bet_status':
      return 'main'
    case 'bet_cards':
      return 'cards'
    case 'bet_corners':
      return 'corners'
  }
}

export function isBetMarketUpdateEnabled(
  event: Event,
  market: BetMarketType,
): boolean {
  if (!event.betting) {
    return true
  }

  const betMarketMasterName =
    market === 'main'
      ? betMarketMasterBaseName
      : `${market}_${betMarketMasterBaseName}`
  const betStatus = event.betting.bet_statuses.find(
    (bet) => bet.name === betMarketMasterName,
  )

  return betStatus?.value === 'active'
}
