<template>
  <tr>
    <slot />
  </tr>
</template>

<style scoped>
:deep(td:first-child) {
  @apply rounded-l;
}

:deep(td:last-of-type) {
  @apply rounded-r;
}
</style>
