export enum SoccerEventDetailId {
  WeatherConditions = 8,
  // TODO: change common detail ids to `CommonEventDetailId`
  PeriodLength = 16,
  NumberOfPeriods = 17,
  FirstHalfStartDate = 19,
  SecondHalfStartDate = 20,
  PitchConditions = 36,
  FirstExtraHalfStartDate = 44,
  SecondExtraHalfStartDate = 45,
  ExtraPeriodLength = 50,
  ExtraPeriodType = 58,
  TimerDirection = 124,
  Attendance = 141,
  PenaltyShootoutStartDate = 150,
  InjuryTime = 151,
  BallMovement = 160,
  HasFormations = 178,
  KickOffTeam = 226,
  FirstHalfInjuryTime = 201,
  SecondHalfInjuryTime = 202,
  FirstExtraHalfInjuryTime = 203,
  SecondExtraHalfInjuryTime = 204,
  HasLineups = 205,
  PenaltyShootoutStarting = 227,
}
