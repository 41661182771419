import memoizee from 'memoizee'
import { computed, ComputedRef, ref, Ref } from 'vue'

import { getParticipantIdTeamSide, TeamSide } from '@collector/shared-ui-domain'
import { Incident, Participant } from '@collector/sportsapi-client'
import { EventSettingsRecord } from '@desktop/utils/database'

import { useEventSettings } from './useEventSettings'
import { useParticipants } from './useParticipants'

const memoizedUseTeamSides = memoizee(function (
  eventSettings: Ref<EventSettingsRecord>,
) {
  const currentFirstTeamSide = ref<TeamSide>('home')
  const currentSecondTeamSide = ref<TeamSide>('away')

  function changeSides(): void {
    const tmp = currentFirstTeamSide.value

    currentFirstTeamSide.value = currentSecondTeamSide.value
    currentSecondTeamSide.value = tmp
  }

  // initial switch based on loaded event settings value
  if (eventSettings.value.teamSideChanged) {
    changeSides()
  }

  return {
    currentFirstTeamSide,
    currentSecondTeamSide,
    changeSides,
  }
})

type UseTeamSides = {
  changeSides(): void

  /** home/away for team on left, reacts to changeSides */
  currentFirstTeamSide: Ref<TeamSide>

  /** home/away for team on right, reacts to changeSides */
  currentSecondTeamSide: Ref<TeamSide>

  getWithCurrentTeamSides<T>(
    homeValue: Ref<T>,
    awayValue: Ref<T>,
  ): [ComputedRef<T>, ComputedRef<T>]

  getParticipantTeamId(teamSide: TeamSide): Participant['id']

  getIncidentTeamSide(
    incident: Partial<Pick<Incident, 'participant_team_id'>>,
  ): TeamSide | null
}

export function useTeamSides(): UseTeamSides {
  const { eventSettings, updateEventSettings } = useEventSettings()
  const { homeParticipant, awayParticipant } = useParticipants()

  const {
    currentFirstTeamSide,
    currentSecondTeamSide,
    changeSides: _changeSides,
  } = memoizedUseTeamSides(eventSettings)

  function changeSides(): void {
    _changeSides()
    updateEventSettings({
      teamSideChanged: !eventSettings.value.teamSideChanged,
    })
  }

  function getWithCurrentTeamSides<T>(
    homeValue: Ref<T>,
    awayValue: Ref<T>,
  ): [ComputedRef<T>, ComputedRef<T>] {
    return [
      computed(() =>
        currentFirstTeamSide.value === 'home'
          ? homeValue.value
          : awayValue.value,
      ),
      computed(() =>
        currentSecondTeamSide.value === 'away'
          ? awayValue.value
          : homeValue.value,
      ),
    ]
  }

  function getIncidentTeamSide(
    incident: Partial<Pick<Incident, 'participant_team_id'>>,
  ): TeamSide | null {
    return getParticipantIdTeamSide(
      incident.participant_team_id,
      homeParticipant.value,
      awayParticipant.value,
    )
  }

  function getParticipantTeamId(teamSide: TeamSide): Participant['id'] {
    return teamSide === 'home'
      ? homeParticipant.value.id
      : awayParticipant.value.id
  }

  return {
    changeSides,
    currentFirstTeamSide,
    currentSecondTeamSide,
    getIncidentTeamSide,
    getParticipantTeamId,
    getWithCurrentTeamSides,
  }
}
