import wretch from 'wretch'
import QueryStringAddon from 'wretch/addons/queryString'
import { retry } from 'wretch/middlewares/retry'

import {
  isInternetDisconnectedError,
  isWretchError,
} from '@collector/shared-utils'
import { ApiErrorResponse } from '@collector/sportsapi-client-legacy'

import type { HttpClient } from '../httpClient'

export function getAuthorizationHeader(token: string): string {
  return `Bearer ${token}`
}

export function withAuthorizationHeader(
  httpClient: HttpClient,
  token: string,
): HttpClient {
  return httpClient.auth(getAuthorizationHeader(token))
}

export function withRetryMiddleware(
  httpClient: HttpClient,
  maxAttempts: number,
): HttpClient {
  return httpClient.middlewares([retry({ delayTimer: 100, maxAttempts })])
}

export function withTimezoneQueryStringParam(
  httpClient: HttpClient,
  timezone: string,
): HttpClient {
  return httpClient.query({ tz: timezone })
}

export function createHttpClient(baseUrl: string): HttpClient {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return withTimezoneQueryStringParam(
    wretch(baseUrl).addon(QueryStringAddon),
    timezone,
  )
}

export function createAuthClient(baseUrl: string, token: string): HttpClient {
  const httpClient = createHttpClient(baseUrl)
  const authClient = withAuthorizationHeader(httpClient, token)

  return authClient
}

export function getErrorMessage(error: unknown): string {
  if (isWretchError(error)) {
    const message = error.json
      ? (error.json as ApiErrorResponse).api.error.message
      : error.text

    return message ?? ''
  }

  if (isInternetDisconnectedError(error)) {
    return 'Network error. Check your internet connection and try again.'
  }

  if (error instanceof Error) {
    return error.message
  }

  return String(error)
}
