<script setup lang="ts">
import * as R from 'ramda'
import { computed, ref, watch } from 'vue'

import { Expand, MultiSelect, Toggle } from '@collector/shared-ui'
import { Incident } from '@collector/sportsapi-client'
import { useIncidents } from '@desktop/views/Relation/composables'
import {
  IncidentsGroup,
  isIncidentsGroup,
} from '@desktop/views/Relation/Sports/configuration/incidentsGroup'
import { watchArray } from '@vueuse/core'

import FilterTagSelect from './FilterTagSelect.vue'
import FilterTagToggle from './FilterTagToggle.vue'
import { useIncidentFilters } from './useIncidentFilters'
import { useIncidentsGroup } from './useIncidentsGroup'
import { filterIncidents } from './utils'

// composables
const { incidentsUpdate } = useIncidents()
const { incidentsGroups } = useIncidentsGroup()
const { incidentFiltersToggle, incidentFiltersSelect, activeFilterFunctions } =
  useIncidentFilters()

// state
const filteredIncidents = ref<(Incident | IncidentsGroup)[]>([])

// computed
const isAnyFilterActive = computed(() => !!activeFilterFunctions.value.length)

// methods
function filterAllIncidents(): void {
  filteredIncidents.value = filterIncidents(
    incidentsGroups.value,
    activeFilterFunctions.value,
  )
}

// init
filterAllIncidents()

// watchers
watch(
  activeFilterFunctions,
  filterAllIncidents,
) /** full filtering every time any filter changes */

watchArray(
  incidentsGroups,
  (newVal, oldVal) => {
    if (newVal.length > oldVal.length) {
      const newIncidents = newVal.slice(oldVal.length)

      const newFilteredIncidents = filterIncidents(
        newIncidents,
        activeFilterFunctions.value,
      )

      if (newFilteredIncidents.length) {
        filteredIncidents.value.push(...newIncidents)
      }
    }
  },
  { deep: true },
)

watch(incidentsUpdate, (newVal) => {
  /**
   * for 'update' and 'delete', either remove or keep modified incident on current filtered view
   */
  for (const incidentUpdate of newVal) {
    if (incidentUpdate.data.action !== 'insert') {
      const incidentIndex = R.findLastIndex((value) => {
        if (isIncidentsGroup(value)) {
          return value.incidents.some(
            (incident) => incident.id === incidentUpdate.data.id,
          )
        }

        return value.id === incidentUpdate.data.id
      }, filteredIncidents.value)

      const isIncidentIncludedInFilters =
        incidentIndex !== -1
          ? filterIncidents(
              [filteredIncidents.value[incidentIndex]],
              activeFilterFunctions.value,
            ).length === 1
          : true

      if (isIncidentIncludedInFilters) {
        if (incidentIndex === -1) {
          // incident is not visible on current filtered view, run full filtering
          // (because incident can appear on any place)
          filterAllIncidents()
        }
        // else - incident is still visible on current filters, do nothing
        //
      } else if (incidentIndex !== -1) {
        filteredIncidents.value.splice(incidentIndex, 1)
      }
      // else - incident wasn't visible on current filters, do nothing
    }
  }
})

// expose
defineExpose({
  filteredIncidents,
  isAnyFilterActive,
})
</script>

<template>
  <Expand
    class="select-none"
    variant="header"
  >
    <template #title>Filters & Manual add incident</template>

    <template
      v-if="activeFilterFunctions.length"
      #collapsed
    >
      <div class="flex flex-wrap gap-1 px-4 pb-2">
        <FilterTagSelect
          v-for="incidentFilter in incidentFiltersSelect"
          :key="incidentFilter.name"
          :incidentFilter
          @delete="
            Array.isArray(incidentFilter.value)
              ? (incidentFilter.value = [])
              : (incidentFilter.value = undefined)
          "
        />
        <FilterTagToggle
          v-for="incidentFilter in incidentFiltersToggle"
          :key="incidentFilter.name"
          :incidentFilter
          @delete="incidentFilter.value = false"
        />
      </div>
    </template>

    <div class="space-y-1 px-4 pb-2">
      <div class="grid grid-cols-6 gap-x-[0.5vw] gap-y-1.5">
        <MultiSelect
          v-for="(incidentFilter, index) in incidentFiltersSelect"
          :key="index"
          v-model="incidentFilter.value"
          :class="index < 2 ? 'col-span-3' : 'col-span-2'"
          :options="incidentFilter.options"
          :withFilter="incidentFilter.withFilter"
        >
          {{ incidentFilter.name }}
        </MultiSelect>
      </div>
      <div class="flex gap-x-4">
        <Toggle
          v-for="incidentFilter in incidentFiltersToggle"
          :key="incidentFilter.name"
          v-model="incidentFilter.value"
        >
          Show {{ incidentFilter.name }}
        </Toggle>
      </div>

      <div class="pt-2">
        <slot />
      </div>
    </div>
  </Expand>
</template>
