import {
  ApiResponse,
  Incident,
  type Event,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../../httpClient'

export type SwapIncidentsPostPayload = {
  event_incident_id: number
  incident_id: number
  team_id: number
}

export type SwapIncidentsPostResponse = ApiResponse<{
  deletePushMessageUuid: string
  deletedIncident: Incident
  message: string
  storePushMessageUuid: string
  storedIncident: Incident
}>

/**
 * Swaps given incident with another one.
 *
 * `/v2/events/{eventId}/incidents/swap`
 */
export async function post(
  httpClient: HttpClient,
  eventId: Event['id'],
  payload: SwapIncidentsPostPayload,
): Promise<SwapIncidentsPostResponse> {
  return httpClient
    .url(`/events/${eventId}/incidents/swap`)
    .post(payload)
    .json()
}
