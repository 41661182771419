<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { Overlay } from '@collector/shared-ui'
import {
  hasIncidentPosition,
  isIncidentAddedByUser,
  isIncidentEditable,
  TeamSide,
} from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import * as AuthInfo from '@desktop/globalState/authInfo'
import {
  useEventSettings,
  useIncidents,
  useParticipants,
  useSportMaps,
} from '@desktop/views/Relation/composables'
import { useEventIncidentsQueue } from '@desktop/views/Relation/Sports/composables'
import {
  injectIncidentsRequirePointingPosition,
  injectPitchInteractionDisabledStatuses,
} from '@desktop/views/Relation/Sports/provide-inject'

import { getPercentagePositionWithTeamSideChanged } from '../../Field/utils'
import BallPositionIndicator from './PointOutIncidentBallPositionIndicator.vue'

// emits
const emit = defineEmits<{
  open: [open: boolean]
}>()

// composables
const { getIncidentName } = useSportMaps()
const { incidents, incidentsUpdate } = useIncidents()
const eventIncidentsQueue = useEventIncidentsQueue()
const { eventSettings } = useEventSettings()
const { homeParticipant } = useParticipants()

// inject
const incidentsRequirePointingPositionConfig =
  injectIncidentsRequirePointingPosition()
const pitchInteractionDisabledStatuses =
  injectPitchInteractionDisabledStatuses()

// data
const incidentsRequirePointingPositionSet = new Set(
  incidentsRequirePointingPositionConfig.map(({ incidentId }) => incidentId),
)
const scoutUserId = AuthInfo.get().id

// state
const currentIncidentsWithoutPosition = ref<Incident[]>(
  filterIncidentsWithoutPosition(incidents.value),
)

const currentIncidentToUpdate = computed(() => {
  return currentIncidentsWithoutPosition.value[0]
})

const open = computed(() => {
  return !!currentIncidentToUpdate.value
})

// methods
function filterIncidentsWithoutPosition(incidents: Incident[]): Incident[] {
  return incidents.filter(
    (incident) =>
      !pitchInteractionDisabledStatuses.has(incident.status_id) &&
      incidentsRequirePointingPositionSet.has(incident.incident_id) &&
      isIncidentEditable(incident) &&
      !hasIncidentPosition(incident) &&
      isIncidentAddedByUser(incident, scoutUserId),
  )
}

function deleteIncidentFromState(incidentToDelete: Incident): void {
  const incidentIndex = currentIncidentsWithoutPosition.value.findIndex(
    (incident) => incident.id === incidentToDelete.id,
  )

  if (incidentIndex !== -1) {
    currentIncidentsWithoutPosition.value.splice(incidentIndex, 1)
  }
}

function storeIncidentIfRequired(incident: Incident): void {
  if (incidentsRequirePointingPositionSet.has(incident.incident_id)) {
    currentIncidentsWithoutPosition.value = [
      ...currentIncidentsWithoutPosition.value,
      incident,
    ]
  }
}

function saveIncidentPosition(clickedPosition: { x: number; y: number }): void {
  const teamSide: TeamSide =
    currentIncidentToUpdate.value.participant_team_id ===
    homeParticipant.value.id
      ? 'home'
      : 'away'
  const positionWithTeamSidesChanged = getPercentagePositionWithTeamSideChanged(
    clickedPosition,
    !!eventSettings.value.teamSideChanged,
  )
  const position = incidentsRequirePointingPositionConfig
    .find(
      ({ incidentId }) =>
        incidentId === currentIncidentToUpdate.value.incident_id,
    )!
    .transformPosition(positionWithTeamSidesChanged, teamSide)
  const payload = {
    x_pos: position.x,
    y_pos: position.y,
  }

  eventIncidentsQueue.updateIncident(payload, currentIncidentToUpdate.value)
}

// watchers
watch(incidentsUpdate, (incidentMessages) => {
  for (const incidentMessage of incidentMessages) {
    const shouldBeProcessed =
      incidentMessage.source === scoutUserId &&
      !pitchInteractionDisabledStatuses.has(incidentMessage.data.status_id)

    if (!shouldBeProcessed) {
      continue
    }

    switch (incidentMessage.data.action) {
      case 'delete':
        deleteIncidentFromState(incidentMessage.data)
        break
      case 'update':
        if (hasIncidentPosition(incidentMessage.data)) {
          deleteIncidentFromState(incidentMessage.data)
        }
        break
      case 'insert':
        storeIncidentIfRequired(incidentMessage.data)
        break
    }
  }
})

watch(
  open,
  () => {
    emit('open', open.value)
  },
  { immediate: true },
)
</script>

<template>
  <Overlay
    position="contained"
    :modelValue="open"
    scrim="none"
  >
    <BallPositionIndicator @click="saveIncidentPosition">
      <div
        class="absolute left-0 top-0 w-full"
        :style="{ containerType: 'size' }"
      >
        <div class="w-full pt-6 text-center text-xl font-thin opacity-50">
          <div class="font-bold">
            {{ getIncidentName(currentIncidentToUpdate.incident_id) }}
          </div>
          <div>Point out the position on the field</div>
        </div>
      </div>
    </BallPositionIndicator>
  </Overlay>
</template>
