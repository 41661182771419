import {
  BaseParticipant,
  Event,
  LineupsShowResponse,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type SubParticipantsPatchPayload = {
  position?: SubParticipantPosition | null
  lineup_id: number
  id: number | null
  shirt_nr?: string | null
  short_name: string
  player_position?: string | null
  stats?: BaseParticipant['stats']
}

/**
 * isLineupsModal:
 *    * true - API sends lineups message to queue
 *    * false - API updates stats
 */
export async function patch(
  httpClient: HttpClient,
  eventId: Event['id'],
  squad: SubParticipantsPatchPayload[],
  isLineupsModal = true,
): Promise<LineupsShowResponse> {
  const metadata = isLineupsModal ? { isLineupsModal: true } : {}

  return httpClient
    .url(`/events/${eventId}/sub-participants`)
    .patch({ data: squad, ...metadata })
    .json()
}
