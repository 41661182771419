import { cva, VariantProps } from 'class-variance-authority'

export { default as Overlay } from './Overlay.vue'

export const variants = {
  position: {
    contained: 'absolute w-full h-full',
    screen: 'fixed w-screen h-screen',
  },
  scrim: {
    none: '',
    sm: 'bg-neutral-light-3/80',
    md: 'bg-neutral-light-5/80',
    lg: 'bg-neutral-light-7/80',
  },
} as const

export const overlayCva = cva('inset-0 flex items-center justify-center z-40', {
  variants,
  defaultVariants: {
    position: 'screen',
    scrim: 'md',
  },
})
export type OverlayProps = VariantProps<typeof overlayCva>
