import {
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'

import { ManualAddIncidentConfig } from '../../../configuration'

export const manualAddIncidentConfig: ManualAddIncidentConfig<
  SoccerIncidentId,
  SoccerEventStatusId
> = {
  keyIncidents: [
    SoccerIncidentId.Goal,
    SoccerIncidentId.Penalty,
    SoccerIncidentId.MissedPenalty,
    SoccerIncidentId.PenaltySavedByGoalkeeper,
    SoccerIncidentId.Corner,
    SoccerIncidentId.YellowCard,
    SoccerIncidentId.RedCard,
    SoccerIncidentId.SubstitutionIn,
  ],
  otherIncidents: [
    SoccerIncidentId.ShotOnTarget,
    SoccerIncidentId.ShotOffTarget,
    SoccerIncidentId.ShotBlocked,
    SoccerIncidentId.Offside,
    SoccerIncidentId.Foul,
    SoccerIncidentId.ThrowIn,
    SoccerIncidentId.Injury,
    SoccerIncidentId.Tackle,
    SoccerIncidentId.Interception,
  ],
  eventStatuses: [
    SoccerEventStatusId.FirstHalf,
    SoccerEventStatusId.SecondHalf,
    SoccerEventStatusId.Halftime,
    SoccerEventStatusId.WaitingForExtratime,
    SoccerEventStatusId.ExtratimeFirstHalf,
    SoccerEventStatusId.ExtratimeSecondHalf,
    SoccerEventStatusId.ExtratimeHalftime,
    SoccerEventStatusId.PenaltyShootout,
  ],
}
