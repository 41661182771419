import { createProvideInject } from '@collector/shared-ui'

import { PitchOverlaySupportedStatuses } from '../configuration/pitchOverlaySupportedStatuses'

export const [
  providePitchOverlaySupportedStatuses,
  injectPitchOverlaySupportedStatuses,
] = createProvideInject<PitchOverlaySupportedStatuses>(
  'pitchOverlaySupportedStatuses',
)
