<script setup lang="ts">
import { Incident } from '@collector/sportsapi-client'
import { useSportMaps } from '@desktop/views/Relation/composables'

interface Props {
  incident: Incident
  isDeleted?: boolean
}

const props = defineProps<Props>()

// composables
const { eventStatusToSportStatusMap } = useSportMaps()

// data
const eventStatusName =
  eventStatusToSportStatusMap[props.incident.status_id].name
</script>

<template>
  <div
    class="text-xs"
    :class="{
      'text-neutral-dark-3/40': isDeleted,
    }"
  >
    {{ eventStatusName }}
  </div>
</template>
