import {
  FreeKickAttribute,
  MissedPenaltyAttribute,
  RedCardAttribute,
  ShotBlockedAttribute,
  ShotOffTargetAttribute,
  ShotOnTargetAttribute,
  SoccerEventStatusId,
  SoccerGoalAttribute,
  SoccerIncidentId,
  SoccerPersonStatId,
  VideoAssistantRefereeAttribute,
  YellowCardAttribute,
} from '@collector/sportsapi-client-legacy'
import { SubParticipantOption } from '@desktop/views/Relation/utils'

import { HistoryIncidentsConfig } from '../../../configuration'

function isSubParticipantOnBench(option: SubParticipantOption): boolean {
  return (
    option.metadata.stats[SoccerPersonStatId.OnBench] === 1 &&
    option.metadata.stats[SoccerPersonStatId.Appearance] === 0
  )
}

export const historyIncidentsConfig: HistoryIncidentsConfig<SoccerIncidentId> =
  {
    [SoccerIncidentId.SubstitutionIn]: {
      participant: {
        name: 'in',
        required: true,
        keepSelectedOption: true,
        filterSubParticipantsOptions: (options) => {
          return options.filter(isSubParticipantOnBench)
        },
      },
    },
    [SoccerIncidentId.SubstitutionOut]: {
      participant: {
        name: 'out',
        required: true,
        keepSelectedOption: true,
        filterSubParticipantsOptions: (options) => {
          return options.filter((option) => !isSubParticipantOnBench(option))
        },
      },
    },
    [SoccerIncidentId.Goal]: {
      participant: {
        name: 'scorer',
        shouldLoadOppositeSubParticipants: (attributeIds) =>
          attributeIds.includes(SoccerGoalAttribute.OwnGoal),
        required: true,
      },
      assistant: (incident) => {
        switch (incident.status_id) {
          case SoccerEventStatusId.PenaltyShootout:
            return undefined
          default:
            return {
              name: 'assistant',
              shouldLoadOppositeSubParticipants: (attributeIds) =>
                attributeIds.includes(SoccerGoalAttribute.OwnGoal),
            }
        }
      },
      attributeIds: (incident) => {
        switch (incident.status_id) {
          case SoccerEventStatusId.PenaltyShootout:
            return undefined
          default:
            return [
              SoccerGoalAttribute.PenaltyGoal,
              SoccerGoalAttribute.OwnGoal,
            ]
        }
      },
      singleAttributes: true,
    },
    [SoccerIncidentId.Penalty]: {
      participant: {
        name: 'penalty taker',
        required: true,
      },
      assistant: 'penalty winner',
    },
    [SoccerIncidentId.MissedPenalty]: {
      participant: {
        name: 'executor',
        required: true,
      },
      attributeIds: [MissedPenaltyAttribute.ShotWoodwork],
      swappable: (incident) =>
        incident.status_id !== SoccerEventStatusId.PenaltyShootout,
    },
    [SoccerIncidentId.PenaltySavedByGoalkeeper]: {
      participant: {
        name: 'executor',
        required: true,
      },
    },
    [SoccerIncidentId.RedCard]: {
      participant: {
        name: 'given',
        required: true,
      },
      attributeIds: [
        RedCardAttribute.SecondYellowCard,
        RedCardAttribute.OffField,
      ],
    },
    [SoccerIncidentId.YellowCard]: {
      participant: {
        name: 'given',
        required: true,
      },
      attributeIds: [YellowCardAttribute.OffField],
    },
    [SoccerIncidentId.ShotOnTarget]: {
      participant: 'taken by',
      assistant: {
        name: 'goal line clearer',
        shouldLoadOppositeSubParticipants: true,
      },
      attributeIds: [
        ShotOnTargetAttribute.GoallineClearance,
        ShotOnTargetAttribute.InsidePenaltyArea,
        ShotOnTargetAttribute.OutsidePenaltyArea,
        ShotOnTargetAttribute.LeftFoot,
        ShotOnTargetAttribute.RightFoot,
        ShotOnTargetAttribute.Head,
        ShotOnTargetAttribute.UpperLeft,
        ShotOnTargetAttribute.UpperRight,
        ShotOnTargetAttribute.UpperMiddle,
        ShotOnTargetAttribute.BottomMiddle,
        ShotOnTargetAttribute.BottomLeft,
        ShotOnTargetAttribute.BottomRight,
      ],
    },
    [SoccerIncidentId.ShotOffTarget]: {
      participant: 'taken by',
      attributeIds: [
        ShotOffTargetAttribute.ShotWoodwork,
        ShotOffTargetAttribute.Crossbar,
        ShotOffTargetAttribute.Post,
        ShotOffTargetAttribute.InsidePenaltyArea,
        ShotOffTargetAttribute.OutsidePenaltyArea,
        ShotOffTargetAttribute.LeftFoot,
        ShotOffTargetAttribute.RightFoot,
        ShotOffTargetAttribute.Head,
      ],
    },
    [SoccerIncidentId.ShotBlocked]: {
      participant: 'taken by',
      assistant: {
        name: 'blocked by',
        shouldLoadOppositeSubParticipants: true,
      },
      attributeIds: [
        ShotBlockedAttribute.InsidePenaltyArea,
        ShotBlockedAttribute.OutsidePenaltyArea,
        ShotBlockedAttribute.LeftFoot,
        ShotBlockedAttribute.RightFoot,
        ShotBlockedAttribute.Head,
      ],
    },
    [SoccerIncidentId.Foul]: { participant: 'foul by' },
    [SoccerIncidentId.FreeKick]: {
      attributeIds: [FreeKickAttribute.DangerousFreeKick],
    },
    [SoccerIncidentId.Offside]: { participant: 'caught' },
    [SoccerIncidentId.Injury]: { participant: 'caught' },
    [SoccerIncidentId.PossibleVAR]: {
      attributeIds: [
        VideoAssistantRefereeAttribute.PossibleGoal,
        VideoAssistantRefereeAttribute.PossiblePenalty,
        VideoAssistantRefereeAttribute.PossibleRedCard,
      ],
      singleAttributes: true,
    },
    [SoccerIncidentId.AddedTime]: {
      displayInfo: 'minutes',
    },
  }
