import { Participant, SubParticipant } from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'

import { getTeamIdToSubParticipantsMap } from './subParticipant'

export async function fetchSubParticipantsForParticipant(
  eventId: number,
  participantTeamId: Participant['id'],
): Promise<SubParticipant[]> {
  const subParticipants =
    await sportsApiClient.Events.SubParticipants.get(eventId)

  const teamIdToSubParticipantsMap = getTeamIdToSubParticipantsMap(
    subParticipants.api.data.sub_participants,
  )

  return teamIdToSubParticipantsMap[participantTeamId] || []
}
