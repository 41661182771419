<script setup lang="ts">
import { Button } from '@collector/shared-ui'

import PlayerName from '../PlayerName.vue'
import { Player } from '../utils'

interface Props {
  players: Player[]
}
withDefaults(defineProps<Props>(), { players: () => [] })

type Emits = {
  playerRemove: [Player]
}
const emit = defineEmits<Emits>()
</script>

<template>
  <div
    v-for="player in players"
    :key="'lineup_id' in player ? player.lineup_id : player.id"
    class="bg-neutral-light-10 flex items-center gap-x-4 rounded p-1"
  >
    <slot v-bind="{ player }" />

    <PlayerName :player>
      <slot name="playerName" />
    </PlayerName>

    <Button
      class="bg-error-6 text-neutral-light-10"
      icon="x"
      square
      size="sm"
      @click="emit('playerRemove', player)"
    />
  </div>
</template>
