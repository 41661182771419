import { EventDetailId } from '@collector/sportsapi-client-legacy'

export type ExtraOptions = {
  withClear?: boolean
  width: 'wide' | 'medium' | 'narrow' // due to lack of auto-width for longest option in select, it must be defined manually
}

export type EventInfoEventDetail = EventDetailId | [EventDetailId, ExtraOptions]

export function isEventDetailId(
  eventInfoEventDetail: EventInfoEventDetail,
): eventInfoEventDetail is EventDetailId {
  return !Array.isArray(eventInfoEventDetail)
}
