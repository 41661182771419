<script setup lang="ts">
import { computed } from 'vue'

import { CommonResultId } from '@collector/sportsapi-client-legacy'
import { useParticipants } from '@desktop/views/Relation/composables'
import { injectFirstHalfResultId } from '@desktop/views/Relation/Sports/provide-inject'

// composables
const { getHomeParticipantResult, getAwayParticipantResult } = useParticipants()

// inject
const firstHalfResultId = injectFirstHalfResultId()

// computed
const homeParticipantResult = getHomeParticipantResult(CommonResultId.Result)
const awayParticipantResult = getAwayParticipantResult(CommonResultId.Result)

const homeParticipantResultFirstHalf =
  getHomeParticipantResult(firstHalfResultId)
const awayParticipantResultFirstHalf =
  getAwayParticipantResult(firstHalfResultId)

const hasFirstHalfResult = computed(
  () =>
    homeParticipantResultFirstHalf.value !== null &&
    awayParticipantResultFirstHalf.value !== null,
)
</script>

<template>
  <div class="text-neutral-dark-3 mx-5 min-w-28 text-center">
    <div class="mb-1 text-nowrap text-4xl font-bold">
      {{ homeParticipantResult }} : {{ awayParticipantResult }}
    </div>
    <div
      v-if="hasFirstHalfResult"
      class="text-xs"
    >
      HT ({{ homeParticipantResultFirstHalf }} :
      {{ awayParticipantResultFirstHalf }})
    </div>
  </div>
</template>
