<script setup lang="ts">
import { computed } from 'vue'

import { TeamSide } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'
import { useTeamSides } from '@desktop/views/Relation/composables'

import PossessionAwayAttack from './PossessionAwayAttack.svg'
import PossessionAwayDangerousAttack from './PossessionAwayDangerousAttack.svg'
import PossessionAwayDefaultIndicator from './PossessionAwayDefaultIndicator.svg'
import PossessionAwayInPossession from './PossessionAwayInPossession.svg'
import PossessionHomeAttack from './PossessionHomeAttack.svg'
import PossessionHomeDangerousAttack from './PossessionHomeDangerousAttack.svg'
import PossessionHomeDefaultIndicator from './PossessionHomeDefaultIndicator.svg'
import PossessionHomeInPossession from './PossessionHomeInPossession.svg'

interface Props {
  teamSideInPossession: TeamSide
  incident: Incident | null
}

const props = defineProps<Props>()

// composables
const { getIncidentTeamSide } = useTeamSides()

// methods
function getPossesionDefaultIndicator(): string {
  return props.teamSideInPossession === 'home'
    ? PossessionHomeDefaultIndicator
    : PossessionAwayDefaultIndicator
}

// computed
const indicatorImage = computed(() => {
  // after changePossession() - show default indicator
  if (
    props.incident &&
    getIncidentTeamSide(props.incident) !== props.teamSideInPossession
  ) {
    return getPossesionDefaultIndicator()
  }

  switch (props.incident?.incident_id) {
    case SoccerIncidentId.InPossession:
      return props.teamSideInPossession === 'home'
        ? PossessionHomeInPossession
        : PossessionAwayInPossession
    case SoccerIncidentId.Attack:
      return props.teamSideInPossession === 'home'
        ? PossessionHomeAttack
        : PossessionAwayAttack
    case SoccerIncidentId.DangerousAttack:
      return props.teamSideInPossession === 'home'
        ? PossessionHomeDangerousAttack
        : PossessionAwayDangerousAttack
    default:
      return getPossesionDefaultIndicator()
  }
})
</script>

<template>
  <img
    class="pointer-events-none absolute h-full select-none"
    :class="{
      'left-0': props.teamSideInPossession === 'home',
      'right-0': props.teamSideInPossession === 'away',
    }"
    :src="indicatorImage"
    alt="Possession indicator"
  />
</template>
