import { Competition } from '@collector/sportsapi-client-legacy'

import { injectCompetition } from '../provide-inject'

type UseCompetition = {
  competition: Competition
}

export function useCompetition(): UseCompetition {
  const competition = injectCompetition()

  return { competition }
}
