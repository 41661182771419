<script setup lang="ts">
import { computed, StyleValue } from 'vue'

interface Props {
  position: { x: number; y: number }
}

const props = defineProps<Props>()

// computed
const position = computed<StyleValue>(() => ({
  width: '1.25rem',
  height: '1.25rem',
  top: `calc(${props.position.y}% - 0.625rem)`, // ball height is 20px, we need half
  left: `calc(${props.position.x}% - 0.625rem)`, // ball width is 20px, we need half
}))
</script>

<template>
  <img
    class="pointer-events-none absolute h-7 w-7 select-none"
    src="./ball.svg"
    :style="position"
  />
</template>
