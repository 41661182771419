import {
  Confirmation,
  IncidentActionType,
} from '@collector/sportsapi-client-legacy'
import { Incident } from '@collector/sportsapi-types'

export function isIncidentActive(incident: Incident): boolean {
  return (
    incident.confirmation !== Confirmation.Cancelled &&
    incident.action !== IncidentActionType.DELETE
  )
}
