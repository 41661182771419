import { ref, Ref } from 'vue'
import { useRouter } from 'vue-router'

import { ConfirmQueueIncident } from '@collector/desktop-feature-incidents-queue-core'
import { QueueRabbitClient } from '@collector/queue-gateway-shared-client'
import {
  ClientErrorType,
  RabbitErrorMessage,
} from '@collector/queue-gateway-shared-types'
import { Incident } from '@collector/sportsapi-client'
import {
  Event,
  EventScout,
  Participant,
  SubParticipant,
} from '@collector/sportsapi-client-legacy'
import { Env } from '@desktop/envs'
import {
  logOut,
  reauthenticateSportsApiClient,
} from '@desktop/globalState/userAuth'
import { setOnNewAccessToken } from '@desktop/globalState/userAuth/emitter'
import { Routes } from '@desktop/router/routes'
import { SportsApiAccessToken } from '@desktop/utils/localStorage'

import { updateEventOnConnect } from './event'
import { updateEventScoutsOnConnect } from './eventScouts'
import {
  initIncidentIdIncidentIndexMap,
  updateIncidentsOnConnect,
} from './incidents'
import { updateSubParticipantsOnConnect } from './subParticipants'
import { IncidentIdIncidentIndexMap, IncidentsUpdate } from './types'
import { useConfirmIncident } from './useConfirmIncident'

type InitQueueRabbitClient = {
  onQueueRabbitConfirmIncident: ConfirmQueueIncident
  incidentIdIncidentIndexMap: IncidentIdIncidentIndexMap
  incidentsUpdate: IncidentsUpdate
}

export function initQueueRabbitClient(
  event: Ref<Event>,
  eventTimestamp: Ref<number>,
  participants: Ref<Participant[]>,
  subParticipants: Ref<SubParticipant[]>,
  incidents: Ref<Incident[]>,
  eventScouts: Ref<EventScout[]>,
): InitQueueRabbitClient {
  const router = useRouter()
  const accessToken = SportsApiAccessToken.get()

  const queueRabbitClient = new QueueRabbitClient({
    eventId: event.value.id,
    url: Env.VITE_QUEUE_GATEWAY_URL,
    auth: accessToken ? { accessToken } : undefined,
  })

  setOnNewAccessToken((accessToken) => {
    queueRabbitClient.setAuth({ accessToken })
  })

  const incidentsUpdate: IncidentsUpdate = ref([])
  const { onQueueRabbitConfirmIncident, onQueueRabbitErrorMessage } =
    useConfirmIncident(incidentsUpdate)
  const { incidentIdIncidentIndexMap } = initIncidentIdIncidentIndexMap(
    incidents.value,
  )

  queueRabbitClient.onConnect(() => {
    updateEventOnConnect(
      queueRabbitClient,
      event.value,
      eventTimestamp,
      participants,
      subParticipants,
    )
    updateIncidentsOnConnect(
      queueRabbitClient,
      incidents.value,
      incidentsUpdate,
      incidentIdIncidentIndexMap,
    )
    updateEventScoutsOnConnect(queueRabbitClient, eventScouts)

    queueRabbitClient.subError<RabbitErrorMessage>({
      onMessage: onQueueRabbitErrorMessage,
    })

    updateSubParticipantsOnConnect(queueRabbitClient, subParticipants)
  })

  queueRabbitClient.onClientError((clientError) => {
    clientError.errors.forEach(async (error) => {
      const options = { cause: error }

      switch (error.type) {
        case ClientErrorType.JwtExpiredError: {
          const loggedIn = await reauthenticateSportsApiClient()
          if (!loggedIn) {
            break
          }

          const accessToken = SportsApiAccessToken.get()
          if (accessToken) {
            queueRabbitClient.setAuth({ accessToken })
          }
          break
        }
        case ClientErrorType.JwtError:
          logOut()
          throw new Error(`JWT error: ${error.message}`, options)
        case ClientErrorType.UnauthorizedError:
          router.replace(Routes.Home)
          throw new Error(`Unauthorized error: ${error.message}`, options)
        default:
          throw new Error(
            `Queue Gateway error: ${JSON.stringify(error)}`,
            options,
          )
      }
    })
  })

  return {
    onQueueRabbitConfirmIncident,
    incidentsUpdate,
    incidentIdIncidentIndexMap,
  }
}
