<script setup lang="ts">
import Button from '../Button/Button.vue'

interface Props {
  disabled?: boolean
  variant?: 'default' | 'neutral'
  size?: '2xs' /** 16px */ | 'xs' /** 24px */
}

withDefaults(defineProps<Props>(), {
  disabled: false,
  variant: 'default',
  size: 'xs',
})

// model
const checked = defineModel<boolean>()
</script>

<template>
  <label
    class="inline-flex items-center"
    :class="{
      'cursor-pointer': !disabled,
    }"
  >
    <Button
      :class="{
        'bg-neutral-dark-3/15 !border-neutral-light-10': disabled,
        'bg-success-4 border-success-4 text-neutral-light-10':
          checked && !disabled && variant === 'default',
        'bg-neutral-light-4 border-neutral-light-0 text-neutral-dark-10':
          checked && !disabled && variant === 'neutral',

        'border-neutral-dark-3/15 text-neutral-light-10': !checked && !disabled,
      }"
      :disabled
      :icon="checked ? 'check' : ''"
      iconSize="xs"
      :size
      square
      @click="checked = !checked"
    />
    <span
      v-if="$slots.default"
      class="flex select-none pl-2 text-xs font-bold"
      :class="{
        'text-neutral-dark-10/50': disabled,
      }"
    >
      <slot />
    </span>
  </label>
</template>
