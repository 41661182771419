import { Component, h } from 'vue'

import { useModalStack } from './useModalStack'

export class CancelModalError extends Error {}

type UseConfirmModal = {
  /**
   * Returned promise is:
   *  - resolved when opened Component emits 'confirm'
   *  - rejected when Component emits 'cancel'
   *
   * Should be used in function passed to confirm button in modal, resulting in shorter syntax of confirming series of modals.
   *
   * Awaiting rejected promise will immediately stop further execution of the function,
   * but CancelModalError should be catched to not report error.
   */
  openModalForConfirm(modal: Component): Promise<void>
  /**
   * Returned promise is:
   * - resolved with `true` when opened Component emits 'confirm'
   * - resolved with `false` when opened Component emits 'cancel'
   *
   * Should be used in custom confirm modals, opened in scenarios other that after clicking confirm button in modal.
   * (openModalForConfirm should be used for that)
   */
  openModalCancelConfirm(modal: Component): Promise<boolean>
}

export function useConfirmModal(): UseConfirmModal {
  const modalStack = useModalStack()

  function openModal(
    modal: Component,
    props: object = {},
    slots?: { [name: string]: unknown },
  ): { close: () => void } {
    const component = h(
      modal,
      { ...props, onClose: removeModalFromStack },
      slots,
    )

    function removeModalFromStack(): void {
      modalStack.value.delete(component)
    }

    modalStack.value.add(component)

    return { close: () => removeModalFromStack() }
  }

  function openModalCancelConfirm(modal: Component): Promise<boolean> {
    return new Promise((resolve) =>
      openModal(modal, {
        onCancel: () => resolve(false),
        onConfirm: () => resolve(true),
      }),
    )
  }

  function openModalForConfirm(modal: Component): Promise<void> {
    return new Promise((resolve, reject) =>
      openModal(modal, {
        onCancel: () => reject(new CancelModalError()),
        onConfirm: resolve,
      }),
    )
  }

  return {
    openModalCancelConfirm,
    openModalForConfirm,
  }
}
