import { ApiResponse } from '../ApiResponse'

export enum EventScoutRank {
  Main = 'main',
  Helper = 'helper',
}

export type EventScout = {
  id: number
  scout_id: number
  rank: EventScoutRank
  name: string
  surname: string
  full_name: string
  email: string
  // TODO update if needed
  acceptance: string
  // TODO update if needed
  scout_salary: unknown
  // TODO update if needed
  extra_salary: unknown
  // TODO update if needed
  note: unknown
}

export type EventScoutsShowResponse = ApiResponse<{ scouts: EventScout[] }>
