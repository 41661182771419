import { AddIncidentPayload, UpdateIncidentPayload } from '../incidents'
import { ConfirmQueueIncident, QueueIncident } from '../types'

function validateQueueIndex(queue: QueueIncident[], index: number): void {
  if (index < 0 || index >= queue.length) {
    throw Error(`index = ${index} is out of bounds`)
  }
}

export function enqueue(
  queueIncident: QueueIncident,
  queue: QueueIncident[],
): void {
  queue.push(queueIncident)
}

export function dequeue(queue: QueueIncident[], index: number): void {
  validateQueueIndex(queue, index)

  queue.splice(index, 1)
}

export function peek(queue: QueueIncident[], index: number): QueueIncident {
  validateQueueIndex(queue, index)

  return queue[index]
}

// TODO: remove this calculation when Team API will fix the issue
export function updatePayload(
  payload: UpdateIncidentPayload | AddIncidentPayload,
): void {
  const { x_pos, y_pos } = payload

  if (x_pos === 0) {
    payload.x_pos = 1
  }

  if (y_pos === 0) {
    payload.y_pos = 1
  }
}

export function confirmProcessedQueueIncident(
  uuid: string,
  confirmQueueIncident: ConfirmQueueIncident,
): Promise<void> {
  const timeout = 10000

  return new Promise((_resolve, _reject) => {
    const timeoutId = setTimeout(() => {
      _reject(
        Error(
          `Incident with uuid = ${uuid} was processed by API, but acknowledgment message wasn't received within ${
            timeout / 1000
          } seconds.`,
        ),
      )
    }, timeout)

    function resolve(): void {
      clearTimeout(timeoutId)
      _resolve()
    }

    function reject(error: Error): void {
      clearTimeout(timeoutId)
      _reject(error)
    }

    confirmQueueIncident(uuid, resolve, reject)
  })
}

export function setError(queueIncident: QueueIncident, error: unknown): void {
  queueIncident.setStatus('Error')
  queueIncident.error = error
}

export function getErrorQueueIncidents(
  queue: QueueIncident[],
): QueueIncident[] {
  return queue.filter((incident) => incident.getStatus() === 'Error')
}
