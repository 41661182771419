import { ApiResponse, SubParticipant } from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type SubParticipantsGetResponse = ApiResponse<{
  sub_participants: SubParticipant[]
}>

export async function get(
  httpClient: HttpClient,
  eventId: number,
): Promise<SubParticipantsGetResponse> {
  return httpClient.url(`/events/${eventId}/sub-participants`).get().json()
}
