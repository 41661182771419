import { Ref, ref, watch } from 'vue'

import { Incident } from '@collector/sportsapi-client'
import { useIncidents } from '@desktop/views/Relation/composables'
import {
  IncidentsGroup,
  isIncidentsGroup,
} from '@desktop/views/Relation/Sports/configuration'
import { injectIncidentIdsGroup } from '@desktop/views/Relation/Sports/provide-inject'

type UseIncidentsGroup = {
  incidentsGroups: Ref<(Incident | IncidentsGroup)[]>
}

export function useIncidentsGroup(): UseIncidentsGroup {
  // composables
  const { incidents, incidentsUpdate } = useIncidents()

  // inject
  const incidentIdsGroup = injectIncidentIdsGroup()

  // state
  const incidentsGroups = ref<(Incident | IncidentsGroup)[]>([])

  // methods
  function addIncidentsGroup(incident: Incident): void {
    if (
      incidentIdsGroup &&
      incidentIdsGroup.incidentIds.includes(incident.incident_id)
    ) {
      const lastIncident =
        incidentsGroups.value[incidentsGroups.value.length - 1]

      if (
        !!lastIncident &&
        isIncidentsGroup(lastIncident) &&
        lastIncident.incidents[0].incident_id !== incident.incident_id
      ) {
        lastIncident.incidents.push(incident)
      } else {
        incidentsGroups.value.push({
          name: incidentIdsGroup.name,
          incidents: [incident],
        })
      }
    } else {
      incidentsGroups.value.push(incident)
    }
  }

  // init
  for (const incident of incidents.value) {
    addIncidentsGroup(incident)
  }

  // watchers
  watch(incidentsUpdate, (newVal) => {
    for (const incidentUpdate of newVal) {
      if (incidentUpdate.data.action === 'insert') {
        addIncidentsGroup(incidentUpdate.data)
      }
    }
  })

  return {
    incidentsGroups,
  }
}
