import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

let inited = false

export function initFontAwesome(): void {
  if (inited) return

  library.add(fas)
  inited = true
}
