import {
  EventIncidentsQueue,
  QueueIncident,
  QueueStatus,
} from '@collector/desktop-feature-incidents-queue-core'
import { Event } from '@collector/sportsapi-client-legacy'
import {
  EventIncidentsQueueRecord,
  EventIncidentsQueueTable,
} from '@desktop/utils/database'

function persistEventIncidentsQueue(
  eventId: Event['id'],
  queueIncidents: QueueIncident[],
  queueStatus: QueueStatus,
): void {
  EventIncidentsQueueTable.put(eventId, {
    eventId,
    rawQueueIncidents: queueIncidents,
    queueStatus,
  })
}

/**
 * save queue on every change to persistent storage
 */
export function persistOnChangeEventIncidentsQueue(
  eventIncidentsQueue: EventIncidentsQueue,
): void {
  eventIncidentsQueue.subscribeOnChange(({ queue, status }) => {
    persistEventIncidentsQueue(eventIncidentsQueue.eventId, queue, status)
  })
}

/**
 * Retrive queue backup from persistent storage
 */
export async function getEventIncidentsQueueRecord(
  eventId: Event['id'],
): Promise<EventIncidentsQueueRecord | undefined> {
  return EventIncidentsQueueTable.get(eventId)
}
