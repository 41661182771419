<script setup lang="ts">
import { ref } from 'vue'

import { Modal, runLoader, Separator } from '@collector/shared-ui'
import { Incident, Participant } from '@collector/sportsapi-client'
import { IncidentId } from '@collector/sportsapi-client-legacy'
import { useParticipants } from '@desktop/views/Relation/composables'

import PossibleIncidentReplacements from './PossibleIncidentReplacements.vue'
import SwappedIncident from './SwappedIncident.vue'
import { swapIncident } from './utils'

interface Props {
  incident: Incident
}
const props = defineProps<Props>()

type Emits = { close: [] }
const emit = defineEmits<Emits>()

// composables
const { homeParticipant, awayParticipant } = useParticipants()

// state
const selectedReplacement = ref<
  [participantId: Participant['id'], incidentId: IncidentId] | []
>([])

// methods
function getSelectedIncidentId(
  participant: Participant,
): IncidentId | undefined {
  return selectedReplacement.value[0] === participant.id
    ? selectedReplacement.value[1]
    : undefined
}

async function confirmSwapIncident(): Promise<void> {
  await runLoader(
    swapIncident(
      props.incident,
      selectedReplacement.value[1]!,
      selectedReplacement.value[0]!,
    ),
  )
}
</script>

<template>
  <Modal
    title="Replace incident"
    :confirmButton="{
      text: 'Replace',
      confirm: confirmSwapIncident,
      disabled: !selectedReplacement.length,
    }"
    @close="emit('close')"
  >
    <div class="mb-2 text-center text-sm font-bold">Incident to replace</div>
    <SwappedIncident :incident />
    <div class="mt-8 text-center text-sm font-bold">Replace to</div>

    <div class="flex gap-x-8">
      <PossibleIncidentReplacements
        class="w-full"
        :participant="homeParticipant"
        :incident
        :selectedIncidentId="getSelectedIncidentId(homeParticipant)"
        @select="selectedReplacement = $event"
      />

      <PossibleIncidentReplacements
        class="w-full"
        :participant="awayParticipant"
        :incident
        :selectedIncidentId="getSelectedIncidentId(awayParticipant)"
        @select="selectedReplacement = $event"
      />
    </div>

    <Separator class="mb-6 mt-10" />
  </Modal>
</template>
