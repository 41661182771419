export function sortFormations(formations: string[]): string[] {
  return formations.sort((a, b) => {
    const aParts = a.split('-').map(Number)
    const bParts = b.split('-').map(Number)

    // Compare number of defenders (first number)
    if (aParts[0] !== bParts[0]) {
      return aParts[0] - bParts[0]
    }

    // Compare number of midfielders (sum of all middle numbers)
    const aMidfielders = aParts.slice(1, -1).reduce((sum, num) => sum + num, 0)
    const bMidfielders = bParts.slice(1, -1).reduce((sum, num) => sum + num, 0)

    if (aMidfielders !== bMidfielders) {
      return aMidfielders - bMidfielders
    }

    // Compare number of attackers (last number)
    return aParts[aParts.length - 1] - bParts[bParts.length - 1]
  })
}
