<script setup lang="ts">
import * as R from 'ramda'
import { computed, reactive } from 'vue'

import { Icon, Modal, runLoader, Separator } from '@collector/shared-ui'
import {
  CommonResultId,
  StatusType,
  YesNoEnum,
} from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import {
  useEvent,
  useParticipants,
  useStatistics,
} from '@desktop/views/Relation/composables'

import AdditionalOption from './AdditionalOption.vue'
import Summary from './Summary/Summary.vue'
import Table from './Table/Table.vue'

type Emits = { close: [] }
const emit = defineEmits<Emits>()

// composables
const {
  homeParticipant,
  awayParticipant,
  homeParticipantResultsMap,
  awayParticipantResultsMap,
} = useParticipants()

const { statsVisible } = useStatistics()

const { event } = useEvent()

// state
interface State {
  winner: [number, number]
  progress: [number, number]
}

const state = reactive<State>(R.clone(getDefaultState()))

const hasEventStatusFinished = computed(
  () => event.value.status_type === StatusType.Finished,
)

const verificationDisabled = computed(
  () =>
    !hasEventStatusFinished.value ||
    state.winner.some((winner) => winner === -1) ||
    state.progress.some((progress) => progress === -1),
)

// methods
function getDefaultState(): State {
  if (event.value.verified_result === YesNoEnum.No) {
    return {
      winner: [-1, -1],
      progress: [-1, -1],
    }
  }

  return {
    winner: [
      Number(homeParticipantResultsMap.value[CommonResultId.Winner].value),
      Number(awayParticipantResultsMap.value[CommonResultId.Winner].value),
    ],
    progress: [
      Number(homeParticipantResultsMap.value[CommonResultId.Progress].value),
      Number(awayParticipantResultsMap.value[CommonResultId.Progress].value),
    ],
  }
}

async function verifyResults(): Promise<void> {
  await runLoader(
    Promise.all([
      // verify event
      sportsApiClient.Events.patch(event.value.id, {
        event: { is_result_verified: YesNoEnum.Yes },
      }),

      // update winner and progress
      sportsApiClient.Events.Participants.patch(event.value.id, [
        {
          id: homeParticipant.value.id,
          stats: [],
          results: [
            {
              id: CommonResultId.Winner,
              value: state.winner[0],
            },
            {
              id: CommonResultId.Progress,
              value: state.progress[0],
            },
          ],
        },
        {
          id: awayParticipant.value.id,
          stats: [],
          results: [
            {
              id: CommonResultId.Winner,
              value: state.winner[1],
            },
            {
              id: CommonResultId.Progress,
              value: state.progress[1],
            },
          ],
        },
      ]),
    ]),
  )
  statsVisible.value = true
}
</script>

<template>
  <Modal
    title="Event verification"
    :confirmButton="{
      text: 'Verify results',
      confirm: verifyResults,
      disabled: verificationDisabled,
      tooltip: hasEventStatusFinished
        ? undefined
        : `select Event Status 'Finished' to Verify results`,
    }"
    @close="emit('close')"
  >
    <Separator class="mb-2" />
    <Summary />
    <Separator class="mt-2" />

    <div class="mx-4">
      <Table />
    </div>

    <Separator class="my-2" />

    <AdditionalOption
      v-model="state.winner"
      :options="[
        homeParticipant.short_name,
        'Draw',
        awayParticipant.short_name,
      ]"
      title="Winner"
    >
      <Icon icon="trophy" />
    </AdditionalOption>

    <AdditionalOption
      v-model="state.progress"
      class="mt-2"
      :options="[homeParticipant.short_name, 'N/A', awayParticipant.short_name]"
      title="Progress"
    >
      <Icon
        icon="arrow-turn-up"
        size="lg"
      />
    </AdditionalOption>

    <Separator class="mt-2" />
  </Modal>
</template>
