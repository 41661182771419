import {
  EventScout,
  includesAdminRoles,
  includesControllerRole,
  isScout,
  type AuthInfoObject,
} from '@collector/sportsapi-client-legacy'

function isAssignedToEvent(
  userId: number,
  assignedScouts: EventScout[],
): boolean {
  return assignedScouts.find((x) => x.scout_id === userId) !== undefined
}

export function hasAccessToEvent(
  authInfo: AuthInfoObject,
  assignedScouts: EventScout[],
): boolean {
  const { roles, id: userId } = authInfo

  switch (true) {
    case includesAdminRoles(roles):
    case includesControllerRole(roles):
    case isScout(roles) && isAssignedToEvent(userId, assignedScouts):
      return true
    default:
      return false
  }
}
