<script setup lang="ts">
import { getCurrentInstance, onBeforeMount, ref } from 'vue'

import { vOnClickOutside } from '@vueuse/components'

import Button from './Button/Button.vue'

interface Props {
  title?: string
  withCloseIcon?: boolean
}

defineProps<Props>()

// data
const teleportElementId = 'dropdown-teleport'

// state
const shouldOpen = ref(false)

// methods
function open(): void {
  shouldOpen.value = true
}

function close(): void {
  shouldOpen.value = false
}

// init
defineExpose({
  open,
  close,
})

onBeforeMount(() => {
  const instance = getCurrentInstance()

  const appRootElement = instance?.proxy?.$root?.$el?.parentNode

  if (!appRootElement?.querySelector(`#${teleportElementId}`)) {
    const dropdownTeleport = document.createElement('div')
    dropdownTeleport.id = teleportElementId
    appRootElement.prepend(dropdownTeleport)
  }
})
</script>

<template>
  <div
    v-if="shouldOpen"
    v-on-click-outside="close"
    class="shadow-simple-shadow bg-neutral-light-9 absolute min-h-12 rounded-2xl p-4"
  >
    <div
      v-if="title"
      class="text-lg font-bold"
    >
      {{ title }}
    </div>
    <Button
      v-if="withCloseIcon"
      class="text-neutral-dark-3/60 hover:text-neutral-2 absolute right-4 top-4 border-none text-lg"
      icon="close"
      iconSize="lg"
      size="xs"
      square
      @click="close"
    />
    <slot />

    <Teleport :to="`#${teleportElementId}`">
      <!-- layer which disallows accidental click on UI controls with lower z-index -->
      <div class="absolute z-10 h-full w-full" />
    </Teleport>
  </div>
</template>
