import { TopicPayload } from './payloads.interface'

export enum ClientErrorType {
  JwtError = 'JwtError',
  JwtExpiredError = 'JwtExpiredError',
  UnknownError = 'UnknownError',
  UnauthorizedError = 'UnauthorizedError',
}

export interface ClientError {
  message: string
  type: ClientErrorType
  [key: string]: unknown
}
export interface ClientErrorPayload {
  errors: ClientError[]
}

interface TopicEvents {
  [key: string]: (payload: unknown[]) => void
}

interface CommonEvents {
  clientError: (payload: ClientErrorPayload, callback: () => void) => void
  unsubscribe: (payload: TopicPayload) => void
}

export type ServerToClientEvents = TopicEvents & CommonEvents
