import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'

import { IncidentsRequirePointingPosition } from '../../../configuration/incidentsRequirePointingPosition'

export const incidentsRequirePointingPosition: IncidentsRequirePointingPosition =
  [
    {
      incidentId: SoccerIncidentId.Corner,
      transformPosition: (position, teamSide) => {
        const x = teamSide === 'home' ? 100 : 0
        const y = position.y > 50 ? 100 : 0
        return { x, y }
      },
    },
    {
      incidentId: SoccerIncidentId.ThrowIn,
      transformPosition: (position) => {
        return {
          x: position.x,
          y: position.y > 50 ? 100 : 0,
        }
      },
    },
    {
      incidentId: SoccerIncidentId.FreeKick,
      transformPosition: (position) => {
        return position
      },
    },
  ]
