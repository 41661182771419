<script lang="ts" setup>
import { Icon } from '@collector/shared-ui'

import { Column } from './types'

// props
interface Props {
  columns: Column[]
  numberOfStickyColumns: number
  sortColumn: string
}

defineProps<Props>()

// emits
type Emits = {
  sort: [dataField: string, direction: 'asc' | 'desc']
}

const emit = defineEmits<Emits>()

// methods
function onSortButtonClick(column: Column): void {
  emit(
    'sort',
    column.dataField,
    column.sortDirection === 'asc' ? 'desc' : 'asc',
  )
  column.sortDirection = column.sortDirection === 'desc' ? 'asc' : 'desc'
}
</script>

<template>
  <thead
    class="sticky top-0 z-10"
    data-testid="stats-table-header"
  >
    <tr class="z-30 text-left">
      <th
        v-for="(column, index) in columns.slice(0, numberOfStickyColumns)"
        :key="column.dataField"
        class="bg-neutral-light-5 text-neutral-dark-10 sticky z-10 cursor-pointer whitespace-nowrap px-2 text-xs font-thin"
        :class="{
          'left-0 w-2': index === 0,
          'left-8 w-full min-w-[11rem]': index !== 0,
        }"
        :data-testid="`stats-column-${column.dataField}`"
        @click="() => onSortButtonClick(column)"
      >
        <div class="flex h-full w-full items-center">
          <div
            class="mr-1 text-wrap"
            :class="{
              'font-bold': column.dataField === sortColumn,
            }"
          >
            {{ column.name }}
          </div>
          <Icon
            v-if="column.sortable"
            :icon="column.sortDirection === 'asc' ? 'caret-up' : 'caret-down'"
            :data-testid="`stats-column-${column.dataField}-sort`"
          />
        </div>
      </th>
      <th
        v-for="column in columns.slice(numberOfStickyColumns)"
        :key="column.dataField"
        class="bg-neutral-light-5 text-neutral-dark-10 cursor-pointer whitespace-nowrap px-2 text-xs font-thin"
        :data-testid="`stats-column-${column.dataField}`"
        @click="() => onSortButtonClick(column)"
      >
        <div class="flex h-full w-24 items-center justify-center">
          <div
            class="mr-1 text-wrap text-center"
            :class="{
              'font-bold': column.dataField === sortColumn,
            }"
          >
            {{ column.name }}
          </div>
          <Icon
            v-if="column.sortable"
            :icon="column.sortDirection === 'asc' ? 'caret-up' : 'caret-down'"
            :data-testid="`stats-column-${column.dataField}-sort`"
          />
        </div>
      </th>
    </tr>
  </thead>
</template>
