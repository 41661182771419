import { SoccerStatId } from '@collector/sportsapi-client-legacy'

import { ImportantStats } from '../../../configuration'

export const importantStatsId: ImportantStats = [
  SoccerStatId.Corners,
  SoccerStatId.YellowCards,
  SoccerStatId.RedCards,
  SoccerStatId.Substitutions,
]
