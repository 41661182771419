export enum SoccerLineupPositions {
  Goalkeeper = 'goalkeeper',
  Defender = 'defender',
  Midfielder = 'midfielder',
  Attacker = 'attacker',
  Unknown = 'unknown',
  NoPosition = '',
}

export enum SoccerLineupPositionId {
  Goalkeeper = 31,
  Defender = 32,
  Midfielder = 33,
  Attacker = 34,
  Unknown = 30,
}
