import {
  createAuthClient as _createAuthClient,
  HttpClient,
} from '@collector/sportsapi-client'
import { Env } from '@desktop/envs'
import {
  SportsApiAccessToken,
  SportsApiRefreshToken,
} from '@desktop/utils/localStorage'

export function createAuthClient(token: string): HttpClient {
  return _createAuthClient(Env.VITE_SPORTS_API_URL, token)
}

export function clearSportsApiTokens(): void {
  SportsApiAccessToken.remove()
  SportsApiRefreshToken.remove()
}
