import type { LoginResponse } from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type AuthLoginPostPayload = {
  email: string
  password: string
}

export type AuthLoginPostResponse = LoginResponse

export async function post(
  httpClient: HttpClient,
  payload: AuthLoginPostPayload,
): Promise<AuthLoginPostResponse> {
  return httpClient.url('/auth/login').post(payload).json()
}
