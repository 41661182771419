import * as AuthInfo from './auth/info'
import * as Events from './events'
import * as EventsReset from './events/reset'
import { HttpClient } from './httpClient'
import * as Links from './links'
import * as Participants from './participants'
import * as Skins from './skins'
import * as Sport from './sport'

export type SportsApiClient = ReturnType<typeof createSportsApiClient>

/**
 * Api endpoints for authenticated user.
 *
 * Requires client with token received after successful logIn.
 */
// implicit TypeScript return type - to avoid unnecessary boilerplate
//
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createSportsApiClient(httpClient: HttpClient) {
  // client - first argument auto-provided to exposed methods
  const sportsApiClient = {
    AuthInfo: {
      get: AuthInfo.get.bind(null, httpClient),
    },
    Events: {
      // TODO: create helper function "bindHttpClient", replace ".bind(null, httpClient)"
      reset: EventsReset.reset.bind(null, httpClient),
      getById: Events.getById.bind(null, httpClient),
      patch: Events.patch.bind(null, httpClient),
      IncidentsQueue: {
        delete: Events.IncidentsQueue.delete.bind(null, httpClient),
        patch: Events.IncidentsQueue.patch.bind(null, httpClient),
        post: Events.IncidentsQueue.post.bind(null, httpClient),
      },
      Incidents: {
        get: Events.Incidents.get.bind(null, httpClient),
        post: Events.Incidents.post.bind(null, httpClient),
        Swap: {
          post: Events.Incidents.Swap.post.bind(null, httpClient),
        },
      },
      SwappableIncidents: {
        get: Events.SwappableIncidents.get.bind(null, httpClient),
      },
      SubParticipants: {
        get: Events.SubParticipants.get.bind(null, httpClient),
        patch: Events.SubParticipants.patch.bind(null, httpClient),
        post: Events.SubParticipants.post.bind(null, httpClient),
        delete: Events.SubParticipants.delete.bind(null, httpClient),
      },
      Participants: {
        get: Events.Participants.get.bind(null, httpClient),
        patch: Events.Participants.patch.bind(null, httpClient),
        Stats: {
          patch: Events.Participants.updateStats.bind(null, httpClient),
        },
        Lineups: {
          clearLineup: Events.Participants.Lineups.clearLineup.bind(
            null,
            httpClient,
          ),
        },
      },
      Scouts: {
        get: Events.Scouts.get.bind(null, httpClient),
      },
    },
    Links: {
      get: Links.get.bind(null, httpClient),
    },
    Participants: {
      Squad: {
        getBySeasonId: Participants.Squad.getBySeasonId.bind(null, httpClient),
      },
      get: Participants.get.bind(null, httpClient),
      getById: Participants.getById.bind(null, httpClient),
    },
    Sport: {
      get: Sport.getById.bind(null, httpClient),
    },
    Skins: {
      getForParticipant: Skins.getForParticipant.bind(null, httpClient),
    },
  }

  return sportsApiClient
}
