import { Fragment, Slot, VNode } from 'vue'

function isVnodeEmpty(vnodes: Array<VNode>): boolean {
  return vnodes.every((node: VNode) => {
    if (node.type === Comment) {
      return true
    }

    if (
      node.type === Text &&
      typeof node.children === 'string' &&
      !node.children.trim()
    ) {
      return true
    }

    if (node.type === Fragment && isVnodeEmpty(node.children as Array<VNode>)) {
      return true
    }

    return false
  })
}

// https://mokkapps.de/vue-tips/check-if-slot-is-empty#check-if-a-slot-has-content
export function hasSlotContent(slot: Slot | undefined): boolean {
  if (!slot) {
    return false
  }

  return !isVnodeEmpty(slot())
}
