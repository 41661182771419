<script setup lang="ts">
import { Icon, IconName } from '@collector/shared-ui'

import { PocketIncidentIconType } from '../../configuration'

type Props = {
  icon: PocketIncidentIconType
  disabled?: boolean
}

defineProps<Props>()

// data
const icons: PocketIncidentIconType[] = ['var-ended', 'var', 'added-time']

// methods
function getImageUrl(name: string): string {
  return new URL(`./${name}.svg`, import.meta.url).toString()
}
</script>

<template>
  <div class="h-5 w-6">
    <img
      v-if="icons.includes(icon)"
      :class="{
        'opacity-30': disabled,
      }"
      :src="getImageUrl(icon)"
    />
    <Icon
      v-else
      :icon="icon as IconName"
    />
  </div>
</template>
