<script setup lang="ts">
import { ref } from 'vue'

import { Button } from '@collector/shared-ui'
import { Incident } from '@collector/sportsapi-client'

import SwapIncidentsModal from './SwapIncidentsModal.vue'

interface Props {
  incident: Incident
}

defineProps<Props>()

// state
const isModalVisible = ref(false)
</script>

<template>
  <Button
    class="bg-team-home-5 border-team-home-5 hover:bg-team-home-7 hover:border-team-home-7 text-neutral-light-10"
    icon="exchange-alt"
    iconSize="sm"
    square
    size="xs"
    @click="isModalVisible = true"
  />
  <SwapIncidentsModal
    v-if="isModalVisible"
    :incident
    @close="isModalVisible = false"
  />
</template>
