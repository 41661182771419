import memoizee from 'memoizee'
import { computed, ComputedRef, Ref } from 'vue'

import {
  Stat,
  StatId,
  SubParticipant,
} from '@collector/sportsapi-client-legacy'

import { injectSubParticipants } from '../provide-inject'
import {
  getSortedPlayers,
  getSubparticipantsOptions,
  SubParticipantOption,
} from '../utils/sportsapi'
import { useParticipants } from './useParticipants'

type UseSubParticipants = {
  homePlayersOptions: ComputedRef<SubParticipantOption[]>
  awayPlayersOptions: ComputedRef<SubParticipantOption[]>
  homeSubParticipants: ComputedRef<SubParticipant[]>
  awaySubParticipants: ComputedRef<SubParticipant[]>
  homeSubParticipantsStatsMap: ComputedRef<
    Record<number | string, Record<StatId, Stat>>
  >
  awaySubParticipantsStatsMap: ComputedRef<
    Record<number | string, Record<StatId, Stat>>
  >
}

const memoizedGetPlayersOptions = memoizee(function (
  subParticipants: Ref<SubParticipant[]>,
) {
  return computed(() =>
    getSubparticipantsOptions(getSortedPlayers(subParticipants.value)),
  )
})

const memoizedGetSubParticipantStatsMap = memoizee(function (
  subParticipants: Ref<SubParticipant[]>,
): ComputedRef<Record<number | string, Record<StatId, Stat>>> {
  return computed(() =>
    subParticipants.value.reduce(
      (acc, subParticipant) => {
        // manually added players have no id, but short_name :(
        acc[subParticipant.id || subParticipant.short_name] = (
          subParticipant.stats || []
        ).reduce(
          (acc, stat) => {
            acc[stat.id] = stat
            return acc
          },
          {} as Record<StatId, Stat>,
        )

        return acc
      },
      {} as Record<number | string, Record<StatId, Stat>>,
    ),
  )
})

export function useSubParticipants(): UseSubParticipants {
  const subParticipants = injectSubParticipants()

  const { homeParticipant, awayParticipant } = useParticipants()

  const homeSubParticipants = computed(() =>
    subParticipants.value.filter(
      (x) => Number(x.team_id) === homeParticipant.value.id,
    ),
  )
  const awaySubParticipants = computed(() =>
    subParticipants.value.filter(
      (x) => Number(x.team_id) === awayParticipant.value.id,
    ),
  )

  const homePlayersOptions = memoizedGetPlayersOptions(homeSubParticipants)
  const awayPlayersOptions = memoizedGetPlayersOptions(awaySubParticipants)

  const homeSubParticipantsStatsMap =
    memoizedGetSubParticipantStatsMap(homeSubParticipants)
  const awaySubParticipantsStatsMap =
    memoizedGetSubParticipantStatsMap(awaySubParticipants)

  return {
    awaySubParticipants,
    homeSubParticipants,
    homePlayersOptions,
    awayPlayersOptions,
    homeSubParticipantsStatsMap,
    awaySubParticipantsStatsMap,
  }
}
