import Dexie, { IndexableType } from 'dexie'

export async function upsertTableItem(
  database: Dexie,
  tableName: string,
  primaryKey: IndexableType,
  item: Record<string, unknown>,
): Promise<void> {
  const table = database.table(tableName)

  await database.transaction('rw', table, async () => {
    const existingData = await table.get(primaryKey)
    const primaryKeyName = table.schema.primKey.keyPath as string

    const mergedData = {
      ...existingData,
      ...item,
    }

    for (const key of Object.keys(mergedData)) {
      if (mergedData[key] === undefined) delete mergedData[key]
    }

    return table.put({
      ...mergedData,
      [primaryKeyName]: primaryKey,
    })
  })
}
