<script setup lang="ts">
import Checkbox from '../Checkbox/Checkbox.vue'
import Group from '../Group/Group.vue'

interface Props {
  options: string[]
  slotClass?: string
  disabled?: boolean
}

const props = defineProps<Props>()

// model
const selectedIndex = defineModel<number>()

// methods
function getOptionValue(option: number): string {
  return props.options[option - 1]
}

function selectIndex(index: number): void {
  selectedIndex.value = index
}
</script>

<template>
  <div
    v-if="options.length"
    class="flex gap-2"
  >
    <Group
      :optionsCount="options.length"
      :selectedOption="
        selectedIndex === undefined ? undefined : selectedIndex + 1
      "
    >
      <template #default="{ option, selected }">
        <Checkbox
          class="w-full rounded border py-2 pl-2"
          :class="{
            'border-neutral-3 bg-neutral-3/15': selected,
            'border-neutral-light-3 bg-neutral-light-9': !selected,
          }"
          :disabled
          :modelValue="selected"
          @update:modelValue="selectIndex(option - 1)"
        >
          <span>
            {{ getOptionValue(option) }}
          </span>
        </Checkbox>
      </template>
    </Group>
  </div>
</template>
