<script setup lang="ts">
import { ref, watch } from 'vue'

interface Props {
  optionsCount: number
  selectedOption?: number
}

const props = defineProps<Props>()

// state
const selectedOption = ref(props.selectedOption)

// methods
function selectOption(option: number | undefined): void {
  selectedOption.value = option
}

// watch
watch(props, (val) => {
  selectOption(val.selectedOption)
})
</script>

<template>
  <template v-for="i in optionsCount">
    <slot
      :option="i"
      :selected="i === selectedOption"
      :selectOption="() => selectOption(i)"
    ></slot>
  </template>
</template>
