<script setup lang="ts">
import { ref } from 'vue'

import { Dropdown } from '@collector/shared-ui'

import Button from '../Button.vue'
import StatusesDropdown from './StatusesDropdown.vue'

// state
const dropdown = ref<InstanceType<typeof Dropdown> | null>(null)

// methods
function openDropdown(): void {
  dropdown.value?.open()
}

function closeDropdown(): void {
  dropdown.value?.close()
}
</script>

<template>
  <Button
    icon="stopwatch"
    @click="openDropdown"
  >
    Statuses
  </Button>
  <Dropdown
    ref="dropdown"
    class="top-9 w-96"
    title="Statuses"
    withCloseIcon
  >
    <StatusesDropdown @close="closeDropdown" />
  </Dropdown>
</template>
