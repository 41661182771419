<script setup lang="ts">
import { SportIcon } from '@collector/shared-ui-domain'
import { IncidentId, Sport } from '@collector/sportsapi-client-legacy'

import { useSport } from '../composables'

type SportIconProps = InstanceType<typeof SportIcon>['$props']

interface Props {
  incidentId: IncidentId
  size?: SportIconProps['size']
}

const props = defineProps<Props>()

// composables
const { sport } = useSport()

// data
const sportIconName = getSportIconName(sport)

// methods
function getSportIconName(sport: Sport): SportIconProps['name'] {
  const sportName = sport.name.toUpperCase()

  return `incidents/${sportName}/${props.incidentId}.svg` as SportIconProps['name']
}
</script>

<template>
  <SportIcon
    :name="sportIconName"
    :size
  />
</template>
