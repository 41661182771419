import {
  ApiResponse,
  Event,
  SubParticipant,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export async function _delete(
  httpClient: HttpClient,
  eventId: Event['id'],
  subParticipantLineupId: SubParticipant['lineup_id'],
): Promise<ApiResponse<void>> {
  return httpClient
    .url(`/events/${eventId}/sub-participants/${subParticipantLineupId}`)
    .delete()
    .json()
}
