import type {
  ApiResponse,
  Confirmation,
  EventStatusId,
  IncidentAttributeId,
  IncidentId,
  Property,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type Incident = {
  id: number
  action: 'insert' | 'update' | 'delete'
  old_id: number | null
  parent_id: number | null
  event_id: number
  incident_id: IncidentId
  attribute_id: number | null
  confirmation: Confirmation
  participant_id: number
  assistant_id: number | null
  assistant_name: string | null
  secondary_assistant_id: number | null
  secondary_assistant_name: string | null
  participant_team_id: number
  participant_name: string
  status_id: EventStatusId
  minute: number | null
  second: number | null
  home_score: number
  away_score: number
  home_score1: number
  away_score1: number
  home_score2: number | null
  away_score2: number | null
  add_data: unknown | null
  display_info: string | null
  old_added_id: string
  counter: number | null
  x_pos: number | null
  y_pos: number | null
  confirmation_time: number | null
  participant: {
    old_participant_id: string
  }
  for: string
  details: string
  category: 'standard' | 'emergency'
  has_details: boolean
  ct: number
  ut: number
  dt: number | null
  attribute_ids: IncidentAttributeId[] | null
  properties: Property[]
  additional_info: Partial<{
    assistant_id: number | null
    assistant_name: string
  }>
  source_id?: number
}

export type IncidentGetResponse = ApiResponse<{
  event_incidents: Incident[]
}>

export type IncidentsGetQuery = {
  sort_type?: string
  sort_order?: 'asc' | 'desc'
  limit?: 5 | 10 | 25 | 50 | 100 | 250 | 500 | 99999
}

export async function get(
  httpClient: HttpClient,
  eventId: number,
  query: IncidentsGetQuery = {},
): Promise<IncidentGetResponse> {
  return httpClient
    .url(`/events/${eventId}/incidents`)
    .query(query)
    .get()
    .json()
}
