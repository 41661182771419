<script setup lang="ts">
import { computed } from 'vue'

import { Overlay } from '@collector/shared-ui'
import { nullCoalesce } from '@collector/shared-utils'
import {
  SoccerEventStatusId,
  SoccerResultId,
} from '@collector/sportsapi-client-legacy'
import { useEvent, useParticipants } from '@desktop/views/Relation/composables'

import PenaltyTeamResult from './PenaltyTeamResult.vue'
import { usePenaltyChips } from './usePenaltyChips'

const { event } = useEvent()
const {
  getHomeParticipantResult,
  getAwayParticipantResult,
  homeParticipant,
  awayParticipant,
} = useParticipants()

const { homeChips, awayChips } = usePenaltyChips(
  homeParticipant,
  awayParticipant,
)

const open = computed(() => {
  return event.value.status_id === SoccerEventStatusId.PenaltyShootout
})
</script>

<template>
  <Overlay
    class="bg-success-1/70"
    position="contained"
    :modelValue="open"
    scrim="none"
  >
    <div class="@lg:p-3 h-full w-full p-2 text-white">
      <div class="@lg:text-xl text-center text-xs font-bold">
        Penalty shootout
      </div>
      <div
        class="@lg:text-4xl grid grid-cols-[1fr_auto_1fr] gap-x-1 text-lg font-bold"
      >
        <div class="text-right">
          {{
            nullCoalesce(getHomeParticipantResult(SoccerResultId.Penalty), 0)
          }}
        </div>
        <div>:</div>
        <div class="text-left">
          {{
            nullCoalesce(getAwayParticipantResult(SoccerResultId.Penalty), 0)
          }}
        </div>
      </div>
      <div class="@lg:mt-1 grid grid-cols-[50%,50%]">
        <PenaltyTeamResult
          class="pr-3"
          dir="ltr"
          :participant="homeParticipant"
          :chips="homeChips"
        />
        <PenaltyTeamResult
          class="pl-3"
          dir="rtl"
          :participant="awayParticipant"
          :chips="awayChips"
        />
      </div>
    </div>
  </Overlay>
</template>
