import * as R from 'ramda'

import { Participants } from '@collector/sportsapi-client'
import {
  Event,
  Participant,
  SoccerPersonStatId,
  Stat,
  StatId,
  SubParticipant,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'

export type Player = (
  | Participants.Squad.SeasonSquadParticipant
  | SubParticipant
) & {
  position?: SubParticipant['position']
  lineup_id?: SubParticipant['lineup_id']
}

function getStat(player: Player, statId: StatId): Stat | null {
  return (
    ('stats' in player &&
      player.stats?.find(({ id }) => id === (statId as number))) ||
    null
  )
}

export function setStat(
  player: Player,
  id: StatId,
  value: number | string | null,
): void {
  const stat = getStat(player, id)
  if (stat) stat.value = value
  else if ('stats' in player) player.stats?.push({ id, value })
  else (player as SubParticipant).stats = [{ id, value }]
}

function getCapitanStat(player: Player): Stat | null {
  return getStat(player, SoccerPersonStatId.Captain)
}

function getPPositionStat(player: Player): Stat | null {
  return getStat(player, SoccerPersonStatId.PPosition)
}

export function isCaptain(player: Player): boolean {
  return getCapitanStat(player)?.value === 1
}

export function setCaptainStat(
  player: Player,
  value: number | string | null,
): void {
  return setStat(player, SoccerPersonStatId.Captain, value)
}

export function setPPositionStat(
  player: Player,
  value: number | string | null,
): void {
  return setStat(player, SoccerPersonStatId.PPosition, value)
}

export async function fetchParticipantSquadBySeasonId(
  participantId: number,
  seasonId: number,
): Promise<Participants.Squad.SeasonSquadParticipant[]> {
  const squad = await sportsApiClient.Participants.Squad.getBySeasonId(
    participantId,
    seasonId,
  )

  return squad.api.data.participants || []
}

export function getBirthdateText(birthdate: string): string {
  return birthdate || 'Birthdate is not set'
}

export function isPlayerSame(a: Player, b: Player): boolean {
  if ('lineup_id' in a && 'lineup_id' in b) return a.lineup_id === b.lineup_id
  else return a.id === b.id
}

export function findPlayer<TPlayer extends Player>(
  haystack: TPlayer[],
  player: Player,
): TPlayer | undefined {
  return haystack.find((v) => isPlayerSame(v, player))
}

export async function updateLineups(
  subParticipants: Player[],
  initialSubParticipants: Player[],
  eventId: Event['id'],
  participantId: Participant['id'],
): Promise<void> {
  /**
   * Players in toUpdatePre must be updated to avoid conflicts from API
   * (which should have been handled in a proper updateMany implementation)
   * - former goalkeeper must be updated first (more than one cannot exist)
   */
  const toRemove: Player[] = []
  const toUpdatePre: Player[] = []
  const toUpdate: Player[] = []
  const toAdd: Player[] = []

  // move objects into toUpdate and toAdd arrays
  for (const subParticipant of subParticipants) {
    const initialSubParticipant = findPlayer(
      initialSubParticipants,
      subParticipant,
    )
    if (!initialSubParticipant) toAdd.push(subParticipant)
    else if (!R.equals(subParticipant, initialSubParticipant)) {
      const currentGk =
        subParticipant.position === SubParticipantPosition.Goalkeeper
      const initialGk =
        initialSubParticipant.position === SubParticipantPosition.Goalkeeper
      const gkChanged = currentGk !== initialGk
      if (gkChanged) setPPositionStat(subParticipant, currentGk ? 1 : null)

      if (gkChanged && initialGk) toUpdatePre.push(subParticipant)
      else toUpdate.push(subParticipant)
    }
  }

  // move objects into toRemove array
  for (const initialSubParticipant of initialSubParticipants) {
    const subParticipant = findPlayer(subParticipants, initialSubParticipant)
    if (!subParticipant) toRemove.push(initialSubParticipant)
  }

  // perform mutation request in specific order (remove, (update, update, add))
  // to avoid API conflicts
  const removePromises = toRemove.map((subParticipant) =>
    sportsApiClient.Events.SubParticipants.delete(
      eventId,
      (subParticipant as SubParticipant).lineup_id,
    ),
  )

  const toUpdatePayloads = toUpdate.map(getUpdatePayload)
  const toUpdatePrePayloads = toUpdatePre.map(getUpdatePayload)
  const batches = [toUpdatePrePayloads, toUpdatePayloads]
  for (const batch of batches) {
    if (batch.length)
      await sportsApiClient.Events.SubParticipants.patch(eventId, batch, false)
  }

  const addPromises = toAdd.map(async (subParticipant) => {
    const addPromise = sportsApiClient.Events.SubParticipants.post(eventId, {
      ...subParticipant,
      team_id: participantId,
    } as Participants.Squad.SeasonSquadParticipant)

    const resultSubParticipants = (await addPromise).api.data.sub_participants
    const added = findPlayer(resultSubParticipants, subParticipant)
    if (added) {
      let needsUpdate = false

      // stats are not set on create, separate request needed
      // (Captain, PPosition)
      if (isCaptain(subParticipant)) {
        setCaptainStat(added, 1)
        needsUpdate = true
      }

      if (added.position === SubParticipantPosition.Goalkeeper) {
        setPPositionStat(added, 1)
        needsUpdate = true
      }

      if (needsUpdate)
        await sportsApiClient.Events.SubParticipants.patch(
          eventId,
          [added],
          false,
        )
    }
  })
  await Promise.all([...addPromises, ...removePromises])
}

function getUpdatePayload(subParticipant: Player): SubParticipant {
  const stats = []

  const captainStat = getCapitanStat(subParticipant)
  if (captainStat) stats.push(captainStat)

  const ppositionStat = getPPositionStat(subParticipant)
  if (ppositionStat) stats.push(ppositionStat)

  return { ...subParticipant, stats } as SubParticipant
}
