import { SportsApiClient } from '@collector/sportsapi-client'

import {
  BaseIncident,
  QueueIncidentError,
  QueueIncidentStatus,
} from './BaseIncident'

export class DeleteIncident extends BaseIncident {
  readonly id: number

  public readonly type = 'Delete'

  constructor(
    eventId: number,
    id: number,
    status?: QueueIncidentStatus,
    time?: number,
    error?: QueueIncidentError,
  ) {
    super(eventId, status, time, error)

    this.id = id
  }

  public getId(): number {
    return this.id
  }

  public async process(sportsApiClient: SportsApiClient): Promise<string> {
    const response = await sportsApiClient.Events.IncidentsQueue.delete(
      this.eventId,
      this.id,
    )

    return response.api.data.uuid
  }
}
