<script setup lang="ts">
import { Logo } from '@collector/shared-ui-domain'

interface Props {
  appMode: string
}

const props = defineProps<Props>()

// data
const title =
  {
    beta: 'BETA',
    development: 'DEV',
  }[props.appMode] || ''
</script>

<template>
  <Logo class="h-9" />
  <div class="relative mt-4 flex">
    <img
      class="w-56"
      src="../../assets/logo-title.svg"
      alt="Logo content"
    />
    <span
      v-if="title"
      class="text-primary-5 absolute -right-12 -top-3 pl-2 text-xl font-bold"
    >
      {{ title }}
    </span>
  </div>
</template>
