<script setup lang="ts">
import { Button } from '@collector/shared-ui'
import { TeamSide } from '@collector/shared-ui-domain'
import { useEventSettings } from '@desktop/views/Relation/composables'
import { useBlockades } from '@desktop/views/Relation/Sports/composables'
import { injectPitchInteractionDisabledStatuses } from '@desktop/views/Relation/Sports/provide-inject'

// composables
const { eventSettings, updateEventSettings } = useEventSettings()
const { hasCurrentEventStatus } = useBlockades()

// inject
const pitchInteractionDisabledStatuses =
  injectPitchInteractionDisabledStatuses()

// methods
function changePossession(): void {
  const teamSidePossession: TeamSide =
    eventSettings.value.teamSidePossession === 'home' ? 'away' : 'home'

  updateEventSettings({ teamSidePossession })
}

function isChangePossesionBlocked(): boolean {
  return hasCurrentEventStatus(pitchInteractionDisabledStatuses)
}
</script>

<template>
  <Button
    class="enabled:hover:bg-error-6/60 text-neutral-dark-0 bg-neutral-light-2 border-neutral-light-2 font-bold enabled:hover:border-transparent"
    size="lg"
    width="full"
    :disabled="isChangePossesionBlocked()"
    @click="changePossession"
  >
    Change possession
  </Button>
</template>
