import { ApiResponse, EventScout } from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type EventScoutsGetResponse = ApiResponse<{
  scouts: EventScout[]
}>

export async function get(
  httpClient: HttpClient,
  eventId: number,
): Promise<EventScoutsGetResponse> {
  return httpClient.url(`/events/${eventId}/scouts`).get().json()
}
