import { ErrorEvent } from '@sentry/types'

import { deleteContextPageTitle, getContextPageTitle } from './context'
import {
  deleteExtraIssueTitle,
  getExtraIssueTitle,
  hasExtraIssueTitle,
} from './extra'

export function groupWithCustomNamedIssue(event: ErrorEvent): void {
  if (hasExtraIssueTitle(event.extra)) {
    const issueTitle = getExtraIssueTitle(event.extra)
    deleteExtraIssueTitle(event.extra)

    const pageTitle = getContextPageTitle(event.contexts)

    const issueCustomName = issueTitle + pageTitle

    // create "fake" exception as issue custom name
    event.exception = event.exception || { values: [] }
    event.exception.values?.push({ type: issueCustomName })

    // group issues
    event.fingerprint = [issueCustomName]
  }

  // clean context helping for grouping issues
  deleteContextPageTitle(event.contexts)
}
