export enum ShotOffTargetAttribute {
  LeftFoot = 408,
  RightFoot = 409,
  Head = 436,
  InsidePenaltyArea = 437,
  OutsidePenaltyArea = 438,
  ShotWoodwork = 439,
  Crossbar = 739,
  Post = 740,
}
