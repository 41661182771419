import { ClickPosition } from '@collector/shared-ui'
import { isDangerousAttackForPercentagePositionX } from '@collector/shared-ui-domain'
import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'

/**
 * Normalize percentage position for same team either on home or away side
 */
export function getPercentagePositionWithTeamSideChanged(
  percentagePosition: ClickPosition,
  teamSideChanged: boolean,
): ClickPosition {
  return teamSideChanged
    ? {
        x: 100 - percentagePosition.x,
        y: 100 - percentagePosition.y,
      }
    : percentagePosition
}

export function getIncidentIdForPercentagePositionX(
  percentagePositionX: ClickPosition['x'],
): SoccerIncidentId {
  switch (true) {
    case percentagePositionX >= 0 && percentagePositionX < 50:
      return SoccerIncidentId.InPossession
    case percentagePositionX >= 50 && percentagePositionX < 75:
      return SoccerIncidentId.Attack
    case isDangerousAttackForPercentagePositionX(percentagePositionX):
      return SoccerIncidentId.DangerousAttack
    default:
      return SoccerIncidentId.InPossession
  }
}
