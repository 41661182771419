<script setup lang="ts">
import Status from '@desktop/views/Relation/components/RelationView/Main/Summary/Status.vue'
import { useParticipants } from '@desktop/views/Relation/composables'
import Summary from '@desktop/views/Relation/Sports/components/Summary.vue'

import Clock from './Clock/Clock.vue'
import Participant from './Participant.vue'

interface Props {
  reversed?: boolean
}

defineProps<Props>()

const { homeParticipant, awayParticipant } = useParticipants()
</script>

<template>
  <Summary>
    <template #header>
      <div class="flex">
        <Participant :participant="homeParticipant" />
        <Status />
        <Participant
          :participant="awayParticipant"
          reversed
        />
      </div>
    </template>
    <template #middle>
      <Clock />
    </template>
  </Summary>
</template>
