export interface ClickPosition {
  x: number
  y: number
}

export function addElementClickHandler(
  element: HTMLElement,
  handler: (position: ClickPosition) => void,
): void {
  function onElementClick(event: MouseEvent): void {
    const { clientX: x, clientY: y } = event

    handler({
      x,
      y,
    })
  }

  element.addEventListener('click', onElementClick)
}

export function getElementPercentagePosition(
  element: HTMLElement,
  position: ClickPosition,
): ClickPosition {
  const rect = element.getBoundingClientRect()

  return {
    x: Math.abs(Math.round(((position.x - rect.left) / rect.width) * 100)),
    y: Math.abs(Math.round(((position.y - rect.top) / rect.height) * 100)),
  }
}
