import { Contexts } from '@sentry/types'
import * as Sentry from '@sentry/vue'

const key = 'pageTitle'

/**
 * Context key for page title, which is used for naming grouped issues in Sentry
 */
export function setContextPageTitle(value: string): void {
  Sentry.setContext(key, { value })
}

export function getContextPageTitle(contexts: Contexts | undefined): string {
  const contextPageTitle = contexts?.[key]

  return (contextPageTitle?.value as string) ?? ''
}

export function deleteContextPageTitle(contexts: Contexts | undefined): void {
  delete contexts?.[key]
}
