import { SoccerEventDetailId } from '@collector/sportsapi-client-legacy'

import { EventInfoEventDetail } from '../../../configuration'

export const eventInfoEventDetails: EventInfoEventDetail[] = [
  [SoccerEventDetailId.WeatherConditions, { withClear: true, width: 'medium' }],
  [SoccerEventDetailId.PitchConditions, { withClear: true, width: 'medium' }],
  SoccerEventDetailId.PeriodLength,
  SoccerEventDetailId.ExtraPeriodLength,
  [SoccerEventDetailId.HasLineups, { width: 'narrow' }],
  [SoccerEventDetailId.HasFormations, { width: 'narrow' }],
  [SoccerEventDetailId.ExtraPeriodType, { width: 'wide' }],
]
