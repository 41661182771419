import type {
  Event,
  IncidentsPostDTO,
  IncidentsPostResponse,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type IncidentsPostPayload = IncidentsPostDTO

export type { IncidentsPostResponse }

/**
 * Insert incident without queue
 *
 * `/v2/events/{eventId}/incidents`
 */
export async function post(
  httpClient: HttpClient,
  eventId: Event['id'],
  payload: { incident: IncidentsPostPayload },
): Promise<IncidentsPostResponse> {
  return httpClient.url(`/events/${eventId}/incidents`).post(payload).json()
}
