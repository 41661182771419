<script setup lang="ts">
import { MultiSelect, TextInput } from '@collector/shared-ui'
import {
  EventDetailId,
  SportDetailInputType,
  SportDetailPossibleValue,
  SportDetails,
} from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { useEvent, useSportMaps } from '@desktop/views/Relation/composables'
import {
  ExtraOptions,
  isEventDetailId,
} from '@desktop/views/Relation/Sports/configuration'
import { injectEventInfoEventDetails } from '@desktop/views/Relation/Sports/provide-inject'

import { injectSuccessBadge } from './provide-inject/successBadge'

// inject
const eventInfoEventDetails = injectEventInfoEventDetails()
const { showSuccessBadge } = injectSuccessBadge()

// composables
const { eventDetailIdToSportDetailsMap } = useSportMaps()
const { event, eventDetailsMap } = useEvent()

const eventDetails = eventInfoEventDetails.map((eventInfoEventDetail) => {
  const detailId = isEventDetailId(eventInfoEventDetail)
    ? eventInfoEventDetail
    : eventInfoEventDetail[0]

  return {
    sportDetail: eventDetailIdToSportDetailsMap[detailId],
    extraOptions: Array.isArray(eventInfoEventDetail)
      ? {
          width: eventInfoEventDetail[1].width,
          withClear: !!eventInfoEventDetail[1].withClear,
        }
      : ({} as ExtraOptions),
  }
})

// methods
function getSelectOptions(sportDetail: SportDetails): string[] {
  return sportDetail.possible_values.map((possibleValue) => possibleValue.value)
}

function getEventDetailValue(detailId: EventDetailId): number | string | null {
  return eventDetailsMap.value[detailId].value
}

function getSportDetailPossibleValueKey(
  detail: SportDetails,
  value: string,
): SportDetailPossibleValue['key'] {
  return detail.possible_values.find(
    (possibleValue) => possibleValue.value === value,
  )?.key as number
}

async function updateValue(
  detailId: number,
  value: string | number,
): Promise<void> {
  await sportsApiClient.Events.patch(event.value.id, {
    details: { [detailId]: value },
  })

  showSuccessBadge()
}
</script>

<template>
  <template
    v-for="{ extraOptions, sportDetail } in eventDetails"
    :key="sportDetail.id"
  >
    <TextInput
      v-if="sportDetail.input === SportDetailInputType.Number"
      class="w-28"
      inputClass="w-full"
      :modelValue="getEventDetailValue(sportDetail.id) as string"
      numeric
      @blur="
        ({ value }) => {
          if (getEventDetailValue(sportDetail.id) !== value) {
            updateValue(sportDetail.id, value)
          }
        }
      "
    >
      {{ sportDetail.name }}
    </TextInput>
    <MultiSelect
      v-else-if="sportDetail.input === SportDetailInputType.Select"
      :class="{
        'w-64': extraOptions.width === 'wide',
        'w-36': extraOptions.width === 'medium',
        'w-24': extraOptions.width === 'narrow',
      }"
      :options="getSelectOptions(sportDetail)"
      :modelValue="getEventDetailValue(sportDetail.id) as string"
      :withClear="extraOptions.withClear"
      @update:modelValue="
        (value) => {
          updateValue(
            sportDetail.id,
            getSportDetailPossibleValueKey(sportDetail, value as string) || '',
          )
        }
      "
    >
      {{ sportDetail.name }}
    </MultiSelect>
  </template>
</template>
