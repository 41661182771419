import { createProvideInject } from '@collector/shared-ui'

import { PitchInteractionDisabledStatuses } from '../configuration'

export const [
  providePitchInteractionDisabledStatuses,
  injectPitchInteractionDisabledStatuses,
] = createProvideInject<PitchInteractionDisabledStatuses>(
  'pitchInteractionDisabledStatuses',
)
