<script setup lang="ts">
import { initWidget, StatscoreWidget } from '@collector/desktop-feature-widgets'
import { Env } from '@desktop/envs'
import { useEvent } from '@desktop/views/Relation/composables'

const { event } = useEvent()

initWidget(Env.VITE_WIDGETS_EMBEDDER_URL)
</script>

<template>
  <StatscoreWidget
    :configurationId="Env.VITE_WIDGETS_TIMELINE_GROUP_ID"
    :eventId="event.id"
  />
</template>
