import {
  getPercentagePositionXWithParticipant,
  isDangerousAttackForPercentagePositionX,
} from '@collector/shared-ui-domain'
import {
  FreeKickAttribute,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'

import { IncidentsQueuePayloadConfig } from '../../../configuration'

export const incidentsQueuePayloadConfig: IncidentsQueuePayloadConfig<SoccerIncidentId> =
  {
    [SoccerIncidentId.Foul]: {
      addIncident: (payload, queue, { oppositeParticipant }) => {
        queue.addIncident({
          ...payload,
          incident_id: SoccerIncidentId.Foul,
        })

        queue.addIncident({
          ...payload,
          participant_team_id: oppositeParticipant.id,
          incident_id: SoccerIncidentId.FreeKick,
        })
      },
    },
    [SoccerIncidentId.FreeKick]: {
      addIncident: (payload, queue, { oppositeParticipant }) => {
        queue.addIncident({
          ...payload,
          incident_id: SoccerIncidentId.Foul,
        })

        queue.addIncident({
          ...payload,
          participant_team_id: oppositeParticipant.id,
          incident_id: SoccerIncidentId.FreeKick,
        })
      },
      updateIncident: (payload, id, queue, { participant }) => {
        if (payload.x_pos !== undefined && payload.y_pos !== undefined) {
          const isDangerousAttack = isDangerousAttackForPercentagePositionX(
            getPercentagePositionXWithParticipant(payload.x_pos, participant),
          )

          if (isDangerousAttack) {
            payload.attribute_ids = [FreeKickAttribute.DangerousFreeKick]
          }
        }

        queue.updateIncident(payload, id)
      },
    },
    [SoccerIncidentId.Offside]: {
      addIncident: (payload, queue, { oppositeParticipant }) => {
        queue.addIncident({
          ...payload,
          incident_id: SoccerIncidentId.Offside,
        })
        queue.addIncident({
          ...payload,
          participant_team_id: oppositeParticipant.id,
          incident_id: SoccerIncidentId.FreeKick,
        })
      },
    },
    [SoccerIncidentId.SubstitutionIn]: {
      addIncident: (payload, queue) => {
        queue.addIncident({
          ...payload,
          incident_id: SoccerIncidentId.SubstitutionOut,
        })
        queue.addIncident({
          ...payload,
          incident_id: SoccerIncidentId.SubstitutionIn,
        })
      },
    },
  }
