export const enum StatusType {
  Live = 'live',
  Scheduled = 'scheduled',
  Finished = 'finished',
  ToFinish = 'to finish',
  Cancelled = 'cancelled',
  Interrupted = 'interrupted',
  Deleted = 'deleted',
  Other = 'other',
}
