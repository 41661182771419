<script setup lang="ts">
import { ButtonHTMLAttributes, computed } from 'vue'

import Icon from '../Icon/Icon.vue'
import { IconName, IconProps } from '../Icon/types'

type FontSize = 'text-2xs' | 'text-xs' | 'text-sm'

interface Props extends /* @vue-ignore */ ButtonHTMLAttributes {
  size?:
    | '2xs' /** 16px */
    | 'xs' /** 24px */
    | 'sm' /** 32px */
    | 'md' /** 40px */
    | 'lg' /** 48px */
  width?: 'full' | 'slim'
  icon?: IconName | ''
  iconSize?: IconProps['size']
  disabled?: boolean
  title?: string
  square?: boolean
  fontSize?: FontSize
}

const props = withDefaults(defineProps<Props>(), {
  iconSize: '1x',
})

const shape = computed(() =>
  props.square ? 'square' : props.size !== undefined ? 'rectangle' : undefined,
)

const fontSize = computed<FontSize | ''>(() => {
  if (props.fontSize) return props.fontSize
  if (props.size === 'xs' && shape.value === 'rectangle' && !props.width)
    return 'text-xs'
  if (shape.value) return 'text-sm'

  return ''
})
</script>

<template>
  <button
    class="select-none text-nowrap hover:transition hover:duration-150 hover:ease-in focus:outline-none focus:duration-75"
    :class="{
      // clickable button
      'cursor-default':
        $attrs.type !== 'submit' && $attrs.onClick === undefined,

      // font size
      [fontSize]: fontSize,

      // based styled button
      'inline-flex items-center justify-center rounded border': shape,
      'text-neutral-dark-10/50 border-neutral-light-3': shape && disabled,

      // rectangle
      'w-full': width === 'full' && shape === 'rectangle',

      'h-6': size === 'xs' && shape === 'rectangle',
      'px-0.5': size === 'xs' && shape === 'rectangle' && width === 'slim',
      'px-2': size === 'xs' && shape === 'rectangle' && !width,

      'h-8': size === 'sm' && shape === 'rectangle',
      'pl-3 pr-4': size === 'sm' && shape === 'rectangle' && !width,
      'px-1': size === 'sm' && shape === 'rectangle' && width === 'slim',
      'gap-1': size === 'sm' && shape === 'rectangle' && $slots.default,

      'h-10': size === 'md' && shape === 'rectangle',
      'px-4.5': size === 'md' && shape === 'rectangle' && !width,
      'gap-2': size === 'md' && shape === 'rectangle' && $slots.default,

      'h-12': size === 'lg' && shape === 'rectangle',
      'px-5': size === 'lg' && shape === 'rectangle' && !width,
      'gap-2.5': size === 'lg' && shape === 'rectangle' && $slots.default,

      // square
      'max-h-4 min-h-4 min-w-4 max-w-4': size === '2xs' && shape === 'square',
      'max-h-6 min-h-6 min-w-6 max-w-6': size === 'xs' && shape === 'square',
      'max-h-8 min-h-8 min-w-8 max-w-8': size === 'sm' && shape === 'square',
      'max-h-12 min-h-12 min-w-12 max-w-12':
        size === 'lg' && shape === 'square',
    }"
    type="button"
    :disabled
    :title
  >
    <Icon
      v-if="icon !== undefined"
      :class="{
        'pb-0.25 px-0.75': size === 'sm' && shape === 'rectangle',
        invisible: icon === '',
      }"
      :icon="icon === '' ? 'square' : icon"
      :size="iconSize"
    />
    <slot />
  </button>
</template>
