<script setup lang="ts">
import { computed } from 'vue'

import { getParticipantTeamSide } from '@collector/shared-ui-domain'
import { Participant, SubParticipant } from '@collector/sportsapi-client-legacy'

import FormationsPitchSubParticipant from './FormationsPitchSubParticipant.vue'
import { getFormationSectionsPlayersPosition } from './utils'

interface Props {
  participant: Participant
  playerPositionToSubParticipantMap: Record<string, SubParticipant>
}

const props = defineProps<Props>()

// data
const teamSide = getParticipantTeamSide(props.participant)

// model
const selectedPlayerPosition = defineModel<string>('selectedPlayerPosition')
const formation = defineModel<string>('formation', { default: '' })

// computed
const formationSectionsPlayersPosition = computed(() =>
  getFormationSectionsPlayersPosition(formation.value),
)

// methods
function togglePlayerPosition(playerPosition: string): void {
  if (selectedPlayerPosition.value === playerPosition) {
    selectedPlayerPosition.value = ''
  } else {
    selectedPlayerPosition.value = playerPosition
  }
}

function findSubParticipant(
  playerPosition: string,
): SubParticipant | undefined {
  return props.playerPositionToSubParticipantMap[playerPosition]
}
</script>

<template>
  <div class="relative">
    <img
      class="w-[calc(413*1rem/16)]"
      src="./pitch-vertical.png"
      alt="pitch with formation buttons"
    />
    <div class="absolute top-0 flex h-full w-full flex-col-reverse">
      <template
        v-for="sectionPlayersPostion in formationSectionsPlayersPosition"
        :key="String(sectionPlayersPostion)"
      >
        <div class="flex h-full flex-row-reverse items-center justify-around">
          <FormationsPitchSubParticipant
            v-for="playerPosition in sectionPlayersPostion"
            :key="playerPosition"
            :subParticipant="findSubParticipant(playerPosition)"
            :teamSide
            :selected="
              playerPosition === '1'
                ? false
                : selectedPlayerPosition === playerPosition
            "
            :itemClass="playerPosition !== '1' ? 'cursor-pointer' : ''"
            @click="
              playerPosition === '1'
                ? undefined
                : togglePlayerPosition(playerPosition)
            "
          />
          <!-- GK with position '1' cannot be selected-->
        </div>
      </template>
    </div>
  </div>
</template>
