<script setup lang="ts">
import { Button, Tooltip, useBreakpoints } from '@collector/shared-ui'

import ScoutsName from './ScoutsName.vue'

const { breakpoints } = useBreakpoints()
</script>

<template>
  <div class="z-10 flex items-center pr-2">
    <ScoutsName v-if="breakpoints.md.value" />

    <Tooltip
      v-else
      class="inline-block"
    >
      <Button
        class="bg-neutral-light-2"
        icon="user"
        size="xs"
        square
      />
      <template #tooltip>
        <ScoutsName />
      </template>
    </Tooltip>
  </div>
</template>
