<script setup lang="ts">
import { computed } from 'vue'

import { Button, Icon, Overlay, runLoader } from '@collector/shared-ui'
import {
  Participant,
  SoccerEventDetailId,
} from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { useEvent } from '@desktop/views/Relation/composables'
import { useParticipantsQuery } from '@desktop/views/Relation/composables/queries'

interface Props {
  participant: Participant
}
const props = defineProps<Props>()

// emits
type Emits = {
  clear: []
}
const emit = defineEmits<Emits>()

// composables
const { event, eventDetailsMap } = useEvent()
const { fetch: fetchParticipants } = useParticipantsQuery(event.value.id)

// computed
const isClearable = computed(() => {
  return (
    eventDetailsMap.value[SoccerEventDetailId.HasLineups].value ===
      'probable' && props.participant.lineups_copied
  )
})

// methods
async function clearLineup(): Promise<void> {
  await runLoader(
    sportsApiClient.Events.Participants.Lineups.clearLineup(
      event.value.id,
      props.participant.id,
    ),
  )
  await fetchParticipants()
  emit('clear')
}
</script>

<template>
  <Overlay
    class="-mx-4 -mt-2"
    style="width: calc(100% + 32px); height: calc(100% + 8px)"
    :modelValue="isClearable"
    position="contained"
    scrim="md"
  >
    <div
      class="bg-neutral-light-9 m-4 flex max-w-[28rem] flex-col items-center gap-4 rounded-md p-4 text-center"
    >
      <div
        class="text-success-2 bg-success-9 flex size-16 items-center justify-center rounded-full p-2 text-4xl"
        :rounded="false"
      >
        <Icon icon="users" />
      </div>
      <div class="text-xl font-bold">
        Use the "clear lineup" button to delete probable lineup set for this
        team
      </div>
      <Button
        class="bg-success-5 hover:bg-success-5/80 font-bold text-white duration-100"
        size="md"
        @click="clearLineup"
      >
        Clear lineup
      </Button>
    </div>
  </Overlay>
</template>
