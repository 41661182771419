import { ApiResponse, Competition } from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../httpClient'

export type EventsGetByIdResponse = ApiResponse<{
  competition: Competition
}>

export async function getById(
  httpClient: HttpClient,
  eventId: number,
): Promise<EventsGetByIdResponse> {
  return httpClient.url(`/events/${eventId}`).get().json()
}
