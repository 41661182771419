import md5 from 'md5'

import { Participant } from '@collector/sportsapi-client'

const logoPlaceholderFilename = 'noparticipants.png'
const s3Url = '//scor-s3-cdn.statscore.com/assets/scoreframe/img/logos'
export const logoPlaceholderUrl = `${s3Url}/${logoPlaceholderFilename}`

export function getParticipantLogoUrl(participant: Participant): string {
  const hasLogo = participant.logo === 'yes'
  const isPerson = ['double', 'person'].includes(participant.type)
  const isNational = participant.national === 'yes'

  if (isPerson || (hasLogo && isNational)) {
    return getNationalLogo(participant.area_id)
  } else if (hasLogo) {
    return getTeamLogo(participant.slug)
  }

  return logoPlaceholderUrl
}

function getNationalLogo(areaId: number): string {
  return `${s3Url}/country_${areaId}.png`
}

function getTeamLogo(slug: string): string {
  const hash = md5(`${slug}Softnet`)

  return `${s3Url}/${hash}.png`
}
