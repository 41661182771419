import { io, Socket } from 'socket.io-client'

import {
  AuthPayload,
  ClientErrorPayload,
  ClientToServerEvents,
  ServerToClientEvents,
} from '@collector/queue-gateway-shared-types'

import { ClientConfig } from './interfaces/client-config.interface'
import { SubscribeConfig } from './interfaces/event-config.interface'

export class QueueRabbitClient {
  private socket: Socket<ServerToClientEvents, ClientToServerEvents>

  public constructor(private config: ClientConfig) {
    this.socket = io(config.url as string, { auth: config.auth })

    this.socket.on('unsubscribe', (payload) => this.socket.off(payload.topic))
  }

  public onConnect(onConnect: () => void): void {
    this.socket.on('connect', async () => {
      this.unsubAll()
      onConnect()
    })
  }

  public onClientError(onError: (error: ClientErrorPayload) => void): void {
    this.socket.on('clientError', async (error, ack) => {
      onError(error)
      ack()
    })
  }

  public onDisconnect(onDisconnect: () => void): void {
    this.socket.on('disconnect', onDisconnect)
  }

  public setAuth(payload: AuthPayload): void {
    this.socket.emit('auth', payload)
  }

  public subEvent<T>(config: SubscribeConfig<T>): void {
    this.subscribeAndListen('event', config)
  }

  public unsubEvent(): void {
    this.unsubscribeType('event')
  }

  public subIncident<T>(config: SubscribeConfig<T>): void {
    this.subscribeAndListen('incident', config)
  }

  public unsubIncident(): void {
    this.unsubscribeType('incident')
  }

  public subLineups<T>(config: SubscribeConfig<T>): void {
    this.subscribeAndListen('events_lineups', config)
  }

  public unsubLineups(): void {
    this.unsubscribeType('events_lineups')
  }

  public subScouts<T>(config: SubscribeConfig<T>): void {
    this.subscribeAndListen('events_scouts', config)
  }

  public unsubScouts(): void {
    this.unsubscribeType('events_scouts')
  }

  public subBasicLivescore<T>(config: SubscribeConfig<T>): void {
    this.subscribeAndListen('basic_livescore', config)
  }

  public unsubBasicLivescore(): void {
    this.unsubscribeType('basic_livescore')
  }

  public subError<T>(config: SubscribeConfig<T>): void {
    this.subscribeAndListen('error', config)
  }

  public unsubError(): void {
    this.unsubscribeType('error')
  }

  public unsubAll(): void {
    this.unsubEvent()
    this.unsubIncident()
    this.unsubLineups()
    this.unsubScouts()
    this.unsubBasicLivescore()
    this.unsubError()
  }

  private subscribeAndListen<T>(
    type: string,
    config: SubscribeConfig<T>,
  ): void {
    const topic = this.buildTopic(type)
    const payload = { ...config, topic }
    this.socket.off(topic)
    this.socket.on(topic, (batch) => {
      batch.forEach((p) => config.onMessage?.(p as T))
      config.onMessageBatch?.(batch as T[])
    })
    this.socket.emit('subscribe', payload)
  }

  private unsubscribeType(type: string): void {
    const topic = this.buildTopic(type)
    this.unsubscribeTopic(topic)
  }

  private unsubscribeTopic(topic: string): void {
    this.socket.emit('unsubscribe', { topic })
    this.socket.off(topic)
  }

  private buildTopic(type: string): string {
    return `events/${this.config.eventId}/${type}`
  }

  public destroy(): void {
    this.socket.disconnect()
  }
}
