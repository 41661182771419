export enum SoccerStatId {
  Corners = 13,
  YellowCards = 8,
  RedCards = 9,
  Formation = 716,
  Substitutions = 31,
  Captain = 701,
  TotalShots = 19,
  ShotsOnTarget = 20,
  ShotsOffTarget = 21,
  GoalkeeperSaves = 29,
  Fouls = 22,
  Offsides = 24,
  FreeKicks = 25,
  ThrowIns = 32,
  Assists = 41,
  BallPossessionPercentage = 771,
  Goals = 40,
  OwnGoals = 17,
  PenaltiesGiven = 16,
  PenaltiesScored = 14,
  PenaltiesSaved = 639,
  PenaltiesMissed = 15,
  Attacks = 10,
  DangerousAttacks = 11,
  DangerousFreeKicks = 26,
  GoalKicks = 30,
  ShotWoodwork = 27,
  GoalLineClearences = 1395,
  Tackles = 1168,
  Interceptions = 487,
  FirstLineups = 36,
  Bench = 37,
  Appearance = 35,
  CurrentGoalkeeper = 1312,
  ShotsBlocked = 28,
  Blocks = 71,
}
