import memoizee from 'memoizee'
import * as R from 'ramda'
import { computed, ComputedRef, Ref } from 'vue'

import { getParticipantTeamSide } from '@collector/shared-ui-domain'
import { Participant } from '@collector/sportsapi-client'
import {
  Result,
  ResultId,
  Stat,
  StatId,
} from '@collector/sportsapi-client-legacy'

import { formatResultValue } from '../utils'
import { useParticipantsQuery } from './queries'
import { useEvent } from './useEvent'

const memoizedGetParticipant = memoizee(function (
  participants: Ref<Participant[]>,
  counter: number,
): ComputedRef<Participant> {
  return computed(
    () =>
      participants.value.find(
        (participant) => participant.counter === counter,
      )!,
  )
})

const memoizedGetParticipantResultsMap = memoizee(function (
  participant: Ref<Participant>,
): ComputedRef<Record<ResultId, Result>> {
  return computed(() => R.indexBy(R.prop('id'), participant.value.results))
})

const memoizedGetParticipantStatsMap = memoizee(function (
  participant: Ref<Participant>,
): ComputedRef<Record<StatId, Stat>> {
  return computed(() => R.indexBy(R.prop('id'), participant.value.stats))
})

const memoizedGetParticipantResult = function (
  participantResultsMap: ComputedRef<Record<ResultId, Result>>,
  resultId: ResultId | null,
): ComputedRef<ResultValue> {
  return computed(() => {
    const result =
      resultId === null ? null : participantResultsMap.value[resultId]

    return formatResultValue(result?.value ?? null)
  })
}

type ResultValue = string | number | null

type UseParticipants = {
  homeParticipant: ComputedRef<Participant>
  awayParticipant: ComputedRef<Participant>
  getParticipantById(participantId: Participant['id']): ComputedRef<Participant>

  // results
  homeParticipantResultsMap: ComputedRef<Record<ResultId, Result>>
  awayParticipantResultsMap: ComputedRef<Record<ResultId, Result>>
  getHomeParticipantResult: (
    resultId: ResultId | null,
  ) => ComputedRef<ResultValue>
  getAwayParticipantResult: (
    resultId: ResultId | null,
  ) => ComputedRef<ResultValue>

  // stats map
  homeParticipantStatsMap: ComputedRef<Record<StatId, Stat>>
  awayParticipantStatsMap: ComputedRef<Record<StatId, Stat>>
  getParticipantStatsMap: (
    participant: Participant,
  ) => ComputedRef<Record<StatId, Stat>>
}

export function useParticipants(): UseParticipants {
  const { event } = useEvent()
  const { data: participants } = useParticipantsQuery(event.value.id)

  const homeParticipant = memoizedGetParticipant(participants, 1)
  const awayParticipant = memoizedGetParticipant(participants, 2)

  const homeParticipantResultsMap =
    memoizedGetParticipantResultsMap(homeParticipant)
  const awayParticipantResultsMap =
    memoizedGetParticipantResultsMap(awayParticipant)

  const homeParticipantStatsMap =
    memoizedGetParticipantStatsMap(homeParticipant)
  const awayParticipantStatsMap =
    memoizedGetParticipantStatsMap(awayParticipant)

  function getHomeParticipantResult(
    resultId: ResultId | null,
  ): ComputedRef<ResultValue> {
    return memoizedGetParticipantResult(homeParticipantResultsMap, resultId)
  }

  function getAwayParticipantResult(
    resultId: ResultId | null,
  ): ComputedRef<ResultValue> {
    return memoizedGetParticipantResult(awayParticipantResultsMap, resultId)
  }

  function getParticipantStatsMap(
    participant: Participant,
  ): ComputedRef<Record<StatId, Stat>> {
    const teamSide = getParticipantTeamSide(participant)

    return teamSide === 'home'
      ? homeParticipantStatsMap
      : awayParticipantStatsMap
  }

  function getParticipantById(
    participantId: Participant['id'],
  ): ComputedRef<Participant> {
    if (homeParticipant.value.id === participantId) {
      return homeParticipant
    }

    if (awayParticipant.value.id === participantId) {
      return awayParticipant
    }

    throw new Error(`Participant with id ${participantId} not found`)
  }

  return {
    homeParticipant,
    awayParticipant,
    getParticipantById,
    homeParticipantResultsMap,
    awayParticipantResultsMap,
    homeParticipantStatsMap,
    awayParticipantStatsMap,
    getParticipantStatsMap,
    getHomeParticipantResult,
    getAwayParticipantResult,
  }
}
