<script setup lang="ts">
import { computed } from 'vue'

import { SoccerEventDetailId } from '@collector/sportsapi-client-legacy'
import OpenModalButton from '@desktop/views/Relation/components/RelationView/Main/Summary/OpenModalButton.vue'
import { useEvent } from '@desktop/views/Relation/composables'

// composables
const { eventDetailsMap } = useEvent()

// computed
const disabled = computed(
  () =>
    eventDetailsMap.value[SoccerEventDetailId.HasFormations]?.value === 'no',
)
</script>

<template>
  <OpenModalButton
    icon="f"
    title="Formations"
    :disabled
  />
</template>
