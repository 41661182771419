<script setup lang="ts">
import { computed } from 'vue'

import { TextInput } from '@collector/shared-ui'
import { opChain } from '@collector/shared-utils'

// props
interface Props {
  stat: { id: number; name: string; data_type: string }
  baseValues: { home: number | string | null; away: number | string | null }
}

const props = defineProps<Props>()

// model
const homeModel = defineModel<number | string | null>('home', {
  default: '0',
})

const awayModel = defineModel<number | string | null>('away', {
  default: '0',
})

// state
const isChanged = computed(
  () => homeValueChanged.value || awayValueChanged.value,
)

const homeValueChanged = computed(() => {
  return Number(homeModel.value) !== Number(props.baseValues.home)
})

const awayValueChanged = computed(() => {
  return Number(awayModel.value) !== Number(props.baseValues.away)
})
</script>

<template>
  <div
    class="hover:bg-neutral-light-5 flex h-12 w-full max-w-[33rem] flex-row items-center justify-around text-center"
    data-testid="team-stat-container"
  >
    <TextInput
      v-model="homeModel"
      class="mx-1"
      :class="homeValueChanged ? 'font-bold' : ''"
      :inputClass="`text-center w-14 ${homeValueChanged ? 'border-neutral-dark-0' : ''}`"
      :maxlength="opChain(stat, (v) => v.data_type) === 'decimal' ? 5 : 3"
      :data-testid="`home-${opChain(stat, (v) => v.id)}-stat-value`"
    />
    <div
      class="border-b-gray-light-collector flex h-full w-full flex-col justify-center border-b-[1px] text-xs first:border-t-[1px]"
      :class="isChanged ? 'font-bold' : ''"
    >
      {{ opChain(stat, (v) => v.name) }}
    </div>
    <TextInput
      v-model="awayModel"
      class="mx-1"
      :class="awayValueChanged ? 'font-bold' : ''"
      :inputClass="`text-center w-14 ${awayValueChanged ? 'border-neutral-dark-0' : ''}`"
      :maxlength="opChain(stat, (v) => v.data_type) === 'decimal' ? 5 : 3"
      :data-testid="`away-${opChain(stat, (v) => v.id)}-stat-value`"
    />
  </div>
</template>
