import { createProvideInject } from '@collector/shared-ui'

import { IncidentsRequirePointingPosition } from '../configuration/incidentsRequirePointingPosition'

export const [
  provideIncidentsRequirePointingPosition,
  injectIncidentsRequirePointingPosition,
] = createProvideInject<IncidentsRequirePointingPosition>(
  'incidentsRequirePointingPosition',
)
