<template>
  <table class="w-full border-separate border-spacing-y-1">
    <slot />
  </table>
</template>

<style scoped>
:deep(tr th) {
  @apply py-2 font-normal;
}

:deep(tbody tr) {
  @apply odd:bg-white;
}
</style>
