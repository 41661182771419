import { Season, StatsLevel } from '@collector/sportsapi-client-legacy'

import { injectSeason } from '../provide-inject/season'

type UseSeason = {
  season: Season
  isSeasonStatsLevel(statsLevel: StatsLevel): boolean
}

export function useSeason(): UseSeason {
  const season = injectSeason()

  function isSeasonStatsLevel(statsLevel: StatsLevel): boolean {
    return season.stats_lvl === statsLevel
  }

  return { season, isSeasonStatsLevel }
}
