import {
  EventDetailId,
  EventStatusId,
  IncidentAttribute,
  IncidentId,
  SportDetails,
  SportIncident,
  SportStatus,
} from '@collector/sportsapi-client-legacy'

import {
  injectEventDetailIdToSportDetailsMap,
  injectEventStatusToSportStatusMap,
  injectIncidentAttributeIdToAttributeMap,
  injectIncidentIdToSportIncidentMap,
} from '../provide-inject'

type UseSportMaps = {
  eventDetailIdToSportDetailsMap: Record<EventDetailId, SportDetails>
  eventStatusToSportStatusMap: Record<EventStatusId, SportStatus>
  incidentAttributeIdToAttributeMap: Record<
    IncidentAttribute['id'],
    IncidentAttribute
  >
  incidentIdToSportIncidentMap: Record<IncidentId, SportIncident>
  getIncidentName(incidentId: IncidentId): string
}

export function useSportMaps(): UseSportMaps {
  const eventDetailIdToSportDetailsMap = injectEventDetailIdToSportDetailsMap()
  const eventStatusToSportStatusMap = injectEventStatusToSportStatusMap()
  const incidentIdToSportIncidentMap = injectIncidentIdToSportIncidentMap()
  const incidentAttributeIdToAttributeMap =
    injectIncidentAttributeIdToAttributeMap()

  function getIncidentName(incidentId: IncidentId): string {
    return incidentIdToSportIncidentMap[incidentId].name ?? 'Unknown incident'
  }

  return {
    eventDetailIdToSportDetailsMap,
    eventStatusToSportStatusMap,
    incidentIdToSportIncidentMap,
    incidentAttributeIdToAttributeMap,
    getIncidentName,
  }
}
