import { Ref, ref } from 'vue'

type UseTimeout = {
  ready: Ref<boolean>
  start(): void
}

export function useTimeout(timeoutMs: number): UseTimeout {
  const ready = ref(false)

  function start(): void {
    setTimeout(() => {
      ready.value = true
    }, timeoutMs)
  }

  return {
    ready,
    start,
  }
}
