import {
  ApiResponse,
  BaseParticipant,
  Participant,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../httpClient'

export * as Squad from './squad'

export type ParticipantSquadGetBySeasonIdResponse = ApiResponse<{
  participants?: Participant[]
}>

export type ParticipantGetByIdResponse = ApiResponse<{
  participant: Participant
}>

type ParticipantGetResponse = ApiResponse<{
  participants: BaseParticipant[]
}>

export type ParticipantsGetQuery = {
  sport_id: number
  subtype?: string
  gender?: string
  provider_id?: number
  provider_participant_id?: number
  type?: string
  short_name?: string
}

export async function get(
  httpClient: HttpClient,
  query: ParticipantsGetQuery,
): Promise<ParticipantGetResponse> {
  return httpClient.url(`/participants`).query(query).get().json()
}

export async function getById(
  httpClient: HttpClient,
  participantId: number,
): Promise<ParticipantGetByIdResponse> {
  return httpClient.url(`/participants/${participantId}`).get().json()
}
