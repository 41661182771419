<script setup lang="ts">
import { useStatistics } from '../../composables'
import Content from './Content.vue'
import Header from './Header/Header.vue'

const { statsVisible } = useStatistics()
</script>

<template>
  <div class="px-4 pt-2">
    <Header />
    <div class="max-w-screen mt-3">
      <slot
        v-if="statsVisible"
        name="statistics"
      />

      <Content v-show="!statsVisible">
        <template #summary>
          <slot name="summary" />
        </template>
        <template #middle>
          <slot name="middle" />
        </template>
        <template #bottom>
          <slot name="bottom" />
        </template>
      </Content>
    </div>
  </div>
</template>
