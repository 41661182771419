<script setup lang="ts">
import { TransitionFadeOut } from '@collector/shared-ui'

import ChangeSidesButton from './ChangeSidesButton.vue'
import QueueDisplayedIncident from './QueueDisplayedIncident/QueueDisplayedIncident.vue'
import { useLastDisplayedIncident } from './QueueDisplayedIncident/useLastDisplayedIncident'
import QueueErrorStatusIndicator from './QueueErrorStatusIndicator.vue'

const { lastDisplayedIncident, status } = useLastDisplayedIncident()
</script>

<template>
  <div class="flex gap-x-2">
    <div class="flex w-full justify-center">
      <TransitionFadeOut>
        <QueueDisplayedIncident
          v-if="lastDisplayedIncident && status"
          :status
          :incident="lastDisplayedIncident"
        />
      </TransitionFadeOut>
    </div>
    <QueueErrorStatusIndicator />
    <ChangeSidesButton />
  </div>
</template>
