import {
  ApiResponse,
  Event,
  Participant,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../../httpClient'

export async function clearLineup(
  httpClient: HttpClient,
  eventId: Event['id'],
  participantId: Participant['id'],
): Promise<ApiResponse<void>> {
  return httpClient
    .url(
      `/events/${eventId}/participants/${participantId}/lineups/clear-lineup`,
    )
    .post()
    .json()
}
