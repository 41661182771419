export function isPressedNumber(event: KeyboardEvent): boolean {
  return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)
}

export function isPressedEscape(event: KeyboardEvent): boolean {
  return event.key === 'Escape'
}

export function isPressedTab(event: KeyboardEvent): boolean {
  return event.key === 'Tab'
}

export function isPressedEnter(event: KeyboardEvent): boolean {
  return event.key === 'Enter'
}

export function isPressedBackspace(event: KeyboardEvent): boolean {
  return event.key === 'Backspace'
}

export function isPressedSelectAll(event: KeyboardEvent): boolean {
  return (
    (event.key === 'a' || event.key === 'A') && (event.metaKey || event.ctrlKey)
  )
}

export function isPressedDelete(event: KeyboardEvent): boolean {
  return event.key === 'Delete'
}

export function isPressedArrow(event: KeyboardEvent): boolean {
  return (
    event.key === 'ArrowUp' ||
    event.key === 'ArrowDown' ||
    event.key === 'ArrowLeft' ||
    event.key === 'ArrowRight'
  )
}

export function getHandlePressed(
  isPressed: (event: KeyboardEvent) => boolean,
  callback: () => void,
): (event: KeyboardEvent) => void {
  return function (event: KeyboardEvent) {
    if (isPressed(event)) {
      callback()
    }
  }
}
