import {
  EventUpdateResponse,
  UpdateEventDTO,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../httpClient'

export async function patch(
  httpClient: HttpClient,
  eventId: number,
  payload: UpdateEventDTO,
): Promise<EventUpdateResponse> {
  return httpClient.url(`/events/${eventId}`).patch(payload).json()
}
