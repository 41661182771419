import { getFormattedTime } from '@collector/shared-utils'

type MinuteSecond = { minute: number | null; second: number | null }

export function getEventTime({ minute, second }: MinuteSecond): string {
  if (minute === null || second === null) {
    return '--:--'
  }

  return getFormattedTime(minute, second)
}
