import { ComputedRef } from 'vue'

import {
  CommonIncidentId,
  EventStatusId,
} from '@collector/sportsapi-client-legacy'

import {
  provideBetIncidents,
  provideEventInfoEventDetails,
  provideEventPeriodTime,
  provideEventStatuses,
  provideEventStatusIncidentsOnFieldConfig,
  provideFirstHalfResultId,
  provideHandyBarInfoAlwaysVisibleIncidents,
  provideHistoryIncidentsConfig,
  provideImportantStatsId,
  provideIncidentIdsGroup,
  provideIncidentsInFilter,
  provideIncidentsQueuePayloadConfig,
  providePitchDisabledStatuses,
  providePitchIncidentsConfig,
  providePitchInteractionDisabledStatuses,
  providePocketIncidentsConfig,
  provideRelatedEventStatusConfig,
} from '.'
import {
  BetIncidents,
  EventInfoEventDetail,
  EventStatusIncidentsOnFieldConfig,
  FirstHalfResultId,
  HandyBarInfoAlwaysVisibleIncidents,
  HistoryIncidentsConfig,
  ImportantStats,
  IncidentIdsGroup,
  IncidentsInFilter,
  IncidentsQueuePayloadConfig,
  ManualAddIncidentConfig,
  PitchDisabledStatuses,
  PitchIncidentsConfig,
  PitchInteractionDisabledStatuses,
  PocketIncidentsConfig,
  RelatedEventStatusConfig,
  StatisticsConfig,
} from '../configuration'
import { PitchOverlaySupportedStatuses } from '../configuration/pitchOverlaySupportedStatuses'
import { provideManualAddIncidentConfig } from './manualAddIncidentConfig'
import { providePitchOverlaySupportedStatuses } from './pitchOverlaySupportedStatuses'
import { provideStatisticsConfig } from './statisticsConfig'

type Params = {
  betIncidents?: BetIncidents
  pitchInteractionDisabledStatuses: PitchInteractionDisabledStatuses
  eventInfoEventDetails: EventInfoEventDetail[]
  eventPeriodTime: ComputedRef<number>
  eventStatusIncidentsOnFieldConfig: EventStatusIncidentsOnFieldConfig | null
  eventStatuses: EventStatusId[] | null
  incidentsInFilter: IncidentsInFilter
  incidentIdsGroup: IncidentIdsGroup | null
  firstHalfResultId: FirstHalfResultId | null
  handyBarInfoAlwaysVisibleIncidents: HandyBarInfoAlwaysVisibleIncidents | null
  historyIncidentsConfig: HistoryIncidentsConfig
  importantStatsId: ImportantStats
  incidentsQueuePayloadConfig: IncidentsQueuePayloadConfig | null
  manualAddIncidentConfig: ManualAddIncidentConfig
  pocketIncidentsConfig: PocketIncidentsConfig
  pitchDisabledStatuses: PitchDisabledStatuses
  pitchIncidentsConfig: PitchIncidentsConfig
  relatedEventStatusConfig: RelatedEventStatusConfig
  pitchOverlaySupportedStatuses: PitchOverlaySupportedStatuses
  statisticsConfig: StatisticsConfig
}

/**
 * Vue provide/inject - provide specific sport configuration
 *
 * You cant use async/await here - Vue will show warning and won't resolve value provided with inject
 */
export function provideSportConfiguration(params: Params): void {
  provideBetIncidents(
    new Set([
      CommonIncidentId.Betstart,
      CommonIncidentId.Betstop,
      ...(params.betIncidents || []),
    ]),
  )
  providePitchInteractionDisabledStatuses(
    params.pitchInteractionDisabledStatuses,
  )
  provideEventInfoEventDetails(params.eventInfoEventDetails)
  provideEventPeriodTime(params.eventPeriodTime)
  provideEventStatuses(params.eventStatuses)
  provideEventStatusIncidentsOnFieldConfig(
    params.eventStatusIncidentsOnFieldConfig,
  )
  provideIncidentIdsGroup(params.incidentIdsGroup)
  provideIncidentsInFilter(params.incidentsInFilter)
  provideFirstHalfResultId(params.firstHalfResultId)
  provideHandyBarInfoAlwaysVisibleIncidents(
    params.handyBarInfoAlwaysVisibleIncidents,
  )
  provideHistoryIncidentsConfig(params.historyIncidentsConfig)
  provideImportantStatsId(params.importantStatsId)
  provideIncidentsQueuePayloadConfig(params.incidentsQueuePayloadConfig)
  provideManualAddIncidentConfig(params.manualAddIncidentConfig)
  providePitchDisabledStatuses(params.pitchDisabledStatuses)
  providePitchIncidentsConfig(params.pitchIncidentsConfig)
  providePocketIncidentsConfig(params.pocketIncidentsConfig)
  provideRelatedEventStatusConfig(params.relatedEventStatusConfig)
  providePitchOverlaySupportedStatuses(params.pitchOverlaySupportedStatuses)
  provideStatisticsConfig(params.statisticsConfig)
}
