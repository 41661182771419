import ShortUniqueId from 'short-unique-id'

const shortUniqueIdAlphabetical = new ShortUniqueId({
  length: 16,
  dictionary: 'alpha',
})

const uidWithTimestamp = new ShortUniqueId({
  dictionary: 'hex',
})

export function getTimeBasedUUID(): string {
  const timeUuid = uidWithTimestamp.stamp(12)
  const splitTimeUuid = `${timeUuid.substring(0, 8)}-${timeUuid.substring(
    8,
    12,
  )}`

  // let's format generated id like UUID
  return uidWithTimestamp.formattedUUID(`${splitTimeUuid}-$s4-$s4-$s12`)
}

export function getRandomHtmlId(): string {
  return shortUniqueIdAlphabetical.rnd()
}
