<script setup lang="ts">
import { computed, ref } from 'vue'

import { Box } from '@collector/shared-ui'
import { useBlockades } from '@desktop/views/Relation/Sports/composables'
import { injectPocketIncidentsConfig } from '@desktop/views/Relation/Sports/provide-inject'

import MorePocketIncidentsModal from './MorePocketIncidentsModal.vue'
import PocketIncidentButton from './PocketIncidentButton.vue'
import PocketIncidentIcon from './PocketIncidentIcon.vue'

// state
const isModalVisible = ref(false)

// inject
const { morePocketIncidents, pocketIncidents, blockedPocketIncidentsMap } =
  injectPocketIncidentsConfig()

// composables
const { isIncidentBlocked } = useBlockades()

// computed
const moreButtonDisabled = computed(
  () =>
    blockedPocketIncidentsMap &&
    morePocketIncidents?.every(([morePocketIncident]) =>
      isIncidentBlocked(morePocketIncident, blockedPocketIncidentsMap),
    ),
)

const incidentDisabled = computed(() => {
  return (incidentId: number) => {
    return (
      blockedPocketIncidentsMap &&
      isIncidentBlocked(incidentId, blockedPocketIncidentsMap)
    )
  }
})
</script>

<template>
  <Box class="flex gap-2">
    <PocketIncidentButton
      v-for="[incidentId, name, icon] in pocketIncidents"
      :key="incidentId"
      class="@md:px-2"
      :title="name"
      :incidentId
    >
      <div
        :class="{
          'flex flex-col items-center': icon,
        }"
      >
        <PocketIncidentIcon
          v-if="icon"
          :icon
          :disabled="incidentDisabled(incidentId)"
        />

        <div class="@md:block hidden">
          {{ name }}
        </div>
      </div>
    </PocketIncidentButton>
    <PocketIncidentButton
      v-if="morePocketIncidents"
      :disabled="moreButtonDisabled"
      title="More"
      @click="isModalVisible = true"
    >
      <div class="flex flex-col items-center">
        <div class="h-5 w-6">...</div>
        <div class="@md:block hidden">More</div>
      </div>
    </PocketIncidentButton>
    <MorePocketIncidentsModal
      v-if="isModalVisible"
      @close="isModalVisible = false"
    />
  </Box>
</template>
