import { MaybeGetter } from '@collector/shared-utils'
import { Incident } from '@collector/sportsapi-client'
import {
  IncidentAttributeId,
  IncidentId,
} from '@collector/sportsapi-client-legacy'

import { SubParticipantOption } from '../../utils'

export type ShouldLoadOppositeSubParticipants =
  | boolean
  | ((attributeIds: IncidentAttributeId[]) => boolean)

export type FilterSubParticipantsOptions = (
  subParticipants: SubParticipantOption[],
) => SubParticipantOption[]

export type SubParticipantSettings = {
  name: string
  required?: boolean
  keepSelectedOption?: boolean
  shouldLoadOppositeSubParticipants?: ShouldLoadOppositeSubParticipants
  filterSubParticipantsOptions?: FilterSubParticipantsOptions
}

export type IncidentSettings = {
  participant?: string | SubParticipantSettings
  assistant?: MaybeGetter<string | SubParticipantSettings, Incident>
  attributeIds?: MaybeGetter<IncidentAttributeId[] | undefined, Incident>
  singleAttributes?: boolean
  /** label for display_info property of Incident */
  displayInfo?: string
  swappable?: MaybeGetter<boolean, Incident>
}

export type HistoryIncidentsConfig<T extends IncidentId = IncidentId> = {
  [key in T]?: IncidentSettings
}

export type SubParticipantType = 'participant' | 'assistant'

export function isSubParticipantSetting(
  value: string | SubParticipantSettings | undefined,
): value is SubParticipantSettings {
  return typeof value === 'object'
}
