<script setup lang="ts">
import {
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import BetMarketButton from '@desktop/views/Relation/Sports/components/BetMarketButton/BetMarketButton.vue'

const disabledStatuses = new Set([
  SoccerEventStatusId.NotStarted,
  SoccerEventStatusId.StartDelayed,
  SoccerEventStatusId.Postponed,
  SoccerEventStatusId.Abandoned,
  SoccerEventStatusId.Cancelled,
])
</script>

<template>
  <BetMarketButton
    betMarketEventKey="bet_cards"
    betStatusActiveText="Close cards"
    betStatusInactiveText="Open cards"
    :openMarketsIncidentId="SoccerIncidentId.BookingMarketsEnabled"
    :closeMarketsIncidentId="SoccerIncidentId.BookingMarketsDisabled"
    :disabledStatuses
  >
    <img
      class="h-3.5 w-3.5"
      src="./card.svg"
    />
  </BetMarketButton>
</template>
