<script setup lang="ts">
import { ref } from 'vue'

import { EventIncidentsQueue } from '@collector/desktop-feature-incidents-queue-core'
import { Button, Dropdown } from '@collector/shared-ui'
import { GetIncident, IsIncidentImportant } from '@collector/shared-ui-domain'
import {
  IncidentId,
  Participant,
  SportIncident,
} from '@collector/sportsapi-client-legacy'

import { setShowQueueCallback } from '../utils'
import QueueView from './QueueView/QueueView.vue'

type ButtonProps = InstanceType<typeof Button>['$props']

interface Props {
  eventIncidentsQueue: EventIncidentsQueue
  getIncident: GetIncident
  isIncidentImportant: IsIncidentImportant
  homeParticipant: Participant
  awayParticipant: Participant
  incidentIdToSportIncidentMap: Record<IncidentId, SportIncident>
  icon?: ButtonProps['icon']
  size?: ButtonProps['size']
  iconSize?: ButtonProps['iconSize']
  square?: ButtonProps['square']
}

const props = defineProps<Props>()

// state
const incidentsCount = ref<number | null>(null)
const shouldShowError = ref(false)
const dropdown = ref<InstanceType<typeof Dropdown> | null>(null)

// methods
function openDropdown(): void {
  dropdown.value?.open()
}

// init
props.eventIncidentsQueue.subscribeOnChange(
  ({ queue, errorQueueIncidents }) => {
    incidentsCount.value = queue.length
    shouldShowError.value = !!errorQueueIncidents.length
  },
)

setShowQueueCallback(openDropdown)
</script>

<template>
  <Button
    class="relative"
    :class="{
      'error-pulse': shouldShowError,
      [`${$attrs.class}`]: $attrs.class,
    }"
    :size
    :icon
    :iconSize
    :square
    @click="openDropdown"
  >
    <slot />
    <span
      v-if="incidentsCount !== null"
      class="text-2xs absolute bottom-0 right-0.5"
    >
      {{ incidentsCount }}
    </span>
  </Button>
  <Dropdown
    ref="dropdown"
    class="top-9"
  >
    <QueueView
      :eventIncidentsQueue
      :isIncidentImportant
      :getIncident
      :homeParticipant
      :awayParticipant
      :incidentIdToSportIncidentMap
    />
  </Dropdown>
</template>

<style scoped>
@keyframes error-pulse-animation {
  50% {
    @apply bg-error-5;
    @apply border-error-5;
    @apply text-neutral-light-10;
  }
}

.error-pulse {
  animation: error-pulse-animation 3s infinite;
}
</style>
