<script setup lang="ts">
import FormControl from '../components/FormControl.vue'

interface Props {
  size?: 'xs' /** 24px */
}

withDefaults(defineProps<Props>(), { size: 'xs' })

// model
const value = defineModel<boolean>()
</script>

<template>
  <FormControl>
    <template #label>
      <slot />
    </template>
    <input
      v-model="value"
      class="[&:checked~.toggle]:bg-success-4 peer sr-only"
      type="checkbox"
    />
    <div
      class="toggle bg-neutral-dark-3 border-neutral-light-10 after:bg-neutral-light-10 relative h-6 w-[2.82rem] cursor-pointer overflow-hidden rounded-full border after:absolute after:start-[0.03rem] after:top-[0.02rem] after:h-[1.32rem] after:w-[1.32rem] after:rounded-full after:transition-all peer-checked:after:translate-x-full peer-focus:outline-none rtl:peer-checked:after:-translate-x-full"
      @click="value = !value"
    />
  </FormControl>
</template>
