<script setup lang="ts">
import { overlayCva, OverlayProps } from '.'

interface Props {
  scrim?: OverlayProps['scrim']
  position?: OverlayProps['position']
}

defineProps<Props>()

const open = defineModel<boolean>({ default: false })
</script>

<template>
  <div
    v-if="open"
    :class="overlayCva({ position, scrim, ...$attrs })"
  >
    <slot />
  </div>
</template>
