import { createProvideInject } from '@collector/shared-ui'

import {
  AddPlayer,
  CanAddSubParticipant,
  CanAddSubParticipantTitle,
} from '../types'

export const [provideCanAddPlayer, injectCanAddPlayer] = createProvideInject<{
  canAddSubParticipant: CanAddSubParticipant
  canAddSubParticipantTitle: CanAddSubParticipantTitle
}>('canAddPlayer')

export const [provideAddPlayer, injectAddPlayer] =
  createProvideInject<AddPlayer>('addPlayer')
