import { Events, SportsApiClient } from '@collector/sportsapi-client'

import {
  BaseIncident,
  QueueIncidentError,
  QueueIncidentStatus,
} from './BaseIncident'

export type UpdateIncidentPayload = Omit<
  Events.IncidentsQueue.IncidentsQueuePatchPayload,
  'action' | 'event_id'
>

export class UpdateIncident extends BaseIncident {
  readonly payload: UpdateIncidentPayload

  readonly id: number

  readonly type = 'Update'

  constructor(
    eventId: number,
    payload: UpdateIncidentPayload,
    id: number,
    status?: QueueIncidentStatus,
    time?: number,
    error?: QueueIncidentError,
  ) {
    super(eventId, status, time, error)

    this.payload = payload
    this.id = id
  }

  public getPayload(): UpdateIncidentPayload {
    return this.payload
  }

  public getId(): number {
    return this.id
  }

  public async process(sportsApiClient: SportsApiClient): Promise<string> {
    const response = await sportsApiClient.Events.IncidentsQueue.patch(
      this.eventId,
      this.id,
      {
        incident: {
          ...this.payload,
          event_id: this.eventId,
          action: 'update',
        },
      },
    )

    return response.api.data.uuid
  }
}
