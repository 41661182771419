<script setup lang="ts" generic="T">
import { computed, ref, watch } from 'vue'

import Pagination from './Pagination/Pagination.vue'

interface Props {
  items: T[]
  reversed?: boolean
  listClass?: string | Record<string, boolean>
}

const props = defineProps<Props>()

if (props.reversed === false) {
  throw Error('reversed = false unsupported')
}

// data
const maxItemsPerPage = 10

// state
const currentPage = ref(1)

// computed
const pagesCount = computed(
  () => Math.ceil(props.items.length / maxItemsPerPage) || 1,
)

const itemsOnPage = computed(() => {
  const endIndex =
    props.items.length - (currentPage.value - 1) * maxItemsPerPage
  const startIndex =
    endIndex - maxItemsPerPage > 0 ? endIndex - maxItemsPerPage : 0

  return props.items.slice(startIndex, endIndex).reverse()
})

// watchers
watch(pagesCount, () => {
  if (currentPage.value > pagesCount.value) {
    currentPage.value = 1
  }
})
</script>

<template>
  <div :class="$attrs.class">
    <template v-for="(item, index) in itemsOnPage">
      <slot
        name="item"
        :item
      >
        <div :key="index">
          {{ item }}
        </div>
      </slot>
    </template>
    <slot
      v-if="!items.length"
      name="empty"
    />
  </div>
  <Pagination
    v-model="currentPage"
    class="mt-4"
    :pagesCount
  />
</template>
