import { Events, Incident } from '@collector/sportsapi-client'
import { IncidentId } from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'

export function swapIncident(
  incident: Incident,
  newIncidentId: IncidentId,
  newIncidentParticipantId: number,
): Promise<Events.Incidents.Swap.SwapIncidentsPostResponse> {
  const eventId = incident.event_id

  return sportsApiClient.Events.Incidents.Swap.post(eventId, {
    event_incident_id: incident.id,
    incident_id: newIncidentId,
    team_id: newIncidentParticipantId,
  })
}
