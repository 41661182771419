import { hasAccessToEvent } from '@collector/sportsapi-client'
import { EventScout, Sport } from '@collector/sportsapi-client-legacy'
import * as AuthInfo from '@desktop/globalState/authInfo'

import { isSportImplemented } from '../Sports/utils'

export function validateAssignedScouts(assignedScouts: EventScout[]): void {
  if (!hasAccessToEvent(AuthInfo.get(), assignedScouts))
    throw Error('You are not assigned to this relation')
}

export function validateRelationAccess(
  sport: Sport,
  assignedScouts: EventScout[],
): void {
  if (!isSportImplemented(sport.id)) {
    throw Error(`Unsupported sport: "${sport.name}" (id = ${sport.id})`)
  }

  validateAssignedScouts(assignedScouts)
}
