import {
  isBetMarketUpdateEnabled as _isBetMarketUpdateEnabled,
  BetMarketType,
} from '@collector/shared-ui-domain'
import { EventStatusId, IncidentId } from '@collector/sportsapi-client-legacy'

import { useEvent } from '../../composables'
import { BlockedIncidentsMap } from '../configuration/blockedIncidentsMap'
import { injectPitchDisabledStatuses } from '../provide-inject'

type UseBlockades = {
  isIncidentBlocked(
    incidentId: IncidentId,
    blockedIncidentsMap: BlockedIncidentsMap,
  ): boolean
  hasCurrentEventStatus(disabledStatuses: Set<EventStatusId>): boolean
  isPitchBlocked(): boolean
  isBetMarketUpdateEnabled(market: BetMarketType): boolean
}

export function useBlockades(): UseBlockades {
  const { event } = useEvent()

  const pitchDisabledStatuses = injectPitchDisabledStatuses()

  function isIncidentBlocked(
    incidentId: IncidentId,
    blockedIncidentsMap: BlockedIncidentsMap,
  ): boolean {
    const blockedIncidents = blockedIncidentsMap.get(event.value.status_id)

    if (!blockedIncidents) {
      return false
    }

    // searching in Set - O(1)
    return blockedIncidents.has(incidentId)
  }

  function hasCurrentEventStatus(
    disabledStatuses: Set<EventStatusId>,
  ): boolean {
    return disabledStatuses.has(event.value.status_id)
  }

  function isPitchBlocked(): boolean {
    return hasCurrentEventStatus(pitchDisabledStatuses)
  }

  function isBetMarketUpdateEnabled(market: BetMarketType): boolean {
    return _isBetMarketUpdateEnabled(event.value, market)
  }

  return {
    isIncidentBlocked,
    hasCurrentEventStatus,
    isPitchBlocked,
    isBetMarketUpdateEnabled,
  }
}
