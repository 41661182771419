<script setup lang="ts">
import { onMounted, ref } from 'vue'

import {
  addElementClickHandler,
  getElementPercentagePosition,
} from '@collector/shared-ui'
import { getPercentagePositionXWithParticipant } from '@collector/shared-ui-domain'
import {
  useEventSettings,
  useLastIncident,
} from '@desktop/views/Relation/composables'
import {
  useEventIncidentsQueue,
  useParticipantInPossesion,
} from '@desktop/views/Relation/Sports/composables'

import FieldIndicators from './FieldIndicators/FieldIndicators.vue'
import {
  getIncidentIdForPercentagePositionX,
  getPercentagePositionWithTeamSideChanged,
} from './utils'

// composables
const { eventSettings } = useEventSettings()
const { lastNonDeletedIncident } = useLastIncident()
const eventIncidentsQueue = useEventIncidentsQueue()
const { participantInPossession } = useParticipantInPossesion()

// state
const field = ref<HTMLElement>()

// init
onMounted(() => {
  const fieldElement = field.value!

  // when fieldElement is available, handle incident for new clicked position
  addElementClickHandler(fieldElement, (position) => {
    if (!participantInPossession.value) {
      return
    }

    const percentagePosition = getPercentagePositionWithTeamSideChanged(
      getElementPercentagePosition(fieldElement, position),
      !!eventSettings.value.teamSideChanged,
    )
    const { x: x_pos, y: y_pos } = percentagePosition

    const lastIncident = lastNonDeletedIncident.value

    // if clicked position.x requires new incident, or existing incident needs update to new position
    const incidentId = getIncidentIdForPercentagePositionX(
      getPercentagePositionXWithParticipant(
        percentagePosition.x,
        participantInPossession.value,
      ),
    )

    const payload = {
      incident_id: incidentId,
      x_pos,
      y_pos,
    }

    // new position in the same area as last incident
    const isEqualIncidentIdAsLastIncident =
      incidentId === lastIncident?.incident_id

    // new position was clicked by the same participant
    const isEqualParticipantAsLastIncident =
      lastIncident?.participant_team_id === participantInPossession.value.id

    if (isEqualIncidentIdAsLastIncident && isEqualParticipantAsLastIncident) {
      eventIncidentsQueue.updateIncident(payload, lastIncident)
    } else {
      eventIncidentsQueue.addIncident({
        ...payload,
        participant_team_id: participantInPossession.value.id,
      })
    }
  })
})
</script>

<template>
  <div
    ref="field"
    class="h-full"
  >
    <FieldIndicators :class="{ 'rotate-180': eventSettings.teamSideChanged }" />
  </div>
</template>
