import * as R from 'ramda'
import { computed, ComputedRef, Ref, ref, watch } from 'vue'

import { isIncidentDeleted } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import { useIncidents } from '@desktop/views/Relation/composables'

type UseLastIncident = {
  lastNonDeletedIncident: Ref<Incident | undefined>
  lastIncidentWithPosition: ComputedRef<Incident | undefined>
}

export function useLastIncident(): UseLastIncident {
  // composables
  const { incidents, incidentsUpdate } = useIncidents()

  // state
  const lastNonDeletedIncident = ref(
    findLastNonDeletedIncident(incidents.value),
  )
  const lastIncidentWithPosition = computed(() =>
    findLastNonDeletedIncidentWithPosition(incidents.value),
  )

  // methods
  function findLastNonDeletedIncident(
    incidents: Incident[],
  ): Incident | undefined {
    return R.findLast((incident) => !isIncidentDeleted(incident), incidents)
  }

  // methods
  function findLastNonDeletedIncidentWithPosition(
    incidents: Incident[],
  ): Incident | undefined {
    return R.findLast(
      (incident) =>
        !isIncidentDeleted(incident) &&
        typeof incident.x_pos === 'number' &&
        typeof incident.x_pos === 'number',
      incidents,
    )
  }

  // watchers
  watch(incidentsUpdate, (newVal) => {
    const { data } = R.last(newVal)!

    if (
      data.action === 'delete' &&
      data.id === lastNonDeletedIncident.value?.id
    ) {
      lastNonDeletedIncident.value = findLastNonDeletedIncident(incidents.value)
    } else if (data.action === 'insert') {
      lastNonDeletedIncident.value = data
    }
  })

  return {
    lastIncidentWithPosition,
    lastNonDeletedIncident,
  }
}
