import { Result, Stat } from '@collector/sportsapi-client-legacy'

function formatResultOrStatValue(
  value: Result['value'] | Stat['value'],
): string | number {
  return value === null || value === '' ? '-' : value
}

export function formatResultValue(value: Result['value']): string | number {
  return formatResultOrStatValue(value)
}

export function formatStatValue(value: Stat['value']): string | number {
  return formatResultOrStatValue(value)
}
