import type {
  AuthInfoObject,
  AuthInfoResponse,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type AuthInfoType = AuthInfoObject
export type AuthInfoGetResponse = AuthInfoResponse

export async function get(
  httpClient: HttpClient,
): Promise<AuthInfoGetResponse> {
  return httpClient.url('/auth/info').get().json()
}
