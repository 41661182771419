<script setup lang="ts">
import { watch } from 'vue'

import { useEvent, useStatistics } from '@desktop/views/Relation/composables'
import {
  fetchParticipants,
  fetchSubParticipants,
} from '@desktop/views/Relation/dependencies'
import { injectSubParticipants } from '@desktop/views/Relation/provide-inject'
import { injectParticipants } from '@desktop/views/Relation/provide-inject/participants'

import Button from './Button.vue'

// composables
const { statsVisible } = useStatistics()
const { event } = useEvent()

const subParticipants = injectSubParticipants()
const participants = injectParticipants()

// watches
watch(statsVisible, async (newVal) => {
  if (newVal) {
    participants.value = await fetchParticipants(event.value.id)
    subParticipants.value = await fetchSubParticipants(event.value.id)
  }
})
</script>

<template>
  <Button
    icon="chart-pie"
    :disabled="statsVisible"
    @click="statsVisible = !statsVisible"
  >
    Statistics
  </Button>
</template>
