import {
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'

import { EventStatusIncidentsOnFieldConfig } from '../../../configuration'

const eventStatusToIncidentIdMap: Map<SoccerEventStatusId, SoccerIncidentId[]> =
  new Map([
    [
      SoccerEventStatusId.NotStarted,
      [
        SoccerIncidentId.MatchAboutToStart,
        SoccerIncidentId.KickOff,
        SoccerIncidentId.FirstHalf,
      ],
    ],
    [
      SoccerEventStatusId.StartDelayed,
      [
        SoccerIncidentId.MatchAboutToStart,
        SoccerIncidentId.KickOff,
        SoccerIncidentId.FirstHalf,
      ],
    ],
    [SoccerEventStatusId.Halftime, [SoccerIncidentId.SecondHalf]],
    [
      SoccerEventStatusId.WaitingForExtratime,
      [SoccerIncidentId.ExtratimeFirstHalf],
    ],
    [
      SoccerEventStatusId.ExtratimeHalftime,
      [SoccerIncidentId.ExtratimeSecondHalf],
    ],
    [
      SoccerEventStatusId.WaitingForPenalty,
      [SoccerIncidentId.PenaltyShootoutStarted],
    ],
  ])

const kickOffIncidentId = SoccerIncidentId.KickOff
const secondHalfIncidentId = SoccerIncidentId.SecondHalf

export const eventStatusIncidentsOnFieldConfig: EventStatusIncidentsOnFieldConfig =
  {
    eventStatusToIncidentIdMap,
    kickOffIncidentId,
    secondHalfIncidentId,
  }
