import { getTimeBasedUUID } from '@collector/shared-utils'
import { SportsApiClient, type Events } from '@collector/sportsapi-client'

import {
  BaseIncident,
  QueueIncidentError,
  QueueIncidentStatus,
} from './BaseIncident'

export type AddIncidentPayload = Required<
  Pick<Events.IncidentsQueue.IncidentsQueuePostPayload, 'minute' | 'second'>
> &
  Events.IncidentsQueue.IncidentsQueuePostPayload

export class AddIncident extends BaseIncident {
  readonly payload: AddIncidentPayload

  readonly uuid: string

  public readonly type = 'Add'

  constructor(
    eventId: number,
    payload: AddIncidentPayload,
    uuid = getTimeBasedUUID(),
    status?: QueueIncidentStatus,
    time?: number,
    error?: QueueIncidentError,
  ) {
    super(eventId, status, time, error)

    this.payload = payload
    this.uuid = uuid
  }

  public getPayload(): AddIncidentPayload {
    return this.payload
  }

  public getUuid(): string {
    return this.uuid
  }

  public async process(sportsApiClient: SportsApiClient): Promise<string> {
    await sportsApiClient.Events.IncidentsQueue.post(this.eventId, {
      incident: this.payload,
      uuid: this.uuid,
    })

    return this.uuid
  }
}
