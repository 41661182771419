<script setup lang="ts">
import { CommonResultId } from '@collector/sportsapi-client-legacy'
import Dropdown from '@desktop/components/Dropdown.vue'
import { useParticipants } from '@desktop/views/Relation/composables'

import Results from './Results.vue'

// composables
const { getHomeParticipantResult, getAwayParticipantResult } = useParticipants()

// computed
const homeParticipantResult = getHomeParticipantResult(CommonResultId.Result)
const awayParticipantResult = getAwayParticipantResult(CommonResultId.Result)
</script>

<template>
  <Dropdown
    ref="dropdown"
    variant="wide"
  >
    <template #trigger>
      <div
        class="text-nowrap text-4xl font-bold"
        :class="{
          'cursor-pointer hover:underline': true,
        }"
      >
        {{ homeParticipantResult }} : {{ awayParticipantResult }}
      </div>
    </template>
    <template #content>
      <Results />
    </template>
  </Dropdown>
</template>
