import { watch } from 'vue'

import { ConfirmQueueIncident } from '@collector/desktop-feature-incidents-queue-core'
import { RabbitErrorMessage } from '@collector/queue-gateway-shared-types'

import { IncidentsUpdate } from './types'

type UseConfirmIncident = {
  onQueueRabbitConfirmIncident: ConfirmQueueIncident
  onQueueRabbitErrorMessage(message: RabbitErrorMessage): void
}

export function useConfirmIncident(
  incidentsUpdate: IncidentsUpdate,
): UseConfirmIncident {
  const uuidToPromiseMap = new Map<
    string,
    { resolve: () => void; reject: (error: Error) => void }
  >()
  const confirmedUuids = new Set<string>()
  const errorUuids = new Map<string, Error>()

  // confirming incident scenarios:
  // 1. first, returned function is executed with uuid (returned from request to /incidents-queue), THEN we receive incidentUpdate with the same uuid
  // 2. we receive incidentUpdate with uuid, THEN returned function is executed with uuid - it's possible on slow internet
  //   (API response was delayed, but incident was already processed by the API queue)
  watch(incidentsUpdate, (newVal) => {
    for (const { uuid } of newVal) {
      if (uuidToPromiseMap.has(uuid)) {
        const { resolve } = uuidToPromiseMap.get(uuid)!
        resolve()

        uuidToPromiseMap.delete(uuid)
      } else {
        confirmedUuids.add(uuid)
      }
    }
  })

  function onQueueRabbitConfirmIncident(
    uuid: string,
    resolve: () => void,
    reject: (error: Error) => void,
  ): void {
    if (confirmedUuids.has(uuid)) {
      resolve()
      confirmedUuids.delete(uuid)
    } else if (errorUuids.has(uuid)) {
      reject(errorUuids.get(uuid)!)
      errorUuids.delete(uuid)
    } else {
      uuidToPromiseMap.set(uuid, {
        resolve,
        reject,
      })
    }
  }

  function onQueueRabbitErrorMessage(message: RabbitErrorMessage): void {
    const { uuid } = message
    const error = new Error(message.data.message)

    if (uuidToPromiseMap.has(uuid)) {
      const { reject } = uuidToPromiseMap.get(uuid)!

      reject(error)
      uuidToPromiseMap.delete(uuid)
    } else {
      errorUuids.set(uuid, error)
    }
  }

  return {
    onQueueRabbitConfirmIncident,
    onQueueRabbitErrorMessage,
  }
}
