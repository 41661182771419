<script lang="ts" setup>
import { Ref, ref, watch } from 'vue'

import { MultiSelect, MultiSelectObjectOption } from '@collector/shared-ui'
import { isLettersAndSpaces } from '@collector/shared-utils'
import { Participants } from '@collector/sportsapi-client'
import { BaseParticipant } from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { useEvent, useSport } from '@desktop/views/Relation/composables'

import { injectSuccessBadge } from './provide-inject/successBadge'

type OptionValue = { id: number; name: string }

// inject
const { showSuccessBadge } = injectSuccessBadge()

// composables
const { sport } = useSport()
const { event } = useEvent()

const defaultOptions = fetchSelectOptions()

// state
const selectedRefereeOptions = ref<MultiSelectObjectOption[]>()
const selectedReferee = ref<OptionValue>()
const searchTerm = ref('')
const options: Ref<
  Promise<MultiSelectObjectOption[]> | MultiSelectObjectOption[]
> = ref(defaultOptions)

// methods
async function fetchReferees(
  query?: Partial<Participants.ParticipantsGetQuery>,
): Promise<BaseParticipant[]> {
  const referees = await sportsApiClient.Participants.get({
    sport_id: sport.id,
    subtype: 'referee',
    ...query,
  })

  return referees.api.data.participants || []
}

async function fetchSelectOptions(
  query?: Partial<Participants.ParticipantsGetQuery>,
): Promise<MultiSelectObjectOption[]> {
  const referees = await fetchReferees(query)

  return referees.map(getOption)
}

function getOption(participant: BaseParticipant): MultiSelectObjectOption {
  return {
    name: participant.short_name,
    value: getOptionValue(participant),
    metadata: participant,
  }
}

function getOptionValue(participant: BaseParticipant): {
  id: number
  name: string
} {
  return {
    id: participant.id,
    name: participant.short_name,
  }
}

async function searchForReferee(searchTermValue: string): Promise<void> {
  if (isLettersAndSpaces(searchTermValue) && searchTermValue.length > 2) {
    options.value = fetchSelectOptions({
      short_name: searchTermValue,
    })
  } else if (selectedRefereeOptions.value) {
    options.value = selectedRefereeOptions.value
  } else if (selectedReferee.value === undefined) {
    options.value = defaultOptions
  }
}

async function updateEventReferee(newReferee: OptionValue): Promise<void> {
  selectedReferee.value = newReferee

  if (newReferee === undefined) {
    options.value = defaultOptions
  }

  const refereeId = newReferee?.id ?? null

  await sportsApiClient.Events.patch(event.value.id, {
    event: { referee_id: refereeId },
  })

  selectedRefereeOptions.value = await options.value
  showSuccessBadge()
}

function getSelectOptionDescription(participant: BaseParticipant): string {
  return `${participant.area_name} ${participant.details.birthdate ? `(${participant.details.birthdate})` : ''}`
}

// watchers
watch(
  event,
  async (update) => {
    if (selectedReferee.value?.id === update.referee_id) {
      return
    }

    if (update.referee_id === null) {
      selectedReferee.value = undefined
      return
    }

    const response = await sportsApiClient.Participants.getById(
      update.referee_id,
    )

    if (response.api.data.participant) {
      selectedReferee.value = getOptionValue(response.api.data.participant)

      const refereeOptions = [getOption(response.api.data.participant)]
      options.value = refereeOptions

      if (!selectedRefereeOptions.value) {
        selectedRefereeOptions.value = refereeOptions
      }
    }
  },
  { deep: true, immediate: true },
)

watch(searchTerm, () => {
  searchForReferee(searchTerm.value.trim())
})
</script>

<template>
  <MultiSelect
    v-model:searchTerm="searchTerm"
    class="w-48"
    :modelValue="selectedReferee"
    withFilter
    :options
    @update:modelValue="updateEventReferee($event as OptionValue)"
  >
    Referee
    <template
      v-if="selectedReferee"
      #displayedValue
    >
      {{ selectedReferee.name }}
    </template>
    <template #option="{ option }">
      <div class="leading-3">
        <div>
          {{ (option.metadata as BaseParticipant).short_name }}
        </div>
        <div class="text-2xs">
          {{ getSelectOptionDescription(option.metadata as BaseParticipant) }}
        </div>
      </div>
    </template>
  </MultiSelect>
</template>
