import { Attachment, Extras } from '@sentry/types'
import * as Sentry from '@sentry/vue'

export function captureFeedbackWithAttachments(
  message: string,
  extras: Extras,
  attachments: Attachment[],
  feedback?: string,
): void {
  const eventId = Sentry.captureMessage(message, (scope) => {
    attachments.forEach((attachment) => {
      scope.addAttachment(attachment)
    })

    scope.setExtras(extras)
    return scope
  })

  if (feedback) {
    Sentry.captureUserFeedback({
      event_id: eventId,
      comments: feedback,
      email: '',
      name: '',
    })
  }
}
