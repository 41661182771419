<script setup lang="ts">
import { Button } from '@collector/shared-ui'
import { TeamSide } from '@collector/shared-ui-domain'
import { IncidentId } from '@collector/sportsapi-client-legacy'

import { useSportMaps } from '../composables'
import SportIncidentIcon from './SportIncidentIcon.vue'

type ButtonProps = InstanceType<typeof Button>['$props']

interface Props {
  incidentId: IncidentId
  teamSide?: TeamSide | null
  size: 'md' /** 40px */ | 'lg' /** 48px */
  disabled?: boolean
  width?: ButtonProps['width']
  selected?: boolean
  withActive?: boolean
}

const props = defineProps<Props>()

// emits
type Emits = {
  click: [incidentId: IncidentId]
}

defineEmits<Emits>()

// composables
const { getIncidentName } = useSportMaps()

// data
const incidentName = getIncidentName(props.incidentId)
</script>

<template>
  <Button
    class="disabled:text-neutral-dark-2/50 font-bold"
    :class="{
      'text-neutral-light-9': selected,
      'text-neutral-dark-2': !selected,

      // home
      'border-team-home-5 hover:bg-team-home-5': teamSide === 'home',
      'active:bg-team-home-5/15': teamSide === 'home' && withActive,
      'bg-team-home-5/15': teamSide === 'home' && !selected,
      'bg-team-home-5': teamSide === 'home' && selected,
      'disabled:bg-team-home-7/10 disabled:border-team-home-9':
        teamSide === 'home' && disabled,
      //
      // away
      'border-team-away-5 hover:bg-team-away-5': teamSide === 'away',
      'active:bg-team-away-5/15': teamSide === 'away' && withActive,
      'bg-team-away-5/15': teamSide === 'away' && !selected,
      'bg-team-away-5': teamSide === 'away' && selected,
      'disabled:bg-team-away-7/10 disabled:border-team-away-9':
        teamSide === 'away' && disabled,
    }"
    :disabled
    :size
    :width
    @click="$emit('click', incidentId)"
  >
    <SportIncidentIcon
      :class="{
        'opacity-50': disabled,
      }"
      :incidentId
      :size="size === 'md' ? '3xs' : undefined"
    />
    <slot v-bind="{ incidentName }">
      {{ incidentName }}
    </slot>
  </Button>
</template>
