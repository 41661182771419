import Sentry from '@collector/shared-plugin-sentry-vue'
import { isWretchError } from '@collector/shared-utils'

import { useErrors } from '../globalState/errors'

const handleError = (error: unknown): void => {
  if (!isWretchError(error)) {
    try {
      // send to Sentry non-HTTP errors (HTTP errors are automatically sent by Sentry internal plugin)
      Sentry.captureException(error)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Sentry.captureException failed', err)
    }
  }

  // eslint-disable-next-line no-console
  console.error(error instanceof Error ? error.message : String(error))
  // eslint-disable-next-line no-console
  console.error(error)

  const { enqueueError } = useErrors()

  enqueueError(error)
}

type HandleError = (error: unknown) => void

export function getHandleError(): HandleError {
  let lastError: unknown

  return function (error: unknown) {
    if (lastError !== error) {
      lastError = error
      handleError(error)
    }
  }
}
