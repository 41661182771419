<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'

import { STATSCOREWidgetsError } from '../types'

const unmountWidget = ref()

type Props = {
  eventId: number
  configurationId: string
}

const props = defineProps<Props>()

onMounted(() => {
  if (!window.STATSCOREWidgets) {
    throw new Error('STATSCOREWidgets is not defined')
  }

  window.STATSCOREWidgets.onLoad((err: STATSCOREWidgetsError) => {
    if (err) {
      throw err
    }

    const element = document.getElementById(props.configurationId)

    const inputData = {
      eventId: props.eventId,
      language: 'en',
    }

    const options = {}

    if (!element) {
      return
    }

    const widget = new window.STATSCOREWidgets.WidgetGroup(
      element,
      props.configurationId,
      inputData,
      options,
    )

    unmountWidget.value = () => widget.destroy()
  })
})

onBeforeUnmount(async () => {
  if (unmountWidget.value) {
    await unmountWidget.value()
  }
})
</script>

<template>
  <div :id="configurationId" />
</template>
