import { computed, ComputedRef, ref, watch } from 'vue'

import {
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import { Incident, Participant } from '@collector/sportsapi-types'
import { useIncidents } from '@desktop/views/Relation/composables'
import { isIncidentActive } from '@desktop/views/Relation/utils'

function isPenaltyIncident(
  participant: Participant,
  incident: Incident,
): boolean {
  if (incident.status_id !== SoccerEventStatusId.PenaltyShootout) return false
  if (incident.participant_team_id !== participant.id) return false
  if (!isIncidentActive(incident)) return false
  return true
}

function getPenaltyFlag(incident: Incident): boolean | null {
  if (incident.incident_id === SoccerIncidentId.Goal) return true
  else if (incident.incident_id === SoccerIncidentId.MissedPenalty) return false
  return null
}

type PenaltyFlagMap = Map<Incident['id'], boolean>

function getPenaltyFlagMap(
  participant: Participant,
  incidents: Incident[],
): PenaltyFlagMap {
  const flagMap: PenaltyFlagMap = new Map()
  for (const incident of incidents) {
    if (isPenaltyIncident(participant, incident)) {
      const penaltyFlag = getPenaltyFlag(incident)
      if (penaltyFlag !== null) flagMap.set(incident.id, penaltyFlag)
    }
  }
  return flagMap
}

function usePenaltyFlags(
  participant: ComputedRef<Participant>,
): ComputedRef<boolean[]> {
  const { incidents, incidentsUpdate } = useIncidents()
  const flagMap = ref(getPenaltyFlagMap(participant.value, incidents.value))

  watch(incidentsUpdate, (newIncidents) => {
    for (const incidentUpdate of newIncidents) {
      const incident = incidentUpdate.data
      if (isPenaltyIncident(participant.value, incident)) {
        const penaltyFlag = getPenaltyFlag(incident)
        if (penaltyFlag !== null) flagMap.value.set(incident.id, penaltyFlag)
      } else flagMap.value.delete(incident.id)
    }
  })

  return computed(() => [...flagMap.value.values()])
}

function usePenaltyParticipantChips(
  flags: ComputedRef<boolean[]>,
  oppositeFlags: ComputedRef<boolean[]>,
): ComputedRef<boolean[]> {
  return computed(() => {
    const seriesCount = Math.max(flags.value.length, oppositeFlags.value.length)
    const displayCount = Math.ceil(seriesCount / 5) * 5

    const missingCount = getMissingCount(
      oppositeFlags,
      flags,
      seriesCount,
      displayCount,
    )
    const indeterminateArray = Array(missingCount).fill(null)
    const seriesChips = flags.value.concat(indeterminateArray)
    const startIndex = Math.max(0, seriesChips.length - 15)
    const visibleChips = seriesChips.slice(startIndex, seriesChips.length)

    return visibleChips
  })
}

function getMissingCount(
  oppositeFlags: ComputedRef<boolean[]>,
  flags: ComputedRef<boolean[]>,
  seriesCount: number,
  displayCount: number,
): number {
  let missingNextRow = 0
  const oppResult = oppositeFlags.value.reduce((a, b) => a + (b ? 1 : 0), 0)
  const result = flags.value.reduce((a, b) => a + (b ? 1 : 0), 0)
  if (oppResult === result && seriesCount % 5 === 0) missingNextRow = 5

  return displayCount - flags.value.length + missingNextRow
}

export function usePenaltyChips(
  homeParticipant: ComputedRef<Participant>,
  awayParticipant: ComputedRef<Participant>,
): { homeChips: ComputedRef<boolean[]>; awayChips: ComputedRef<boolean[]> } {
  const homeFlags = usePenaltyFlags(homeParticipant)
  const awayFlags = usePenaltyFlags(awayParticipant)

  return {
    homeChips: usePenaltyParticipantChips(homeFlags, awayFlags),
    awayChips: usePenaltyParticipantChips(awayFlags, homeFlags),
  }
}
