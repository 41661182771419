import {
  EventStatusId,
  SoccerEventStatusId,
} from '@collector/sportsapi-client-legacy'

export const pitchOverlaySupportedStatuses: EventStatusId[] = [
  SoccerEventStatusId.FinishedRegularTime,
  SoccerEventStatusId.FinishedAfterExtratime,
  SoccerEventStatusId.FinishedAfterPenalties,
  SoccerEventStatusId.FinishedAwardedWin,
  SoccerEventStatusId.Abandoned,
  SoccerEventStatusId.Postponed,
  SoccerEventStatusId.Cancelled,
]
