import { Ref } from 'vue'

import { QueueRabbitClient } from '@collector/queue-gateway-shared-client'
import { RabbitIncidentMessage } from '@collector/queue-gateway-shared-types'
import { deleteAllProperties } from '@collector/shared-utils'
import { Incident } from '@collector/sportsapi-client'

import { IncidentIdIncidentIndexMap } from './types'

function getMaxIncidentUt(incidents: Incident[]): number {
  return incidents.reduce((acc, incident) => Math.max(acc, incident.ut), 0)
}

function updateIncidents(
  incidentsUpdate: RabbitIncidentMessage[],
  incidents: Incident[],
  incidentIdIncidentIndexMap: IncidentIdIncidentIndexMap,
): void {
  for (const incidentUpdate of incidentsUpdate) {
    if (incidentUpdate.data.action === 'insert') {
      incidents.push(incidentUpdate.data)
      incidentIdIncidentIndexMap.set(
        incidentUpdate.data.id,
        incidents.length - 1,
      )
    }

    if (
      incidentUpdate.data.action === 'update' ||
      incidentUpdate.data.action === 'delete'
    ) {
      const incidentIndex = incidentIdIncidentIndexMap.get(
        incidentUpdate.data.id,
      )
      if (incidentIndex !== undefined) {
        const incident = incidents[incidentIndex]

        // clear all properties of incident and apply changes to same object
        deleteAllProperties(incident)
        Object.assign(incident, incidentUpdate.data)
      }
    }
  }
}

function createIncidentIdIncidentIndexMap(
  incidents: Incident[],
): IncidentIdIncidentIndexMap {
  const incidentIdIncidentIndexMap = new Map<number, number>()

  incidents.forEach((incident, index) => {
    incidentIdIncidentIndexMap.set(incident.id, index)
  })

  return incidentIdIncidentIndexMap
}

export function initIncidentIdIncidentIndexMap(incidents: Incident[]): {
  incidentIdIncidentIndexMap: IncidentIdIncidentIndexMap
} {
  const incidentIdIncidentIndexMap = createIncidentIdIncidentIndexMap(incidents)

  return { incidentIdIncidentIndexMap }
}

export function updateIncidentsOnConnect(
  queueRabbitClient: QueueRabbitClient,
  incidents: Incident[],
  incidentsUpdate: Ref<RabbitIncidentMessage[]>,
  incidentIdIncidentIndexMap: IncidentIdIncidentIndexMap,
): void {
  /**
   * - last item in incidentsUpdate is always item with max ut, but incidentsUpdate is empty before first update
   * - last item in incidents array is not always item with max ut (incidentsUpdate affects incidents across whole array)
   */
  const maxIncidentUt = incidentsUpdate.value.length
    ? incidentsUpdate.value[incidentsUpdate.value.length - 1].ut
    : getMaxIncidentUt(incidents)

  queueRabbitClient.subIncident<RabbitIncidentMessage>({
    onMessageBatch: (messages) => {
      updateIncidents(messages, incidents, incidentIdIncidentIndexMap)

      incidentsUpdate.value = messages
    },
    sync: {
      method: 'timestamp',
      timestamp: maxIncidentUt * 1000,
    },
  })
}
