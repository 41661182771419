import { Ref } from 'vue'

import { EventScout } from '@collector/sportsapi-client-legacy'

import { injectEventScouts } from '../provide-inject'

type UseEventScouts = {
  eventScouts: Ref<EventScout[]>
}

export function useEventScouts(): UseEventScouts {
  const eventScouts = injectEventScouts()

  return { eventScouts }
}
