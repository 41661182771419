export function replaceAtEnd(
  originalString: string,
  substringToReplace: string,
  replacement: string,
): string {
  if (
    substringToReplace !== '' &&
    originalString.endsWith(substringToReplace)
  ) {
    return originalString.slice(0, -substringToReplace.length) + replacement
  } else {
    return originalString
  }
}

export function isLettersAndSpaces(str: string): boolean {
  return /^[a-zA-Z\s]+$/.test(str)
}
