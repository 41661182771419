<script setup lang="ts">
import ResultWithDropdown from '@desktop/views/Relation/components/RelationView/Main/Summary/ResultWithDropdown/ResultWithDropdown.vue'
import { useEvent } from '@desktop/views/Relation/composables'

const { event } = useEvent()
</script>

<template>
  <div
    class="w-full max-w-36 text-center"
    data-test-id="MainScore"
  >
    <ResultWithDropdown />
    <div class="text-neutral-dark-0 mb-1 text-xs">
      {{ event.status_name }}
    </div>
  </div>
</template>
