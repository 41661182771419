<script setup lang="ts">
import { computed } from 'vue'

import Group from '../../../components/Group/Group.vue'
import Button from '../../Button/Button.vue'

// props
interface Props {
  pagesCount: number
}

const props = defineProps<Props>()

// data
const maxDisplayedPages = 7
const middlePage = Math.ceil(maxDisplayedPages / 2)
const middlePageMargin = Math.floor((maxDisplayedPages - middlePage) / 2)

// state
const currentPage = defineModel<number>({ required: true })

// computed
const paginationVariantLeft = computed(() => currentPage.value <= middlePage)
const paginationVariantRight = computed(
  () => currentPage.value > props.pagesCount - middlePage,
)

// methods
function isInMiddlePages(page: number): boolean {
  return (
    page >= currentPage.value - middlePageMargin &&
    page <= currentPage.value + middlePageMargin
  )
}

function isPageComponent(page: number): boolean {
  // display full pagination
  if (props.pagesCount <= maxDisplayedPages) return true

  if (paginationVariantLeft.value) {
    return page <= maxDisplayedPages - 2 || page === props.pagesCount
  }

  if (paginationVariantRight.value) {
    return page === 1 || page > props.pagesCount - maxDisplayedPages + 2
  }

  // pagination variant: middle
  return page === 1 || isInMiddlePages(page) || page === props.pagesCount
}

function isBetweenPagesComponent(page: number): boolean {
  const leftSide = 2
  const rightSide = props.pagesCount - 1

  if (paginationVariantLeft.value) {
    return page === rightSide
  }

  if (paginationVariantRight.value) {
    return page === leftSide
  }

  // pagination variant: middle
  return page === leftSide || page === rightSide
}

// Example of pagination view with 13 pages and 9 maxDisplayedPages
// When moving between pages 5-9, the pagination should keep current page in the middle
//
// pagination               currentPage
//
// 1 2 3 4 5 6 7  ... 13      1
// 1 2 3 4 5 6 7  ... 13      2
// 1 2 3 4 5 6 7  ... 13      3
// 1 2 3 4 5 6 7  ... 13      4
// 1 2 3 4 5 6 7  ... 13      5
//
// 1.. 4 5 6 7 8 ...  13      6
// 1.. 5 6 7 8 9 ...  13      7
// 1.. 6 7 8 9 10 ... 13      8
//
// 1.. 7 8 9 10 11 12 13      9
// 1.. 7 8 9 10 11 12 13      10
// 1.. 7 8 9 10 11 12 13      11
// 1.. 7 8 9 10 11 12 13      12
// 1.. 7 8 9 10 11 12 13      13
</script>

<template>
  <div class="flex gap-x-1">
    <Group
      :optionsCount="pagesCount"
      :selectedOption="currentPage"
    >
      <template #default="{ selected, option }">
        <Button
          v-if="isPageComponent(option)"
          class="hover:bg-neutral-9 hover:border-neutral-9"
          :class="{
            'border-neutral-light-5': !selected,
            'bg-neutral-light-2 border-neutral-light-2 font-bold': selected,
          }"
          square
          size="xs"
          @click="currentPage = option"
        >
          {{ option }}
        </Button>
        <Button
          v-else-if="isBetweenPagesComponent(option)"
          class="text-neutral-dark-0 border-none"
          size="xs"
          square
        >
          ...
        </Button>
      </template>
    </Group>
  </div>
</template>
