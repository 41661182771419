import { EventStatusId } from '@collector/sportsapi-client-legacy'

import { useSport, useSportMaps } from '../../composables'
import { injectEventStatuses } from '../provide-inject'

type UseEventStatuses = {
  eventStatuses: { name: string; value: EventStatusId }[]
}

export function useEventStatuses(): UseEventStatuses {
  const { sport } = useSport()
  const { eventStatusToSportStatusMap } = useSportMaps()
  const _eventStatuses = injectEventStatuses()

  const statuses = _eventStatuses
    ? _eventStatuses
    : sport.statuses.map((status) => status.id)

  const eventStatuses = statuses.map((eventStatusId) => ({
    name: eventStatusToSportStatusMap[eventStatusId as EventStatusId].name,
    value: eventStatusId,
  }))

  return { eventStatuses }
}
