<script setup lang="ts">
import { computed } from 'vue'

import { Separator } from '@collector/shared-ui'
import { EventStatsLevel, StatsLevel } from '@collector/sportsapi-client-legacy'
import { useEvent, useSeason } from '@desktop/views/Relation/composables'

// composables
const { event } = useEvent()
const { season } = useSeason()

// data
const seasonStatsLevel = getStatsLevel(season.stats_lvl)

// methods
function getStatsLevel(level: EventStatsLevel | StatsLevel): string {
  return level === 'vip' ? 'VIP' : level
}

// computed
const eventStatsLevel = computed(() =>
  getStatsLevel(event.value.event_stats_lvl),
)
</script>

<template>
  <div class="flex items-center text-xs">
    <span>
      Season:
      <span class="font-bold capitalize">{{ seasonStatsLevel }}</span>
    </span>

    <Separator vertical />

    <span>
      Event:
      <span class="font-bold capitalize">{{ eventStatsLevel }}</span>
    </span>

    <Separator vertical />
  </div>
</template>
