import { isIncidentEditable } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import {
  Confirmation,
  EventStatusId,
  IncidentId,
  SportIncident,
  YesNoEnum,
} from '@collector/sportsapi-client-legacy'
import {
  HistoryIncidentsConfig,
  IncidentSettings,
  isSubParticipantSetting,
  SubParticipantType,
} from '@desktop/views/Relation/Sports/configuration'
import { SubParticipantOptionValue } from '@desktop/views/Relation/utils'

export function isIncidentImportant(
  incident: Incident,
  incidentIdToSportIncidentMap: Record<IncidentId, SportIncident>,
): boolean {
  return (
    incidentIdToSportIncidentMap[incident.incident_id]?.important ===
    YesNoEnum.Yes
  )
}

export function isIncidentEmpty(
  incident: Incident,
  historyIncidentsConfig: HistoryIncidentsConfig,
): boolean {
  if (!isIncidentEditable(incident)) {
    return false
  }

  const incidentSettings = historyIncidentsConfig[incident.incident_id]

  if (incidentSettings) {
    const hasAllRequiredSubParticipants =
      hasRequiredSubParticipant(incident, incidentSettings, 'participant') &&
      hasRequiredSubParticipant(incident, incidentSettings, 'assistant')

    return !hasAllRequiredSubParticipants
  } else {
    return false
  }
}

function hasRequiredSubParticipant(
  incident: Incident,
  settings: IncidentSettings,
  key: SubParticipantType,
): boolean {
  const setting = settings[key]

  if (isSubParticipantSetting(setting)) {
    return setting.required
      ? incident[`${key}_id`] !== null || incident[`${key}_name`] !== null
      : true
  }

  return true
}

export function hasIncidentParticipantId(
  incident: Incident,
  participantId: Incident['participant_team_id'],
): boolean {
  return incident.participant_team_id === participantId
}

export function hasIncidentConfirmation(
  incident: Incident,
  confirmation: Confirmation,
): boolean {
  return incident.confirmation === confirmation
}

export function hasIncidentEventStatus(
  incident: Incident,
  eventStatuses: Set<EventStatusId>,
): boolean {
  return eventStatuses.has(incident.status_id)
}

export function hasIncidentId(
  incident: Incident,
  incidentIds: Set<IncidentId>,
): boolean {
  return incidentIds.has(incident.incident_id)
}

export function hasIncidentParticipantOrAssistant(
  incident: Incident,
  playerOptions: Set<SubParticipantOptionValue>,
): boolean {
  for (const player of playerOptions) {
    if (
      (Number(player.teamId) === incident.participant_team_id &&
        incident.participant_name === player.shortName) ||
      (incident.assistant_name !== null &&
        incident.assistant_name === player.shortName)
    ) {
      return true
    }
  }

  return false
}
