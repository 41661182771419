<script setup lang="ts">
import { computed, ref } from 'vue'

import { ButtonGroup } from '@collector/shared-ui'
import { StatsLevel } from '@collector/sportsapi-client-legacy'
import { useSeason } from '@desktop/views/Relation/composables'

import AddNewPlayer from './AddNewPlayer.vue'
import SearchForPlayer from './SearchForPlayer.vue'

// composables
const { isSeasonStatsLevel } = useSeason()

// data
/** assumes stats_lvl is higher that StatsLevel */
const isSearchForPlayerAvailable =
  !isSeasonStatsLevel(StatsLevel.Bronze) &&
  !isSeasonStatsLevel(StatsLevel.Silver)

const addNewPlayerText = 'Add new player'

const options = isSearchForPlayerAvailable
  ? ['Search for a player', addNewPlayerText]
  : [addNewPlayerText]

// state
const selectedOptionIndex = ref(0)

// computed
const isAddNewPlayerSelected = computed(
  () => options[selectedOptionIndex.value] === addNewPlayerText,
)
</script>

<template>
  <div class="space-y-1">
    <ButtonGroup
      v-model="selectedOptionIndex"
      :options
      variant="flat"
      withBorder
    />
    <div class="bg-neutral-light-10 flex h-16 gap-x-1 rounded p-2">
      <AddNewPlayer v-if="isAddNewPlayerSelected" />
      <SearchForPlayer v-else />
    </div>
  </div>
</template>
