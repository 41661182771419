import { Events } from '@collector/sportsapi-client'
import { IncidentId } from '@collector/sportsapi-client-legacy'

import { injectSwappableIncidents } from '../provide-inject'

type UseSwappableIncidents = {
  swappableIncidents: Events.SwappableIncidents.SwappableIncidentsConfigurationMap
  swappableIncidentsId: IncidentId[]
}

export function useSwappableIncidents(): UseSwappableIncidents {
  const swappableIncidents = injectSwappableIncidents()

  const swappableIncidentsId: IncidentId[] =
    Object.keys(swappableIncidents).map(Number)

  return {
    swappableIncidents,
    swappableIncidentsId,
  }
}
