<script setup lang="ts">
import { computed, ref } from 'vue'

import { Button } from '@collector/shared-ui'
import { YesNoEnum } from '@collector/sportsapi-client-legacy'
import { useEvent } from '@desktop/views/Relation/composables'

import { buttonClass } from '../consts'
import VerifyResultModal from './VerifyResultModal/VerifyResultModal.vue'

// state
const isModalVisible = ref(false)

// composables
const { event } = useEvent()

// computed
const resultVerified = computed(
  () => event.value.verified_result === YesNoEnum.Yes,
)
</script>

<template>
  <Button
    :class="{
      'bg-success-5 border-success-5 hover:bg-success-6 hover:border-success-6 text-neutral-light-10 font-bold':
        resultVerified,
      [buttonClass]: !resultVerified,
    }"
    icon="circle-check"
    size="sm"
    iconSize="sm"
    @click="isModalVisible = true"
  >
    Results
  </Button>
  <VerifyResultModal
    v-if="isModalVisible"
    @close="isModalVisible = false"
  />
</template>
