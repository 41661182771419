export enum ShotOnTargetAttribute {
  GoallineClearance = 731,
  LeftFoot = 404,
  RightFoot = 405,
  InsidePenaltyArea = 427,
  OutsidePenaltyArea = 428,
  Head = 429,
  UpperLeft = 430,
  UpperRight = 431,
  UpperMiddle = 432,
  BottomMiddle = 433,
  BottomLeft = 434,
  BottomRight = 435,
}
