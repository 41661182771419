import { Competition, Events, Incident } from '@collector/sportsapi-client'
import {
  EventScout,
  Participant,
  Sport,
  SportId,
  SubParticipant,
} from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'

export async function fetchCompetitionWithTimestamp(
  eventId: number,
): Promise<{ competition: Competition; competitionTimestamp: number }> {
  const event = await sportsApiClient.Events.getById(eventId)

  return {
    competition: event.api.data.competition,
    competitionTimestamp: event.api.timestamp,
  }
}

export async function fetchEventScouts(eventId: number): Promise<EventScout[]> {
  const scouts = await sportsApiClient.Events.Scouts.get(eventId)

  return scouts.api.data.scouts
}

export async function fetchIncidents(eventId: number): Promise<Incident[]> {
  const incidents = await sportsApiClient.Events.Incidents.get(eventId, {
    sort_type: 'id',
    sort_order: 'asc',
    limit: 99999,
  })

  return incidents.api.data.event_incidents
}

export async function fetchParticipants(
  eventId: number,
): Promise<Participant[]> {
  const incidents = await sportsApiClient.Events.Participants.get(eventId)

  return incidents.api.data.participants
}

export async function fetchSport(sportId: SportId): Promise<Sport> {
  const sport = await sportsApiClient.Sport.get(sportId)

  return sport.api.data.sport
}

export async function fetchSubParticipants(
  eventId: number,
): Promise<SubParticipant[]> {
  const subParticipants =
    await sportsApiClient.Events.SubParticipants.get(eventId)

  return subParticipants.api.data.sub_participants
}

export async function fetchSwappableIncidents(
  eventId: number,
): Promise<Events.SwappableIncidents.SwappableIncidentsConfigurationMap> {
  const swappableIncidents =
    await sportsApiClient.Events.SwappableIncidents.get(eventId)

  return swappableIncidents.api.data
}
