import type {
  ApiResponse,
  AuthInfoObject,
  Competition,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../httpClient'

export type AuthInfoType = AuthInfoObject

type Link = {
  id: number
  name: string | null
  url: string
  sport_id: number
  object_type: string
  object_id: number
  update_time: string
  type: 'official site' | 'livescore' | 'standings' | 'details'
  default: string
  ct: string
  ut: string
  dt: string | null
  object_name: string
  sport: {
    table_prefix: string
    name: string
  }
  country: {
    id: number
    country_name: string
  }
}

type LinksGetResponse = ApiResponse<{
  links: Link[]
}>

export async function get(
  httpClient: HttpClient,
  params?: { object_id: Competition['id'] },
): Promise<LinksGetResponse> {
  return httpClient
    .url('/links')
    .query({
      type: 'official site',
      object_type: 'competition',
      ...params,
    })
    .get()
    .json()
}
