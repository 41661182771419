import { EventIncidentsQueue } from '@collector/desktop-feature-incidents-queue-core'
import Sentry, {
  createExtraIssueTitle,
} from '@collector/shared-plugin-sentry-vue'

export function captureQueueIncidentErrors(
  eventIncidentsQueue: EventIncidentsQueue,
): void {
  eventIncidentsQueue.subscribeOnChange(({ currentError }) => {
    if (currentError) {
      Sentry.captureException(
        currentError,
        createExtraIssueTitle('Queue error: '),
      )
    }
  })
}
