<script setup lang="ts">
import { computed } from 'vue'

import { Incident } from '@collector/sportsapi-client'
import { Confirmation } from '@collector/sportsapi-client-legacy'

interface Props {
  incident: Incident
  isDeleted: boolean
}

const props = defineProps<Props>()

// computed
const confirmation = computed(() => {
  if (props.incident.confirmation === null) {
    return ''
  }

  return `(${props.incident.confirmation})`
})
</script>

<template>
  <div
    v-if="confirmation"
    class="text-sm font-bold"
    :class="{
      uppercase: incident.confirmation === Confirmation.ToBeDone,
      'text-neutral-3':
        incident.confirmation === Confirmation.ToBeDone && !isDeleted,
      'text-neutral-3/40':
        incident.confirmation === Confirmation.ToBeDone && isDeleted,
      capitalize: incident.confirmation !== Confirmation.ToBeDone,
      'text-success-2':
        incident.confirmation === Confirmation.Confirmed && !isDeleted,
      'text-success-2/40':
        incident.confirmation === Confirmation.Confirmed && isDeleted,
      'text-error-5':
        incident.confirmation === Confirmation.Cancelled && !isDeleted,
      'text-error-5/40':
        incident.confirmation === Confirmation.Cancelled && isDeleted,
    }"
  >
    {{ confirmation }}
  </div>
</template>
