import { Incident } from '@collector/sportsapi-client'
import { Participant } from '@collector/sportsapi-client-legacy'

import { TeamSide } from '../types'

export function getParticipantTeamSide(participant: Participant): TeamSide {
  return participant.counter === 1 ? 'home' : 'away'
}

export function getParticipantIdTeamSide(
  participantId: Participant['id'] | undefined | null,
  homeParticipant: Participant,
  awayParticipant: Participant,
): TeamSide | null {
  if (participantId === homeParticipant.id) {
    return 'home'
  }

  if (participantId === awayParticipant.id) {
    return 'away'
  }

  return null
}

export function getParticipantIdOppositeTeamSide(
  participantId: Participant['id'] | undefined | null,
  homeParticipant: Participant,
  awayParticipant: Participant,
): TeamSide | null {
  if (participantId === homeParticipant.id) {
    return 'away'
  }

  if (participantId === awayParticipant.id) {
    return 'home'
  }

  return null
}

export function resolveParticipant(
  participantTeamId: Incident['participant_team_id'] | undefined | null,
  homeParticipant: Participant,
  awayParticipant: Participant,
): Participant {
  if (participantTeamId === homeParticipant.id) {
    return homeParticipant
  }

  if (participantTeamId === awayParticipant.id) {
    return awayParticipant
  }

  throw Error('Participant not found')
}

export function resolveOppositeParticipant(
  participantTeamId: Incident['participant_team_id'] | undefined | null,
  homeParticipant: Participant,
  awayParticipant: Participant,
): Participant {
  if (participantTeamId === homeParticipant.id) {
    return awayParticipant
  }

  if (participantTeamId === awayParticipant.id) {
    return homeParticipant
  }

  throw Error('Participant not found')
}
