import { Breadcrumb, BreadcrumbHint } from '@sentry/vue'

type BeforeBreadcrumb = (
  breadcrumb: Breadcrumb,
  hint?: BreadcrumbHint,
) => Breadcrumb

/**
 * Attaches the request body to the breadcrumb when the request is not a GET request
 * and the request url matches the allowedUrls pattern
 *
 * @param breadcrumb - provided from the Sentry SDK
 * @param hint - provided from the Sentry SDK
 * @param allowedUrls - regex pattern to match the request url
 * @returns breadcrumb with body attached
 */
function attachBodyToBreadcrumb(
  breadcrumb: Breadcrumb,
  hint: BreadcrumbHint,
  allowedUrls: RegExp,
): Breadcrumb {
  if (breadcrumb.category === 'fetch' && breadcrumb.data?.method !== 'GET') {
    if (hint && hint.input.length > 1) {
      const url = breadcrumb.data?.url

      if (allowedUrls.exec(url) !== null) {
        const bodyString = hint.input[1].body || '{}'
        breadcrumb.data = {
          ...breadcrumb.data,
          body: JSON.parse(bodyString),
        }
      }
    }
  }

  return breadcrumb
}

export function getBeforeBreadcrumb(
  urlsWithTrackedPayload: RegExp,
): BeforeBreadcrumb {
  const beforeBreadcrumb: BeforeBreadcrumb = (breadcrumb, hint) => {
    if (!hint) {
      return breadcrumb
    }

    const modifiedBreadcrumb = attachBodyToBreadcrumb(
      breadcrumb,
      hint,
      urlsWithTrackedPayload,
    )

    return modifiedBreadcrumb
  }

  return beforeBreadcrumb
}
