<script setup lang="ts">
import { MultiSelect } from '@collector/shared-ui'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { useEvent } from '@desktop/views/Relation/composables'
import { useEventStatuses } from '@desktop/views/Relation/Sports/composables'

import { injectSuccessBadge } from './provide-inject/successBadge'

// inject
const { showSuccessBadge } = injectSuccessBadge()

// composables
const { event } = useEvent()
const { eventStatuses } = useEventStatuses()

async function updateValue(value: number): Promise<void> {
  await sportsApiClient.Events.patch(event.value.id, {
    event: { event_status_id: value },
  })

  showSuccessBadge()
}
</script>

<template>
  <MultiSelect
    class="w-44"
    :options="eventStatuses"
    :modelValue="event.status_id"
    :withClear="false"
    @update:modelValue="updateValue($event as number)"
  >
    Status
  </MultiSelect>
</template>
