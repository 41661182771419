import { Role } from '../responses/auth/AuthInfoResponse'

export const enum RoleId {
  Scout = 1,
  Administrator = 2,
  ClientAdministrator = 3,
  ScoutsSupervisor = 4,
  JuniorScout = 5,
  EditorsSupervisor = 6,
  Editor = 7,
  LivescoreProClientAdminstrator = 8,
  ScoreFrameClientAdminstrator = 9,
  SportsAPIClientAdminstrator = 10,
  SportWidgetsClientAdminstrator = 11,
  ScoutsFeedClientAdminstrator = 12,
  OddsAdministrator = 13,
  HR = 14,
  RecruitmentAccountAdministrator = 15,
  ScoreframeLeagueClubClientAdmin = 16,
  MappingSupplier = 17,
  PrematchProClientAdministrator = 18,
  ScoutsQualityController = 22,
  ScoutsQualityAssistant = 23,
}

const controllerRoles = [
  RoleId.ScoutsQualityAssistant,
  RoleId.ScoutsQualityController,
]

const adminRoles = [RoleId.Administrator]

export function includesControllerRole(roles: Role[]): boolean {
  return roles.some(({ id }) => controllerRoles.includes(id))
}

export function includesAdminRoles(roles: Role[]): boolean {
  return roles.some(({ id }) => adminRoles.includes(id))
}

export function isScout(roles: Role[]): boolean {
  return roles.some(({ id }) => id === RoleId.Scout)
}
