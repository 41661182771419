<script setup lang="ts">
import HandyInfoBar from '@desktop/views/Relation/components/RelationView/Main/HandyInfoBar/HandyInfoBar.vue'
import {
  useParticipants,
  useTeamSides,
} from '@desktop/views/Relation/composables'
import ChangePossesionButton from '@desktop/views/Relation/Sports/components/ChangePossesionButton.vue'
import PitchTeamIncidents from '@desktop/views/Relation/Sports/components/Soccer/components/RelationView/Main/PitchWithIncidents/PitchTeamIncidents.vue'

import Pitch from './Pitch/Pitch.vue'

const { homeParticipant, awayParticipant } = useParticipants()

const { getWithCurrentTeamSides } = useTeamSides()

const [currentFirstParticipant, currentSecondParticipant] =
  getWithCurrentTeamSides(homeParticipant, awayParticipant)
</script>

<template>
  <div class="flex gap-x-4">
    <PitchTeamIncidents
      class="w-full min-w-[8rem] flex-shrink-[3]"
      :participant="currentFirstParticipant"
    />

    <div class="@md:min-w-[30rem] flex w-full max-w-[30rem] flex-col gap-y-2">
      <HandyInfoBar />
      <Pitch />
      <ChangePossesionButton />
    </div>

    <PitchTeamIncidents
      class="w-full min-w-[8rem] flex-shrink-[3]"
      :participant="currentSecondParticipant"
      dir="rtl"
    />
  </div>
</template>
