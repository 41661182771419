import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

export function formatTime(unixTime: number): string {
  return format(unixTime * 1000, 'HH:mm:ss')
}

export function formatDatetime(unixTime: number): string {
  return format(unixTime * 1000, 'yyyy.MM.dd HH:mm:ss')
}

export function formatDateInTimezone(
  date: string | Date,
  dateFormat: string,
): string {
  return formatInTimeZone(
    date,
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    dateFormat,
  )
}
