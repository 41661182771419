import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'

import { BetIncidents } from '../../../configuration'

export const betIncidents: BetIncidents = [
  SoccerIncidentId.BookingMarketsEnabled,
  SoccerIncidentId.BookingMarketsDisabled,
  SoccerIncidentId.CornerMarketsEnabled,
  SoccerIncidentId.CornerMarketsDisabled,
]
