<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { CheckboxGroup } from '@collector/shared-ui'
import { StatusType } from '@collector/sportsapi-client-legacy'
import { useEvent } from '@desktop/views/Relation/composables'

interface Props {
  options: string[]
  title: string
  disabled?: boolean
}

defineProps<Props>()

// composables
const { event } = useEvent()

// model
const model = defineModel<[number, number]>({ default: [null, null] })

// state
const selectedOptionIndex = ref<number | undefined>(getSelectedOptionIndex())

// methods
function getSelectedOptionIndex(): number | undefined {
  if (model.value[0] === 0 && model.value[1] === 0) {
    return 1
  }

  return model.value[0] === 1 ? 0 : model.value[1] === 1 ? 2 : undefined
}

// computed
const eventFinished = computed(
  () => event.value.status_type === StatusType.Finished,
)

// watchers
watch(selectedOptionIndex, (index) => {
  model.value = index === 0 ? [1, 0] : index === 2 ? [0, 1] : [0, 0]
})
</script>

<template>
  <div class="flex items-center">
    <div class="ml-0.5 mr-3 flex w-[22%] items-center">
      <span class="mr-1 w-6">
        <slot />
      </span>
      <span class="text-sm font-bold">
        {{ title }}
      </span>
    </div>
    <CheckboxGroup
      v-model="selectedOptionIndex"
      class="flex-1"
      :disabled="!eventFinished"
      :options
    />
  </div>
</template>
