import { createNullableProvideInject } from '@collector/shared-ui'

import { EventStatusIncidentsOnFieldConfig } from '../configuration'

export const [
  provideEventStatusIncidentsOnFieldConfig,
  injectEventStatusIncidentsOnFieldConfig,
] = createNullableProvideInject<EventStatusIncidentsOnFieldConfig>(
  'eventStatusIncidentsOnFieldConfig',
)
