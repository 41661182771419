<script setup lang="ts">
import { ref, watch } from 'vue'

import { getErrorMessage } from '@collector/sportsapi-client'
import { ErrorWithCause, useErrors } from '@desktop/globalState/errors'

const { errors, peekError, dequeueError } = useErrors()
const lastErrorType = ref<string | undefined>(undefined)

function showLastError(): void {
  const errorUnknown = peekError()

  if (errorUnknown === undefined) {
    return
  }
  const error = errorUnknown as ErrorWithCause

  const shouldShowError =
    lastErrorType.value === undefined ||
    lastErrorType.value !== error.cause?.type

  if (shouldShowError) {
    // TODO: replace with "red cloud with error" component
    alert(getErrorMessage(error))
  }

  lastErrorType.value = error.cause?.type
  dequeueError()
}

showLastError()

watch(
  errors,
  () => {
    showLastError()
  },
  { deep: true },
)
</script>

<template>
  <div></div>
</template>
