const loaderElement = document.getElementById('loader')

export function stopLoader(): void {
  loaderElement?.classList.add('hidden')
  loaderElement?.classList.remove('grid')
  loaderElement?.getAnimations().forEach((a) => a.cancel())
}

export function startLoader(): void {
  loaderElement?.classList.remove('hidden')
  loaderElement?.classList.add('grid')
}

/**
 * Run loader while promise is pending, and stop it when promise is resolved or rejected
 *
 * @returns Promise that resolves to the value of the input promise
 */
export async function runLoader<T>(promise: Promise<T>): Promise<T> {
  startLoader()

  try {
    return await promise
  } finally {
    stopLoader()
  }
}
