import * as R from 'ramda'

import { hasEqualShape } from '@collector/shared-utils'
import { Incident } from '@collector/sportsapi-client'
import { IncidentId } from '@collector/sportsapi-client-legacy'

export type IncidentIdsGroup = {
  name: string
  incidentIds: [IncidentId, IncidentId]
}

export function isIncidentIdsGroup(
  value: IncidentId | IncidentIdsGroup,
): value is IncidentIdsGroup {
  return R.is(Object, value)
}

export type IncidentsGroup = {
  name: string
  incidents: [Incident] | [Incident, Incident]
}

export function isIncidentsGroup(
  value: Incident | IncidentsGroup,
): value is IncidentsGroup {
  return hasEqualShape(
    {
      name: String,
      incidents: Array,
    },
    value as IncidentsGroup,
  )
}
