<script setup lang="ts">
import { Icon } from '@collector/shared-ui'
import { Env } from '@desktop/envs'
import { useEvent, useSport } from '@desktop/views/Relation/composables'

// composables
const { sportResourceName } = useSport()
const { event } = useEvent()

// data
const configId = Env.VITE_LIVEMATCH_PRO_CONFIG_ID
const url = `${Env.VITE_LIVEMATCH_PRO_DEMO_URL}/en/${sportResourceName}?configId=${configId}&eventId=${event.value.id}`
</script>

<template>
  <a
    class="border-neutral-light-2 bg-neutral-light-2 inline-flex max-h-6 min-h-6 min-w-6 max-w-6 items-center justify-center rounded border"
    :href="url"
    target="_blank"
  >
    <Icon
      icon="desktop"
      size="xs"
    />
  </a>
</template>
