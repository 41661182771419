<script setup lang="ts">
import { useParticipants } from '@desktop/views/Relation/composables'

import ParticipantWithStats from './ParticipantWithStats.vue'
import Result from './Result.vue'

// composables
const { homeParticipant, awayParticipant } = useParticipants()
</script>

<template>
  <div class="bg-neutral-light-10 flex rounded-lg px-4 py-2">
    <ParticipantWithStats
      class="text-right"
      :participant="homeParticipant"
    />
    <Result />
    <ParticipantWithStats :participant="awayParticipant" />
  </div>
</template>
