import * as R from 'ramda'

import {
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'

import { RelatedEventStatusConfig } from '../../../configuration'

const sharedOtherStatuses: SoccerIncidentId[] = [
  SoccerIncidentId.NotStarted,
  SoccerIncidentId.FinishedAwardedWin,
  SoccerIncidentId.Interrupted,
  SoccerIncidentId.Postponed,
  SoccerIncidentId.Abandoned,
  SoccerIncidentId.Cancelled,
  SoccerIncidentId.ToFinish,
]

export const relatedEventStatusConfig: RelatedEventStatusConfig<
  SoccerEventStatusId,
  SoccerIncidentId
> = {
  [SoccerEventStatusId.NotStarted]: {
    possibleNextStatuses: [
      SoccerIncidentId.FirstHalf,
      SoccerIncidentId.StartDelayed,
    ],
    otherStatuses: R.filter(
      (incidentId) => incidentId !== SoccerIncidentId.NotStarted,
      sharedOtherStatuses,
    ),
  },
  [SoccerEventStatusId.FirstHalf]: {
    possibleNextStatuses: [SoccerIncidentId.Halftime],
    otherStatuses: sharedOtherStatuses,
  },
  [SoccerEventStatusId.Halftime]: {
    possibleNextStatuses: [SoccerIncidentId.SecondHalf],
    otherStatuses: sharedOtherStatuses,
  },
  [SoccerEventStatusId.SecondHalf]: {
    possibleNextStatuses: [
      {
        incidentId: SoccerIncidentId.FinishedRegularTime,
        description: 'Click if you are sure that match is finished',
      },
      {
        incidentId: SoccerIncidentId.WaitingForExtratime,
        description: 'Click if there will be a an extratime next',
      },
      {
        incidentId: SoccerIncidentId.WaitingForPenalty,
        description: 'Click if there will be a penalty shootout next',
      },
    ],
    otherStatuses: sharedOtherStatuses,
  },
  [SoccerEventStatusId.WaitingForExtratime]: {
    possibleNextStatuses: [SoccerIncidentId.ExtratimeFirstHalf],
    otherStatuses: sharedOtherStatuses,
  },
  [SoccerEventStatusId.ExtratimeFirstHalf]: {
    possibleNextStatuses: [SoccerIncidentId.ExtratimeHalftime],
    otherStatuses: sharedOtherStatuses,
  },
  [SoccerEventStatusId.ExtratimeHalftime]: {
    possibleNextStatuses: [SoccerIncidentId.ExtratimeSecondHalf],
    otherStatuses: sharedOtherStatuses,
  },
  [SoccerEventStatusId.ExtratimeSecondHalf]: {
    possibleNextStatuses: [
      {
        incidentId: SoccerIncidentId.WaitingForPenalty,
        description: 'Click if there will be a penalty shootout next',
      },
      SoccerIncidentId.FinishedAfterExtratime,
    ],
    otherStatuses: sharedOtherStatuses,
  },
  [SoccerEventStatusId.WaitingForPenalty]: {
    possibleNextStatuses: [SoccerIncidentId.PenaltyShootoutStarted],
    otherStatuses: sharedOtherStatuses,
  },
  [SoccerEventStatusId.PenaltyShootout]: {
    possibleNextStatuses: [SoccerIncidentId.FinishedAfterPenalties],
    otherStatuses: sharedOtherStatuses,
  },
}
