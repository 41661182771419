import { Directive, DirectiveBinding } from 'vue'

interface RequiredDirectiveBinding extends DirectiveBinding {
  value: {
    required: boolean
    hasValue: boolean
  }
}

const errorClass = 'border-error-5'
const successClass = 'border-success-5'

// Helper function to toggle classes
function updateClasses(
  el: HTMLElement,
  binding: RequiredDirectiveBinding,
): void {
  const { required, hasValue } = binding.value

  if (required) {
    if (hasValue) {
      el.classList.remove(errorClass)
      el.classList.add(successClass)
    } else {
      el.classList.remove(successClass)
      el.classList.add(errorClass)
    }
  }
}

export const vRequired: Directive = {
  beforeMount(el: HTMLElement, binding: RequiredDirectiveBinding) {
    updateClasses(el, binding)
  },
  updated(el: HTMLElement, binding: RequiredDirectiveBinding) {
    updateClasses(el, binding)
  },
}
