<script setup lang="ts">
import * as R from 'ramda'

import { Tag } from '@collector/shared-ui'

import { IncidentFilterSelect } from './types'

interface Props {
  incidentFilter: IncidentFilterSelect
}

defineProps<Props>()

// emits
defineEmits(['delete'])

// methods
function getOptionNames(incidentFilter: IncidentFilterSelect): string {
  const names = incidentFilter.options.reduce((acc, option) => {
    if (
      incidentFilter.value === option.value ||
      (Array.isArray(incidentFilter.value) &&
        R.includes(option.value, incidentFilter.value))
    ) {
      acc.push(option.name)
    }

    return acc
  }, [] as string[])

  return names.length > 1 ? `${names.length} selected` : names.join(', ')
}
</script>

<template>
  <Tag
    v-if="
      Array.isArray(incidentFilter.value)
        ? incidentFilter.value.length
        : incidentFilter.value !== undefined
    "
    @delete="$emit('delete')"
  >
    <span class="capitalize">
      {{ incidentFilter.name }}:
      {{ getOptionNames(incidentFilter) }}
    </span>
  </Tag>
</template>
