import { inject, InjectionKey, provide } from 'vue'

type ProvideInject<T> = [(value: T) => void, () => NonNullable<T>]

type NullableProvideInject<T> = [(value: T | null) => void, () => T | null]

export function injectStrict<T>(key: InjectionKey<T>): NonNullable<T> {
  const resolved = inject(key)

  if (!resolved) {
    throw Error(
      `(provide/inject) value not provided for injected key: ${key.description}`,
    )
  }

  return resolved
}

export function createInjectionKey<T>(key: string): InjectionKey<T> {
  return Symbol(key)
}

export function createProvideInject<T>(name: string): ProvideInject<T> {
  const key = createInjectionKey<T>(name)

  function provided(value: T): void {
    provide(key, value)
  }

  function injected(): NonNullable<T> {
    return injectStrict(key)
  }

  return [provided, injected]
}

/**
 * Allow passing null for inject which is optional
 * - explicitly use null for injects that you don't need in sport
 * - implicit undefined can cause error in case you forget to provide
 * - prevents vue warning for undefined inject
 */
export function createNullableProvideInject<T>(
  name: string,
): NullableProvideInject<T> {
  const key = createInjectionKey<T>(name)

  function provided(value: T | null): void {
    provide(key, value as T)
  }

  function injected(): T | null {
    const injectedValue = inject(key)

    return injectedValue === undefined ? null : injectedValue
  }

  return [provided, injected]
}
