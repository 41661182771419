<script setup lang="ts">
import { CommonResultId } from '@collector/sportsapi-client-legacy'
import { useParticipants, useSport } from '@desktop/views/Relation/composables'

// data
const { sport } = useSport()
const { getHomeParticipantResult, getAwayParticipantResult } = useParticipants()

const sportResults = sport.results
  .filter(
    (result) =>
      ![CommonResultId.Winner, CommonResultId.Progress].includes(
        result.id as CommonResultId,
      ),
  )
  .map((result) => ({
    id: result.id,
    name: result.name,
  }))
</script>

<template>
  <div class="flex max-h-24 flex-col flex-wrap justify-between">
    <div
      v-for="(result, index) in sportResults"
      :key="index"
      class="min-w-44"
    >
      <div
        :key="index"
        class="flex min-h-6 items-center justify-between rounded px-1"
        :class="{
          'bg-neutral-light-10': index % 2 === 0,
          'bg-neutral-light-9=8': index % 2 !== 0,
          'mr-2': index < sportResults.length / 2,
          'ml-2': index >= sportResults.length / 2,
        }"
      >
        <div class="text-xs">
          {{ result.name }}
        </div>
        <div class="flex min-w-20 justify-center gap-1 text-sm font-bold">
          <span>
            {{ getHomeParticipantResult(result.id) }}
          </span>
          <span>:</span>
          <span>
            {{ getAwayParticipantResult(result.id) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
