<script setup lang="ts">
import {
  getParticipantTeamSide,
  ParticipantLogo,
  ParticipantName,
} from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import { IncidentId, Participant } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@desktop/views/Relation/components/IncidentButton.vue'
import {
  useSwappableIncidents,
  useTeamSides,
} from '@desktop/views/Relation/composables'

interface Props {
  incident: Incident
  participant: Participant
  selectedIncidentId?: IncidentId
}

const props = defineProps<Props>()

// emits
type Emits = {
  select: [[participantId: Participant['id'], incidentId: IncidentId]]
}

defineEmits<Emits>()

// composables
const { getIncidentTeamSide } = useTeamSides()
const { swappableIncidents } = useSwappableIncidents()

// data
const teamSide = getParticipantTeamSide(props.participant)

// methods
function getSwappableIncidentsId(): IncidentId[] | undefined {
  const incidentTeamSide = getIncidentTeamSide(props.incident)

  if (!incidentTeamSide) {
    return
  }

  const config = swappableIncidents[props.incident.incident_id]

  return incidentTeamSide === teamSide ? config?.sameTeam : config?.rivalTeam
}
</script>

<template>
  <div class="@md:min-w-[22rem] flex min-w-[42vw] flex-col gap-y-3">
    <div class="flex items-center justify-center gap-x-2 text-sm font-bold">
      <ParticipantLogo :participant />
      <ParticipantName :participant />
    </div>
    <div class="flex gap-x-2">
      <IncidentButton
        v-for="incidentId in getSwappableIncidentsId()"
        :key="incidentId"
        class="w-full"
        size="lg"
        :incidentId
        :selected="selectedIncidentId === incidentId"
        :teamSide
        @click="$emit('select', [participant.id, incidentId])"
      >
        <template #default="{ incidentName }">
          <span class="@md:text-nowrap text-wrap text-xs leading-3">
            {{ incidentName }}
          </span>
        </template>
      </IncidentButton>
    </div>
  </div>
</template>
