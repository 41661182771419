<script setup lang="ts">
import { Separator } from '@collector/shared-ui'
import { getEventTime, ParticipantName } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import {
  useParticipants,
  useSportMaps,
  useTeamSides,
} from '@desktop/views/Relation/composables'

import IncidentContainer from '../IncidentContainer.vue'
import IncidentEventStatus from '../IncidentEventStatus.vue'
import IncidentTime from '../IncidentTime.vue'

interface Props {
  incident: Incident
}

const props = defineProps<Props>()

// composables
const { getIncidentTeamSide } = useTeamSides()
const { getParticipantById } = useParticipants()
const { getIncidentName } = useSportMaps()

// data
const incidentTeamSide = getIncidentTeamSide(props.incident)
const participant = getParticipantById(props.incident.participant_team_id)
</script>

<template>
  <IncidentContainer
    class="flex h-10 items-center px-3 py-2 text-sm"
    :incident
    :teamSide="incidentTeamSide"
  >
    <IncidentTime :incident />
    <Separator
      class="mx-4 h-full"
      vertical
    />
    <IncidentEventStatus :incident />

    <span class="mx-2 font-bold">
      {{ getEventTime(incident) }}
    </span>

    <Separator
      class="mx-4 h-full"
      vertical
    />

    <ParticipantName
      class="font-bold"
      :participant
    />

    <span class="ml-4 font-bold">
      {{ getIncidentName(incident.incident_id) }}
    </span>
  </IncidentContainer>
</template>
