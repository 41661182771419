<script setup lang="ts">
import { Button } from '@collector/shared-ui'
import { useTeamSides } from '@desktop/views/Relation/composables'

const { changeSides } = useTeamSides()
</script>

<template>
  <div class="p-5">
    <Button
      class="text-neutral-light-10 bg-neutral-3 border-neutral-3 [&>span]:hover:text-neutral-dark-0 hover:bg-neutral-9 hover:border-neutral-9 flex-row-reverse px-2 font-bold"
      width="full"
      size="md"
      icon="exchange"
      @click="changeSides"
    >
      <span>Change sides</span>
    </Button>
  </div>
</template>
