<script setup lang="ts">
import { watch } from 'vue'
import { useRoute } from 'vue-router'

import { LoginForm } from '@collector/desktop-feature-login-form'
import {
  ModalContainer,
  startLoader,
  stopLoader,
  useModalStack,
} from '@collector/shared-ui'

import Error from './components/Error.vue'
import { Env } from './envs'
import { useErrors } from './globalState/errors'
import { initLoggedIn, loggedIn, logIn } from './globalState/userAuth'

// composables
const route = useRoute()
useModalStack() // provide modal stack

// watchers
watch(loggedIn, (value) => {
  if (value !== null) {
    stopLoader()
  }
})

// init
initLoggedIn()

/**
 * temporary disable alerts on production until <Notification> component
 * is implemented to display less intrusive alerts
 */
if (Env.VITE_APP_MODE === 'production') {
  useErrors().disable()
}
</script>

<template>
  <div class="max-w-screen min-h-screen">
    <Error />
    <RouterView
      v-if="loggedIn"
      :key="route.fullPath"
      v-slot="{ Component }"
    >
      <Suspense
        @pending="startLoader"
        @resolve="stopLoader"
      >
        <component :is="Component" />
      </Suspense>
    </RouterView>
    <LoginForm
      v-else-if="loggedIn === false"
      :apiUrl="Env.VITE_SPORTS_API_URL"
      :appMode="Env.VITE_APP_MODE"
      @logIn="logIn"
    />
  </div>

  <ModalContainer />
</template>
