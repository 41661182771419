import { TeamSide } from '@collector/shared-ui-domain'

import { Database } from '..'
import { upsertTableItem } from '../utils'

export type EventSettingsRecord = {
  eventId?: number
  teamSideChanged?: boolean
  teamSidePossession?: TeamSide
}

export function upsert(
  eventId: number,
  eventSettings: EventSettingsRecord,
): Promise<void> {
  const database = Database.get()

  return upsertTableItem(
    database,
    Database.Tables.EVENT_SETTINGS,
    eventId,
    eventSettings,
  )
}

export function get(eventId: number): Promise<EventSettingsRecord | undefined> {
  const database = Database.get()
  const table = database.table(Database.Tables.EVENT_SETTINGS)

  return table.get(eventId)
}
