import {
  Callback,
  setSelfAdjustingInterval,
  StopInterval,
} from '@collector/shared-utils'

type Timer = {
  startTimer: (callback: Callback) => void
  stopTimer: () => void
}

export function createTimer(): Timer {
  let stopInterval: StopInterval | null = null

  function startTimer(callback: Callback): void {
    stopInterval = setSelfAdjustingInterval(callback, 1000)
  }

  function stopTimer(): void {
    if (stopInterval) {
      stopInterval()
    }
  }

  return {
    startTimer,
    stopTimer,
  }
}
