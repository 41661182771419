import { readonly, Ref, ref } from 'vue'

import Sentry from '@collector/shared-plugin-sentry-vue'
import { isHttpError } from '@collector/shared-utils'
import {
  AuthRefresh,
  createSportsApiClient,
  getAuthorizationHeader,
  HttpClient,
  SportsApiClient,
} from '@collector/sportsapi-client'
import {
  SportsApiAccessToken,
  SportsApiRefreshToken,
} from '@desktop/utils/localStorage'

import * as AuthInfo from '../authInfo'
import {
  clearSportsApiClient,
  setSportsApiClient,
  sportsApiClient,
} from '../sportsApiClient'
import { emitOnNewAccessToken } from './emitter'
import { reauthenticateBeforeTokenExpiration } from './reauthenticate'
import { clearSportsApiTokens, createAuthClient } from './utils'

// initial state of logged user is unknown
const _loggedIn: Ref<null | boolean> = ref(null)

export const loggedIn = readonly(_loggedIn)

/**
 * Login user with new tokens
 */
export async function logIn(
  accessToken: string,
  refreshToken: string,
): Promise<void> {
  initTokens(accessToken, refreshToken)

  const authInfo = (await sportsApiClient.AuthInfo.get()).api.data
  AuthInfo.init(authInfo)

  _loggedIn.value = true
}

/**
 * Check if user is still logged in, update loggedIn.value
 */
export async function initLoggedIn(): Promise<void> {
  const accessToken = SportsApiAccessToken.get()

  if (accessToken) {
    const authClient = createAuthClient(accessToken)

    try {
      // check if auth token is still valid - before setting up global sportsApiClient
      //
      // 200 - valid
      // 400 - malformed
      // 401 - expired
      const authInfo = (await createSportsApiClient(authClient).AuthInfo.get())
        .api.data

      setupSportsApiClient(authClient)
      AuthInfo.init(authInfo)

      _loggedIn.value = true
    } catch (error) {
      if (isHttpError(error, 401)) {
        const loggedIn = await reauthenticateSportsApiClient()
        _loggedIn.value = loggedIn
      } else if (isHttpError(error, 400)) {
        clearSportsApiTokens()
        _loggedIn.value = false
      } else {
        throw error
      }
    }
  } else {
    _loggedIn.value = false
  }
}

/**
 * Logout user, delete sportsAPI tokens
 */
export function logOut(): void {
  _loggedIn.value = false

  Sentry.setUser(null)
  clearSportsApiClient()
  AuthInfo.clear()
  clearSportsApiTokens()

  // eslint-disable-next-line no-console
  console.log('User logged out')
}

/**
 * Try to reauthenticate user with refresh token
 *
 * @returns true if reauthentication was successful
 */
export async function reauthenticateSportsApiClient(): Promise<boolean> {
  const accessToken = SportsApiAccessToken.get()
  const refreshToken = SportsApiRefreshToken.get()

  if (accessToken && refreshToken) {
    const authClient = createAuthClient(refreshToken)

    try {
      const response = await AuthRefresh.post(authClient, {
        access_token: accessToken,
      })

      initTokens(response.api.data.token, response.api.data.refresh_token)

      return true
    } catch (error) {
      // refreshing access_token with refresh_token failed
      if (isHttpError(error, 401)) {
        logOut()
      } else {
        logOut()

        // unexpected error from API
        throw error
      }
    }
  } else {
    logOut()
  }

  return false
}

/**
 * Extend http client with error handler for 401 status code,
 * which tries to reauthenticate user and replay unauthenticated request
 */
export function withAuthorizationErrorHandler(
  httpClient: HttpClient,
): SportsApiClient {
  const httpClientWithHandler = httpClient.catcher(
    401,
    async (_error, request) => {
      const loggedIn = await reauthenticateSportsApiClient()
      if (!loggedIn) {
        return
      }

      const accessToken = SportsApiAccessToken.get()
      if (!accessToken) {
        throw Error('Access token not found')
      }

      emitOnNewAccessToken(accessToken)

      // Replay the original request with new token
      return request
        .auth(getAuthorizationHeader(accessToken))
        .fetch()
        .unauthorized((err) => {
          throw err // if still 401, reject Promise to prevent infinite loop of replayed requests
        })
        .json()
    },
  )

  const sportsApiClient = createSportsApiClient(httpClientWithHandler)

  return sportsApiClient
}

/**
 * init sportsAPI client and set it as global client for further requests,
 * then set timeout to reauthenticate user before access token expiration
 */
function setupSportsApiClient(authClient: HttpClient): void {
  setSportsApiClient(withAuthorizationErrorHandler(authClient))

  reauthenticateBeforeTokenExpiration(async () => {
    await reauthenticateSportsApiClient()

    const accessToken = SportsApiAccessToken.get()
    if (accessToken) {
      emitOnNewAccessToken(accessToken)
    }
  })
}

function initSportsApiClient(accessToken: string): void {
  const authClient = createAuthClient(accessToken)

  setupSportsApiClient(authClient)
}

/**
 * create sportsAPI client with new tokens, save tokens in browser storage
 */
function initTokens(accessToken: string, refreshToken: string): void {
  initSportsApiClient(accessToken)

  SportsApiAccessToken.save(accessToken)
  SportsApiRefreshToken.save(refreshToken)
}
