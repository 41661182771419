import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'

import { IncidentIdsGroup } from '../../../configuration'

export const incidentIdsGroup: IncidentIdsGroup = {
  name: 'Substitution',
  incidentIds: [
    SoccerIncidentId.SubstitutionOut,
    SoccerIncidentId.SubstitutionIn,
  ],
}
