<script setup lang="ts">
import { useParticipants } from '@desktop/views/Relation/composables'

const { homeParticipant, awayParticipant } = useParticipants()
</script>

<template>
  <div class="px-2 text-2xl font-bold">
    {{ homeParticipant.short_name }} - {{ awayParticipant.short_name }}
  </div>
</template>
