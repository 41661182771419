import { Ref } from 'vue'

import { RabbitIncidentMessage } from '@collector/queue-gateway-shared-types'
import { createProvideInject } from '@collector/shared-ui'
import { Incident } from '@collector/sportsapi-client'

import { IncidentIdIncidentIndexMap } from '../utils'

export const [provideIncidents, injectIncidents] =
  createProvideInject<Ref<Incident[]>>('incidents')

export const [provideIncidentsUpdate, injectIncidentsUpdate] =
  createProvideInject<Ref<RabbitIncidentMessage[]>>('incidentsUpdate')

export const [
  provideIncidentIdIncidentIndexMap,
  injectIncidentIdIncidentIndexMap,
] = createProvideInject<IncidentIdIncidentIndexMap>(
  'incidentIdIncidentIndexMap',
)
