<script setup lang="ts">
import { computed } from 'vue'

import { getParticipantTeamSide } from '@collector/shared-ui-domain'
import {
  Participant,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import PitchIncidentButton from '@desktop/views/Relation/Sports/components/PitchIncidentButton.vue'
import PitchTeamName from '@desktop/views/Relation/Sports/components/PitchTeamName.vue'

interface Props {
  participant: Participant
}

const props = defineProps<Props>()

// computed
const teamSide = computed(() => getParticipantTeamSide(props.participant))

const penaltyPosition = computed(() =>
  teamSide.value === 'home' ? { x: 90, y: 50 } : { x: 10, y: 50 },
)
</script>

<template>
  <div class="flex flex-col gap-y-2">
    <PitchTeamName
      :teamSide
      :participant
    />
    <div class="grid grid-cols-6 gap-x-0.5 gap-y-[0.165rem]">
      <PitchIncidentButton
        class="col-span-6"
        :teamSide
        :incidentId="SoccerIncidentId.Goal"
        horizontal
        tbd
        dir="ltr"
      />
      <PitchIncidentButton
        class="col-span-3 row-start-2"
        :teamSide
        :incidentId="SoccerIncidentId.Penalty"
        :position="penaltyPosition"
        tbd
      />
      <PitchIncidentButton
        class="col-span-3 col-start-4 row-start-2"
        :teamSide
        :incidentId="SoccerIncidentId.Corner"
        tbd
      />
      <PitchIncidentButton
        class="col-span-3 row-start-3"
        :teamSide
        :incidentId="SoccerIncidentId.YellowCard"
        tbd
      />
      <PitchIncidentButton
        class="col-span-3 col-start-4 row-start-3"
        :teamSide
        :incidentId="SoccerIncidentId.RedCard"
        tbd
      />
      <PitchIncidentButton
        class="col-span-3 row-start-4"
        :teamSide
        :incidentId="SoccerIncidentId.ShotOnTarget"
      />
      <PitchIncidentButton
        class="col-span-3 col-start-4 row-start-4"
        :teamSide
        :incidentId="SoccerIncidentId.ShotOffTarget"
      />
      <PitchIncidentButton
        class="col-span-3 row-start-5"
        :teamSide
        :incidentId="SoccerIncidentId.ShotBlocked"
      />
      <PitchIncidentButton
        class="col-span-3 col-start-4 row-start-5"
        :teamSide
        :incidentId="SoccerIncidentId.ThrowIn"
      />
      <PitchIncidentButton
        class="col-span-3 row-start-6"
        :teamSide
        :incidentId="SoccerIncidentId.Offside"
      />
      <PitchIncidentButton
        class="col-span-3 col-start-4 row-start-6"
        :teamSide
        :incidentId="SoccerIncidentId.Foul"
      />
      <PitchIncidentButton
        class="col-span-3 row-start-7"
        :teamSide
        :incidentId="SoccerIncidentId.PenaltySavedByGoalkeeper"
        name="Penalty saved"
      />
      <PitchIncidentButton
        class="col-span-3 col-start-4 row-start-7"
        :teamSide
        :incidentId="SoccerIncidentId.MissedPenalty"
        name="Penalty missed"
      />
      <PitchIncidentButton
        class="col-span-2 row-start-8"
        :teamSide
        :incidentId="SoccerIncidentId.Injury"
      />
      <PitchIncidentButton
        class="col-span-2 col-start-3 row-start-8"
        :teamSide
        :incidentId="SoccerIncidentId.SubstitutionIn"
        name="Subs"
      />
      <PitchIncidentButton
        class="col-span-2 col-start-5 row-start-8"
        :teamSide
        :incidentId="SoccerIncidentId.GoalKick"
      />
    </div>
  </div>
</template>
