import {
  AddIncident,
  AddIncidentPayload,
  DeleteIncident,
  QueueIncidentError,
  QueueIncidentStatus,
  UpdateIncident,
  UpdateIncidentPayload,
} from '../incidents'
import { QueueIncidentType } from '../incidents/types'
import { QueueIncident } from '../types'

/**
 * Object representing data parsed from stringified QueueIncident
 */
export type RawQueueIncident = {
  type: QueueIncidentType
  eventId: number
  payload?: Record<string, unknown>
  id?: number
  uuid?: string
  status: QueueIncidentStatus
  time: number
  error: QueueIncidentError
}

/**
 * Convert raw queue incidents to queue incidents, which can be passed to EventIncidentsQueue to restore queue state
 */
export function parseRawQueueIncidents(
  rawQueueIncidents: RawQueueIncident[],
): QueueIncident[] {
  const queueIncidents = rawQueueIncidents.map((incident: RawQueueIncident) => {
    switch (incident.type) {
      case 'Add':
        return new AddIncident(
          incident.eventId,
          incident.payload as AddIncidentPayload,
          incident.uuid!,
          incident.status,
          incident.time,
          incident.error,
        )

      case 'Update':
        return new UpdateIncident(
          incident.eventId,
          incident.payload as UpdateIncidentPayload,
          incident.id!,
          incident.status,
          incident.time,
          incident.error,
        )

      case 'Delete':
        return new DeleteIncident(
          incident.eventId,
          incident.id!,
          incident.status,
          incident.time,
          incident.error,
        )

      default:
        throw new Error(`incident type ${incident.type} not implemented`)
    }
  })

  return queueIncidents
}
