<template>
  <div
    class="bg-neutral-light-7 grid h-full w-full grid-cols-2 overflow-hidden"
  >
    <div class="stadium col-start-2 row-span-2">
      <div
        class="track transform portrait:translate-x-1/2 portrait:translate-y-1/2 landscape:translate-x-1/2 landscape:translate-y-1/3"
      >
        <div class="lane lane1 border-8" />
        <div class="lane lane2 border-8" />
        <div class="lane lane3 border-8" />
        <div class="lane lane4 border-8" />
        <div class="lane lane5 border-8" />
      </div>
    </div>
    <div class="stadium row-span-2 row-start-2 justify-self-end">
      <div
        class="track landscape-xs:translate-y-1/4 portrait:@md:translate-y-3/4 transform portrait:translate-x-1/2 portrait:translate-y-1/2 landscape:translate-x-1/2 landscape:translate-y-1/2"
      >
        <div class="lane lane1 border-2" />
        <div class="lane lane2 border-2" />
        <div class="lane lane3 border-2" />
        <div class="lane lane4 border-2" />
        <div class="lane lane5 border-2" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stadium {
  overflow: hidden;
  width: 53.75rem;
  height: 40rem;
}

.track {
  width: 100%;
  height: 100%;
  border-radius: 200px;
}

.lane {
  position: absolute;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  border-radius: 12.5rem;
  @apply border-neutral-light-6 border-solid;
}

.lane1 {
  width: 97%;
  height: 69%;
}

.lane2 {
  width: 91%;
  height: 60%;
}

.lane3 {
  width: 84%;
  height: 49%;
}

.lane4 {
  width: 77%;
  height: 39%;
}

.lane5 {
  width: 70%;
  height: 29%;
}
</style>
