import { SportsApiClient } from '@collector/sportsapi-client'

let _sportsApiClient: SportsApiClient | null

const sportsApiClientHandler = {
  get(_target: SportsApiClient, prop: keyof SportsApiClient) {
    if (!_sportsApiClient) {
      throw Error('sportsApiClient is not initialized')
    }
    return _sportsApiClient[prop]
  },
}

/*
 * Proxy to real _sportsApiClient object
 *
 * - throws error if _sportsApiClient is not initialized
 * - when replacing _sportsApiClient with new authenticated client,
 *   exported sportsApiClient still points to the same object
 */
const sportsApiClient = new Proxy({} as SportsApiClient, sportsApiClientHandler)

/**
 * Set sportApi client for further requests
 */
export function setSportsApiClient(value: SportsApiClient): void {
  _sportsApiClient = value
}

/**
 * Clear sportApi client if it's not longer able to make authorized requests
 */
export function clearSportsApiClient(): void {
  _sportsApiClient = null
}

export { sportsApiClient }
