import * as R from 'ramda'
import { Ref, ref, watch } from 'vue'

import {
  QueueIncident,
  UpdateIncident,
} from '@collector/desktop-feature-incidents-queue-core'
import { createReschedulableTimeout } from '@collector/shared-utils'
import { Incident } from '@collector/sportsapi-client'
import { useIncidents } from '@desktop/views/Relation/composables'
import { injectEventIncidentsQueue } from '@desktop/views/Relation/provide-inject'
import { injectHandyBarInfoAlwaysVisibleIncidents } from '@desktop/views/Relation/Sports/provide-inject'

import QueueDisplayedIncident from './QueueDisplayedIncident.vue'
import { DisplayedIncidentWithIds } from './types'
import { getDisplayedIncidentWithIds as _getDisplayedIncidentWithIds } from './utils'

type QueueDisplayedIncidentProps = InstanceType<
  typeof QueueDisplayedIncident
>['$props']

type Status = Ref<QueueDisplayedIncidentProps['status'] | null>

type UseLastDisplayedIncident = {
  lastDisplayedIncident: Ref<DisplayedIncidentWithIds | null>
  status: Status
}

export function useLastDisplayedIncident(): UseLastDisplayedIncident {
  // inject
  const eventIncidentsQueue = injectEventIncidentsQueue()
  const handyBarInfoAlwaysVisibleIncidents =
    injectHandyBarInfoAlwaysVisibleIncidents()

  // composables
  const { getIncident, incidentsUpdate } = useIncidents()
  const getDisplayedIncidentWithIds = _getDisplayedIncidentWithIds.bind(
    null,
    getIncident,
  )

  // state
  const lastDisplayedIncident: Ref<DisplayedIncidentWithIds | null> = ref(null)
  const status: Status = ref(null)

  // data
  const hideTimeoutMs = 5000

  const { clearScheduledTimeout: clearHideLastIncident, rescheduleTimeout } =
    createReschedulableTimeout(() => {
      lastDisplayedIncident.value = null
      status.value = null
    }, hideTimeoutMs)

  // methods
  function scheduleHideLastIncident(): void {
    rescheduleTimeout()
  }

  function isInHandyBarInfoAlwaysVisibleIncidents(
    incidentId: Incident['incident_id'],
  ): boolean {
    if (handyBarInfoAlwaysVisibleIncidents === null) return false

    return handyBarInfoAlwaysVisibleIncidents.has(incidentId)
  }

  function shouldShowLastDisplayedIncident(
    queueIncident: QueueIncident,
  ): boolean {
    if (queueIncident.type === 'Add') return true

    if (
      queueIncident instanceof UpdateIncident &&
      isInHandyBarInfoAlwaysVisibleIncidents(
        queueIncident.getPayload().incident_id,
      )
    ) {
      return !!queueIncident.getPayload().confirmation
    }

    return false
  }

  // init
  eventIncidentsQueue.subscribeOnChange(
    ({ queue, currentError, errorQueueIncidents }) => {
      const newQueueIncidentInProgress = queue.find(
        (queueIncident) =>
          queueIncident.status === 'In Progress' &&
          queueIncident.type !== 'Delete',
      )

      if (
        newQueueIncidentInProgress &&
        shouldShowLastDisplayedIncident(newQueueIncidentInProgress)
      ) {
        status.value = 'pending'
        lastDisplayedIncident.value = getDisplayedIncidentWithIds(
          newQueueIncidentInProgress,
        )

        // prevent hiding incident, scheduled by previous success incident
        clearHideLastIncident()

        return
      } else if (currentError) {
        status.value = 'error'
        lastDisplayedIncident.value = getDisplayedIncidentWithIds(
          R.last(errorQueueIncidents)!,
        )

        // keep new error incident on screen
        clearHideLastIncident()

        return
      } else if (lastDisplayedIncident.value && status.value === 'error') {
        // when deleting errored incidents on queue
        for (const queueIncident of errorQueueIncidents) {
          const displayedIncident = getDisplayedIncidentWithIds(queueIncident)

          if (
            (displayedIncident.uuid !== undefined &&
              displayedIncident.uuid === lastDisplayedIncident.value.uuid) ||
            (displayedIncident.id !== undefined &&
              displayedIncident.id === lastDisplayedIncident.value.id)
          ) {
            // incident with error is still on queue, do not hide last incident
            return
          }
        }

        lastDisplayedIncident.value = null
      } else if (lastDisplayedIncident.value && status.value === 'pending') {
        // 409 conflict (incident duplicate), but no connection to queue-gateway
        status.value = 'success'
        scheduleHideLastIncident()
      }
    },
  )

  // watchers
  watch(incidentsUpdate, (update) => {
    const { data: incidentUpdate } = R.last(update)!

    if (
      incidentUpdate.action === 'insert' ||
      (incidentUpdate.action === 'update' &&
        isInHandyBarInfoAlwaysVisibleIncidents(incidentUpdate.incident_id) &&
        incidentUpdate.confirmation_time === incidentUpdate.ut) // incident was confirmed / cancelled
    ) {
      status.value = 'success'

      // if incident was sent by other scout
      if (!lastDisplayedIncident.value) {
        lastDisplayedIncident.value = {
          incident_id: incidentUpdate.incident_id,
          minute: incidentUpdate.minute,
          second: incidentUpdate.second,
          participant_team_id: incidentUpdate.participant_team_id,
          category: incidentUpdate.category,
        }
      }

      scheduleHideLastIncident()
    }
  })

  return {
    lastDisplayedIncident,
    status,
  }
}
