export enum SportId {
  Basketball = 1,
  Volleyball = 2,
  IceHockey = 3,
  Tennis = 4,
  Soccer = 5,
  Handball = 6,
  Baseball = 7,
  AmericanFootball = 8,
  Bandy = 9,
  Floorball = 10,
  SkiJumping = 11,
  Biathlon = 12,
  Cycling = 13,
  Snooker = 14,
  Rugby = 15,
  Futsal = 16,
  Darts = 17,
  BeachSoccer = 18,
  BeachVolleyball = 19,
  AlpineSkiing = 20,
  SpeedSkating = 21,
  NordicCombined = 22,
  Luge = 23,
  CrossCcountry = 24,
  Bobsleigh = 25,
  Speedway = 26,
  Cricket = 27,
  Curling = 28,
  Badminton = 29,
  HorseRacing = 30,
  Snowboard = 31,
  Golf = 32,
  Waterpolo = 33,
  Pesepallo = 35,
  Formula1 = 36,
  Nascar = 37,
  MotoGP = 38,
  DTM = 39,
  GaaFootball = 41,
  GaaHurling = 42,
  Boxing = 43,
  MixedMartialArts = 44,
  AusFootball = 45,
  TableTennis = 46,
  Bowls = 47,
  FieldHockey = 48,
  FreestyleSkiing = 49,
  ShortTrack = 50,
  Skeleton = 51,
  FigureSkating = 52,
  Marathon = 53,
  Others = 54,
  Diving = 55,
  Swimming = 56,
  SynchronizedSwimming = 57,
  Archery = 58,
  Canoe = 59,
  Equestrian = 60,
  Fencing = 61,
  Gymnastics = 62,
  Judo = 63,
  ModernPentathlon = 64,
  Rowing = 65,
  Sailing = 66,
  Shooting = 67,
  Taekwondo = 68,
  Triathlon = 69,
  Weightlifting = 70,
  Wrestling = 71,
  Squash = 72,
  ESports = 73,
  Financials = 74,
  Chess = 75,
  CounterStrike = 76,
  LeagueOfLegends = 77,
  Dota2 = 78,
  Basketball3x3 = 80,
}
