import {
  AddIncidentPayload,
  DeleteIncident,
  UpdateIncidentPayload,
} from '@collector/desktop-feature-incidents-queue-core'
import {
  resolveOppositeParticipant,
  resolveParticipant,
} from '@collector/shared-ui-domain'
import { Incident, Participant } from '@collector/sportsapi-client'

import { useEvent, useParticipants } from '../../composables'
import { injectEventIncidentsQueue } from '../../provide-inject'
import { injectIncidentsQueuePayloadConfig } from '../provide-inject'
import { useEventClock } from './useEventClock'

export type AddIncidentPayloadWithOptionalTime = Omit<
  AddIncidentPayload,
  'minute' | 'second'
> &
  Partial<Pick<AddIncidentPayload, 'minute' | 'second'>>

type UseEventIncidentsQueue = {
  addIncident(payload: AddIncidentPayloadWithOptionalTime): void
  updateIncident(
    payload: Omit<UpdateIncidentPayload, 'incident_id'>,
    incident: Pick<Incident, 'id' | 'incident_id'>,
  ): void
  deleteIncident(id: DeleteIncident['id']): void
}

export function useEventIncidentsQueue(): UseEventIncidentsQueue {
  const eventIncidentsQueue = injectEventIncidentsQueue()
  const incidentsQueuePayloadConfig = injectIncidentsQueuePayloadConfig()

  const { event } = useEvent()
  const { eventTime } = useEventClock()
  const { homeParticipant, awayParticipant } = useParticipants()

  function resolveParticipants(participantTeamId: number | undefined | null): {
    participant: Participant
    oppositeParticipant: Participant
  } {
    return {
      participant: resolveParticipant(
        participantTeamId,
        homeParticipant.value,
        awayParticipant.value,
      ),
      oppositeParticipant: resolveOppositeParticipant(
        participantTeamId,
        homeParticipant.value,
        awayParticipant.value,
      ),
    }
  }

  /**
   * Add incident payload on queue to create incident, with:
   * - current event time
   * - current event status
   * - optionally using incidentsQueuePayloadConfig for specific incidentId
   */
  function addIncident(payload: AddIncidentPayloadWithOptionalTime): void {
    const enhancedPayload: AddIncidentPayload = {
      status_id: event.value.status_id,
      ...eventTime.value,
      ...payload,
    }

    const addIncident =
      incidentsQueuePayloadConfig?.[payload.incident_id]?.addIncident

    if (addIncident) {
      addIncident(
        enhancedPayload,
        eventIncidentsQueue,
        resolveParticipants(payload.participant_team_id),
      )
    } else {
      eventIncidentsQueue.addIncident(enhancedPayload)
    }
  }

  /**
   * Add incident payload on queue to update incident, with:
   * - current event status
   * - optionally using incidentsQueuePayloadConfig for specific incidentId
   */
  function updateIncident(
    payload: Omit<UpdateIncidentPayload, 'incident_id'>,
    incident: Pick<Incident, 'id' | 'incident_id' | 'participant_team_id'>,
  ): void {
    const enhancedPayload: UpdateIncidentPayload = {
      incident_id: incident.incident_id,
      status_id: event.value.status_id,
      ...payload,
    }

    const updateIncident =
      incidentsQueuePayloadConfig?.[incident.incident_id]?.updateIncident

    if (updateIncident) {
      updateIncident(
        enhancedPayload,
        incident.id,
        eventIncidentsQueue,
        resolveParticipants(incident.participant_team_id),
      )
    } else {
      eventIncidentsQueue.updateIncident(enhancedPayload, incident.id)
    }
  }

  return {
    addIncident,
    updateIncident,
    deleteIncident:
      eventIncidentsQueue.deleteIncident.bind(eventIncidentsQueue),
  }
}
