<script setup lang="ts">
import { ref } from 'vue'

import { SportIconName } from './types'

interface Props {
  name: SportIconName
  size?: '2xs' | '3xs'
}

const props = withDefaults(defineProps<Props>(), { size: '2xs' })

// data
const sportIconsBaseBucketURL =
  'https://lmp-s3-cdn.statscore.com/LSP/clients/default/template1/icons/'

// state
const sportIconURL = ref(`${sportIconsBaseBucketURL}${props.name}`)

// methods
function changeToPlaceholderIcon(): void {
  sportIconURL.value = `${sportIconsBaseBucketURL}info.svg`
}
</script>

<template>
  <img
    :class="{
      'h-5 w-5': size === '2xs',
      'h-3.5 w-3.5': size === '3xs',
    }"
    :src="sportIconURL"
    @error="changeToPlaceholderIcon"
  />
</template>
