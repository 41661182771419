<script setup lang="ts" generic="T extends unknown">
import Group from '../../components/Group/Group.vue'
import Button from '../Button/Button.vue'

// props
interface Props {
  options: T[]
  withSeparator?: boolean
  withBorder?: boolean
  variant?: 'default' | 'flat'
  width?: 'full'
}

withDefaults(defineProps<Props>(), {
  variant: 'default',
})

// data
const selectedIndex = defineModel<number>()

// methods
function selectIndex(index: number): void {
  selectedIndex.value = index
}
</script>

<template>
  <div
    v-if="options.length"
    class="flex overflow-hidden"
    :class="{
      'space-x-0.5': withSeparator && options.length === 2,
      'bg-neutral-light-8':
        withSeparator && selectedIndex === undefined && options.length === 2,
      'bg-transparent':
        withSeparator && selectedIndex !== undefined && options.length === 2,
      'shadow-simple-shadow rounded': variant === 'default',
    }"
  >
    <Group
      :optionsCount="options.length"
      :selectedOption="
        selectedIndex === undefined ? undefined : selectedIndex + 1
      "
    >
      <template #default="{ option, selected }">
        <div
          class="text-sm [&>*]:w-full"
          :class="{
            '[&>*]:border-none': !withBorder,
            'w-full': width === 'full',
          }"
          @click="selectIndex(option - 1)"
        >
          <slot
            :option="options[option - 1]"
            :selected
          >
            <Button
              class="cursor-pointer"
              :class="{
                'bg-neutral-light-2 text-neutral-dark-0': selected,
                'bg-neutral-light-10 text-neutral-dark-2': !selected,
                'rounded-r-none': selected && option === 1,
                'rounded-l-none': selected && option === options.length,
                'rounded-r-none border-r-0':
                  !selected && withBorder && option === 1,
                'rounded-l-none border-l-0':
                  !selected && withBorder && option === options.length,
                'rounded-none': !withBorder || variant === 'flat',
                'border-neutral-light-2 border-[1px]': withBorder,
                'font-bold':
                  variant === 'flat' || (variant === 'default' && selected),
              }"
              size="md"
            >
              {{ options[option - 1] }}
            </Button>
          </slot>
        </div>
      </template>
    </Group>
  </div>
</template>
