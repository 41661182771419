import { Component, inject, InjectionKey, provide, Ref, ref } from 'vue'

const modalStackInjectKey = Symbol('modalStackInjectKey') as InjectionKey<
  Ref<Set<Component>>
>

export function useModalStack(): Ref<Set<Component>> {
  let stack = inject(modalStackInjectKey, null)
  if (stack === null) provide(modalStackInjectKey, (stack = ref(new Set())))
  return stack
}
