<script setup lang="ts">
import { Button } from '@collector/shared-ui'
import { useTeamSides } from '@desktop/views/Relation/composables'

const { changeSides } = useTeamSides()
</script>

<template>
  <Button
    class="bg-neutral-light-7 border-neutral-light-2 hover:bg-neutral-light-2 text-neutral-dark-0 flex-row-reverse gap-x-2 font-bold"
    icon="exchange"
    size="xs"
    @click="changeSides"
  >
    Change sides
  </Button>
</template>
