import { Ref, ref } from 'vue'

type UseStatistics = {
  statsVisible: Ref<boolean>
}

const statsVisible = ref(false)

export function useStatistics(): UseStatistics {
  return {
    statsVisible,
  }
}
