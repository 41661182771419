import { ApiResponse, Sport, SportId } from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../httpClient'

export type SportGetByIdResponse = ApiResponse<{
  sport: Sport
}>

export async function getById(
  httpClient: HttpClient,
  sportId: SportId,
): Promise<SportGetByIdResponse> {
  return httpClient.url(`/sports/${sportId}`).get().json()
}
