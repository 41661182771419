<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { initFontAwesome } from './init'
import { IconProps } from './types'

initFontAwesome()

const props = defineProps<IconProps>()
</script>

<template>
  <FontAwesomeIcon v-bind="props" />
</template>
