import {
  IncidentAttribute,
  SportIncident,
} from '@collector/sportsapi-client-legacy'

export function getIncidentAttributeIdToAttributeMap(
  sportIncidents: SportIncident[],
): Record<string, IncidentAttribute> {
  return sportIncidents.reduce(
    (acc, sportIncident) => {
      for (const attribute of sportIncident.attributes) {
        acc[attribute.id] = attribute
      }

      return acc
    },
    {} as Record<IncidentAttribute['id'], IncidentAttribute>,
  )
}
