<script lang="ts" setup>
import { Ref, ref } from 'vue'

import {
  EventStatusOverlay,
  QueueErrorStatusOverlay,
} from '@collector/shared-ui'
import { EventStatusId } from '@collector/sportsapi-client-legacy'
import { useEvent, useSportMaps } from '@desktop/views/Relation/composables'
import { injectEventIncidentsQueue } from '@desktop/views/Relation/provide-inject'
import { injectPitchOverlaySupportedStatuses } from '@desktop/views/Relation/Sports/provide-inject/pitchOverlaySupportedStatuses'

// composables
const { event } = useEvent()
const { eventStatusToSportStatusMap } = useSportMaps()

// state
const queueErrored: Ref<boolean> = ref(false)

// inject
const eventIncidentsQueue = injectEventIncidentsQueue()

// subscribe
eventIncidentsQueue.subscribeOnChange(({ errorQueueIncidents }) => {
  queueErrored.value = !!(errorQueueIncidents.length > 0)
})

// data
const supportedStatuses = injectPitchOverlaySupportedStatuses()

// methods
function getStatusName(statusId: EventStatusId): string {
  return eventStatusToSportStatusMap[statusId].name
}
</script>

<template>
  <div>
    <QueueErrorStatusOverlay
      v-if="queueErrored"
      :class="$attrs.class"
    />
    <template v-else>
      <template
        v-for="(status, index) in supportedStatuses"
        :key="`event-status-overlay-${index}`"
      >
        <EventStatusOverlay
          v-if="event.status_id === status"
          :class="$attrs.class"
        >
          {{ getStatusName(status) }}
        </EventStatusOverlay>
      </template>
    </template>
  </div>
</template>
