<script lang="ts" setup>
import * as R from 'ramda'
import { computed, ref, watch } from 'vue'

import { Button, ButtonGroup } from '@collector/shared-ui'
import { ParticipantLogo } from '@collector/shared-ui-domain'
import {
  Stat,
  StatId,
  SubParticipant,
} from '@collector/sportsapi-client-legacy'
import {
  useParticipants,
  useSport,
  useSubParticipants,
} from '@desktop/views/Relation/composables'

import { injectStatisticsConfig } from '../../provide-inject'
import Table from './Table/Table.vue'
import { Column, Data } from './Table/types'

// composables
const { sport } = useSport()
const { awayParticipant, homeParticipant } = useParticipants()
const {
  awaySubParticipants,
  homeSubParticipants,
  homeSubParticipantsStatsMap,
  awaySubParticipantsStatsMap,
} = useSubParticipants()
const statisticsConfig = injectStatisticsConfig()

// state
const selectedOptionIndex = ref(0)

const playerStats = statisticsConfig.player.map((x) =>
  sport.stats.person.find((stat) => stat.id === x),
)

const homePlayersStats = computed(() =>
  getDataForPlayers(
    homeSubParticipants.value,
    homeSubParticipantsStatsMap.value,
  ),
)

const awayPlayersStats = computed(() =>
  getDataForPlayers(
    awaySubParticipants.value,
    awaySubParticipantsStatsMap.value,
  ),
)

const homeEditedData = ref<Data[][]>(R.clone(homePlayersStats.value))
const awayEditedData = ref<Data[][]>(R.clone(awayPlayersStats.value))

// watches
watch(homePlayersStats, (newVal) => {
  homeEditedData.value = R.clone(newVal)
})

watch(awayPlayersStats, (newVal) => {
  awayEditedData.value = R.clone(newVal)
})

const columns = ref<Column[]>([
  {
    dataField: 'shirt_nr',
    name: '#',
    sortable: true,
    sortDirection: 'asc',
  },
  {
    dataField: 'short_name',
    name: 'Player name',
    sortable: true,
    sortDirection: 'asc',
  },
  ...playerStats.map((x) => {
    return {
      dataField: x?.code?.toString() ?? '',
      name: x?.name ?? '',
      sortable: true,
      sortDirection: 'asc',
    } as Column
  }),
])

// methods
function getStatValue(
  statsMap: Record<string | number, Record<StatId, Stat>>,
  playerKey: number | string,
  statId: StatId | undefined,
): string {
  if (statId === undefined) {
    return ''
  }
  const player = statsMap[playerKey]

  if (!player) {
    return ''
  }

  return player[statId]?.value?.toString() ?? '0'
}

function getDataForPlayers(
  players: SubParticipant[],
  statsMap: Record<string | number, Record<StatId, Stat>>,
): Data[][] {
  return players.map((x) => [
    {
      value: x?.shirt_nr?.toString() || '',
      name: 'shirt_nr',
      dataType: 'text',
      isDirty: false,
    },
    {
      value: x?.short_name ?? '',
      name: 'short_name',
      dataType: 'text',
      isDirty: false,
    },
    ...playerStats.map((stat) => ({
      value: getStatValue(statsMap, x.id || x.short_name, stat?.id),
      name: stat?.code ?? '',
      id: stat?.id ?? 0,
      dataType: stat?.data_type ?? '',
      isDirty: false,
    })),
  ])
}
</script>

<template>
  <div class="flex flex-grow px-4">
    <div class="flex w-full flex-grow flex-col">
      <ButtonGroup
        v-model="selectedOptionIndex"
        class="mb-4"
        :options="[homeParticipant, awayParticipant]"
        withBorder
        width="full"
      >
        <template #default="{ option, selected }">
          <Button
            :class="{
              'bg-team-home-5 border-team-home-5 font-bold':
                option === homeParticipant && selected,
              'flex-row-reverse': option === awayParticipant,
              'bg-team-away-5 border-team-away-5 font-bold':
                option === awayParticipant && selected,
              'rounded-r-none': option === homeParticipant,
              'rounded-l-none': option === awayParticipant,
            }"
            size="md"
            @click="selectedOptionIndex = option === homeParticipant ? 0 : 1"
          >
            <ParticipantLogo :participant="option" />
            {{ option.short_name }}
          </Button>
        </template>
      </ButtonGroup>
      <div class="overflow-y-hidden">
        <Table
          v-model:columns="columns"
          :data="
            selectedOptionIndex === 0 ? homePlayersStats : awayPlayersStats
          "
          :editedData="
            selectedOptionIndex === 0 ? homeEditedData : awayEditedData
          "
        />
      </div>
    </div>
    <slot :data="selectedOptionIndex === 0 ? homeEditedData : awayEditedData" />
  </div>
</template>
