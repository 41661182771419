import { cva, VariantProps } from 'class-variance-authority'

export { default as FlagChip } from './FlagChip.vue'

export const variants = {
  size: {
    xs: 'size-4 min-w-4 min-h-4',
    sm: 'size-6 min-w-6 min-h-6',
    md: 'size-8 min-w-8 min-h-8',
    lg: 'size-10 min-w-10 min-h-10',
  },
  rounded: {
    sm: 'rounded-sm',
    '': 'rounded',
    md: 'rounded-md',
    lg: 'rounded-lg',
  },
  variant: {
    fill: 'text-neutral-light-10',
    'fill-border':
      'text-neutral-light-10  border-[1px] border-neutral-light-10',
    outlined: 'border-[2px]',
  },
  color: { checked: '', unchecked: '', indeterminate: '' },
  interactive: { interactive: 'cursor-pointer' },
} as const

export const flagChipCva = cva(
  'inline-flex gap-1 items-center justify-center aspect-square transition-colors',
  {
    variants,
    compoundVariants: [
      // fill[-border]
      {
        variant: ['fill-border', 'fill'],
        color: 'checked',
        class: 'bg-success-3',
      },
      {
        variant: ['fill-border', 'fill'],
        color: 'unchecked',
        class: 'bg-error-2 hover:bg-error-3',
      },
      {
        variant: ['fill-border', 'fill'],
        color: 'indeterminate',
        class: 'bg-neutral-light-7',
      },

      // fill[-border] interactive
      {
        variant: ['fill-border', 'fill'],
        color: 'checked',
        interactive: 'interactive',
        class: 'hover:bg-success-4 active:bg-success-5',
      },
      {
        variant: ['fill-border', 'fill'],
        color: 'unchecked',
        interactive: 'interactive',
        class: 'hover:bg-error-4 active:hover:bg-error-5',
      },
      {
        variant: ['fill-border', 'fill'],
        color: 'indeterminate',
        interactive: 'interactive',
        class: 'hover:bg-neutral-light-6 active:hover:bg-neutral-light-5',
      },

      // outlined
      {
        variant: 'outlined',
        color: 'checked',
        class: 'border-success-3 text-success-3',
      },
      {
        variant: 'outlined',
        color: 'unchecked',
        class: 'border-error-3 text-error-3',
      },
      {
        variant: 'outlined',
        color: 'indeterminate',
        class: 'border-neutral-light-7 text-neutral-light-7',
      },
      // outlined interactive
      {
        variant: 'outlined',
        color: 'checked',
        interactive: 'interactive',
        class:
          'hover:border-success-4 hover:text-success-4 hover:bg-success-10 active:border-success-5 active:text-success-5 active:bg-success-10/50',
      },
      {
        variant: 'outlined',
        color: 'unchecked',
        interactive: 'interactive',
        class:
          'hover:border-error-4 hover:text-error-4 hover:bg-error-10 active:border-error-5 active:text-error-5 active:bg-error-10/50',
      },
      {
        variant: 'outlined',
        color: 'indeterminate',
        interactive: 'interactive',
        class:
          'hover:border-neutral-light-6 hover:text-neutral-light-6 hover:bg-neutral-light-9 active:border-neutral-light-5 active:text-neutral-light-5 active:bg-neutral-light-8',
      },
    ],
    defaultVariants: {
      variant: 'fill',
      rounded: '',
      size: 'md',
    },
  },
)
export type FlagChipProps = VariantProps<typeof flagChipCva>
