<script setup lang="ts">
import { Modal } from '@collector/shared-ui'
import { injectPocketIncidentsConfig } from '@desktop/views/Relation/Sports/provide-inject'

import PocketIncidentButton from './PocketIncidentButton.vue'

type Emits = { close: [] }
const emit = defineEmits<Emits>()

// data
const { morePocketIncidents } = injectPocketIncidentsConfig()
</script>

<template>
  <Modal
    title="More pocket incidents"
    @close="emit('close')"
  >
    <div class="grid grid-cols-2 grid-rows-2 gap-2">
      <PocketIncidentButton
        v-for="[incidentId, name] in morePocketIncidents"
        :key="incidentId"
        class="px-2"
        fontSize="text-xs"
        :incidentId
        @incidentSent="emit('close')"
      >
        {{ name }}
      </PocketIncidentButton>
    </div>
  </Modal>
</template>
