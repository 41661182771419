type Timeout = {
  clearScheduledTimeout(): void
  rescheduleTimeout(): void
}

/**
 * Create a wrapper for timeout that can be scheduled and cleared
 *
 * Useful for re-scheduling a timeout when a new event occurs, which avoids executing old timeout
 */
export function createReschedulableTimeout(
  callback: () => void,
  timeoutMs: number,
): Timeout {
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  function clearScheduledTimeout(): void {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
  }

  function rescheduleTimeout(): void {
    clearScheduledTimeout()

    timeoutId = setTimeout(callback, timeoutMs)
  }

  return {
    clearScheduledTimeout,
    rescheduleTimeout,
  }
}
