import wretch, { WretchError } from 'wretch'

/**
 *
 * ERR_INTERNET_DISCONNECTED
 */
export function isInternetDisconnectedError(error: unknown): boolean {
  return error instanceof Error && error.message === 'Failed to fetch'
}

export function isHttpError(
  error: unknown,
  status: number | number[],
): boolean {
  if (isWretchError(error)) {
    if (Array.isArray(status)) {
      return status.includes(error.status)
    } else {
      return status === error.status
    }
  }

  return false
}

export function isWretchError(error: unknown): error is WretchError {
  return error instanceof wretch.WretchError
}
