import { Directive } from 'vue'

type FocusableElement = HTMLInputElement | HTMLTextAreaElement

export const vAutofocus: Directive = {
  mounted: (el: FocusableElement, binding) => {
    if (binding && binding.value === true) {
      el.focus()
    }
  },
}
