type LocalStorage = {
  save: (value: string) => void
  get: () => string | null
  remove: () => void
}

export function createLocalStorage(key: string): LocalStorage {
  function save(value: string): void {
    localStorage.setItem(key, value)
  }

  function get(): string | null {
    return localStorage.getItem(key)
  }

  function remove(): void {
    localStorage.removeItem(key)
  }

  return {
    save,
    get,
    remove,
  }
}
