import { Integration } from '@sentry/types'
import * as Sentry from '@sentry/vue'

import { DefaultIntegrationsOptions } from './types'

export function getDefaultIntegrations(
  options: DefaultIntegrationsOptions,
): Integration[] {
  return [
    Sentry.browserTracingIntegration({ router: options.router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      minReplayDuration: 0,
      networkDetailAllowUrls: options.networkDetailAllowUrlsPattern,
    }),
    Sentry.extraErrorDataIntegration(),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [[500, 599]],
      failedRequestTargets: [/.*/],
      ...options,
    }),
  ]
}
