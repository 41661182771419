import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'

import { HandyBarInfoAlwaysVisibleIncidents } from '../../../configuration'

export const handyBarInfoAlwaysVisibleIncidents: HandyBarInfoAlwaysVisibleIncidents =
  new Set([
    SoccerIncidentId.Goal,
    SoccerIncidentId.YellowCard,
    SoccerIncidentId.RedCard,
    SoccerIncidentId.Corner,
    SoccerIncidentId.Penalty,
  ])
