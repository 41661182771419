import { Ref, ref } from 'vue'

const enabled: Ref<boolean> = ref(true)
const errors: Ref<unknown[]> = ref([])

export interface ErrorWithCause extends Error {
  cause: { type?: string }
}

type UseErrors = {
  enabled: Ref<boolean>
  errors: Ref<unknown[]>
  enqueueError: (error: unknown) => void
  peekError: () => unknown | undefined
  dequeueError: () => void
  enable: () => void
  disable: () => void
}

export function useErrors(): UseErrors {
  function enqueueError(error: unknown): void {
    if (!enabled.value) return
    errors.value.push(error)
  }

  function peekError(): unknown | undefined {
    return errors.value[0]
  }

  function dequeueError(): void {
    errors.value.shift()
  }

  function enable(): void {
    enabled.value = true
  }

  function disable(): void {
    enabled.value = false
  }

  return {
    enabled,
    errors,
    enqueueError,
    peekError,
    dequeueError,
    enable,
    disable,
  }
}
