<script setup lang="ts">
import { ref } from 'vue'

import { Button, TextInput } from '@collector/shared-ui'
import {
  isHttpError,
  isInternetDisconnectedError,
  isValidEmail,
} from '@collector/shared-utils'
import {
  AuthLogin,
  createHttpClient,
  getErrorMessage,
} from '@collector/sportsapi-client'

import Background from './Background.vue'
import InputLabel from './InputLabel.vue'
import LogoWithTitle from './LogoWithTitle.vue'

interface Props {
  apiUrl: string
  appMode: string
}

const props = defineProps<Props>()

// emits
type Emits = {
  logIn: [accessToken: string, refreshToken: string]
}

const emit = defineEmits<Emits>()

// data
const httpClient = createHttpClient(props.apiUrl)

type TextInputProps = InstanceType<typeof TextInput>['$props']
const textInputProps: TextInputProps = {
  size: 'sm',
  variant: 'light',
  width: 'full',
  inputClass: 'px-2',
}

// state
const email = ref('')
const hasEmailError = ref(false)

const password = ref('')
const hasPasswordError = ref(false)

const errorMessage = ref('')
const processing = ref(false)

// methods
async function onSubmit(): Promise<void> {
  /* reset */
  errorMessage.value = ''
  hasEmailError.value = false
  hasPasswordError.value = false
  processing.value = true

  try {
    const response = await AuthLogin.post(httpClient, {
      email: email.value,
      password: password.value,
    })

    emit('logIn', response.api.data.token, response.api.data.refresh_token)
  } catch (error) {
    const { message, emailError, passwordError } = getFormError(error)

    errorMessage.value = message
    hasEmailError.value = !!emailError
    hasPasswordError.value = !!passwordError

    if (
      !isHttpError(error, [400, 401]) &&
      !isInternetDisconnectedError(error)
    ) {
      throw error
    }
  } finally {
    processing.value = false
  }
}

function getFormError(error: unknown): {
  message: string
  emailError?: boolean
  passwordError?: boolean
} {
  switch (true) {
    case isHttpError(error, 400):
      if (!isValidEmail(email.value))
        return {
          message: getErrorMessage(error),
          emailError: true,
        }

      return {
        message: getErrorMessage(error),
        passwordError: password.value === '',
      }
    case isHttpError(error, 401):
      return {
        message: 'Unauthorized: your email and password do not match.',
        emailError: true,
        passwordError: true,
      }
    default:
      return { message: getErrorMessage(error) }
  }
}
</script>

<template>
  <div class="relative h-screen w-full">
    <Background class="absolute" />
    <div class="flex h-full w-full flex-col items-center justify-center">
      <form
        class="relative z-10 w-[25rem]"
        @submit.prevent="onSubmit"
      >
        <div class="absolute -mt-28 flex w-full flex-col items-center">
          <LogoWithTitle :appMode />
        </div>
        <TextInput
          v-model="email"
          v-bind="textInputProps"
          autofocus
          :withOutlineFocus="processing === false ? 'default' : undefined"
          :withError="hasEmailError"
        >
          <InputLabel>E-mail</InputLabel>
        </TextInput>

        <TextInput
          v-model="password"
          v-bind="textInputProps"
          class="mt-1"
          type="password"
          :withOutlineFocus="processing === false ? 'default' : undefined"
          :withError="hasPasswordError"
        >
          <InputLabel>Password</InputLabel>
        </TextInput>
        <div class="text-error-5 mt-2 min-h-4 text-center text-xs font-medium">
          {{ errorMessage }}
        </div>
        <Button
          class="bg-success-4 border-success-4 text-neutral-light-10 rounded-2lg mt-2 font-bold"
          width="full"
          :disabled="processing"
          type="submit"
          size="lg"
        >
          {{ processing ? 'Authorizing..' : 'LOGIN' }}
        </Button>
      </form>
      <img
        class="absolute bottom-4 h-10"
        src="../../assets/statscore.svg"
        alt="STATSCORE Logo"
      />
    </div>
  </div>
</template>
