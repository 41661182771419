import * as R from 'ramda'

import {
  AddIncident,
  DeleteIncident,
  QueueIncident,
  UpdateIncident,
  UpdateIncidentPayload,
} from '@collector/desktop-feature-incidents-queue-core'
import {
  getParticipantIdTeamSide,
  IsIncidentImportant,
  TeamSide,
} from '@collector/shared-ui-domain'
import { formatJSON } from '@collector/shared-utils'
import { Incident, Participant } from '@collector/sportsapi-client'
import { IncidentId, SportIncident } from '@collector/sportsapi-client-legacy'

export type ListItemData = {
  type: string
  incidentTitle: string
  incidentName: string
  displayedData: string
  teamName: string | null
  teamSide: TeamSide | null
  incidentImportant: boolean
}

function getUpdatedIncidentTitle(
  incident: Incident,
  payload: UpdateIncidentPayload,
): string {
  if (payload.minute !== undefined || payload.second !== undefined) {
    return 'Editing incident time'
  }

  if (R.any(R.isNotNil, [payload.participant_id, payload.participant_name])) {
    if (
      R.any(R.isNotNil, [incident.participant_id, incident.participant_name])
    ) {
      return 'Editing player name'
    }

    return 'Adding player name'
  }

  if (R.any(R.isNotNil, [payload.assistant_id, payload.assistant_name])) {
    if (R.any(R.isNotNil, [incident.assistant_id, incident.assistant_name])) {
      return 'Editing player name'
    }

    return 'Adding player name'
  }

  if (
    R.any(
      (value) => value === null,
      [
        payload.participant_id,
        payload.participant_name,
        payload.assistant_id,
        payload.assistant_name,
      ],
    )
  ) {
    return 'Deleting player name'
  }

  if (payload.attribute_ids) {
    if (payload.attribute_ids.length === 0) {
      return 'Deleting attributes'
    }

    if (!incident.attribute_ids || incident.attribute_ids.length === 0) {
      return 'Adding attributes'
    } else {
      return 'Editing attributes'
    }
  }

  return 'Updating'
}

export function getListItemKey(queueIncident: QueueIncident): string {
  if (queueIncident instanceof AddIncident) {
    return `${queueIncident.getUuid()}_${queueIncident.getTime()}`
  }

  return `${queueIncident.getId()}_${queueIncident.getTime()}`
}

export function getListItemData(
  queueIncident: QueueIncident,
  incident: Incident | undefined,
  isIncidentImportant: IsIncidentImportant,
  homeParticipant: Participant,
  awayParticipant: Participant,
  incidentIdToSportIncidentMap: Record<IncidentId, SportIncident>,
): ListItemData {
  function getParticipant(
    participantTeamId: number | undefined,
  ): Participant | null {
    if (participantTeamId === homeParticipant.id) {
      return homeParticipant
    }

    if (participantTeamId === awayParticipant.id) {
      return awayParticipant
    }

    return null
  }

  function getMetadata(
    incidentPayload: Pick<Incident, 'incident_id'>,
    participantTeamId: Incident['participant_team_id'] | undefined,
  ): Pick<ListItemData, 'teamName' | 'teamSide' | 'incidentImportant'> {
    const participant = getParticipant(participantTeamId)

    return {
      teamName: participant?.['short_name'] ?? null,
      teamSide: getParticipantIdTeamSide(
        participantTeamId,
        homeParticipant,
        awayParticipant,
      ),
      incidentImportant: isIncidentImportant(incidentPayload),
    }
  }

  if (queueIncident instanceof AddIncident) {
    return {
      type: 'Incident',
      incidentTitle: 'Adding',
      incidentName:
        incidentIdToSportIncidentMap[queueIncident.getPayload().incident_id]
          ?.name,
      displayedData: formatJSON(queueIncident.getPayload()),
      ...getMetadata(
        queueIncident.getPayload(),
        queueIncident.getPayload().participant_team_id,
      ),
    }
  }

  if (queueIncident instanceof UpdateIncident) {
    return {
      type: 'Update',
      incidentTitle: getUpdatedIncidentTitle(
        incident!,
        queueIncident.getPayload(),
      ),
      incidentName: `(${incidentIdToSportIncidentMap[incident!.incident_id]?.name})`,
      displayedData: formatJSON(queueIncident.getPayload()),
      ...getMetadata(incident!, incident?.participant_team_id),
    }
  }

  if (queueIncident instanceof DeleteIncident) {
    return {
      type: 'Delete',
      incidentTitle: 'Deleting',
      incidentName: incidentIdToSportIncidentMap[incident!.incident_id]?.name,
      displayedData: `id: ${queueIncident.getId()}`,
      ...getMetadata(incident!, incident?.participant_team_id),
    }
  }

  throw new Error(`Unsupported incident type ${queueIncident}`)
}
