<script lang="ts" setup>
import { createReschedulableTimeout } from '@collector/shared-utils'

import { useTimeout } from '../composables'
import Icon from './Icon/Icon.vue'
import TransitionFadeOut from './transitions/TransitionFadeOut.vue'

interface Props {
  title?: string
}

defineProps<Props>()

// data
const hideTimeoutMs = 5000
const reshowTimeoutMs = 150
const { ready: shouldShow, start: reshow } = useTimeout(reshowTimeoutMs)

// methods
const { rescheduleTimeout } = createReschedulableTimeout(() => {
  shouldShow.value = false
}, hideTimeoutMs)

function show(): void {
  if (shouldShow.value) {
    shouldShow.value = false
    reshow()
  } else {
    shouldShow.value = true
  }

  rescheduleTimeout()
}

// expose
defineExpose({ show })
</script>

<template>
  <TransitionFadeOut>
    <div
      v-if="shouldShow"
      class="px-2"
      :title
    >
      <Icon
        class="text-success-4"
        icon="check-circle"
      />
    </div>
  </TransitionFadeOut>
</template>
