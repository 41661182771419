import type {
  Event,
  IncidentsQueuePatchDTO,
  IncidentsQueuePatchResponse,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'
import { Incident } from '../incidents/get'

export type IncidentsQueuePatchPayload = IncidentsQueuePatchDTO & {
  action: 'update'
}

export type { IncidentsQueuePatchResponse }

/**
 * Update existing incident
 *
 * `/v2/events/{eventId}/incidents-queue/{incidentId}`
 */
export async function patch(
  httpClient: HttpClient,
  eventId: Event['id'],
  incidentId: Incident['id'],
  payload: { incident: IncidentsQueuePatchPayload },
): Promise<IncidentsQueuePatchResponse> {
  return httpClient
    .url(`/events/${eventId}/incidents-queue/${incidentId}`)
    .patch(payload)
    .json()
}
