<script setup lang="ts">
import { ref } from 'vue'
import { Collapse } from 'vue-collapsed'

import Box from '../../components/Box/Box.vue'
import Icon from '../Icon/Icon.vue'

interface Props {
  variant?: 'box' | 'header'
}

withDefaults(defineProps<Props>(), {
  variant: 'box',
})

// data
const expanded = ref(false)
</script>

<template>
  <component
    :is="variant === 'box' ? Box : 'div'"
    :class="{
      'px-0 py-0': variant === 'box',
      'bg-neutral-light-7 rounded-tl-lg rounded-tr-lg': variant === 'header',
      'rounded-bl-lg rounded-br-lg': variant === 'header' && expanded,
    }"
  >
    <div
      class="flex h-10 cursor-pointer items-center"
      :class="{
        'px-2': variant === 'box',
        'px-4': variant === 'header',
      }"
      @click="expanded = !expanded"
    >
      <div
        class="flex-1 select-none leading-6"
        :class="{
          'text-sm font-bold': variant === 'header',
          'text-lg': variant === 'box',
        }"
      >
        <slot
          name="title"
          :expanded
        />
      </div>
      <Icon
        class="pr-1"
        :icon="expanded ? 'chevron-up' : 'chevron-down'"
      />
    </div>

    <div
      v-if="!expanded && $slots.collapsed"
      :class="{
        'px-2 pb-2': variant === 'box',
      }"
    >
      <slot name="collapsed" />
    </div>

    <Collapse
      class="vue-collapsed cursor-default"
      :when="expanded"
    >
      <div
        :class="{
          'px-2 pb-2': variant === 'box',
        }"
      >
        <slot />
      </div>
    </Collapse>
  </component>
</template>

<style scoped>
.vue-collapsed[data-collapse='expanded'],
.vue-collapsed[data-collapse='expanding'] {
  opacity: 1;
  transition:
    height 400ms ease-in-out,
    opacity 250ms ease;
}

.vue-collapsed[data-collapse='collapsed'],
.vue-collapsed[data-collapse='collapsing'] {
  opacity: 0;
  transition:
    height 450ms ease-in-out,
    opacity 250ms ease;
}
</style>
