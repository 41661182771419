<script setup lang="ts">
import { computed } from 'vue'

import { getParticipantIdTeamSide } from '@collector/shared-ui-domain'
import { IncidentId, Participant } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@desktop/views/Relation/components/IncidentButton.vue'
import { useParticipants } from '@desktop/views/Relation/composables'

interface Props {
  incidentId: IncidentId
  selected: boolean
  participantId: Participant['id'] | null
}

const props = defineProps<Props>()

// emits
defineEmits(['click'])

// composables
const { homeParticipant, awayParticipant } = useParticipants()

// computed
const teamSide = computed(() =>
  getParticipantIdTeamSide(
    props.participantId,
    homeParticipant.value,
    awayParticipant.value,
  ),
)
</script>

<template>
  <IncidentButton
    class="@md:w-[11rem] !justify-start !text-wrap px-3"
    size="lg"
    :incidentId
    :teamSide
    :disabled="participantId === null"
    :selected
    width="full"
    @click="$emit('click')"
  >
    <template #default="{ incidentName }">
      <!-- TOOD: remove Substitution In depenedency-->
      <span class="text-left text-xs">
        {{ incidentName === 'Substitution in' ? 'Substitution' : incidentName }}
      </span>
    </template>
  </IncidentButton>
</template>
