<script setup lang="ts">
import { MultiSelect } from '@collector/shared-ui'
import {
  RelationStatus,
  relationStatuses,
} from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { useEvent, useEventScouts } from '@desktop/views/Relation/composables'

import { injectSuccessBadge } from './provide-inject/successBadge'

// inject
const { showSuccessBadge } = injectSuccessBadge()

// composables
const { event } = useEvent()
const { eventScouts } = useEventScouts()

// methods
async function updateValue(value: RelationStatus): Promise<void> {
  await sportsApiClient.Events.patch(event.value.id, {
    event: { scout_status: value },
  })

  showSuccessBadge()
}
</script>

<template>
  <MultiSelect
    class="w-28"
    :options="relationStatuses"
    :modelValue="event.relation_status"
    :disabled="eventScouts.length === 0"
    :withClear="false"
    @update:modelValue="updateValue($event as RelationStatus)"
  >
    Relation status
  </MultiSelect>
</template>
