import { AmericanFootballIncidentId } from './american-football/IncidentId'
import { BasketballIncidentId } from './basketball/IncidentId'
import { Basketball3x3IncidentId } from './basketball3x3/IncidentId'
import { BeachVolleyballIncidentId } from './beach-volleyball/IncidentId'
import { CricketIncidentId } from './cricket/IncidentId'
import { HandballIncidentId } from './handball/IncidentId'
import { IceHockeyIncidentId } from './ice-hockey/IncidentId'
import { SoccerIncidentId } from './soccer/IncidentId'
import { VolleyballIncidentId } from './volleyball/IncidentId'

export enum CommonIncidentId {
  TechnicalProblems = 1200,
  MatchResumed = 1001,
  Betstop = 1010,
  Betstart = 1000,
}

export type IncidentId =
  | CommonIncidentId
  | SoccerIncidentId
  | HandballIncidentId
  | VolleyballIncidentId
  | BasketballIncidentId
  | CricketIncidentId
  | BeachVolleyballIncidentId
  | AmericanFootballIncidentId
  | Basketball3x3IncidentId
  | IceHockeyIncidentId
