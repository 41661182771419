import type {
  Event,
  IncidentsQueuePostDTO,
  IncidentsQueuePostResponse,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type IncidentsQueuePostPayload = IncidentsQueuePostDTO

export type { IncidentsQueuePostResponse }

/**
 * Insert incident to queue
 *
 * `/v2/events/{eventId}/incidents-queue`
 */
export async function post(
  httpClient: HttpClient,
  eventId: Event['id'],
  payload: { incident: IncidentsQueuePostDTO; uuid: string },
): Promise<IncidentsQueuePostResponse> {
  return httpClient
    .url(`/events/${eventId}/incidents-queue`)
    .post(payload)
    .json()
}
